import { IFavoritesInfo } from "../actions/favoritesInfoActions";
import { createChannelState } from "../utils/createChannelState";

export interface IViewedProductAction {
  type: "SET_VIEWED" | "REMOVE_VIEWED" | "CLEAR_VIEWED",
  products: IFavoritesInfo[],
  channel?: string; // Added by middleware
}

// Dynamically generate the initial state for all channels
const initialState = createChannelState<IFavoritesInfo[] | null>(null);

const viewedProductReducer = (state = initialState, action: IViewedProductAction) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }

  switch (action.type) {
    case "SET_VIEWED":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.products, // Update only the specified channel's state
      };

    case "REMOVE_VIEWED":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.products, // Replace the product list for the channel
      };

    case "CLEAR_VIEWED":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state;
  }
}

export default viewedProductReducer; 
