import { createPortal } from "react-dom";
import styles from "./styles.module.scss";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { useEffect } from "react";

interface IModalBox extends Required<React.PropsWithChildren> {
  isOpen: boolean,
  onClose: (isOpen: false) => void,
  backgroundClassName?: string,
  className?: string,
  customЬщф?: string,
}

export function ModalBox({ isOpen, children, onClose, backgroundClassName, className }: IModalBox) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }

    return () => {
      document.body.style.overflow = '';
    };
  }, [isOpen]);

  return <>
    {isOpen &&
      createPortal(
        <>
          <div
            className={styles.modalBackground + ` ${backgroundClassName}` }
            onClick={() => onClose(false)}
          >
            <div
              className={styles.modalBox + ` ${className}`}
              onClick={e => e.stopPropagation()}
            >
              {children}
            </div>
          </div>
        </>, document.body)
    }
  </>
}
