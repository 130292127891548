import styles from "./styles.module.scss";
import AccordionCustom from "global/components/accordionCustom/AccordionCustom";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";
import getImageUrl from "global/scripts/getImageUrl";
import { Link } from "react-router-dom";
import { ICategoriesMenu } from "global/types/CategoriesMenu";
import { useContext } from "react";
import CityContext from "global/hooks/city/CityContext";

interface ICatalogMenu {
  menuTree: ICategoriesMenu[],
  setIsOpen: (e: boolean) => void,
}

export function CatalogModal({menuTree, setIsOpen}: ICatalogMenu) {

  const { citySlug } = useContext(CityContext);
  
  return (
    <div className={styles.catalogModal}>
      {menuTree.map((item, i) => (
        <AccordionCustom
          key={item.id}
          type='adaptive'
          eventKey="1"
          title={
            <div className={styles.catalogModal__itemHead}>
              <img src={getImageUrl('categories/menu/', item.menu_icon, 'medium/')} alt={item.name} />
              {item.name}
              <CurveDown className={styles.catalogModal__itemIcon} />
            </div>
          }
          className={styles.catalogModal__accordion}
          bodyClass={styles.catalogModal__accordionBody}
        >
          <ul className={styles.contentLinks}>
            {item.children.map((links, x) => (
              <li className={styles.linkItems} key={x}>
                <AccordionCustom
                  type='adaptive'
                  eventKey="1"
                  title={
                    <div className={styles.link__head}>
                      {links.name}
                      <CurveDown className={styles.link__icon} />
                    </div>
                  }
                  className={styles.link}
                  bodyClass={styles.link__body}
                >
                  {links.children.map((linkItem, i) => (
                    <Link
                      key={linkItem.id}
                      to={linkItem.slug ? `catalog/${citySlug == ""? '': citySlug+'/'}${linkItem.slug}/c${linkItem.id}` : '/'}
                      onClick={() => setIsOpen(false)}
                      className={styles.category}
                    >
                      {linkItem.name}
                    </Link>
                  ))}
                </AccordionCustom>
              </li>
            ))}
          </ul>
        </AccordionCustom>
      ))}
    </div>
  )
}

