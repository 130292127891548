import { ReactComponent as TrashIcon } from "assets/cart/delete.svg";
import { ReactComponent as PlusIcon } from "assets/cart/plus.svg";
import styles from "./styles.module.scss";
import { useAppSelector } from "global/hooks/reduxHooks";
import { removeProductInfo, setProductInfo } from "global/redux/actions/productInfoActions";
import { IProductInfoAction } from "global/redux/reducers/productInfo";
import { ModalBox } from "../modal/ModalBox";
import { useState, useContext } from "react";
import { showAlert } from "global/redux/actions/messageModalActions";
import { t } from "i18next";
import { addFavorites } from "global/scripts/addFavoriteFn";
import { useDispatch } from "react-redux";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

interface ICartProductQuantity {
  maxCount: number,
  disabled?: boolean,
  id: number,
  img: string,
  slug: string,
  popup?: boolean,
  setShow?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CartProductQuantity({ maxCount, disabled = false, id, img, slug, popup, setShow }: ICartProductQuantity) {
  const [modalOpen, setModalOpen] = useState(false);
  const { channel } = useContext(ChannelContext)
  const dispatch = useDispatch();
  const productInfo = useAppSelector(state => state.productInfo[channel]);
  const favoritesInfo = useAppSelector(state => state.favoritesInfo[channel]);
  const token = useAppSelector(state => state.userToken)?.token;
  const count = productInfo?.find(item => item.id === id)?.quantity;

  const increment = productInfo?.map(item => {
    return {
      ...item,
      quantity: item.id === id ? item.quantity + 1 : item.quantity
    };
  }) || [];

  const decrement = productInfo?.map(item => {
    return {
      ...item,
      quantity: item.id === id ? item.quantity - 1 : item.quantity
    };
  }) || [];

  function deleteProduct() {
    window.r46("track", "remove_from_cart", id?.toString());
    (productInfo && id) && dispatch(removeProductInfo(productInfo, id) as IProductInfoAction);
  }

  return (
    <>
      <div className={styles.container}>
        {
          !popup &&
          <>
            {
              count && count < 2
                ? <>
                  <button type="button" className={styles.btn} onClick={() => setModalOpen(true)}><TrashIcon className={styles.icon} /></button>
                  <ModalBox
                    isOpen={modalOpen}
                    onClose={e => setModalOpen(e)}
                  >
                    <div className={styles.modalWrapper}>
                      <div className={styles.modalTitle}>{t("Cart.Delete an item from the shopping cart?")}</div>
                      {
                        !favoritesInfo?.some(item => item.id === id) &&
                        <div
                          className={styles.modalSubtitle + ` ${styles.favourites}`}
                          onClick={() => { addFavorites(id, img, slug, favoritesInfo, dispatch, token || '', showAlert); setModalOpen(false); if (token) { deleteProduct() } }}
                        >
                          {t("Cart.Add to Favorites")}
                        </div>
                      }
                      <div className={styles.modalSubtitle + ` ${styles.deleteTitle}`} onClick={deleteProduct}>{t("Cart.Remove")}</div>
                      <div className={styles.modalSubtitle + ` ${styles.backTitle}`} onClick={() => setModalOpen(false)}>{t("Cart.Cancel")}</div>
                    </div>
                  </ModalBox>
                </>
                : <button
                  type="button"
                  className={styles.btn}
                  onClick={() => { dispatch(setProductInfo(decrement) as IProductInfoAction) }}
                >
                  -
                </button>
            }
          </>
        }
        {
          popup &&
          <>
            <button type="button" className={styles.delete_btn} onClick={() => setModalOpen(true)}><TrashIcon className={styles.delete_icon} /></button>
            <ModalBox
              isOpen={modalOpen}
              onClose={() => null}
              className={styles.modalContainer}
            >
              <div className={styles.modalWrapper}>
                <div className={styles.modalTitle}>{t("Cart.Delete an item from the shopping cart?")}</div>
                {
                  !favoritesInfo?.some(item => item.id === id) &&
                  <div
                    className={styles.modalSubtitle + ` ${styles.favourites}`}
                    onClick={() => { addFavorites(id, img, slug, favoritesInfo, dispatch, token || '', showAlert); setModalOpen(false); if (token) { deleteProduct() } }}
                  >
                    {t("Cart.Add to Favorites")}
                  </div>
                }
                <div className={styles.modalSubtitle + ` ${styles.deleteTitle}`} onClick={deleteProduct}>{t("Cart.Remove")}</div>
                <div className={styles.modalSubtitle + ` ${styles.backTitle}`} onClick={() => setModalOpen(false)}>{t("Cart.Cancel")}</div>
              </div>
            </ModalBox>
          </>
        }
        {
          !popup &&
          <>
            <span className={styles.count + ` ${disabled && styles.disabled}`}>{count}</span>
            <button
              type="button"
              className={styles.btn}
              disabled={disabled || (count === maxCount) || (maxCount === 0)}
              onClick={() => { dispatch(setProductInfo(increment) as IProductInfoAction) }}
            >
              <PlusIcon />
            </button>
          </>
        }
      </div>
    </>
  )
}
