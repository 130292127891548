import { t } from "i18next";

export function useOrderStatusValidate() {
  const errors = {
    phone: '',
    orderId: '',
    isValid: false,
  };

  return (values: {
    phone: string,
    orderId: string
  }) => {

    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 (XXX) XXX-XX-XX';
    }

    if (!values.orderId) {
      errors.orderId = t("Thanks.Order number");
    }

    errors.isValid = true;

    Object.values(errors).forEach(error => {
      if (typeof error === "string" && error.length > 0) {
        errors.isValid = false;
      }
    })

    return errors;
  }
};
