export interface IUserToken {
  id: number,
  token: string,
  token_expires_at: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  birthday?: string,
  gender?: string
}

export const setUserToken = ({ token = '', token_expires_at = "", firstName = '', lastName = '', id, email, phone = '' }: IUserToken) => {
  return {
    type: "SET_LOGIN",
    token: { token, token_expires_at, firstName, lastName, id, email, phone }
  }
}

export const clearUserToken = () => ({
  type: "CLEAR_LOGIN",
})
