import { useState } from "react";
import ConfirmationModal from "global/components/modal/ConfirmationModal";
import AppModalsContext from "global/hooks/modal/AppModalContex";

export const withConfirmationModals = (WrappedComponent:any) => {
    return (props:any) => {
        const [showConfirmationModal, setShowConfirmationModal] = useState(false);
        const [modalProps, setModalProps] = useState({ onConfirm: function () { }, modalText: "Подтвердите действие" });

        function confirmAction(modalText = "", onConfirm = function () { }) {
            setModalProps({ modalText, onConfirm });
            setShowConfirmationModal(true);
          }

        return <>
            <ConfirmationModal show={showConfirmationModal} close={() => setShowConfirmationModal(false)} {...modalProps} />
            <AppModalsContext.Provider value={{ confirmAction, }}>
                <WrappedComponent {...props} />
            </AppModalsContext.Provider>
        </>
    };
};
