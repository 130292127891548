import React from "react";
import { Oval } from "react-loader-spinner";

export default function Loader({
  size = 60,
  thickness = 4,
  type = "",
  classes = "",
  style = "",
  text = "",
  color = "",
  secondaryColor = "",
}) {
  return (
    <div>
      <Oval
        height={size}
        width={size}
        color={ color.length > 0 ? color : "var(--color-brand-primary-shade)"}
        wrapperStyle={{ style }}
        wrapperClass={classes}
        visible={true}
        ariaLabel="oval-loading"
        secondaryColor={secondaryColor.length > 0 ? secondaryColor : "var(--color-checkmark)"}
        strokeWidth={thickness}
        strokeWidthSecondary={thickness}
      />
      <span className="txt-bold-5">{text}</span>
    </div>
  );
}
