import { Form } from "react-bootstrap";
import styles from "../styles.module.scss";

export interface IRoundCheckmark {
  name: string,
  value: string | number,
  label?: string,
  disabled?: boolean,
  checked?: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function RoundCheckmark({ name, value, label, disabled = false, checked, onChange }: IRoundCheckmark) {
  return (
    <>
      <Form.Check
        type="radio"
        label="radio"
        id={`default-radio-mark${value}`}
      >
        <Form.Check.Input
          type="radio"
          name={name}
          disabled={disabled}
          checked={checked}
          className={styles.checkmark}
          value={value}
          onChange={onChange}
        />
        {label && <Form.Check.Label>{label}</Form.Check.Label>}
      </Form.Check>
    </>
  )
}
