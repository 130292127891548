import { t } from "i18next";
import { IForgotPassword } from "../types/forgotPassword";

export function useForgotPassword(show: boolean) {
  const errors = {
    phone: '',
    password: '',
    code: ''
  };

  return (values: IForgotPassword) => {
    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 XXX XXX XX XX';
    }

    if (show && !values.password) {
      errors.password = t("Validation.The field is required");
    } else if (show && values.password.length < 6) {
      errors.password = t("Validation.The field must be more than 8 characters");
    }

    return errors;
  }
};
