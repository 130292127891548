import React from 'react';
import { getCurrentCity, getCurrentCitySlug, setCurrentCity, setCurrentCitySlug } from './CurrentCity';

export const defaultCityContext: ICityContext = {
  city: getCurrentCity() || '1',
  setCity: setCurrentCity,
  citySlug: getCurrentCitySlug() || "",
  setCitySlug: setCurrentCitySlug
}

const CityContext = React.createContext(defaultCityContext);

interface ICityContext {
  readonly city: string,
  readonly setCity: typeof setCurrentCity,
  readonly citySlug: string,
  readonly setCitySlug: typeof setCurrentCitySlug,
}

export default CityContext;
