import React from "react";
import { IProductPage } from "modules/product-page/types/ProductPage";
import { IProductPageContext } from "modules/product-page/services";
import { Helmet } from "react-helmet";

interface ProductSchemaInterface {
  product: IProductPageContext | IProductPage;
}

export const generateProductSchema = ({ product }: ProductSchemaInterface) => {
  const mainSpecs: string[] = [];
  product?.specifications?.forEach((specGroup) => {
    if (specGroup.name === "Основные характеристики") {
      specGroup.specifications.forEach((spec) => {
        const specValues = spec.values.map((value) => value.name).join(", ");
        mainSpecs.push(`${spec.specification.name} - ${specValues}`);
      });
    }
  });
  const nextYearDate = new Date();
  nextYearDate.setFullYear(nextYearDate.getFullYear() + 1);

  const formattedDate = nextYearDate.toISOString().split("T")[0];
  let deliveryDate = product?.deliveryDate;
  if (!deliveryDate) {
    deliveryDate = new Date().toLocaleDateString("ru-RU"); // Преобразуем текущую дату в формат DD.MM.YYYY
  }
  const [day, month, year] = deliveryDate.split(".").map(Number);
  // Создаем объект Date, месяцы в JavaScript нумеруются с 0, поэтому нужно вычесть 1 из месяца

  const targetDate: Date = new Date(year, month - 1, day);
  const today: Date = new Date(); // Текущая дата
  // Нормализуем время до полуночи для обеих дат
  targetDate.setHours(0, 0, 0, 0);
  today.setHours(0, 0, 0, 0);
  const differenceInDaysDelivery: number = Math.ceil(
    (targetDate.getTime() - today.getTime()) / (1000 * 3600 * 24)
  );

  let schema: any = {};
  schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    name: product.name,
    sku: product.vendor_code,
    productID: product.id,
    description: mainSpecs.join(" "),
    category: product.breadcrumbs?.[product.breadcrumbs.length - 1]?.name,
    brand: {
      "@type": "Brand",
      name: product.brand?.name,
    },
    image: product.images,
    offers: {
      "@type": "Offer",
      price: product.cost?.toFixed(2),
      url: window.location.href,
      availability:
        product.availableForPurchase && product.availableForPurchaseFromDc
          ? "http://schema.org/InStock"
          : "http://schema.org/OutOfStock",
      priceCurrency: "KZT",
      priceValidUntil: formattedDate,
      itemCondition: "https://schema.org/NewCondition",
      shippingDetails: {
        "@type": "OfferShippingDetails",
        shippingRate: {
          "@type": "MonetaryAmount",
          value: product.cost ? (product.cost >= 10000 ? 0 : 1000) : 0,
          currency: "KZT",
        },
        shippingDestination: {
          "@type": "DefinedRegion",
          addressCountry: "KZ",
        },
        deliveryTime: {
          "@type": "ShippingDeliveryTime",
          handlingTime: {
            "@type": "QuantitativeValue",
            minValue: 1,
            maxValue: 1,
            unitCode: "DAY",
          },
          transitTime: {
            "@type": "QuantitativeValue",
            minValue: 1,
            maxValue:
              differenceInDaysDelivery > 0 ? differenceInDaysDelivery : 1,
            unitCode: "DAY",
          },
        },
      },
      hasMerchantReturnPolicy: {
        "@type": "MerchantReturnPolicy",
        applicableCountry: "KZ",
        returnPolicyCategory:
          "https://schema.org/MerchantReturnFiniteReturnWindow",
        merchantReturnDays: 14,
        returnMethod: "https://schema.org/ReturnInStore",
        returnFees: "https://schema.org/FreeReturn",
      },
    },
  };
  if (product.mpn) {
    schema.mpn = product.mpn;
  }
  if (product.rating && product.review_count) {
    schema.aggregateRating = {
      "@type": "AggregateRating",
      ratingValue: product.rating.toString(),
      reviewCount: product.review_count,
    };
  }

  return schema;
};
