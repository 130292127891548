export default function getImageUrl(module: string, image: string, size: string) {
  let app = process.env.REACT_APP_ENV
  let cdnUrl = process.env.REACT_APP_CDN_URL;
  let backendUrl = process.env.REACT_APP_BACKEND_URL;
  let url = ''
  if (app === 'test') {
    url = backendUrl + 'storage/' + module + size + image;
  } else {
    url = cdnUrl + 'storage/' + module + size + image;
  }

  return url
}
