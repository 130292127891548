import { CHANNELS } from "./channel/ChannelTypes";
interface IGetUrl {
  lang: string;
  currentUrl: string;
  channelToSet: CHANNELS;
}

export default function getUrl({ lang, currentUrl, channelToSet }: IGetUrl) {
  // Удаляем ведущие слэши для консистентности
  let url = currentUrl.replace(/^\/+/, "");

  // Разбиваем URL на сегменты
  let urlSegments = url.split("/");

  // Убираем код языка, если он есть в начале
  const firstSegment = urlSegments[0];
  if (firstSegment === "kk" || firstSegment === "ru") {
    urlSegments.shift(); // удаляем первый сегмент
  }

  // Список возможных каналов
  let possibleChannels = Object.values(CHANNELS) as string[];

  // Находим индекс первого встретившегося канала из possibleChannels (точное совпадение)
  const firstChannelIndex = urlSegments.findIndex((segment) =>
    possibleChannels.includes(segment)
  );

  // Если индекс не -1, значит нашли канал
  if (firstChannelIndex !== -1) {
    // Удаляем ровно один элемент (только первый найденный канал)
    urlSegments.splice(firstChannelIndex, 1);
  }

  // Создаем массив частей URL
  const urlParts = [];

  // Добавляем код языка, если язык 'kk'
  if (lang !== "ru") {
    urlParts.push(lang);
  }

  // Добавляем канал, если он не 'web' и канал не присутствует в URL
  if (channelToSet !== CHANNELS.WEB) {
    urlParts.push(channelToSet);
  }

  // Добавляем оставшуюся часть URL
  urlParts.push(...urlSegments);

  // Объединяем части с помощью '/'
  const newUrl = "/" + urlParts.filter(Boolean).join("/");

  return newUrl;
}
