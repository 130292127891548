import { IOrderInfoAction } from "../reducers/orderInfo"

export interface IOrderInfo {
  id: number,
  delivery: string,
  branch: string,
  deliveryDate: string,
  deliveryCost: number | null,
  payment: string,
  promoCodeDiscount: number,
  onlinePayment: {
    status: string,
    paymentId: string,
    redirectUrl: string,
    isPaymentStatus: boolean,
    orderId: number,
    kaspiQrData: {
      PaymentMethods: string[],
      QrPaymentBehaviorOptions: {
        QrCodeScanWaitTimeout: number,
        PaymentConfirmationTimeout: number,
        StatusPollingInterval: number
      },
      PaymentId?: number,
      PaymentLink?: string,
      ExpireDate: string,
      QrCode?: string
    }
  },
  payed_at: null,
  updated_at: string,
  created_at: string,
  status: {
    id: number,
    sort: number,
    created_at: string,
    updated_at: string,
    color: string,
    icon: string,
    code: null,
    name: string,
    translation: {
      id: number,
      order_status_id: number,
      locale: string,
      name: string
    }
  },
  paymentType: null,
  bonusCard: null,
  usedBonuses: number,
  clientFullName: string,
  clientPhone: string,
  oldPrice: number,
  wholePurchasePrice: number,
  contactRetailPhones: string,
  items: IOrderItem[],
  cartVendorCode: string[],
  gift_card?: {
    card_design?: number
  },
  bank_data?: {
    name: string,
    description: string,
  }
}

export interface IOrderItem {
  id: number,
  slug: string,
  name: string,
  imageUrl: string,
  guid: string,
  quantity: number,
  price: number,
  old_price: null,
  purchase_price: number,
  isPreorder: boolean,
  gift?: IOrderGift,
  promo_code_applied: boolean,
  delivery_from_dc: number,
  quantity_with_unit: string,
  together_cheaper: {
    guid: string,
    price: number,
    name_with_together_cheaper: string
  },
  vendor_code?: string
}

export interface IOrderGift {
  id: number,
  slug: string,
  guid: string,
  icon: string,
  name: string
}

const orderInfoProps = {
  id: 0,
  delivery: '',
  branch: '',
  deliveryDate: '',
  deliveryCost: 0,
  payment: '',
  promoCodeDiscount: 0,
  onlinePayment: {
    status: '',
    paymentId: '',
    redirectUrl: '',
    isPaymentStatus: false,
    orderId: 0,
    kaspiQrData: {
      PaymentMethods: [""],
      QrPaymentBehaviorOptions: {
        QrCodeScanWaitTimeout: 180,
        PaymentConfirmationTimeout: 65,
        StatusPollingInterval: 5
      },
      ExpireDate: "",
      QrCode: ""
    }
  },
  payed_at: null,
  updated_at: '',
  created_at: '',
  status: {
    id: 0,
    sort: 0,
    created_at: '',
    updated_at: '',
    color: '',
    icon: '',
    code: null,
    name: '',
    translation: {
      id: 0,
      order_status_id: 0,
      locale: '',
      name: ''
    }
  },
  paymentType: null,
  bonusCard: null,
  usedBonuses: 0,
  clientFullName: '',
  clientPhone: '',
  oldPrice: 0,
  wholePurchasePrice: 0,
  contactRetailPhones: '',
  items: [],
  gift_card: {
    card_design: 0
  },
  cartVendorCode: []
}

export const setOrderInfo = (props: IOrderInfo = orderInfoProps): IOrderInfoAction => {
  return {
    type: "SET_ORDER",
    order: { ...props }
  }
}

export const clearOrderInfo = (): Pick<IOrderInfoAction, "type"> => ({
  type: "CLEAR_ORDER",
})
