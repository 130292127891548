import { Accordion } from "react-bootstrap";
import styles from './../styles.module.scss';

export interface IAccordionFooter {
  eventKey: string,
  title: string,
  children: React.ReactNode,
}

export default function AccordionFooter({children, eventKey, title}: IAccordionFooter) {
  return (
    <Accordion className={styles.footer} flush>
      <Accordion.Item eventKey={eventKey} className={styles.footerItem}>
        <Accordion.Header as="div">{title}</Accordion.Header>
        <Accordion.Body className={styles.footerBody}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
