import React from "react";
import { IProductPage } from "../types/ProductPage";
import { IProductCart } from "modules/catalog/types/ProductCartType";

export const initialProductPageContext: IProductPageContext = {
  isLoad: true,
};

export const ProductPageContext = React.createContext(initialProductPageContext);

export interface IProductPageContext extends Partial<IProductPage> {
  isLoad: boolean,
}
