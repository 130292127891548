// bugsnag.js
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import BugsnagPerformance from '@bugsnag/browser-performance';

Bugsnag.start({
  apiKey: 'be14826ffd79fdb4e250ec68afc18c31',
  plugins: [new BugsnagPluginReact()],
  appVersion: process.env.REACT_APP_VERSION || '2.0.0',
  collectUserIp: false,
  releaseStage: process.env.NODE_ENV || "development", 
  enabledReleaseStages: [ 'production' ],
  redactedKeys: [
    'access_token',
    /^token$/i,
    /^password$/i,
    /^cc_/,
  ],
  autoDetectErrors: false,
  autoTrackSessions: false
})
BugsnagPerformance.start({ apiKey: 'be14826ffd79fdb4e250ec68afc18c31' })

export const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);
