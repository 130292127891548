import { useAppSelector } from "global/hooks/reduxHooks"
import { IDeliveryData, IRecipientData } from "modules/cart/types/sendData"

export interface IDeliveryInfo extends IDeliveryData, IRecipientData {
  id?: number,
  localityName?: string,
  branchName?: string,
  selectTab?: "1" | "2" | "3" | "4",
  saveAddress?: boolean
}

export const setDeliveryInfo = ({ branchId = null, date = new Date(new Date().setDate((new Date().getDate()) + 1)).toLocaleDateString(), delivery_cost = 0, house = '', localityId = null, room = '', street = '', time = "", branchName = '', localityName = '', selectTab = "1", comment = "", recipientIsMe = true, firstName = '', lastName = '', email = '', phone = '', express_delivery_cost = 0, saveAddress = false, id = 1 }: IDeliveryInfo) => {
  return {
    type: "SET_DELIVERY",
    delivery: {
      branchId,
      date,
      time,
      delivery_cost,
      localityId,
      room,
      street,
      house,
      localityName,
      branchName,
      selectTab,
      comment,
      recipientIsMe,
      firstName,
      lastName,
      email,
      phone,
      express_delivery_cost,
      saveAddress,
      id
    }
  }
}

export const clearDeliveryInfo = () => ({
  type: "CLEAR_DELIVERY",
})
