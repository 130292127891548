import InputMask from 'react-input-mask';
import styles from '../../styles.module.scss';

export interface IInputNumber {
  type: "number",
  className?: string,
  mask: string,
  name: string,
  label: string,
  value: string | number,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  disabled?: boolean
}

export default function InputNumber({ label, mask, name, onChange,  onBlur, value, className, disabled }: IInputNumber) {
  return (
    <div className={styles.wrapper}>
      <InputMask
        maskChar={null}
        mask={mask}
        className={styles.input + ' ' + className}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label className={value.toString().length > 0 ? styles.label : ''}>{label}</label>
    </div>
  )
}
