import styles from '../../styles.module.scss';

export interface IInputText {
  type: "text",
  className?: string,
  name: string,
  label: string,
  value: number | string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  disabled?: boolean
}

export default function InputText({ label, name, onChange, onBlur, value, className, disabled }: IInputText) {
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input + ' ' + className}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label className={(value.toString()).length > 0 ? styles.label : ''}>{label}</label>
    </div>
  )
}
