import { FormCheck } from "react-bootstrap";
import styles from "./styles.module.scss";

interface ISwitch {
  value: string,
  checked?: boolean,
  onChange: (e: React.FormEvent<HTMLInputElement>) => void,
}

export default function Switch({value, checked, onChange}: ISwitch) {
  return <FormCheck type="switch" checked={checked} id={styles.switch} value={value} onChange={onChange}/>
}
