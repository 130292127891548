import React, { useId } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as StarSVG } from 'assets/icons/star.svg';

interface IRating {
  name: string,
  checked: number,
  disabled?: boolean,
  styleClass?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function Rating({ name, checked, disabled, styleClass, onChange }: IRating) {
  const key = useId();

  return (
    <div className={disabled ? styles.disabledContainer : styles.container}>
      {[5, 4, 3, 2, 1].map(item => (
        <React.Fragment key={item}>
          <input
            type="radio"
            className={styles.input}
            id={`rating-${name + item + key}`}
            name={name}
            value={item}
            checked={checked === item}
            onChange={onChange}
            disabled={disabled}
          />
          <label htmlFor={`rating-${name + item + key}`} className={`${styles.star}  ${styleClass}`}>
            <StarSVG />
          </label>
        </React.Fragment>
      ))}
    </div>
  )
}
