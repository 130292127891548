import Accordion from 'react-bootstrap/Accordion';
import styles from "../styles.module.scss";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";

export interface IAccordionQuestion {
  eventKey: string,
  title: React.ReactNode,
  children: React.ReactNode,
}

export default function AccordionQuestion({ children, title, eventKey }: IAccordionQuestion) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item className={styles.questionItem} eventKey={eventKey}>
        <Accordion.Header as="div" className={styles.questionTitle}>
          {title}
          <div className={styles.questionItem__round}>
            <CurveDown className={styles.questionItem__icon} />
          </div>
        </Accordion.Header>
        <Accordion.Body className={styles.questionBody}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
