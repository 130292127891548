import React from "react";
import { Helmet } from "react-helmet";

export default function DomainVerification() {
  return (
    <Helmet>
      <meta
        name="facebook-domain-verification"
        content="yv9ycazphe91cymxjwtxb8mgcrq3l9"
      />
      <meta name="yandex-verification" content="8b1c398d2f1c1ac6" />
    </Helmet>
  );
}
