import { ReactComponent as Close } from "assets/icons/close.svg";
import styles from "./styles.module.scss";
import InputForm from "global/components/input/InputForm";
import { Spinner } from "react-bootstrap";
import { SetStateAction, FormEvent, useState, useEffect, useRef, useContext } from "react";
import { useFormik } from "formik";
import { useForgotPassword } from "modules/auth/hooks/useForgotPassword";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { t } from "i18next";
import { useNavigate } from "react-router";
import { ROUTE_CABINET } from "router/routes";
import Timer from "modules/broker/components/broker-otp/Timer";
import { IUserTokenAction } from "global/redux/reducers/userToken";
import { setUserToken } from "global/redux/actions/userTokenActions";
import { toast } from "react-toastify";
import { ReactComponent as ArrowLeft } from "assets/user-page/arrow-left.svg";
import getUrl from "global/hooks/getUrl";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import LanguageContext from "global/hooks/language/LanguageContext";
import { useDispatch } from "react-redux";

interface IForgotPasswordWindow {
  onClose: (isOpen: false) => void,
  isLogin: (login: false) => void,
  setIsForgotPassword: React.Dispatch<SetStateAction<boolean>>
}

export default function ForgotPasswordWindow({ onClose, isLogin, setIsForgotPassword }: IForgotPasswordWindow) {
  const [show, setShow] = useState(false);
  const [smsId, setSmsId] = useState<number>();
  const [resend, setResend] = useState(true);
  const { channel } = useContext(ChannelContext)
  const lang = useContext(LanguageContext)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validate = useForgotPassword(show);
  const minutes = Number(process.env.REACT_APP_MINUTES || 2);
  const length = 4;

  const [code, setCode] = useState(Array(length).fill(''));
  const inputRefs: any = useRef([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResend(false)
    }, minutes * 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [resend])

  const formik = useFormik({
    initialValues: {
      phone: '',
      code: code.join(""),
      password: ''
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate, isLoading } = useMutation(
    () => postData({
      url: 'checkout/check-user-send-sms',
      data: {
        "phone": formik.values.phone.replace(/[\s,\(\)\-]/ig, '')
      },
      onSuccess(e) {
        setShow(true);
        setSmsId(e.data.message_id);
      },
      onError(e) {
        formik.errors.phone = t("ForgotPassword.The user with this number was not found");
      },
      channel
    }));

  const resetPassword = useMutation(
    () => postData({
      url: 'users/forgot-password',
      data: {
        "phone": `+${formik.values.phone.replace(/\D/ig, '')}`,
        "sms_message_id": smsId,
        "code": code.join(""),
        "password": formik.values.password
      },
      onSuccess(e) {
        dispatch(setUserToken({
          id: 0,
          token: e.data.token,
          token_expires_at: e.data.token_expires_at,
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        }) as IUserTokenAction);
        navigate(getUrl({currentUrl:`/${ROUTE_CABINET}`,channelToSet:channel, lang}));
        toast.success(t("Modal.The password has been successfully changed"));
        onClose(false);
      },
      onError(e) {
        formik.errors.code = t('Login.The code is incorrect');
      },
      channel
    }));

  function send(e: FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!formik.errors.phone) {
      mutate();
    }
  }

  function reset(e: FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!Object.values(formik.errors).some(item => item.length > 1)) {
      resetPassword.mutate();
    }
  }

  const resendSms = () => {
    mutate();
    setResend(true);
  }

  return (
    <div>
      <div className={styles.authContainer__head}>
        <ArrowLeft onClick={() => onClose(false)} className={styles.authContainer__arrow} />
        <h2 className={styles.authContainer__title}>{t("ForgotPassword.Password Recovery")}</h2>
        <button
          className={styles.closeBtn}
          onClick={() => onClose(false)}
        >
          <Close />
        </button>
      </div>
      {
        !show &&
        <div className={styles.authContainer__text}>{t("ForgotPassword.After submitting the form, you will receive a link to your email or an SMS code to your mobile phone")}</div>
      }
      <form onSubmit={show ? reset : send}>
        <div className={styles.auth__inputContainer}>
          <InputForm
            type="tel"
            label="Телефон"
            mask='+7 999 999 99 99'
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            className={formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}
          />
          {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
        </div>
        {
          show &&
          <div className={styles.authContainer__wrapper}>
            <div className={styles.auth__inputContainer}>
              <InputForm
                type={"password"}
                label={t("ForgotPassword.New password")}
                name="password"
                mask=''
                value={formik.values.password}
                onChange={formik.handleChange}
                className={formik.errors.password && formik.touched.password ? styles.errorInput : ''}
                isError={(formik.errors.password && formik.errors.password?.length > 0) || false}
              />
              {formik.errors.password && formik.touched.password ? <div className={styles.errorText}>{formik.errors.password}</div> : null}
            </div>
            <div>
              <div className={styles.code_text}>{t("Login.Enter the code from the SMS")}</div>
              <div className={styles.wrapperInput}>
                <InputForm
                  type="code"
                  code={code}
                  setCode={setCode}
                  inputRefs={inputRefs}
                />
                {formik.errors.code && formik.touched.code ? <div className={styles.errorText}>{formik.errors.code}</div> : null}
              </div>
            </div>
          </div>
        }
        {
          show
            ? <button type="submit" className={"button " + styles.orangeBtn}>
              {resetPassword.isLoading ?
                <Spinner animation="border" variant="light" size="sm" />
                : <>
                  {t("ForgotPassword.Recover")}
                </>
              }
            </button>
            : <button type="submit" className={"button " + styles.orangeBtn}>
              {isLoading ?
                <Spinner animation="border" variant="light" size="sm" />
                : <>
                  {t("Auth.Get an SMS code")}
                </>
              }
            </button>
        }
        {
          show &&
          <div className={styles.linkWrapper}>
            {
              resend
                ? <Timer targetDate={new Date(new Date().setMinutes(new Date().getMinutes() + minutes)).getTime()} />
                : <div className={styles.linkText} onClick={resendSms}>{t("BrokerOtp.Resend SMS with a code")}</div>
            }
          </div>
        }
      </form>
      <span className={styles.auth__register}>
        {t("ForgotPassword.No account?")}
        <button
          type="button"
          className={styles.auth__blueText}
          onClick={() => { setIsForgotPassword(false); isLogin(false) }}
        >
          {t("Auth.Register")}
        </button>
      </span>
    </div>
  )
}
