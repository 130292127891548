import { t } from "i18next";

interface ISubscribeForm {
  phone?: string,
  email?: string
}

export function useFormValidate() {
  const errors = {
    phone: '',
    email: '',
  };

  return (values: ISubscribeForm) => {
    if (!values.email) {
      errors.email = t("Validation.The E-mail field is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t("Validation.Invalid email address");
    }

    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 (XXX) XXX-XX-XX';
    }

    return errors;
  }
};
