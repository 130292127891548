import { useState, useContext } from "react";
import { IVariationData, IVariations } from "modules/product-page/types/ProductPage";
import styles from "../styles.module.scss";
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { useParams } from "react-router";
import Loader from "global/components/loader/Loader";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

interface IProductColor extends IVariationData {
  isLoading: boolean,
  onClick: () => void,
}

export default function ProductColor({ product, color, checked, active, isLoading, onClick }: IProductColor) {
  const { channel } = useContext(ChannelContext);
  const [imageIsLoaded, setImageIsLoaded] = useState(true)
  const { id } = useParams();
  const paramId = id?.substring(1) || 0;

  const imageOnErrorHandler = () => {
    setImageIsLoaded(false)
  };

  return <div
    onClick={onClick}
    className={`${styles.colorBlock} ${checked && styles.colorBlockActive} ${isLoading && styles.loading} ${!active && styles.colorBlockDisabled}}`}
  >
    {isLoading && <>
      <div className={styles.colorBlock__loading}>
        <Loader size={18}/>
      </div>
    </>}
    {imageIsLoaded
      ? <img src={product.image} alt="" onError={imageOnErrorHandler} />
      : <img src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt="" />
    }
    <div
      className={styles.colorPoint}
      style={{ backgroundColor: color || '' }}
    />
  </div>
}
