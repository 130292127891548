import { combineReducers } from "redux";
import messageModal from "./messageModals";
import userInfo from "./userInfo";
import deliveryInfo from "./deliveryInfo";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import paymentInfo from "./paymentInfo";
import orderInfo from "./orderInfo";
import productInfo from "./productInfo";
import compareInfo from "./compareInfo";
import favoritesInfo from "./favoritesInfo";
import userToken from "./userToken";
import viewedProduct from "./viewedProduct";
import brokerInfo from "./brokerInfo";
import authInfo from "./authInfo";
import utmParam from "./utmParam";
import smartGiftInfo from "./smartGift";
import giftCards from "./giftCards";

const rootPersistConfig = {
  key: "root-v2.5",
  storage: storage,
  blacklist: ["router"],
};

// Combine all reducers
const rootReducer = persistReducer(
  rootPersistConfig,
  combineReducers({
    deliveryInfo,
    paymentInfo,
    orderInfo,
    productInfo,
    compareInfo,
    favoritesInfo,
    viewedProduct,
    brokerInfo,
    messageModal,
    userToken,
    authInfo,
    utmParam,
    smartGiftInfo,
    giftCards,
    userInfo
  }));

export default rootReducer;
