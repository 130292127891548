import { t } from "i18next";

export function useFeedbackReviewValidate() {
  const errors = {
    name: '',
    email: '',
    text: '',
    isValid: false,
  };

  return (values: any) => {
    if (!values.name) {
      errors.name = t("Validation.The Name field is required");
    }
    if (!values.email) {
      errors.email = t("Validation.The E-mail field is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t("Validation.Invalid email address");
    }
    if (!values.text) {
      errors.text = t("Validation.The Comment field is required")
    }
    errors.isValid = true;

    Object.values(errors).forEach(error => {
      if (typeof error === "string" && error.length > 0) {
        errors.isValid = false;
      }
    })

    return errors;
  }
};
