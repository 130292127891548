import { ICities } from "global/types/Cities";
import { setCurrentCity, setCurrentCitySlug } from "./CurrentCity";
import { useLocation, useNavigate, useParams } from "react-router";
import getUrl from "../getUrl";
import { CHANNELS } from "../channel/ChannelTypes";

interface ISelectCity {
  setCity: typeof setCurrentCity;
  selectedCity: string;
  location: ReturnType<typeof useLocation>;
  cityslug: string | undefined;
  navigate: ReturnType<typeof useNavigate>;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCitySlug: typeof setCurrentCitySlug;
  slug: string | undefined;
  id: string | undefined;
  channel: CHANNELS
}

export default function selectCity({
  setCity,
  selectedCity,
  location,
  cityslug,
  navigate,
  setModalOpen,
  setCitySlug,
  slug,
  id,
  channel = CHANNELS.WEB,
}: ISelectCity) {
  setCity(selectedCity);
  setCitySlug(cityslug === "almaty" ? "" : cityslug || "");
  let langUrl = "";
  // Check for url params and change the current language accordingly
  if (location.pathname.includes("/kk/")) {
    langUrl = "/kk";
  }
  // Создаем объект для query params
  const searchParams = new URLSearchParams(location.search);
  if (location.pathname.includes("/catalog") && slug && id) {
    if (cityslug === "" || cityslug === "almaty") {
      navigate({
        pathname: getUrl({currentUrl:`/catalog/${slug}/${id}`, lang: langUrl, channelToSet:channel}),
        search: searchParams.toString(), // Добавляем существующие query params
      });
    } else {
      navigate({
        pathname: getUrl({currentUrl:`/catalog/${cityslug}/${slug}/${id}`, lang: langUrl, channelToSet:channel}),
        search: searchParams.toString(), // Добавляем существующие query params
      });
    }
  } else if(location.pathname.includes("/catalog") && !slug && !id){
    if (cityslug === "" || cityslug === "almaty") {
      navigate({
        pathname: getUrl({currentUrl:`/catalog/`, lang: langUrl, channelToSet:channel}),
        search: searchParams.toString(), // Добавляем существующие query params
      });
    } else {
      navigate({
        pathname: getUrl({currentUrl:`${langUrl}/catalog/${cityslug}/`, lang: langUrl, channelToSet:channel}),
        search: searchParams.toString(), // Добавляем существующие query params
      });
    }
  }
  setModalOpen(false);
}
