import { ModalBox } from "global/components/modal/ModalBox";
import { ReactComponent as Close } from "assets/icons/close.svg";
import styles from "./styles.module.scss";
import { Card, Placeholder } from "react-bootstrap";
import { t } from "i18next";
import { useAppSelector } from "global/hooks/reduxHooks";
import { setDeliveryInfo } from "global/redux/actions/deliveryInfoActions";
import { IDeliveryInfoAction } from "global/redux/reducers/deliveryInfo";
import { useContext, useEffect, useState } from "react";
import MainButton from "global/components/button/Button";
import { ICities } from "global/types/Cities";
import CityContext from "global/hooks/city/CityContext";
import selectCity from "global/hooks/city/SelectCity";
import { useLocation, useNavigate, useParams } from "react-router";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

interface ICityModal {
  modalOpen: boolean,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
  data: {
    data: ICities[];
  } | undefined,
  isLoading: boolean,
  isFetching: boolean
}

export default function CityModal({ modalOpen, setModalOpen, data, isLoading, isFetching }: ICityModal) {
  const { slug, id } = useParams();
  const { city, setCity } = useContext(CityContext);
  const { citySlug, setCitySlug } = useContext(CityContext);
  const { channel } = useContext(ChannelContext);
  const location = useLocation();
  const navigate = useNavigate();
  const LOAD_PRODUCTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

  const [selectedCity, setSelectedCity] = useState(city);
  const [selectedCitySlug, setSelectedCitySlug] = useState(citySlug);

  const dispatch = useDispatch();
  const deliveryInfo = useAppSelector(state => state.deliveryInfo[channel]);

  function getCitiesColumns() {
    if (!data || !data.data || !Array.isArray(data.data)) {
      return [];
    }

    const cities = data.data;

    let subarrays = [];

    for (let i = 0; i < Math.ceil(cities.length / 13); i++) {
      subarrays.push(cities.slice(i * 13, i * 13 + 13));
    }

    return subarrays;
  }

  function changeDeliveryInfo() {
    dispatch(setDeliveryInfo({
      ...deliveryInfo,
      branchName: '',
      localityName: '',
      delivery_cost: 0,
      express_delivery_cost: 0
    }) as IDeliveryInfoAction)
  }

  useEffect(() => {
    setSelectedCity(city)
    setSelectedCitySlug(citySlug)
  }, [city, citySlug]);

  return (
    <ModalBox
      isOpen={modalOpen}
      onClose={e => setModalOpen(e)}
    >
      <div className={styles.cities}>
        <button
          aria-label={t('ProductPage.Close')}
          className={styles.closeBtn}
          onClick={() => setModalOpen(false)}
        >
          <Close className={styles.cities__close} />
        </button>
        <h3 className={styles.cities__header}>{t('Header.Choose your city')}:</h3>
        <div className={styles.cities__columns + ` ${(isLoading || isFetching) && styles.cities__columns_load}`}>
          {
            (isLoading || isFetching)
              ? <>
                <Placeholder as={Card.Title} animation="wave" className={styles.cities__column}>
                  {LOAD_PRODUCTS.map(item => (
                    <Placeholder xs={12} bg="secondary" />
                  ))}
                </Placeholder>
                <Placeholder as={Card.Title} animation="wave" className={styles.cities__column}>
                  {LOAD_PRODUCTS.map(item => (
                    <Placeholder xs={12} bg="secondary" />
                  ))}
                </Placeholder>
              </>
              : getCitiesColumns().map((cityItem, i) => (
                <div key={i} className={styles.cities__column}>
                  {
                    cityItem.map(item => (
                      <label key={item.id} className={styles.cities__item}>
                        <input
                          type="radio"
                          name="cities"
                          checked={item.id === +selectedCity}
                          onClick={() => { setSelectedCity(`${item.id}`); setSelectedCitySlug(`${item.slug}`); changeDeliveryInfo() }}
                        />
                        <span>{item.name}</span>
                      </label>
                    ))
                  }
                </div>
              ))
          }
        </div>
        <MainButton
          className={styles.agreeButton}
          onClick={() => selectCity({ setCity, selectedCity, location, cityslug: selectedCitySlug, navigate, setModalOpen, setCitySlug, id, slug, channel })}
        >
          {t("Header.Confirm")}
        </MainButton>

        <div className={styles.cities__notice}>
          {t("Header.Choosing a city will help provide up-to-date information about the availability of goods and delivery methods")}
        </div>
      </div>
    </ModalBox>
  )
}
