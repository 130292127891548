import React, { useContext } from 'react';
import Characteristic from 'modules/product-page/components/characteristic/Characteristic';
import styles from './styles.module.scss';
import { breakpointMD } from 'global/hooks/constants';
import { useMediaQuery } from 'react-responsive';
import { t } from "i18next";
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';

export default function SpecificationTab() {
  const isModile = useMediaQuery(breakpointMD);
  const product = useContext(ProductPageContext) as IProductPageContext;

  return (
    <div className={styles.specifications}>
      {!isModile && (
        <span className={styles.specifications_Title}>{t("ProductPage.Specifications")}</span>
      )}
      <div>
        {(product.specifications) && product.specifications.map((specification, key) => (
          <React.Fragment key={'spo_'+key}>
            {/* If scep group does not contain any specifications */}
            {Object.keys(specification.specifications).length>0 && (
              <div className={styles.specification} key={key}>
                <div className={styles.specification_Name}>{specification.name}</div>
                <div className={styles.specification_Content}>
                  {specification.specifications
                    && Object.entries(specification.specifications).map(([key, item]) => (
                      <React.Fragment key={key}>
                        <Characteristic
                          title={item.specification.name}
                          values={item.values}
                        />
                      </React.Fragment>
                    ))}
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
