import { FormEvent, useState, SetStateAction, useContext } from "react";
import styles from "./styles.module.scss";
import InputForm from "global/components/input/InputForm";
import Checkbox from "global/components/checkbox/Checkbox";
import { useFormik } from "formik";
import { useLoginValidate } from "modules/auth/hooks/useLoginValidate";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { useAppSelector } from "global/hooks/reduxHooks";
import { setUserToken } from "global/redux/actions/userTokenActions";
import { IUserTokenAction } from "global/redux/reducers/userToken";
import { t } from "i18next";
import { Spinner } from "react-bootstrap";
import { setAuthInfo } from "global/redux/actions/authInfoActions";
import { IAuthInfoAction } from "global/redux/reducers/authInfo";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

interface ILoginWindow {
  isLogin: (login: false) => void,
  onClose: (isOpen: false) => void,
  setIsForgotPassword: React.Dispatch<SetStateAction<boolean>>,
  setAuthorized?: React.Dispatch<React.SetStateAction<boolean>>
}

export function LoginWindow({ isLogin, onClose, setIsForgotPassword, setAuthorized }: ILoginWindow) {
  const { channel } = useContext(ChannelContext);
  const [value, setValue] = useState("loginTel");
  const dispatch = useDispatch();
  const authInfo = useAppSelector(state => state.authInfo);
  const validate = useLoginValidate(value === 'loginEmail');

  const formik = useFormik({
    initialValues: {
      phone: authInfo?.phone || '',
      email: authInfo?.email || '',
      password: authInfo?.password || '',
      remember_me: authInfo?.checked || false,
      checked: false,
      isValid: false
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: { email?: string, phone?: string, password: string, remember_me?: boolean }) => postData<{
      token: string,
      token_expires_at: string,
      user: {
        id: number,
        name: string,
        surname: string,
        email: string,
        phone: string,
      }
    }>({
      url: `auth/login`,
      data: data,
      onSuccess(e) {
        setAuthorized && setAuthorized(true);
        dispatch(setUserToken({
          id: e.data.user.id,
          token: e.data.token,
          token_expires_at: e.data.token_expires_at,
          firstName: e.data.user.name,
          lastName: e.data.user.surname,
          email: e.data.user.email,
          phone: e.data.user.phone
        }) as IUserTokenAction);
        onClose(false);
        if (authInfo?.checked) {
          dispatch(setAuthInfo({
            email: formik.values.email,
            password: formik.values.password,
            phone: formik.values.phone
          }) as IAuthInfoAction)
        }
        toast.success(t("Modal.You have successfully logged in to the site"));
      },
      onError(e) {
        toast.error(t("Modal.The wrong number or password was entered"));
      },
      channel
    })
  });

  function send(e: FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (formik.errors && !Object.values(formik.errors).some((item: string) => item.length > 1)) {
      if (value === 'loginTel') {
        mutate({
          phone: formik.values.phone.replace(/[\s,\(\)\-]/ig, ''),
          password: formik.values.password,
          remember_me: authInfo?.checked
        });
      } else {
        mutate({
          email: formik.values.email,
          password: formik.values.password,
          remember_me: authInfo?.checked
        });
      }
    }
  }

  return (
    <>
      <form
        onSubmit={send}
        className={styles.auth}
      >
        <div className={styles.checkboxWrapper}>
          <Checkbox
            type="round"
            value="loginTel"
            onChange={() => setValue("loginTel")}
            name="login"
            label={t("Global.Phone number")}
            checked={value === "loginTel"}
            className={styles.auth__checkbox}
          />
          <Checkbox
            type="round"
            value="loginEmail"
            onChange={() => setValue("loginEmail")}
            name="login"
            label="Email"
            checked={value === "loginEmail"}
            className={styles.auth__checkbox}
          />
        </div>
        {
          value === "loginTel"
            ? <div className={styles.auth__inputContainer}>
              <InputForm
                type="tel"
                label="Телефон"
                mask='+7 999 999 99 99'
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                className={formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}
              />
              {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
            </div>
            : <div className={styles.auth__inputContainer}>
              <InputForm
                type="text"
                label="Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className={formik.errors.email && formik.touched.email ? styles.errorInput : ''}
              />
              {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
            </div>
        }
        <div className={styles.auth__inputContainer}>
          <InputForm
            type={"password"}
            label={t("Auth.Password")}
            name="password"
            mask=''
            value={formik.values.password}
            onChange={formik.handleChange}
            className={formik.errors.password && formik.touched.password ? styles.errorInput : ''}
            isError={(formik.errors.password && formik.errors.password?.length > 0) || false}
          />
          {formik.errors.password && formik.touched.password ? <div className={styles.errorText}>{formik.errors.password}</div> : null}
        </div>
        <div className={styles.auth__remember}>
          <Checkbox
            type='square'
            value={'checked'}
            checked={formik.values.checked}
            onChange={() => dispatch(setAuthInfo({ ...authInfo, checked: !authInfo?.checked }) as IAuthInfoAction)}
            name='checked'
            label={
              <span
                className={styles.auth__rememberCheck}
              >
                {t("Auth.Remember me")}
              </span>
            }
            className={styles.auth__checkbox}
          />
          <button type="button" className={styles.auth__blueText} onClick={() => { setIsForgotPassword(true); isLogin(false) }}>
            {t("Auth.Forgot your password")}
          </button>
        </div>
        <button type="submit" className={"button " + styles.orangeBtn}>
          {isLoading ?
            <Spinner animation="border" variant="light" size="sm" />
            : <>
              {t('Login.Enter')}
            </>
          }
        </button>
        <span className={styles.auth__register}>
          {t("Auth.No account")}
          <button
            type="button"
            className={styles.auth__blueText}
            onClick={() => isLogin(false)}
          >
            {t("Auth.Register")}
          </button>
        </span>
      </form>
    </>
  )
}
