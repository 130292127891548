import Rating from "global/components/rating/Rating";
import styles from './styles.module.scss';
import { ReactComponent as AnswerSVG } from 'assets/icons/answer.svg';
import { ReactComponent as LikeSVG } from 'assets/icons/like.svg';
import { useState, useContext } from 'react';
import { IMainReviews, IReview } from "modules/product-page/types/Reviews";
import LanguageContext from "global/hooks/language/LanguageContext";
import { ModalBox } from "global/components/modal/ModalBox";
import { FeedbackReview } from "./FeedbackReview";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { useAppSelector } from "global/hooks/reduxHooks";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

interface IReviewAnswer extends IReview {
  data?: IMainReviews,
  isChild?: boolean,
  refetch?: () => void
}

export function Review({ id, rating, name, created_at, text, likes, dislikes, data, isChild, refetch }: IReviewAnswer) {
  const { channel } = useContext(ChannelContext);
  const lang = useContext(LanguageContext);
  const [toggleBtn, setToggleBtn] = useState<"" | "like" | "dislike">("");
  const [reviewsModal, setReviewsModal] = useState(false);
  const [currentLikes, setCurrentLikes] = useState(likes);
  const [currentDislikes, setCurrentDislikes] = useState(dislikes);
  const token = useAppSelector(state => state.userToken);

  const [child_id] = data && Object.keys(data?.reviews).filter(item => +item === id) || [];

  const addToLike = useMutation({
    mutationFn: () => postData({
      url: `product/review/like`,
      data: {
        "user_id": token?.id,
        "review_id": id
      },
      isUserPage: true,
      token: token?.token,
      onSuccess: () => {
        setCurrentLikes(currentLikes + 1);
        if (toggleBtn === "dislike") {
          setCurrentDislikes(currentDislikes - 1);
        }
        setToggleBtn("like");
        refetch?.();
      },
      onError: (error) => {
        console.error(error);
      },
      channel
    }),
  });

  const addToDislike = useMutation({
    mutationFn: () => postData({
      url: `product/review/dislike`,
      data: {
        "user_id": token?.id,
        "review_id": id
      },
      isUserPage: true,
      token: token?.token,
      onSuccess: () => {
        setCurrentDislikes(currentDislikes + 1);
        if (toggleBtn === "like") {
          setCurrentLikes(currentLikes - 1);
        }
        setToggleBtn("dislike");
        refetch?.();
      },
      onError: (error) => {
        console.error(error);
      },
      channel
    }),
  });

  const handleLike = () => {
    if (toggleBtn !== "like") {
      addToLike.mutate();
    }
  };

  const handleDislike = () => {
    if (toggleBtn !== "dislike") {
      addToDislike.mutate();
    }
  };

  return (
    <>
      <div className={styles.messages_container + ` ${isChild && styles.review_answer}`}>
        <div className={styles.messages_initials}>
          {
            !isChild &&
            <div className={styles.reviews_starRating}>
              {rating}/5
              <Rating
                name={`tab-rating-message${id}`}
                onChange={() => null}
                checked={rating}
                styleClass={styles.reviews_stars}
                disabled
              />
            </div>
          }
          <div className={styles.messages_athorInfo}>
            <span className={styles.messages_athorName}>{name}</span>
            <span className={styles.messages_publishedDate}>
              {new Date(created_at).toLocaleString(lang, {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </span>
          </div>
        </div>
        <div className={styles.messages_content}>
          <span className={styles.messages_text}>
            {text}
          </span>
          <div className={styles.messages_actions}>
            <button
              onClick={() => setReviewsModal(true)}
              className={styles.messages_answer}
            >
              <AnswerSVG />
              Ответить
            </button>
            <div className={styles.messages_praise}>
              <button
                className={`${styles.messages_like} ${toggleBtn === "like" && styles.messages_like_active}`}
                onClick={handleLike}
              >
                <LikeSVG />
                {currentLikes}
              </button>
              <button
                className={`${styles.messages_dislike} ${toggleBtn === "dislike" && styles.messages_dislike_active}`}
                onClick={handleDislike}
              >
                <LikeSVG />
                {currentDislikes}
              </button>
            </div>
          </div>
        </div>
        <ModalBox
          isOpen={reviewsModal}
          onClose={() => setReviewsModal(false)}
        >
          <FeedbackReview
            isAnswer
            name={name}
            closeModal={setReviewsModal}
          />
        </ModalBox>
      </div>
      {
        data && data.reviews[+child_id]?.map(item => (
          <Review
            key={item.id}
            isChild
            {...item}
            refetch={refetch}
          />
        ))
      }
    </>
  );
}
