import { getCurrentChannel } from "global/hooks/channel/ChannelStore";
import { Middleware } from "redux";

const channelMiddleware: Middleware = (store) => (next) => (action) => {
  // Получаем текущий канал (используем `getCurrentChannel`)
  const currentChannel = getCurrentChannel();

  // Clone the action to avoid mutation
  const channelAction = { ...action };

  // Add the channel to the action
  if ("payload" in channelAction) {
    // If the action has a payload, add the channel inside the payload
    channelAction.payload = { ...channelAction.payload, channel: currentChannel };
  } else {
    // Otherwise, add the channel at the top level
    channelAction.channel = currentChannel;
  }

  return next(channelAction);
};

export default channelMiddleware;
