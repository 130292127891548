import { useState } from "react";
import styles from "./styles.module.scss";
import { LoginWindow } from "./components/login-window/LoginWindow";
import { ReactComponent as Close } from "assets/icons/close.svg";
import { ReactComponent as ArrowLeft } from "assets/user-page/arrow-left.svg";
import { RegisterWindow } from "./components/register-window/RegisterWindow";
import { t } from "i18next";
import ForgotPasswordWindow from "./components/forgot-password/ForgotPassword";

interface IAuth {
  onClose: (isOpen: false) => void,
  setAuthorized?: React.Dispatch<React.SetStateAction<boolean>>
}

export function AuthWindow({ onClose, setAuthorized }: IAuth) {
  const [isLogin, setIsLogin] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);

  return (
    <>
      <div className={styles.authContainer}>
        {isLogin ?
          <>
            <div className={styles.authContainer__head}>
              <ArrowLeft onClick={() => onClose(false)} className={styles.authContainer__arrow} />
              <h2 className={styles.authContainer__title}>{t('Login.Enter')}</h2>
              <button
                className={styles.closeBtn}
                onClick={() => onClose(false)}
              >
                <Close />
              </button>
            </div>
            <LoginWindow
              isLogin={setIsLogin}
              onClose={onClose}
              setIsForgotPassword={setIsForgotPassword}
              setAuthorized={setAuthorized}
            />
          </>
          : isForgotPassword
            ? <ForgotPasswordWindow
              onClose={onClose}
              isLogin={setIsLogin}
              setIsForgotPassword={setIsForgotPassword}
            />
            : <div className={styles.authContainer__wrapper}>
              <div className={styles.authContainer__head}>
                <ArrowLeft onClick={() => onClose(false)} className={styles.authContainer__arrow} />
                <h2 className={styles.authContainer__title}>{t("Auth.Registration")}</h2>
                <button
                  className={styles.closeBtn}
                  onClick={() => onClose(false)}
                >
                  <Close />
                </button>
              </div>
              <RegisterWindow
                isLogin={setIsLogin}
                onClose={onClose}
              />
            </div>
        }
      </div>
    </>
  )
}
