import { useContext, useEffect, useState } from "react";
import Tab from "global/components/tab/Tab";
import { t } from "i18next";
import { ProductPageContext } from "modules/product-page/services";
import { tabType } from "modules/product-page/types/TabTypes";

interface ITabHead {
  activeTab: tabType,
  setActiveTab: (tab: tabType) => void,
  webview?: boolean
}

export function TabHead({ activeTab, setActiveTab, webview }: ITabHead) {
  const product = useContext(ProductPageContext);
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsHidden((!product?.richContent && !product?.text && !product?.html && !sessionStorage.getItem('TTLStream')));
    }, 4000);
  }, [product.name]);

  return <>
    <Tab
      id={"product-description"}
      isHidden={isHidden}
      title={t("ProductPage.Description")}
      value="description"
      isActive={activeTab === "description"}
      onClick={(value) => setActiveTab(value)}
    />
    <Tab
      title={t("ProductPage.Specifications")}
      value="specifications"
      isActive={activeTab === "specifications"}
      onClick={(value) => setActiveTab(value)}
    />
    <Tab
      title={t("ProductPage.Reviews")}
      value="reviews"
      isActive={activeTab === "reviews"}
      onClick={(value) => setActiveTab(value)}
    />
    {product.videos && product.videos.length > 0 && (
      <Tab
        title={t("ProductPage.Video")}
        value="video"
        isActive={activeTab === "video"}
        onClick={(value) => setActiveTab(value)}
      />
    )}
    <Tab
      title={t("ProductPage.Availability in stores")}
      value="availability"
      isActive={activeTab === "availability"}
      onClick={(value) => setActiveTab(value)}
    />
    {
      !webview &&
      <Tab
        title={t("ProductPage.Installment plan")}
        value="installment"
        isActive={activeTab === "installment"}
        onClick={(value) => setActiveTab(value)}
      />
    }
  </>
}
