import SquareCheck, { ISquareCheck } from "./directive/SquareCheck";
import RoundCheck, { IRoundCheck } from "./directive/RoundCheck";
import RoundCheckmark, { IRoundCheckmark } from "./directive/RoundCheckmark";
import RadioWithImg, { IRadioWithImg } from "./directive/RadioWithImg";

type ACTION_TYPE =
  | {
    type: "square"
  } & ISquareCheck
  | {
    type: "round",
  } & IRoundCheck
  | {
    type: "round-checkmark",
  } & IRoundCheckmark
  | {
    type: "radio-with-img"
  } & IRadioWithImg

export default function Checkbox(props: ACTION_TYPE) {
  switch (props.type) {
    case "square":
      return <SquareCheck {...props} />

    case "round":
      return <RoundCheck {...props} />

    case "round-checkmark":
      return <RoundCheckmark {...props} />

    case "radio-with-img":
      return <RadioWithImg {...props} />

    default:
      return <></>
  }
}
