const LOCAL_STORAGE_ITEM_NAME = 'app-city';
const LOCAL_STORAGE_ITEM_NAME_SLUG = 'app-city-slug';

export const getCurrentCity = () => {
  const city = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
  return city;
}

export const setCurrentCity = (city: string) => {
  if (city!==undefined) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, city);
  }
  else{
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, '1');
  }
}

export const getCurrentCitySlug = () => {
  const citySlug = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME_SLUG);
  return citySlug;
}

export const setCurrentCitySlug = (citySlug: string) => {
  if (citySlug!==undefined) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME_SLUG, citySlug);
  }
  else{
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME_SLUG, "");
  }
}
