import { t } from "i18next";
import { ILogin } from "../types/login";

export function useLoginValidate(isEmail = false) {
  const errors = {
    phone: '',
    email: '',
    password: '',
    isValid: false,
  };

  return (values: ILogin) => {
    if (isEmail) {
      if (!values.email) {
        errors.email = t("Validation.The E-mail field is required");
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = t("Validation.Invalid email address");
      }
    }

    if (!isEmail) {
      if (!values.phone) {
        errors.phone = t("Validation.The Telephone field is required");
      } else if (values.phone.replace(/\D/ig, '').length !== 11) {
        errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 (XXX) XXX-XX-XX';
      }
    }

    if (!values.password) {
      errors.password = t("Validation.The field is required");
    } else if (values.password.length < 6) {
      errors.password = t("Validation.The field must be more than 8 characters");
    }

    errors.isValid = true;

    Object.values(errors).forEach(error => {
      if (typeof error === "string" && error.length > 0) {
        errors.isValid = false;
      }
    })

    return errors;
  }
};
