const LOCAL_STORAGE_ITEM_NAME = 'app-lang';
export const allowedLanguages = ['ru', 'kk'];

export const getCurrentLanguage = () => {
  const lang = localStorage.getItem(LOCAL_STORAGE_ITEM_NAME);
  return lang && allowedLanguages.includes(lang) ? lang : 'ru';
}

interface ILanguage {
  lang: string
}

export const setCurrentLanguage = ({ lang }: ILanguage) => {
  if (lang && allowedLanguages.includes(lang)) {

    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, lang);
  }
}
