import { useContext } from "react";
import {
  UseQueryResult,
  useQuery,
} from "@tanstack/react-query";
import { getData, postData } from "./dataFetching";
import LanguageContext from "global/hooks/language/LanguageContext";
import CityContext from "global/hooks/city/CityContext";
import { getCurrentChannel } from "global/hooks/channel/ChannelStore";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
const version = "v" + process.env.REACT_APP_VERSION || "v0.1.0";

interface IUseQueryBuilder<T> {
  url: string;
  page?: number;
  token?: string;
  isUserPage?: boolean;
  staleTime?: number;
  cacheTime?: number;
  dataJSON?: object | any;
  hasLocaleInUrl?: boolean;
  customUrl?: boolean;
  placeholderData?: T;
  enabled?: boolean;
  retry?: number | boolean;
  method?: string;
  onSuccess?: (e: any) => void;
  onError?: (err: any) => void;
  customLanguage?: string;
}

export function useQueryBuilder<T = any>({
  url = "",
  page = 0,
  staleTime = 100000,
  cacheTime = 100000,
  dataJSON,
  isUserPage,
  token,
  hasLocaleInUrl = false,
  customUrl = false,
  enabled,
  retry,
  placeholderData,
  method = "GET",
  onSuccess,
  onError,
  customLanguage,
}: IUseQueryBuilder<T>): UseQueryResult<T, unknown> {

  const langContext = useContext(LanguageContext || "ru");
  let lang = customLanguage ? customLanguage : langContext;
  const city = useContext(CityContext || "1");
  const { channel } = useContext(ChannelContext || CHANNELS.WEB);
  const localeObject = !hasLocaleInUrl ? { locale: lang } : "";

  let queryKey;
  if (dataJSON && dataJSON) {
    queryKey = [url, lang, page, dataJSON, city, version, channel];
  } else {
    queryKey = [url, lang, page, city, version, channel];
  }

  return useQuery({
    queryKey: queryKey, // url запроса в бэк
    queryFn: async () => {
      let data;
      if (method === "GET") {
        await getData({
          url: url,
          dataJSON: { ...localeObject, ...dataJSON },
          onSuccess: (e) => {
            data = e;
          },
          onError: (e) => {
            data = e;
            throw e;
          },
          isUserPage: isUserPage,
          token,
          customUrl,
          channel,
        });
      } else if (method === "POST") {
        await postData({
          url: url,
          dataJSON: { ...localeObject, ...dataJSON, page: page },
          onSuccess: (e) => {
            data = e;
          },
          onError: (e) => {
            data = e;
            throw e;
          },
          isUserPage: false,
          token,
          customUrl: true,
          channel,
        });
      }
      return data;
    }, // функция для запроса в бэк
    staleTime: staleTime, // время до следующего запроса
    cacheTime: cacheTime, // время хранения кэша
    onSuccess,
    onError,
    enabled: enabled,
    retry: retry,
    placeholderData: placeholderData,
  });
}

interface IUsePaginateQueryBuilder<T> extends IUseQueryBuilder<T> {
  isShowMore?: boolean;
  customUrl?: boolean;
  method?: string;
}

export function usePaginateQueryBuilder<T = any>({
  url = "",
  page = 1,
  staleTime = 100000,
  cacheTime = 100000,
  dataJSON,
  token,
  customUrl = false,
  method = "GET",
  enabled,
}: IUsePaginateQueryBuilder<T>): UseQueryResult<T, unknown> {
  const { channel } = useContext(ChannelContext || CHANNELS.WEB);
  const lang = useContext<string>(LanguageContext || "ru");
  const localeObject = !/\?/.test(url) ? { locale: lang } : {};
  let queryKey;
  if (dataJSON && dataJSON) {
    queryKey = [url, lang, page, dataJSON, version, channel];
  } else {
    queryKey = [url, lang, page, version, channel];
  }

  return useQuery({
    queryKey: queryKey, // url запроса в бэк
    queryFn: async () => {
      let data: any;
      if (method === "GET") {
        await getData({
          url: url,
          dataJSON: { ...localeObject, ...dataJSON, page: page },
          onSuccess: (e) => {
            data = e;
          },
          onError: (e) => {
            data = e;
          },
          isUserPage: false,
          token,
          customUrl: customUrl || false,
          channel,
        });
      } else if (method === "POST") {
        await postData({
          url: url,
          dataJSON: { ...localeObject, ...dataJSON, page: page },
          onSuccess: (e) => {
            data = e;
          },
          onError: (e) => {
            data = e;
          },
          isUserPage: false,
          token,
          customUrl: customUrl || false,
          channel,
        });
      }
      return await data;
    }, // функция для запроса в бэк
    staleTime: staleTime, // время до следующего запроса
    cacheTime: cacheTime, // время хранения кэша
    enabled: enabled,
    keepPreviousData: true,
  });
}
