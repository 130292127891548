import LanguageContext from "global/hooks/language/LanguageContext";
import { SeoDataContext } from "global/hooks/seo/SeoDataContext";
import { useUrlParser } from "global/hooks/useUrlParser";
import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import AlternateTag from "./AlternateTag";
import { useLocation, useParams } from "react-router";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { ChannelContext } from "global/hooks/channel/ChannelContext";


export default function Seo() {
  const { channel } = useContext(ChannelContext);
  const appUrl = process.env.REACT_APP_URL || "https://evrika.com/";
  const location = useLocation();
  const pathname = location.pathname.substring(1);
  const filters = useUrlParser({
    location: location.pathname,
    toFilter: true,
  })();
  let { id, slug } = useParams();
  const seoContext = useContext(SeoDataContext);
  const lang = useContext(LanguageContext);
  const [filtersKeyArray, setfiltersKeyArray] = useState<Array<any>>();
  const filtersCanonical = [
    "brand",
    "klass-noutbuka",
    "seriya",
    "model-smartfona",
    "model-smart-chasy",
    "seriyaplanshety",
    "tehnologiya1",
  ];
  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      let tempArray = [];
      for (const key in filters) {
        // @ts-ignore
        for (var i in filters[key]) {
          tempArray.push({
            key: decodeURIComponent(key),
            data: decodeURIComponent(filters[key][i]),
          });
        }
      }
      setfiltersKeyArray(tempArray);
    } else {
      setfiltersKeyArray([]);
    }
  }, []);
  //add canonical

  let canonical;
  let robotsIndex;
  if (pathname.includes("catalog") && !pathname.includes("filter")) {
    if(id && id[0]==="p"){
      let urlLang = lang==='kk'?'kk/':'';
      canonical = <link href={`${appUrl}${urlLang}catalog/${slug}/${id}` } rel="canonical"></link>;
    }else{
      canonical = <link href={appUrl + pathname} rel="canonical"></link>;
    }
    robotsIndex = <meta name="robots" content="index, follow"></meta>;
  } else if (pathname.includes("catalog") && pathname.includes("filter")) {
    const filterString = Object.keys(filters).map((key) => `${key}`);
    if (
      filterString.length === 1 &&
      filterString.some((element) => {
        return filtersCanonical.includes(element);
      }) &&
      filtersKeyArray?.length === 1 && filtersKeyArray[0].data!==""
    ) {
      canonical = <link href={appUrl + pathname} rel="canonical"></link>;
      robotsIndex = <meta name="robots" content="index, follow"></meta>;
    } else {
      //not canonical
      const pathLocale = pathname.split("filter/")[0];
      canonical = <link href={appUrl + pathLocale} rel="canonical"></link>;
      robotsIndex = <meta name="robots" content="noindex, nofollow"></meta>;
    }
  } else if (
    [
      "/quiz",
      "/checkout",
      "/orders",
      "/cabinet",
      "/online-product-orders",
      "/comparison",
      "/favorites",
      "/thanks",
      "&sort",
      "?sort",
      "page=",
    ].some((path) => location.pathname.includes(path))
  ) {
    canonical = (
      <link
        href={lang === "ru" ? appUrl : appUrl + lang}
        rel="canonical"
      ></link>
    );
    robotsIndex = <meta name="robots" content="noindex, nofollow"></meta>;
  } else {
    canonical = <link href={appUrl + pathname} rel="canonical"></link>;
    robotsIndex = <meta name="robots" content="index, follow"></meta>;
  }

   // for SEO alternate
   const pathsToCheck = ['/catalog/', '/sales/', '/news-page/', '/technical-questions/'];
   const checkedPath = pathsToCheck.some((substring) => location.pathname.includes(substring));

   switch (channel) {
    case CHANNELS.SAMSUNG:
      robotsIndex = <meta name="robots" content="noindex, nofollow"></meta>;
      break;   
    default:
      break;
   }
  return (
    <>
      {!checkedPath ? <AlternateTag /> : null}
    <Helmet>
      {canonical}
      {robotsIndex}
      <script type="application/ld+json">{`
            {
                "@context": "http://schema.org/",
                "@type": "Organization",
                "name": "Evrika",
                "logo": "https://evrika.com/ui-kit/evrika_vektor.svg",
                "url": "https://evrika.com/",
                "email": "support@evrika.com",
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": "ул. Саина 16А, уг. Толе би",
                    "addressLocality": "Almaty",
                    "addressRegion": "Almaty",
                    "postalCode": "050031",
                    "addressCountry": "KZ"
        
                },
                "contactPoint" : [
                            {
                                "@type" : "ContactPoint",
                                "telephone" : "+7 (771) 936-54-54",
                                "contactType" : "Call center"
                            }
                        ],
                "sameAs": [
                    "https://www.facebook.com/evrikacom",
                    "https://www.instagram.com/evrika.kazakhstan/",
                    "https://twitter.com/Evrikacom",
                    "https://www.youtube.com/channel/UCWagl_Sz3tU3IshjsXnhtlQ"
                ]
            }
        `}</script>
      <script type="application/ld+json">
        {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://evrika.com/",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://evrika.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            }
          }
        `}
      </script>
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebPage",
          name: seoContext?.title,
          description: seoContext?.description,
        })}
      </script>
    </Helmet>
    </>
  );
}
