import { DIALOG_ALERT, DIALOG_ERROR, DIALOG_HIDEN, DIALOG_SUCCESS } from "../../constants/alertTypes";

export interface IModalAlert {
  type: typeof DIALOG_ALERT | typeof DIALOG_SUCCESS | typeof DIALOG_ERROR | typeof DIALOG_HIDEN,
  text?: string,
  buttonText?: string,
  subtext?: string,
  id?: number,
  img?: string,
  slug?: string
}

export const showAlert = (text: string, buttonText?: string,  subtext?: string, id?: number, img?: string, slug?: string): IModalAlert => ({
  type: DIALOG_ALERT,
  text,
  subtext,
  buttonText,
  img,
  slug,
  id
});

export const showSuccess = (text: string, buttonText?: string,  subtext?: string, id?: number, img?: string, slug?: string): IModalAlert => ({
  type: DIALOG_SUCCESS,
  text,
  subtext,
  buttonText,
  img,
  slug,
  id
});

export const showError = (text: string, buttonText?: string,  subtext?: string, id?: number, img?: string, slug?: string): IModalAlert => ({
  type: DIALOG_ERROR,
  text,
  subtext,
  buttonText,
  img,
  slug,
  id
});

export const hideMessageModal = (): IModalAlert => ({ type: DIALOG_HIDEN });
