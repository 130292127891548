import { IDeliveryInfo } from "../actions/deliveryInfoActions";
import { createChannelState } from "../utils/createChannelState";

export interface IDeliveryInfoAction {
  type: "SET_DELIVERY" | "CLEAR_DELIVERY",
  delivery: IDeliveryInfo,
  channel?: string; // Added by middleware
}
// Dynamically generate the initial state for all channels
const initialState = createChannelState<IDeliveryInfo | null>(null);

const deliveryInfoReducer = (state = initialState, action: IDeliveryInfoAction) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }

  switch (action.type) {
    case "SET_DELIVERY":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.delivery, // Update only the specified channel's state
      };
    case "CLEAR_DELIVERY":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state;
  }
}

export default deliveryInfoReducer; 
