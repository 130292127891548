import Accordion from 'react-bootstrap/Accordion';
import styles from "../styles.module.scss";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";

export interface IAccordionCompare {
  eventKey: string,
  title: string,
  children: React.ReactNode,
}

export default function AccordionCompare({children, title, eventKey}: IAccordionCompare) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item className={styles.compareItem} eventKey={eventKey}>
        <Accordion.Header as="div" className={styles.filterTitle}>
          {title}
          <CurveDown className={styles.compareItem__icon} />
        </Accordion.Header>
        <Accordion.Body className={styles.compareBody}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
