import Accordion from 'react-bootstrap/Accordion';
import styles from "../styles.module.scss";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";

export interface IAccordionOrder {
  eventKey: string,
  title: React.ReactNode,
  children: React.ReactNode,
  onClick?: () => void,
  isActive?: boolean
}

export default function AccordionOrder({ children, title, eventKey, onClick, isActive }: IAccordionOrder) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item className={styles.orderItem} eventKey={eventKey}>
        <Accordion.Header as="div" onClick={onClick}>
          {title}
          <CurveDown className={styles.orderItem__icon} />
        </Accordion.Header>
        <Accordion.Body className={styles.orderBody}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
