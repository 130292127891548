import React from "react";
import { Helmet } from "react-helmet";

//Add dataLayer for GA4
declare global {
  interface Window {
    dataLayer: {
      push: (event: any) => void;
    };
  }
}

export default function Analytics() {
  return (
    <Helmet>
      <meta name="google-site-verification" content="KIih0daJF-xDB6-KTVnO0lJcW-KCpzjqPFcm9ONeDjU" />
      {/* <!-- Google Tag Manager --> */}
      <script>{`
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-P7F22CS");
    `}</script>
      {/* <!-- End Google Tag Manager --> */}

      {/* <!-- Google tag (gtag.js) --> */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-R4LBT73XHQ"
      ></script>
      <script>{`
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-R4LBT73XHQ");
    `}</script>
      {/* <!-- End Google tag (gtag.js) --> */}

      {/* <!-- Yandex.Metrika counter --> */}
      <script type="text/javascript">
        {`(function (m, e, t, r, i, k, a) {
          m[i] =
            m[i] ||
            function () {
              (m[i].a = m[i].a || []).push(arguments);
            };
          m[i].l = 1 * new Date();
          (k = e.createElement(t)),
            (a = e.getElementsByTagName(t)[0]),
            (k.defer = 1),
            (k.src = r),
            a.parentNode.insertBefore(k, a);
        })(
          window,
          document,
          "script",
          "https://mc.yandex.ru/metrika/tag.js",
          "ym"
        );

        ym(20129290, "init", {
          clickmap: false,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: false,
          ecommerce: "dataLayer",
        });`}
      </script>
      
      {/* <!-- Clarity start --> */}
      <script type="text/javascript">{`
      (function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = "https://www.clarity.ms/tag/" + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, "clarity", "script", "ixy9tg30fe");
    `}</script>
      {/* <!-- Clarity end --> */}
    </Helmet>
  );
}
