import { IUserToken } from "../actions/userTokenActions";

export interface IUserTokenAction {
  type: "SET_LOGIN" | "CLEAR_LOGIN",
  token: IUserToken
}

const userTokenReducer = (state: IUserToken | null = null, action: IUserTokenAction) => {
  switch (action.type) {
    case "SET_LOGIN":
      return action.token;

    case "CLEAR_LOGIN":
      return null;

    default:
      return state;
  }
}

export default userTokenReducer; 
