import iconSRC from "assets/icons/gift.png";
import BannerImg from "assets/banner.png";
import Order from "assets/icons/step-card/order.svg";
import { IData } from "modules/cart/components/step/StepItem";

export const colors = [
  {
    title: "Brand",
    arrColor: [
      {
        background: "bg-brand-primary",
        text: "brand-primary #00BCCC",
      },
      {
        background: "bg-brand-secondary",
        text: "brand-secondary #FB791B",
      },
      {
        background: "bg-brand-tertiary",
        text: "brand-tertiary #029AAD",
      },
      {
        background: "bg-brand-primary-shade",
        text: "brand-primary-shade #029AAD",
      },
      {
        background: "bg-brand-secondary-shade",
        text: "brand-secondary-shade #FF5A00",
      },
      {
        background: "bg-brand-tertiary-shade",
        text: "brand-tertiary-shade #FB791B",
      },
    ],
  },
  {
    title: "Neutrals",
    arrColor: [
      {
        background: "bg-neutrals-white",
        text: "neutrals-white #FFFFFF",
      },
      {
        background: "bg-neutrals-grey",
        text: "neutrals-grey #F2F2F2",
      },
      {
        background: "bg-neutrals-background",
        text: "neutrals-background #EAEAEA",
      },
      {
        background: "bg-neutrals-border-solid",
        text: "neutrals-border-solid #E0E0E0",
      },
      {
        background: "bg-neutrals-turquoise",
        text: "neutrals-turquoise #EAF4F6",
      },
    ],
  },
  {
    title: "Labels",
    arrColor: [
      {
        background: "bg-labels-blue",
        text: "labels-blue #009DDA",
      },
      {
        background: "bg-labels-light-blue",
        text: "labels-light-blue #4A76F6",
      },
      {
        background: "bg-brand-primary",
        text: "brand-primary #00bccc",
      },
      {
        background: "bg-labels-yellow",
        text: "labels-yellow #FFCD00",
      },
      {
        background: "bg-labels-red",
        text: "labels-red #FF3B30",
      },
      {
        background: "bg-labels-orange",
        text: "labels-orange #FB791B",
      },
    ],
  },
];

export const badgeData = [
  {
    withIcon: true,
    label: "gift",
    iconSrc: iconSRC,
    background: "labels-yellow",
  },
  {
    withIcon: false,
    label: "test",
    iconSrc: "",
    background: "labels-blue",
  },
  {
    withIcon: false,
    label: "testovich",
    iconSrc: "",
    background: "labels-light-blue",
  },
  {
    withIcon: false,
    label: "testoviy",
    iconSrc: "",
    background: "labels-red",
  },
  {
    withIcon: false,
    label: "testov",
    iconSrc: "",
    background: "labels-orange",
  },
  {
    withIcon: false,
    label: "Magic",
    iconSrc: "",
    background: "brand-primary",
  },
];

export const accordionData = [
  {
    title: 'Какие есть виды оплат для юридических лиц',
    children: 'Вариант 1. Банковским переводом на расчётный счёт компании «Эврика» по реквизитам, указанным в выставленном счёте.Вариант 2. Наличными, — только в розничном магазине.Вариант 3. Банковской картой, Kaspi, Halyk Bank'
  },
  {
    title: 'Какие есть виды оплат для юридических лиц',
    children: 'Вариант 1. Банковским переводом на расчётный счёт компании «Эврика» по реквизитам, указанным в выставленном счёте.Вариант 2. Наличными, — только в розничном магазине.Вариант 3. Банковской картой, Kaspi, Halyk Bank'
  },
  {
    title: 'Какие есть виды оплат для юридических лиц',
    children: 'Вариант 1. Банковским переводом на расчётный счёт компании «Эврика» по реквизитам, указанным в выставленном счёте.Вариант 2. Наличными, — только в розничном магазине.Вариант 3. Банковской картой, Kaspi, Halyk Bank'
  },
]
export const sliderData = [
  {
    name: "Последний звонок с продуктами Apple!",
    src: BannerImg,
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: "https://cdn.evrika.com/storage/slides/medium/vczEljddWFAkW2fEPwSkE4OlOhiW4pMGXcsmKokZ.jpg.webp?v=1683172020/f_auto",
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: BannerImg,
    url: "#"
  },
  {
    name: "Кондиционеры Klima",
    src: "https://cdn.evrika.com/storage/slides/medium/vczEljddWFAkW2fEPwSkE4OlOhiW4pMGXcsmKokZ.jpg.webp?v=1683172020/f_auto",
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: BannerImg,
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: "https://cdn.evrika.com/storage/slides/medium/vczEljddWFAkW2fEPwSkE4OlOhiW4pMGXcsmKokZ.jpg.webp?v=1683172020/f_auto",
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: BannerImg,
    url: "#"
  },
  {
    name: "Последний звонок с продуктами Apple!",
    src: "https://cdn.evrika.com/storage/slides/medium/vczEljddWFAkW2fEPwSkE4OlOhiW4pMGXcsmKokZ.jpg.webp?v=1683172020/f_auto",
    url: "#"
  },
]
export const menuFooterData = [
  {
    href: '#',
    title: 'О компании'
  },
  {
    href: '#',
    title: 'Корпоративные продажи'
  },
  {
    href: '#',
    title: 'Новости'
  },
  {
    href: '#',
    title: 'О компании'
  },
  {
    href: '#',
    title: 'Аренда торговых помещений'
  },
  {
    href: '#',
    title: 'Корпоративные продажи'
  },
  {
    href: '#',
    title: 'О компании'
  },
  {
    href: '#',
    title: 'Аренда торговых помещений'
  },
  {
    href: '#',
    title: 'Корпоративные продажи'
  },
]


export const footerData = [
  {
    title: 'Компания Эврика',
    eventKey: "1",
    menuItems: menuFooterData
  },
  {
    title: 'Покупателям',
    eventKey: "1",
    menuItems: menuFooterData
  },
  {
    title: 'Услуги и сервисы',
    eventKey: "1",
    menuItems: menuFooterData
  },
]

export const filterData = [
  {
    title: 'Наличие',
    eventKey: "0",
    menuItems: menuFooterData
  },
  {
    title: 'Цена',
    eventKey: "1",
    menuItems: menuFooterData
  },
  {
    title: 'Бренд',
    eventKey: "2",
    menuItems: menuFooterData
  },
]


export const dataDropdown = [
  {
    title: 'По популярности',
    href: 'popular'
  },
  {
    title: 'По убыванию цены',
    href: 'cost-desc'
  },
  {
    title: 'По возрастанию цены',
    href: 'cost-asc'
  },
  {
    title: 'По дате добавления',
    href: 'created-at'
  },
  {
    title: 'По рейтингу',
    href: 'rating'
  }
]

export const dataGender = [
  {
    title: 'Мужской',
    type: 'male'
  },
  {
    title: 'Женский',
    type: 'female'
  },
]

export const dataLang = [
  {
    title: 'Русский',
    type: 'ru'
  },
  {
    title: 'Казахский',
    type: 'kk'
  },
]

export const favoriteDropdown = [
  {
    title: 'По популярности',
    href: 'top'
  },
  {
    title: 'По дате добавления',
    href: 'novelty'
  },
  {
    title: 'От дешевых к дорогим',
    href: 'cheap'
  },
  {
    title: 'От дорогих к дешевым',
    href: 'expensive'
  }
]

export const giftsDropdown = [
  {
    title: '5000',
    type: '5000'
  },
  {
    title: '10000',
    type: '10000'
  },
  {
    title: '20000',
    type: '20000'
  },
  {
    title: '50000',
    type: '50000'
  }
]

export const dataShop = [
  {
    title: 'ул. Жангельдина, 15',
    href: '#/1'
  },
  {
    title: 'Тамерлановское шоссе, 4Б',
    href: '#'
  },
  {
    title: 'ул. Рыскулова, 22',
    href: '#'
  }
]

export const dataFilter = [
  {
    title: 'Apple',
    count: 15,
    link: '/catalog/1231231'
  },
  {
    title: 'Samsung',
    count: 15,
    link: '/catalog/1231232'
  },
  {
    title: 'Realme',
    count: 15,
    link: '/catalog/1231233'
  }
]

export const dataCart: IData[] = [
  {
    title: "Детали заказа",
    iconSRC: Order,
    type: "cart"
  },
  {
    title: "Контактная информация",
    iconSRC: Order,
    type: "contact-info"
  },
  {
    title: "Доставка",
    iconSRC: Order,
    type: "delivery"
  },
  {
    title: "Оплата",
    iconSRC: Order,
    type: "payment"
  }
]
