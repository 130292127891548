export function useBankCoefficientCount() {
  return function (bankCoefficient: string, value: number, cost: number) {
    let coef = 0.0833333333;
    switch (value) {
      case 3:
        coef = 0.3333333333;
        break;
      case 6:
        coef = 0.1666666667;
        break;
      case 12:
        coef = 0.0833333333;
        break;
      case 18:
        coef = 0.0555555556;
        break;
      case 24:
        coef = 0.0416666667;
        break;
      case 36:
        coef = 0.0400247;
        break;
      case 48:
        coef = 0.0334366;
        break;
      case 60:
        coef = 0.0296452;
        break;
      default:
        coef = 0.0833333333;
        break;
    }
    return (Math.round(coef * cost) * value <= cost ? Math.round(coef * cost) + 1 : Math.round(coef * cost));
  };
}
