import { t } from "i18next";
import { IRegister } from "../types/register";

export function useRegisterValidate() {
  const errors: IRegister = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    birthday: '',
    password: '',
    privacy: '',
    confirmPassword: '',
    isValid: false,
  };

  return (values: IRegister) => {
    if (!values.name) {
      errors.name = t("Validation.The Name field is required");
    } else if (values.name.replace(/[а-яё]/gi,'')) {
      errors.name = "Поле Имя должно содержать только кириллицу.";
    } else if (/(ев|ева|ов|ова)/u.test(values.name)) {
      errors.name = "Имя не может быть фамилией";
    } else if (values.name.length < 2) {
      errors.name = "Количество символов в поле Имя должно быть не менее 2.";
    } else if (values.name.length > 50) {
      errors.name = "Количество символов в поле Имя не может превышать 50.";
    } 

    if (!values.surname) {
      errors.surname = t("Validation.The Last name field is required");
    } else if (values.surname.replace(/[а-яё]/gi,'')) {
      errors.surname = "Поле Фамилия должно содержать только кириллицу.";
    } else if (values.surname.length < 2) {
      errors.name = "Количество символов в поле Имя должно быть не менее 2.";
    } else if (values.surname.length > 50) {
      errors.name = "Количество символов в поле Имя не может превышать 50.";
    } 

    if (!values.privacy) {
      errors.privacy = t("Validation.This field is required");
    }

    if (!values.birthday) {
      errors.birthday = t("Validation.The Birthday field is required");
    }

    if (!values.email) {
      errors.email = t("Validation.The E-mail field is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t("Validation.Invalid email address");
    }

    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 XXX XXX XX XX';
    }

    if (!values.password) {
      errors.password = t("Validation.The Name field is required");
    } else if (values.password.length < 6) {
      errors.password = t("Validation.Passwords must have at least 6 characters");
    } else if (!/[A-Za-z]/.test(values.password)) {
      errors.password = t("Validation.The password must contain Latin letters");
    } else if (!/[0-9]/.test(values.password)) {
      errors.password = t("Validation.The password must contain at least 1 digit");
    } else if (!/[A-Z]/.test(values.password)) {
      errors.password = t("Validation.The password must contain capital letters");
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = t("Validation.The field is required");
    } else if (values.confirmPassword !== values.password) {
      errors.confirmPassword = t("Validation.Password mismatch");
    }

    errors.isValid = true;

    Object.values(errors).forEach(error => {
      if (typeof error === "string" && error.length > 0) {
        errors.isValid = false;
      }
    })

    return errors;
  }
};
