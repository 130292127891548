import Accordion from 'react-bootstrap/Accordion';
import styles from "../styles.module.scss";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";

export interface IAccordionProps {
  eventKey: string,
  title: string,
  children: React.ReactNode,
  lastItemIcon?: any,
}

export default function AccordionProductPage({ title, children, eventKey, lastItemIcon }: IAccordionProps) {
  return (
    <Accordion className={styles.wrapper} flush>
      <Accordion.Item className={styles.item} eventKey={eventKey}>
        <div className={styles.count}>{lastItemIcon ? lastItemIcon : eventKey}</div>
        <Accordion.Header as="div">
          {title}
          <CurveDown className={styles.item__icon} />
        </Accordion.Header>
        <Accordion.Body className={styles.body}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
