import { IFavoritesInfo, setFavoritesInfo } from "global/redux/actions/favoritesInfoActions";
import { IModalAlert } from "global/redux/actions/messageModalActions";
import { IFavoritesInfoAction } from "global/redux/reducers/favoritesInfo";
import { t } from "i18next";
import { Dispatch } from "react";
import { toast } from "react-toastify";

export function addFavorites(
  id: number,
  img: string,
  slug: string,
  favoritesInfo: IFavoritesInfo[] | null,
  dispatch: Dispatch<IFavoritesInfoAction>,
  token: string,
  showAlert: any
) {
  if (token && id > 0) {
    if (favoritesInfo) {
      dispatch(setFavoritesInfo([...favoritesInfo, { id, img, slug }]) as IFavoritesInfoAction);
      toast.success(t("Notification.The product has been added to favorites"));
    } else {
      dispatch(setFavoritesInfo([{ id, img, slug }]) as IFavoritesInfoAction);
      toast.success(t("Notification.The product has been added to favorites"));
    }
  } else if (id > 0) {
    dispatch(showAlert("", t("Cart.Log in"), t('Favorites.To add a product to your favorites, you need to log in'), id, img, slug))
  }
}
