import Checkbox from "global/components/checkbox/Checkbox";
import styles from "./styles.module.scss";
import InputForm from "global/components/input/InputForm";
import { useFormik } from "formik";
import { IRegister, IRegisterData } from "modules/auth/types/register";
import { useRegisterValidate } from "modules/auth/hooks/useRegisterValidate";
import i18next, { t } from "i18next";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { useNavigate } from "react-router";
import { ROUTE_CABINET, ROUTE_PRIVACY_POLICY } from "router/routes";
import { useState, useEffect, useRef, useContext } from "react";
import Timer from "modules/broker/components/broker-otp/Timer";
import { setUserToken } from "global/redux/actions/userTokenActions";
import { IUserTokenAction } from "global/redux/reducers/userToken";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import getUrl from "global/hooks/getUrl";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

interface IRegisterWindow {
  isLogin: (login: true) => void,
  onClose: (isOpen: false) => void,
  isCheckout?: boolean
}

export function RegisterWindow({ isLogin, onClose, isCheckout }: IRegisterWindow) {
  const lang = i18next.language;
  const [resend, setResend] = useState(true);
  const navigate = useNavigate();
  const [errorText, setErrorText] = useState("");
  const [showSms, setShowSms] = useState(false);
  const [legal, setLegal] = useState("individual");
  const [gender, setGender] = useState("male")
  const dispatch = useDispatch();
  const validate = useRegisterValidate();
  const minutes = Number(process.env.REACT_APP_MINUTES || 2);
  const length = 4;
  const { channel } = useContext(ChannelContext)

  const [code, setCode] = useState(Array(length).fill(''));
  const inputRefs: any = useRef([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setResend(false)
    }, minutes * 60 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [resend])

  const formik = useFormik<IRegister>({
    initialValues: {
      phone: '',
      email: '',
      name: '',
      surname: '',
      password: '',
      birthday: '',
      privacy: '',
      confirmPassword: '',
      isValid: false
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: IRegister) => postData({
      url: `auth/check`,
      data: data,
      onSuccess(e) {
        setShowSms(true);
        console.log(e);
      },
      onError(e: any) {
        if (e.response?.data.details.phone) {
          formik.errors.phone = t("Auth.A user with this phone number already exists");
        }
        if (e.response?.data.details.email) {
          formik.errors.email = t("Auth.A user with this email already exists");
        }
      },
      channel
    })
  });

  const register = useMutation({
    mutationFn: (data: IRegisterData) => postData<{
      token: string,
      token_expires_at: string,
      user: {
        id: number,
        name: string,
        surname: string,
        email: string,
        phone: string,
      }
    }>({
      url: `auth/register`,
      data: data,
      onSuccess(e) {
        dispatch(setUserToken({
          token: e.data.token,
          token_expires_at: e.data.token_expires_at,
          id: e.data.user.id,
          firstName: e.data.user.name,
          lastName: e.data.user.surname,
          email: e.data.user.email,
          phone: e.data.user.phone
        }) as IUserTokenAction);
        onClose(false);
        navigate(getUrl({currentUrl:`/${ROUTE_CABINET}`,channelToSet:channel, lang}));
      },
      onError(e) {
        setErrorText(t('Login.The code is incorrect'));
      },
      channel
    })
  });

  useEffect(() => {
    if (code.every(element => element !== '')) {
      registerSend()
    }
  }, [code]);

  function send(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (formik.errors.isValid) {
      mutate({
        "name": formik.values.name,
        "surname": formik.values.surname,
        "birthday": formik.values.birthday.split("-").reverse().join('.'),
        "phone": formik.values.phone.replace(/[\s,\(\)\-]/ig, ''),
        "email": formik.values.email,
        "password": formik.values.password
      });
    }
  }

  const resendSms = () => {
    mutate({
      "name": formik.values.name,
      "surname": formik.values.surname,
      "birthday": formik.values.birthday.split("-").reverse().join('.'),
      "phone": formik.values.phone.replace(/[\s,\(\)\-]/ig, ''),
      "email": formik.values.email,
      "password": formik.values.password
    });
    setResend(true);
  }

  function registerSend() {
    register.mutate({
      "isLegal": legal === "individual" ? false : true,
      "name": formik.values.name,
      "surname": formik.values.surname,
      "birthday": formik.values.birthday.split("-").reverse().join('.'),
      "phone": formik.values.phone.replace(/[\s,\(\)\-]/ig, ''),
      "email": formik.values.email,
      "password": formik.values.password,
      "gender": gender,
      "code": code.join("")
    });
  }

  return (
    <>
      {
        !showSms
          ? <form
            onSubmit={send}
            className={styles.auth}
          >
            <div className={styles.checkboxWrapper}>
              <Checkbox
                type="round"
                value="individual"
                onChange={() => setLegal("individual")}
                name="isLegal"
                label={t("Auth.Physical person")}
                checked={legal === "individual"}
                className={styles.auth__checkbox}
              />
              <Checkbox
                type="round"
                value="legal"
                onChange={() => setLegal("legal")}
                name="isLegal"
                label={t("Auth.Legal entity")}
                checked={legal === "legal"}
                className={styles.auth__checkbox}
              />
            </div>
            <div>
              <InputForm
                type="tel"
                mask='+7 999 999 99 99'
                label={t("Global.Telephone")}
                name="phone"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                className={styles.auth__loginNick + ` ${formik.errors.phone && formik.touched.phone && styles.errorInput}`}
              />
              {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
            </div>
            <div>
              <InputForm
                type={"password"}
                label={t("Auth.Password")}
                name="password"
                mask=''
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.auth__loginNick + ` ${formik.errors.password && formik.touched.password && styles.errorInput}`}
                isError={(formik.errors.password && formik.errors.password?.length > 0) || false}
              />
              {formik.errors.password && formik.touched.password ? <div className={styles.errorText}>{formik.errors.password}</div> : null}
            </div>
            <div>
              <InputForm
                type={"password"}
                label={t("Auth.Confirm the password")}
                name="confirmPassword"
                mask=''
                value={formik.values.confirmPassword || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={styles.auth__loginNick + ` ${formik.errors.confirmPassword && formik.touched.confirmPassword && styles.errorInput}`}
                isError={(formik.errors.confirmPassword && formik.errors.confirmPassword?.length > 0) || false}
              />
              {formik.errors.confirmPassword && formik.touched.confirmPassword ? <div className={styles.errorText}>{formik.errors.confirmPassword}</div> : null}
            </div>
            <div>
              <InputForm
                type="email"
                label="Email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                onBlur={formik.handleBlur}
                className={styles.auth__loginNick + ` ${formik.errors.email && formik.touched.email && styles.errorInput}`}
              />
              {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
            </div>
            <div>
              <InputForm
                type="text"
                label={t("Global.Name")}
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
                onBlur={formik.handleBlur}
                className={styles.auth__loginNick + ` ${formik.errors.name && formik.touched.name && styles.errorInput}`}
              />
              {formik.errors.name && formik.touched.name ? <div className={styles.errorText}>{formik.errors.name}</div> : null}
            </div>
            <div>
              <InputForm
                type="text"
                label={t("Global.Surname")}
                name="surname"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.surname}
                className={styles.auth__loginNick + ` ${formik.errors.surname && formik.touched.surname && styles.errorInput}`}
              />
              {formik.errors.surname && formik.touched.surname ? <div className={styles.errorText}>{formik.errors.surname}</div> : null}
            </div>
            <div>
              <input
                type="date"
                name="birthday"
                className={styles.input + ` ${(formik.errors.birthday && formik.touched.birthday) ? styles.errorInput : styles.inputDate}`}
                max={new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toLocaleDateString().split(".").reverse().join('-')}
                min={new Date(new Date().setFullYear(1900)).toLocaleDateString().split(".").reverse().join('-')}
                value={formik.values.birthday.split(".").reverse().join('-')}
                onChange={formik.handleChange}
              />
              {formik.errors.birthday && formik.touched.birthday ? <div className={styles.errorText}>{formik.errors.birthday}</div> : null}
            </div>
            <div className={styles.checkboxWrapper}>
              <Checkbox
                type="round"
                value="male"
                onChange={() => setGender("male")}
                name="form"
                label={t("Global.Male")}
                checked={gender === "male"}
                className={styles.auth__checkbox}
              />
              <Checkbox
                type="round"
                value="female"
                onChange={() => setGender("female")}
                name="form"
                label={t("Global.Female")}
                checked={gender === "female"}
                className={styles.auth__checkbox}
              />
            </div>
            <div>
              <div className={styles.auth__remember}>
                <Checkbox
                  type='square'
                  value={formik.values.privacy || ''}
                  onChange={formik.handleChange}
                  name='privacy'
                  className={styles.auth__checkbox}
                  label={
                    <span
                      className={styles.auth__rememberCheck}
                    >
                      {t("Global.I accept")}
                      <Link
                        className={styles.auth__rememberLink}
                        to={ROUTE_PRIVACY_POLICY}
                        target="_blank"
                      >
                        {t("Global.privacy policy")}
                      </Link>
                    </span>
                  }
                />
              </div>
              {formik.errors.privacy && formik.touched.privacy ? <div className={styles.errorText}>{formik.errors.privacy}</div> : null}
            </div>
            <button type="submit" className={"button " + styles.orangeBtn}>
              {isLoading ?
                <Spinner animation="border" variant="light" size="sm" />
                : <>
                  {t("Auth.Get an SMS code")}
                </>
              }
            </button>
            {
              !isCheckout &&
              <span className={styles.auth__register}>
                {t("Auth.Do you already have an account?")}
                <button
                  type="button"
                  className={styles.auth__blueText}
                  onClick={() => isLogin(true)}
                >
                  {t('Login.Enter')}
                </button>
              </span>
            }
          </form>
          : <div>
            <div className={styles.code_text}>{t("Login.Enter the code from the SMS")}</div>
            <div className={styles.wrapperInput}>
              <InputForm
                type="code"
                code={code}
                setCode={setCode}
                inputRefs={inputRefs}
              />
              {
                errorText.length > 0 &&
                <div className={styles.errorText}>{errorText}</div>
              }
            </div>
            <button className={"button " + styles.orangeBtn} onClick={registerSend}>
              {register.isLoading ?
                <Spinner animation="border" variant="light" size="sm" />
                : <>
                  {t("Auth.Registration")}
                </>
              }
            </button>
            <div className={styles.linkWrapper}>
              {
                resend
                  ? <Timer targetDate={new Date(new Date().setMinutes(new Date().getMinutes() + minutes)).getTime()} />
                  : <div className={styles.linkText} onClick={resendSms}>{t("BrokerOtp.Resend SMS with a code")}</div>
              }
            </div>
          </div>
      }
    </>
  )
}
