import Checkbox from "global/components/checkbox/Checkbox";
import styles from "./styles.module.scss";
import InputForm from "global/components/input/InputForm";
import { useState,useContext } from "react";
import { useFooterValidate } from "modules/footer/hooks/useFooterValidate";
import { useAppSelector } from "global/hooks/reduxHooks";
import { useFormik } from "formik";
import { useMutation } from "@tanstack/react-query";
import { IFooterSubscribe } from "modules/footer/types/footerSubscribe";
import { postData } from "global/scripts/dataFetching";
import i18next, { t } from "i18next";
import { ReactComponent as ButtonIcon } from "assets/footer/arrow-right.svg";
import { toast } from "react-toastify";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export default function FormFooter() {
  const { channel } = useContext(ChannelContext);
  const lang = i18next.language;
  const [value, setValue] = useState("phone");
  const validate = useFooterValidate(value === 'email');
  const userToken = useAppSelector(state => state.userToken);

  const formik = useFormik({
    initialValues: {
      email: userToken?.email || '',
      phone: userToken?.phone || '',
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate } = useMutation({
    mutationFn: (data: IFooterSubscribe) => postData({
      url: `newsletter/subscribe`,
      data: data,
      onSuccess(e) {
        formik.resetForm();
        toast.success(t("Review.The form has been successfully submitted!"));
      },
      onError(e) {
        toast.error(e.message);
      },
      channel
    })
  });

  function submitEmail(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!formik.errors.email) {
      mutate({
        email: formik.values.email,
        locale: lang
      });
    }
  }

  function submitPhone(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!formik.errors.phone) {
      mutate({
        phone: `+${formik.values.phone.replace(/\D/ig, '')}`,
        locale: lang
      });
    }
  }
  return (
    <>
      <div className={styles.checkboxWrapper}>
        <Checkbox
          type="round"
          value="phone"
          onChange={() => setValue("phone")}
          name="form"
          label={t("Global.Phone number")}
          checked={value === "phone"}
        />
        <Checkbox
          type="round"
          value="email"
          onChange={() => setValue("email")}
          name="form"
          label="Email"
          checked={value === "email"}
        />
      </div>
      {
        value === "phone"
          ? <form onSubmit={submitPhone}>
            <div className={styles.input__wrapper}>
              <label style={{"display": "block"}}>
                <InputForm
                  type="tel"
                  mask='+7 999 999 99 99'
                  label={t("Global.Phone number")}
                  name="phone"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.phone}
                  className={styles.input + ` ${formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}`}
                />
              </label>
              <button className={styles.input__icon } aria-label={t('Global.Submit')}><ButtonIcon /></button>
            </div>
            {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
          </form>
          : <form onSubmit={submitEmail}>
            <div className={styles.input__wrapper}>
              <label style={{"display": "block"}}>
                <InputForm
                  type="email"
                  label="Ваш E-mail"
                  name="email"
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  value={formik.values.email}
                  className={styles.input + ` ${formik.errors.email && formik.touched.email ? styles.errorInput : ''}`}
                />
              </label>
              <button className={styles.input__icon} aria-label={t('Global.Submit')}><ButtonIcon /></button>
            </div>
            {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
          </form>
      }
    </>
  )
}
