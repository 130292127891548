export interface IGiftCardsForCheckout {
  series: string | null,
  amount: number | null
}

export const setGiftCards = (card: IGiftCardsForCheckout[]) => {
  return {
    type: "SET_GIFT_CARDS",
    card: card
  }
}

export const clearGiftCards = () => ({
  type: "CLEAR_GIFT_CARDS",
})
