import { useState, useContext } from "react";
import styles from "../styles.module.scss";
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export interface ISliderThumbs {
  image: string,
  alt?: string,
}

export function SliderThumbs({ image, alt = "Evrika image" }: ISliderThumbs) {
  const { channel } = useContext(ChannelContext);
  const [imageIsLoaded, setImageIsLoaded] = useState(true)
  const imageOnErrorHandler = () => {
    setImageIsLoaded(false)
  };

  return <>
    {imageIsLoaded
      ? <img className={styles.thumbsImg} src={image} alt={alt} onError={imageOnErrorHandler}/>
      : <img className={styles.image} src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt={alt} />
    }
  </>
}
