import { SetStateAction, useContext, useEffect, useState } from "react";
import { useAppSelector } from "global/hooks/reduxHooks";
import { Link } from "react-router-dom";
import { ROUTE_CART } from "router/routes";
import EvrikaLogo from "assets/icons/step-card/evrika-logo.png";
import styles from "./styles.module.scss";
import { removeProductInfo } from "global/redux/actions/productInfoActions";
import { IProductInfoAction } from "global/redux/reducers/productInfo";
import { ReactComponent as CartIcon } from "assets/icons/cart.svg";
import i18next, { t } from "i18next";
import { useCost } from "global/hooks/useCost";
import { ReactComponent as PlusIcon } from "assets/cart/plus.svg";
import { ReactComponent as GiftIcon } from "assets/icons/gift.svg";
import CartProductQuantity from "global/components/cart-product-quantity/CartProductQuantity";
import CityContext from "global/hooks/city/CityContext";
import getUrl from "global/hooks/getUrl";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

interface ICartPopup {
  mutate?: () => void,
  setShow: React.Dispatch<SetStateAction<boolean>>
}

export default function CartPopup({ mutate, setShow }: ICartPopup) {
  const lang = i18next.language;
  const dispatch = useDispatch();
  const { channel } = useContext(ChannelContext)
  const productInfo = useAppSelector(state => state.productInfo[channel]);
  const { citySlug } = useContext(CityContext)

  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const imageOnErrorHandler = () => {
    setImageIsLoaded(false);
  };

  const costFn = function (this: any, str: number) {
    return useCost.apply(this, [str]);
  }

  return (
    (productInfo && productInfo?.length > 0)
      ? <>
        <div className={styles.wrapper}>
          {
            productInfo && productInfo.map((item, i) => (
              <div className={styles.productWrapper} key={item.id}>
                <div className={((item.gift && Object.keys(item.gift).length != 0) || (item.gifts && item.gifts.length > 0)) ? styles.cart__mini_with__gift : ''}>
                  <Link to={getUrl({currentUrl:`/catalog/${citySlug==""?"":citySlug+"/"}${item.slug}/p${item.id}`, lang, channelToSet:channel})} className={styles.cart__mini__image} onClick={() => setShow(false)}>
                    {
                      imageIsLoaded
                        ? <img className={styles.productImage} title={item.name} src={item.images[0]} alt={item.name} onError={imageOnErrorHandler} />
                        : <div title={item.name} className={styles.imageWrapper}>
                          <img className={styles.image} src={EvrikaLogo} alt={item.name} />
                        </div>
                    }
                  </Link>
                  {
                    (item.selected_gifts && item.selected_gifts.length > 0) &&
                    <>
                      <div className={styles.cart__mini_plus__icon}><PlusIcon /></div>
                      <Link to={getUrl({currentUrl:`/catalog/${citySlug==""?"":citySlug+"/"}${item.gift ? item.gift.slug : item.selected_gifts[0].slug}/p${item.gift ? item.gift.id : item.selected_gifts[0].id}`, lang, channelToSet:channel})} onClick={() => setShow(false)} className={styles.cart__mini__image}>
                        {
                          imageIsLoaded
                            ? <img
                              className={styles.productImage}
                              title={item.gift ? item.gift.name : item?.selected_gifts[0].name}
                              src={item.gift ? item.gift.images[0] : item?.selected_gifts[0].images ? item?.selected_gifts[0]?.images[0] : EvrikaLogo}
                              alt={item.gift ? item.gift.name : item?.selected_gifts[0].name}
                              onError={imageOnErrorHandler}
                            />
                            : <div title={item.gift ? item.gift.name : item?.selected_gifts[0].name} className={styles.imageWrapper}>
                              <img className={styles.image} src={EvrikaLogo} alt={item.gift ? item.gift.name : item.selected_gifts[0].name} />
                            </div>
                        }
                        <span className={styles.cart__mini_gift__icon}><GiftIcon /></span>
                      </Link>
                    </>
                  }
                </div>
                <div className={styles.contentWrapper}>
                  <div className={styles.titleWrapper}>
                    <Link className={styles.title} to={getUrl({currentUrl:`/catalog/${citySlug==""?"":citySlug+"/"}${item.slug}/p${item.id}`, lang, channelToSet:channel})} onClick={() => setShow(false)}>{item.name}</Link>
                    <CartProductQuantity
                      id={item.id}
                      maxCount={item.stock || 0}
                      img={item.images[0]}
                      slug={item.slug}
                      disabled={!item.availableForPurchase && !item.availableForPurchaseFromDc}
                      popup
                      setShow={setShow}
                    />
                  </div>
                  <div className={styles.titleWrapper}>
                    <div className={styles.count}>{item.quantity} шт</div>
                    <div className={styles.cost}>{costFn(item.cost)} ₸</div>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        <Link className={styles.button} to={getUrl({currentUrl:ROUTE_CART, lang,channelToSet:channel})} onClick={() => { mutate && mutate(); setShow(false); }}>{t("Cart.Place an order")}</Link>
      </>
      : <div className={styles.emptyCart}>
        <CartIcon className={styles.emptyCartIcon} />
        <div>
          <div className={styles.emptyCartTitle}>{t("Cart.Your shopping cart is empty")}</div>
          <div className={styles.emptyCartSubtitle}>{t("Cart.Add your favorite products to the cart")}</div>
        </div>
      </div>
  )
}
