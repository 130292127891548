import { ReactComponent as ArrowSvg } from 'assets/icons/arrow.svg';
import styles from "../styles.module.scss";
import { Link } from 'react-router-dom';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { ROUTE_CATALOG } from 'router/routes';
import { ICategoriesMenu } from 'global/types/CategoriesMenu';
import { t } from "i18next";
import { useContext, useState } from 'react';
import CityContext from 'global/hooks/city/CityContext';

export interface ICategoriesMenuLink {
  categoryName: string,
  categoryHref: string,
  categoryTitle: string,
  categoryIcon: string,
  categoryLinks: ICategoriesMenu[],
  hidden: boolean,
  selectedCity?: string,
  setHidden: (e: boolean) => void
}

export default function 
CategoriesMenuLink({
  categoryName,
  categoryIcon,
  categoryHref,
  categoryTitle,
  categoryLinks,
  hidden,
  selectedCity ="",
  setHidden
}: ICategoriesMenuLink) {
  const { citySlug } = useContext(CityContext);
  return (
    <div
      className={styles.linkContainer}
    >
      <Link to={categoryHref} className={styles.link}>
        <img src={categoryIcon} alt="" />
        {categoryName}
        <ArrowSvg className={styles.arrow} />
      </Link>
      <div
        className={styles.content}
      >
        <div className={styles.contentHead}>
          <span className={styles.title}>
            {categoryTitle}
          </span>
          <Link to={ROUTE_CATALOG + citySlug} className={styles.allCategoriesContent}>
            {t("CategoriesMenu.All categories")}
            <ArrowSvg className={styles.arrow} />
          </Link>
        </div>
        <ul className={styles.contentLinks}>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 900: 3, 1080: 4 }}
          >
            <Masonry
              columnsCount={4}
              gutter='10px'
            >
              {categoryLinks.map((link, x) => (
                <li className={styles.linkItems} key={x}>
                  <Link to={`catalog/${citySlug == ""? '': citySlug+'/'}${link.slug ? link.slug : ''}/c${link.id}`} className={styles.topLink}>{link.name}</Link>
                  {link.children.map((item, y) => (
                    <Link to={`catalog/${citySlug == ""? '': citySlug+'/'}${item.slug ? item.slug : ''}/c${item.id}`} className={styles.otherLinks} key={y}>{item.name}</Link>
                  ))}
                </li>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        </ul>
      </div>
    </div>
  )
}
