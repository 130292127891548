import styles from './styles.module.scss';
import { MouseEvent, useState, useContext } from "react";
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { useMediaQuery } from 'react-responsive';
import { breakpointDF } from 'global/hooks/constants';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';

export interface IZoomSlide {
  image: string,
  zoomSettings: {
    zoomWidth: number,
    zoomHeight: number,
    topPadding: number
  }
  className?: string,
  alt?: string,
  onClick?: () => void,
}

export default function ZoomSlide({
  image,
  alt = "Evrika image",
  onClick = () => null,
  zoomSettings,
  className
}: IZoomSlide) {
  const { channel } = useContext(ChannelContext);
  const [loop, setLoop] = useState({
    backgroundImage: `url()`,
    backgroundPosition: '0% 0%'
  });
  const isTablet = useMediaQuery(breakpointDF);
  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const imageOnErrorHandler = () => {
    setImageIsLoaded(false)
  };

  const handleMouseMove = (e: MouseEvent<HTMLElement>) => {
    const node = e.target as HTMLElement;
    const { left, top, width, height } = node.getBoundingClientRect();
    const x = (e.pageX - left) / width * zoomSettings.zoomWidth;
    const y = (e.pageY - top - zoomSettings.topPadding) / height * zoomSettings.zoomHeight;
    setLoop({
      backgroundImage: `url(${image})`,
      backgroundPosition: `${x}% ${y}%`
    });
  }

  return (
    <div
      className={styles.sliderSlide + ' ' + className}
      onMouseMove={handleMouseMove}
      style={!isTablet ? loop : {}}
      onClick={onClick}
    >
      {imageIsLoaded
        ? <img src={image} alt={alt} onError={imageOnErrorHandler} />
        : <img className={styles.image} src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt={alt} />
      }
    </div>
  )
}
