import Accordion from 'react-bootstrap/Accordion';
import styles from "../styles.module.scss";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";

export interface IAccordionAdaptive {
  eventKey: string,
  title: React.ReactNode,
  children: React.ReactNode,
  className: string,
  headerClass?: string,
  bodyClass?: string,
}

export default function AccordionAdaptive({
  children,
  title,
  eventKey,
  className,
  headerClass,
  bodyClass
}: IAccordionAdaptive) {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item className={`${styles.adaptive} ${className}`} eventKey={eventKey}>
        <Accordion.Header as="div" className={`${styles.filterTitle} ${headerClass ?? ''}`}>
          {title}
        </Accordion.Header>
        <Accordion.Body className={`${styles.adaptiveBody} ${bodyClass ?? ''}`}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
