import styles from '../../styles.module.scss';

export interface IInputEmail {
  type: "email",
  className?: string,
  name: string,
  label: string,
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  disabled?: boolean
}

export default function InputEmail({ label, name, onChange, onBlur, value, className, disabled }: IInputEmail) {
  return (
    <div className={styles.wrapper}>
      <input
        className={styles.input + ' ' + className} 
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type='email'
        disabled={disabled}
      />
      <label className={value.length > 0 ? styles.label : ''}>{label}</label>
    </div>
  )
}
