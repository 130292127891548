import Help from "modules/help/Help";
import Layout from "modules/layout/Layout";
import LoadingScreen from "modules/loading/LoadingScreen";
import WebviewProductPage from "modules/product-page/webview/WebviewProductPage";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  ROUTE_CATALOG,
  ROUTE_CATALOG_SLUG,
  ROUTE_SALE_ITEM,
  ROUTE_ABOUT,
  ROUTE_HELP,
  ROUTE_CATALOG_SLUG_FILTERED,
  ROUTE_CHECKOUT,
  ROUTE_CITY,
  ROUTE_SEARCH,
  ROUTE_THANKS,
  ROUTE_BROKER_OTP,
  ROUTE_BROKER_OFFERS,
  ROUTE_COMPARE,
  ROUTE_COMPARE_SLUG,
  ROUTE_VACANCIES,
  ROUTE_FAQ,
  ROUTE_CABINET,
  ROUTE_CABINET_SLUG,
  ROUTE_ORDERS,
  ROUTE_ONLINE_PRODUCT_ORDERS,
  ROUTE_TECHNICAL_QUESTIONS,
  ROUTE_NEWS_PAGE,
  ROUTE_ARTICLE_PAGE,
  ROUTE_TECHNICAL_QUESTIONS_SLUG,
  ROUTE_SHOPS_PAGE,
  ROUTE_NEWS_ITEM_PAGE,
  ROUTE_BRANDS_ITEM_PAGE,
  // ROUTE_OFFER,
  // ROUTE_OFFER_FOR_SMS_CONFIRMATION,
  ROUTE_BONUSES,
  // ROUTE_SMART_PROTECT,
  // ROUTE_SMART_SERVICE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_RETURN_AND_EXCHANGE,
  ROUTE_SERVER_ERROR,
  ROUTE_SERVICE_PRICE,
  ROUTE_SMART_MARKET,
  ROUTE_SMART_GIFTS,
  ROUTE_SMART_GIFTS_SLUG,
  ROUTE_CREDIT_AND_INSTALLMENT,
  ROUTE_SITEMAP,
  ROUTE_DELIVERY_AND_PAYMENT,
  ROUTE_RENTAL_OF_COMMERCIAL_PREMISES,
  ROUTE_SERVICE_CENTERS,
  ROUTE_CONTACTS,
  ROUTE_CORPORATE_SALES,
  ROUTE_BEST_PRICE_GUARANTEE,
  ROUTE_HAPPY_BIRTHDAY,
  ROUTE_MARATHON_PROMO_PAGE,
  ROUTE_50000_PROMO_PAGE,
  ROUTE_50000_PROMO_PAGE_2,
  ROUTE_KARAGANDA_PROMO_1,
  ROUTE_KARAGANDA_PROMO_2,
  ROUTE_SYRYMOV_EVENT_PAGE,
  ROUTE_QUIZ_SLUG,
  ROUTE_QUIZ_SLUG_TARGET,
  ROUTE_CATALOG_WEBVIEW,
  ROUTE_CATALOG_WEBVIEW_WITH_CITY,
  ROUTE_CONTENT_PAGES_SLUG,
  ROUTE_KARAGANDA_PROMO_3,
  ROUTE_CONTENT_PAGES_SLUG_OLD,
} from "router/routes";

import { ROUTE_SALES } from "router/routes";

const Home = lazy(() => import("modules/home/Home"));

const CatalogRoute = lazy(() => import("modules/catalog/CatalogRoute"));
const MainCatalog = lazy(() => import("modules/catalog/MainCatalog"));

const About = lazy(() => import("modules/about/About"));
const TextPage = lazy(() => import("modules/text-page/TextPage"));

const Sales = lazy(() => import("modules/sales/Sales"));
const SalesItem = lazy(() => import("modules/sales/SalesItem"));

const Checkout = lazy(() => import("modules/cart/Checkout"));

const Vacancies = lazy(() => import("modules/vacancies/Vacancies"));
const TechnicalQuestions = lazy(
  () => import("modules/technical-questions-page/TechnicalQuestionsPage")
);
const TechnicalQuestionAnswers = lazy(
  () => import("modules/technical-questions-page/TechnicalQuestionsAnswer")
);
const FAQPage = lazy(() => import("modules/faq-page/FAQPage"));
const ReturnExchangePage = lazy(
  () => import("modules/return-and-exchange/ReturnExchange")
);
const NewsPage = lazy(() => import("modules/news-page/NewsPage"));
const NewsItemPage = lazy(() => import("modules/news-page/NewsItemPage"));
const BrandsItemPage = lazy(() => import("modules/brands/BrandsItemPage"));
const ArticlePage = lazy(() => import("modules/news-page/NewsPage"));

const ShopsPage = lazy(() => import("modules/shops-page/ShopsPage"));
const BonusesPage = lazy(() => import("modules/bonuses/Bonuses"));
const CreditAndInstallmentPage = lazy(
  () => import("modules/credit-and-installment/CreditAndInstallment")
);
const DeliveryAndPaymentPage = lazy(
  () => import("modules/delivery-and-payment/DeliveryAndPayment")
);
const RentalOfCommercialPremisesPage = lazy(
  () =>
    import("modules/rental-of-commercial-premises/RentalOfCommercialPremises")
);
const ServiceCentersPage = lazy(
  () => import("modules/service-centers/ServiceCenters")
);
const ContactsPage = lazy(() => import("modules/contacts/Contacts"));
const CorporateSalesPage = lazy(
  () => import("modules/corporate-sales/CorporateSales")
);
const BestPriceGuaranteePage = lazy(
  () => import("modules/best-price-guarantee/BestPriceGuarantee")
);
const InstallationPage = lazy(
  () => import("modules/service-price/ServicePrice")
);
const SmartMarketPage = lazy(
  () => import("modules/smart-market/SmartMarketPage")
);
const SmartGiftsPage = lazy(() => import("modules/smart-gifts/SmartGifts"));
const SmartGiftsPageOld = lazy(() => import("modules/smart-gifts-old/SmartGifts"));

const SmartGiftsGetCardPage = lazy(() => import("modules/smart-gifts-get-cart/SmartGiftsGetCard"));

const Search = lazy(() => import("modules/search/Search"));
const ErrorPage = lazy(() => import("modules/errorPage/ErrorPage"));

const ThanksPage = lazy(() => import("modules/thanks-page/ThanksPage"));

const BrokerOtp = lazy(
  () => import("modules/broker/components/broker-otp/BrokerOtp")
);
const BrokerOffers = lazy(() => import("modules/broker/Broker"));

const Compare = lazy(() => import("modules/compare/Compare"));
const CompareEmpty = lazy(
  () => import("modules/compare/components/compare-empty/CompareEmpty")
);

const UserPage = lazy(() => import("modules/user-page/UserPage"));
const PersonalInfo = lazy(
  () => import("modules/user-page/components/personal-info/PersonalInfo")
);
const Orders = lazy(() => import("modules/user-page/components/orders/Orders"));
const OnlineProductOrder = lazy(
  () =>
    import(
      "modules/user-page/components/online-product-orders/OnlineProductOrders"
    )
);
const PrivacyPolicyPage = lazy(
  () => import("modules/privacy-policy/PrivacyPolicy")
);
const SitemapPage = lazy(() => import("modules/sitemap/Sitemap"));
const BirthdayPage = lazy(
  () => import("modules/promo/birthday-page/PromoBirthday")
);

const ServerError = lazy(() => import("modules/errorPage/ServerError"));

const QuizPage = lazy(() => import("modules/quiz-page/QuizPage"));

const CouponPromoPage = lazy(
  () => import("modules/landing-page/CouponPromoPage")
);
const CouponPromoSmPage = lazy(
  () => import("modules/landing-page/CouponPromoSmPage")
);
const KaragandaPromoBonusPage = lazy(
  () => import("modules/landing-page/KaragandaPromoBonusPage")
);
const KaragandaPromoCouponPage = lazy(
  () => import("modules/landing-page/KaragandaPromoCouponPage")
);
const CouponKaragandyPromoPage = lazy(
  () => import("modules/landing-page/CouponKaragandyPromoPage")
);
const SyrymEventPage = lazy(
  () => import("modules/landing-page/SyrymEventPage")
);

export function GetRouter() {
  var node_env_param = process.env.NODE_ENV || "production";
  const mainRoute = ["/", "/kk/", "/samsung/", "/kk/samsung/"];
  const [queryParameters] = useSearchParams();

  return (
    <Routes>
      {mainRoute.map((e, i) => (
        <Route key={i} path={e} element={<Layout />}>
          <Route index element={<Home />} />
          <Route key="search" path={ROUTE_SEARCH} element={<Search />} />
          <Route key="about" path={ROUTE_ABOUT} element={<About />} />
          {/* <Route key="offer" path={ROUTE_OFFER} element={<Offer />} /> */}
          <Route
            key="text-page"
            path={ROUTE_CONTENT_PAGES_SLUG}
            element={<TextPage />}
          />
          <Route
            key="text-page-old"
            path={ROUTE_CONTENT_PAGES_SLUG_OLD}
            element={<TextPage />}
          />
          <Route key="sales" path={ROUTE_SALES} element={<Sales />} />
          <Route
            key="sale-item"
            path={ROUTE_SALE_ITEM}
            element={<SalesItem />}
          />

          <Route key="catalog" path={ROUTE_CATALOG}>
            <Route key="catalog-main" index element={<MainCatalog />} />
            <Route
              key="catalog-item"
              path={ROUTE_CATALOG_SLUG}
              element={<CatalogRoute />}
            >
              <Route
                key="catalog-filtered"
                path={ROUTE_CATALOG_SLUG_FILTERED}
                element={<CatalogRoute />}
              />
            </Route>
            <Route key="catalog-city" path={ROUTE_CITY}>
              <Route key="catalog-main-city" index element={<MainCatalog />} />
              <Route
                key="catalog-item"
                path={ROUTE_CATALOG_SLUG}
                element={<CatalogRoute />}
              >
                <Route
                  key="catalog-filtered"
                  path={ROUTE_CATALOG_SLUG_FILTERED}
                  element={<CatalogRoute />}
                />
              </Route>
            </Route>
          </Route>
          <Route
            key={"catalog webview"}
            path={ROUTE_CATALOG_WEBVIEW}
            element={<WebviewProductPage />}
          />
          <Route
            key={"catalog webview with city"}
            path={ROUTE_CATALOG_WEBVIEW_WITH_CITY}
            element={<WebviewProductPage />}
          />
          <Route key="checkout" path={ROUTE_CHECKOUT} element={<Checkout />} />
          <Route key="thanks" path={ROUTE_THANKS} element={<ThanksPage />} />
          <Route
            key="broker-otp"
            path={ROUTE_BROKER_OTP}
            element={<BrokerOtp />}
          />
          <Route
            key="broker-offers"
            path={ROUTE_BROKER_OFFERS}
            element={<BrokerOffers />}
          />
          <Route key="compare" path={ROUTE_COMPARE}>
            <Route key="compare-main" index element={<CompareEmpty />} />
            <Route
              key="compare-item"
              path={ROUTE_COMPARE_SLUG}
              element={<Compare />}
            />
          </Route>

          {node_env_param === "development" ? (
            <Route key="help-page" path={ROUTE_HELP} element={<Help />} />
          ) : null}

          <Route
            key="vacancies"
            path={ROUTE_VACANCIES}
            element={<Vacancies />}
          />
          <Route key="faq" path={ROUTE_FAQ} element={<FAQPage />} />
          <Route
            key="return-and-exchange"
            path={ROUTE_RETURN_AND_EXCHANGE}
            element={<ReturnExchangePage />}
          />
          <Route
            key="technical-questions"
            path={ROUTE_TECHNICAL_QUESTIONS}
            element={<TechnicalQuestions />}
          />
          <Route
            key="technical-questions-answer"
            path={ROUTE_TECHNICAL_QUESTIONS_SLUG}
            element={<TechnicalQuestionAnswers />}
          />
          <Route
            key="news-page"
            path={ROUTE_NEWS_PAGE}
            element={<NewsPage />}
          />
          <Route
            key="article-page"
            path={ROUTE_ARTICLE_PAGE}
            element={<ArticlePage />}
          />
          <Route
            key="news-item-page"
            path={ROUTE_NEWS_ITEM_PAGE}
            element={<NewsItemPage />}
          />
          <Route
            key="brands-item-page"
            path={ROUTE_BRANDS_ITEM_PAGE}
            element={<BrandsItemPage />}
          />
          <Route
            key="shops-page"
            path={ROUTE_SHOPS_PAGE}
            element={<ShopsPage />}
          />
          <Route
            key="service-price"
            path={ROUTE_SERVICE_PRICE}
            element={<InstallationPage />}
          />
          <Route
            key="smart-market"
            path={ROUTE_SMART_MARKET}
            element={<SmartMarketPage />}
          />
          <Route key="bonuses" path={ROUTE_BONUSES} element={<BonusesPage />} />
          <Route
            key="best-price-guarantee"
            path={ROUTE_BEST_PRICE_GUARANTEE}
            element={<BestPriceGuaranteePage />}
          />
          <Route
            key="credit-and-installment"
            path={ROUTE_CREDIT_AND_INSTALLMENT}
            element={<CreditAndInstallmentPage />}
          />
          <Route
            key="delivery-and-payment"
            path={ROUTE_DELIVERY_AND_PAYMENT}
            element={<DeliveryAndPaymentPage />}
          />
          <Route
            key="rental-of-commercial-premises"
            path={ROUTE_RENTAL_OF_COMMERCIAL_PREMISES}
            element={<RentalOfCommercialPremisesPage />}
          />
          <Route
            key="service-centers"
            path={ROUTE_SERVICE_CENTERS}
            element={<ServiceCentersPage />}
          />
          <Route
            key="contacts"
            path={ROUTE_CONTACTS}
            element={<ContactsPage />}
          />
          <Route
            key="corporate-sales"
            path={ROUTE_CORPORATE_SALES}
            element={<CorporateSalesPage />}
          />
          <Route key="user-page" path={ROUTE_CABINET}>
            <Route key="user-page-main" index element={<PersonalInfo />} />
            <Route
              key="user-page-item"
              path={ROUTE_CABINET_SLUG}
              element={<UserPage />}
            />
          </Route>
          <Route key="orders" path={ROUTE_ORDERS} element={<Orders />} />
          <Route
            key="online-product-orders"
            path={ROUTE_ONLINE_PRODUCT_ORDERS}
            element={<OnlineProductOrder />}
          />
          <Route
            key="privacy-policy"
            path={ROUTE_PRIVACY_POLICY}
            element={<PrivacyPolicyPage />}
          />
          <Route key="sitemap" path={ROUTE_SITEMAP} element={<SitemapPage />} />
          <Route
            key="promo-birthday"
            path={ROUTE_HAPPY_BIRTHDAY}
            element={<BirthdayPage />}
          />
          <Route key="error-page" path="*" element={<ErrorPage />} />
          <Route
            key="server-error-page"
            path={ROUTE_SERVER_ERROR}
            element={<ServerError />}
          />
          <Route key="quiz" path={ROUTE_QUIZ_SLUG} element={<QuizPage />} />
          <Route
            key="quiz"
            path={ROUTE_QUIZ_SLUG_TARGET}
            element={<QuizPage />}
          />
          {/* <Route
            key="ala-marathon-2024"
            path={ROUTE_MARATHON_PROMO_PAGE}
            element={<MarathonPage />}
          /> */}
          <Route
            key="50"
            path={ROUTE_50000_PROMO_PAGE}
            element={<CouponPromoPage />}
          />
          <Route
            key="sm"
            path={ROUTE_50000_PROMO_PAGE_2}
            element={<CouponPromoSmPage />}
          />
          <Route
            key="07"
            path={ROUTE_KARAGANDA_PROMO_1}
            element={<KaragandaPromoBonusPage />}
          />
          <Route
            key="12"
            path={ROUTE_KARAGANDA_PROMO_2}
            element={<KaragandaPromoCouponPage />}
          />
          <Route
            key="20"
            path={ROUTE_KARAGANDA_PROMO_3}
            element={<CouponKaragandyPromoPage />}
          />
          <Route
            key="srymof-event"
            path={ROUTE_SYRYMOV_EVENT_PAGE}
            element={<SyrymEventPage />}
          />
          {process.env.REACT_APP_NEW_SMART_GIFT=== 'false' && (<Route
            key="smart-gifts"
            path={ROUTE_SMART_GIFTS}
            element={<SmartGiftsPageOld />}
          />)}
          {/* Промо окончено
          <Route
            key="cfo-2024"
            path={ROUTE_CFO_PROMO_PAGE}
            element={<CfoPage />}
          /> */}
        </Route>
      ))}
      {
      process.env.REACT_APP_NEW_SMART_GIFT=== 'true' && (
        mainRoute.map((e, i) => (
          <>
            <Route
              key="smart-gifts"
              path={`${e}${ROUTE_SMART_GIFTS}`}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SmartGiftsPage />
                </Suspense>
              }
            />
            <Route
              key="smart-gifts"
              path={`${e}${ROUTE_SMART_GIFTS_SLUG}`}
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <SmartGiftsGetCardPage />
                </Suspense>
              }
            />
          </>
        )))
      }
    </Routes>
  );
}
