import { Col, Row } from "react-bootstrap";
import { FiMail, FiPhone } from "react-icons/fi";
import styles from "./style.module.scss";
import { FaWhatsapp } from "react-icons/fa";
import { HEADER } from "modules/header/lang/lang";
import { ReactComponent as FacebookIcon } from "assets/icons/FacebookIcon.svg";
import { useContext, useState } from "react";
import i18next from "i18next";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import CallbackForm from "modules/contacts/components/callback-form/CallbackForm";
import { Link } from "react-router-dom";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";

export default function Trigger() {
  const { channel } = useContext(ChannelContext)
  const lang = i18next.language;

  return (
    <>
      <Row>
        <Col xs={2} sm={2} md={2} lg={2} style={{ margin: "auto" }}>
          <FiPhone
            className="brand-primary-shade body-standart-desktop"
            style={{ width: "24px", height: "24px" }}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10}>
          <Link to="tel:+77719365454" className={styles.tel}>
            +7 (771) 936-54-54
          </Link>{" "}
          <div className={styles.time}></div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col xs={2} sm={2} md={2} lg={2}>
          <FaWhatsapp
            className="brand-primary-shade"
            style={{ width: "24px", height: "24px" }}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10}>
          <Link
            to="https://wa.me/77710170177"
            rel="noopener nofollow noreferrer"
            target="_blank"
            className={styles.link}
          >
            {" "}
            WhatsApp
          </Link>
        </Col>
      </Row>
      {channel === CHANNELS.WEB && (
      <Row className="pt-2">
        <Col xs={2} sm={2} md={2} lg={2}>
          <FacebookIcon
            className="brand-primary-shade"
            style={{ width: "24px", height: "24px" }}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10}>
          <Link
            to="https://m.me/175316985985233"
            rel="noopener nofollow noreferrer"
            target="_blank"
            className={styles.link}
          >
            {" "}
            Facebook
          </Link>
        </Col>
      </Row>
      )
      }
      {/* <Row className="pt-2">
        <Col xs={2} sm={2} md={2} lg={2}>
          <HiOutlineChatAlt2
            className="brand-primary-shade"
            style={{ width: "24px", height: "24px" }}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10}>
          <Link
            to="/"
            rel="noopener nofollow noreferrer"
            target="_blank"
            className={styles.link}
          >
            {" "}
            {languageStrings.chat}
          </Link>
        </Col>
      </Row> */}
      <Row className="pt-2">
        <Col xs={2} sm={2} md={2} lg={2}>
          <FiMail
            className="brand-primary-shade"
            style={{ width: "24px", height: "24px" }}
          />
        </Col>
        <Col xs={10} sm={10} md={10} lg={10}>
          <Link
            to="mailto:supportevrika.com"
            rel="noopener nofollow noreferrer"
            target="_blank"
            className={styles.link}
          >
            {" "}
            support@evrika.com
          </Link>
        </Col>
        <CallbackForm />
      </Row>
    </>
  )
}
