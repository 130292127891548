import { Container } from 'react-bootstrap';
import { ReactComponent as CatalogButton } from 'assets/icons/catalog-button.svg';
import styles from './styles.module.scss';
import NavLink from '../nav-link/NavLink';
import { ReactComponent as SaleIcon} from "assets/icons/sale-icon.svg";
import { ReactComponent as ShopIcon} from "assets/icons/shop-icon.svg";
import { ReactComponent as CreditIcon} from "assets/icons/credit-icon.svg";
import { ReactComponent as OrderIcon} from "assets/icons/order-icon.svg";
import { ReactComponent as BonusIcon} from "assets/icons/bonus-icon.svg";
import { ReactComponent as UserSvg } from 'assets/icons/user.svg';
import i18next, { t } from "i18next";
import { useState, useContext } from 'react';
import { ModalBox } from 'global/components/modal/ModalBox';
import { AuthWindow } from 'modules/auth/AuthWindow';
import CategoriesMenu from 'global/components/categories-menu/CategoriesMenu';
import { useAppSelector } from 'global/hooks/reduxHooks';
import { useLocation, useNavigate } from 'react-router';
import { ROUTE_BONUSES, ROUTE_CABINET, ROUTE_CABINET_BONUSES, ROUTE_CREDIT_AND_INSTALLMENT, ROUTE_SALES, ROUTE_SHOPS_PAGE } from 'router/routes';
import { OrderStatus } from './directive/order-status/OrderStatus';
import { setAuthInfo } from 'global/redux/actions/authInfoActions';
import { IAuthInfoAction } from 'global/redux/reducers/authInfo';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';
import getUrl from 'global/hooks/getUrl';
import { useDispatch } from 'react-redux';

export default function LowerHeader() {
  const { channel } = useContext(ChannelContext);
  const location = useLocation();
  const navigate = useNavigate();
  const lang = i18next.language;

  const [hover, setHover] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);

  const dispatch = useDispatch();
  const authInfo = useAppSelector(state => state.authInfo);
  const userToken = useAppSelector(state => state.userToken);

  function login() {
    setShowLoginModal(true);
    dispatch(setAuthInfo({ ...authInfo, checked: false }) as IAuthInfoAction)
  }

  function addActiveClass(pathname: string) {
    const locationPath = location.pathname.split("/").filter(item => item);

    return locationPath[locationPath.length - 1] === pathname;
  }

  return (
    <>
      <div id="stepWrapper" className={styles.wrapper}>
        <Container className={styles.container}>
          <button className={"button " + styles.button}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
          >
            <CatalogButton />
            <span>{t("Header.Product catalog")}</span>
            <div className={styles.catalogMenu}>
              <CategoriesMenu
                isHovered={hover}
              />
            </div>
          </button>
          <div className={styles.wrapper + ` ${styles.list}`}>
            <NavLink href={ROUTE_SALES} activeClassName={addActiveClass("sales")}><SaleIcon/><span>{t('Header.Stocks')}</span></NavLink>
            <div className={styles.divider}></div>
            <NavLink href={ROUTE_SHOPS_PAGE} activeClassName={addActiveClass("shops")} ><ShopIcon/><span>{t('Global.Shops')}</span></NavLink>
            <div className={styles.divider}></div>
            <NavLink href={ROUTE_CREDIT_AND_INSTALLMENT} activeClassName={addActiveClass("credit-and-installment")} ><CreditIcon/><span>{t('Header.Credit and installments')}</span></NavLink>
            <div className={styles.divider}></div>
            {/* //SECTION - order status btn */}
            <NavLink
              onClick={() => setShowOrderStatusModal(true)}
            ><OrderIcon/><span>{t('Header.Order status')}</span></NavLink>
            <div className={styles.divider}></div>
            <NavLink
              href={userToken ? ROUTE_CABINET_BONUSES : ROUTE_BONUSES}
              activeClassName={addActiveClass("bonuses")}
            ><BonusIcon/><span>Smart Bonus</span></NavLink>
          </div>
          {
            userToken?.token
              ? <div className={styles.user} onClick={() => navigate(getUrl({currentUrl:ROUTE_CABINET, lang, channelToSet: channel}))}>
                <UserSvg />
                {
                  userToken.phone &&
                  <div>
                    <span>
                      {userToken?.firstName} {userToken?.lastName.slice(0, 1)}.
                    </span>
                  </div>
                }
              </div>
              : <div className={styles.user} onClick={login}>
                <UserSvg />
                <div>
                  <span>
                    {t("Header.Login / Registration")}
                  </span>
                </div>
              </div>
          }
        </Container>
        <ModalBox
          isOpen={showLoginModal}
          onClose={() => null}
        >
          <AuthWindow
            onClose={setShowLoginModal}
          />
        </ModalBox>
        <ModalBox
          isOpen={showOrderStatusModal}
          onClose={() => null}
        >
          <OrderStatus
            setShowOrderStatusModal={setShowOrderStatusModal}
          />
        </ModalBox>
      </div>
    </>
  )
}
