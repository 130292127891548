import { IDeliveryInfo } from "global/redux/actions/deliveryInfoActions";
import { IGiftCardsForCheckout } from "global/redux/actions/giftCardsAction";
import { IPaymentInfo } from "global/redux/actions/paymentInfoActions";
import { IProductInfo } from "global/redux/actions/productInfoActions";
import { IUserInfo } from "global/redux/actions/userInfoActions";
import { IUserToken } from "global/redux/actions/userTokenActions";
import { ISendData } from "modules/cart/types/sendData";
import { IPersonalInfo } from "modules/user-page/types/personalInfo";

interface ICollectPaymentData {
  city: string | number,
  token?: IUserToken | null,
  deliveryInfo: IDeliveryInfo | null,
  paymentInfo: IPaymentInfo | null,
  userInfo: IUserInfo | null,
  productInfo: IProductInfo[] | null,
  personalInfo: IPersonalInfo | undefined,
  giftCards: IGiftCardsForCheckout[] | null,
  deviceType: string
}

export function CollectPaymentData({
  city,
  token,
  deliveryInfo,
  paymentInfo,
  userInfo,
  productInfo,
  personalInfo,
  giftCards,
  deviceType
}: ICollectPaymentData): ISendData {
  return {
    cityId: +city,
    deliveryId: deliveryInfo?.id || 1,
    paymentId: paymentInfo?.id || 1,
    agree: true,
    channel: "web",
    deviceType: deviceType,
    isGuest: token ? false : true,
    user: token
      ? { firstName: personalInfo?.name, lastName: personalInfo?.surname, email: personalInfo?.email, phone: personalInfo?.phone, userId: personalInfo?.id }
      : (userInfo || {}),
    recipientData: {
      recipientIsMe: deliveryInfo?.recipientIsMe,
      comment: deliveryInfo?.comment,
      firstName: deliveryInfo?.firstName,
      lastName: deliveryInfo?.lastName,
      email: deliveryInfo?.email,
      phone: deliveryInfo?.phone
    },
    deliveryData: {
      localityId: deliveryInfo?.id === 2
        ? deliveryInfo.localityId : null,
      street: deliveryInfo?.id === 2 || deliveryInfo?.id === 4
        ? deliveryInfo.street : "",
      house: deliveryInfo?.id === 2 || deliveryInfo?.id === 4
        ? deliveryInfo.house : "",
      room: deliveryInfo?.id === 2 || deliveryInfo?.id === 4
        ? deliveryInfo.room : "",
      date: deliveryInfo?.id === 2 || deliveryInfo?.id === 4
        ? deliveryInfo.date : "",
      time: deliveryInfo?.id === 2
        ? deliveryInfo.time : "",
      branchId: deliveryInfo?.id === 1
        ? deliveryInfo.branchId : null,
      delivery_cost: deliveryInfo?.id === 2
        ? deliveryInfo?.delivery_cost || 0
        : deliveryInfo?.express_delivery_cost || 0
    },
    paymentData: {
      companyName: paymentInfo?.id === 4
        ? paymentInfo?.companyName : null,
      companyAddress: paymentInfo?.id === 4
        ? paymentInfo?.companyAddress : null,
      bin: paymentInfo?.id === 4
        ? paymentInfo?.bin : null,
      bik: paymentInfo?.id === 4
        ? paymentInfo?.bik : null,
      iik: paymentInfo?.id === 4
        ? paymentInfo?.iik : null,
      repayment_period: paymentInfo?.id === 3
        ? paymentInfo?.repayment_period : null,
      bank: paymentInfo?.id === 3
        ? paymentInfo?.bank : null,
      ipn: paymentInfo?.id === 3
        ? paymentInfo?.ipn : null,
    },
    bonusData: {
      used_bonuses: userInfo?.useBonuses && userInfo?.bonus || null,
      bonus_card: userInfo?.bonusCard || ''
    },
    giftCards: giftCards,
    promocodeName: userInfo?.promocodeName,
    items: productInfo && productInfo.map((item: IProductInfo) => (
      {
        product_id: item.id,
        gift_id: item.selected_gifts?.map(gift => { return gift.id }),//item.gift ? item.gift?.id : (item.gifts.length > 0 ? item.gifts[0]?.id : null),
        quantity: item.quantity,
        price: item.cost,
        old_price: item.old_cost ? item.old_cost : 0,
        purchase_price: item?.purchase_price || item.cost,
        delivery_from_dc: item.availableForPurchaseFromDc,
        together_cheaper_product: null,
        together_cheaper_price: 0,
        together_cheaper_purchase_price: 0,
        promocode: userInfo?.usePromocode || false,
        vendor_code: item?.vendor_code
      }
    ))
  }
}
