export interface IUTMParam {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  expiration: number;
}

export const setUtmParam = (data: IUTMParam) => {
  return {
    type: "SET_UTM",
    utm: data
  }
}

export const clearUtmParam = () => ({
  type: "CLEAR_UTM",
})
