import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Nav,
  OverlayTrigger,
  Placeholder,
  Popover,
  Row,
} from "react-bootstrap";
import MainButton from "global/components/button/Button";
import { ReactComponent as LocationSvg } from "assets/icons/Location.svg";
import { ReactComponent as PhoneSvg } from "assets/icons/Phone.svg";
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";
import { ReactComponent as LogoSamsung } from "assets/logos/Samsung.svg";
import { ReactComponent as LogoUCB } from "assets/logos/Benneton.svg";
import { ReactComponent as LogoSony } from "assets/logos/SonyCentre.svg";
import { ReactComponent as LogoBosch } from "assets/logos/Bosch.svg";
import { ReactComponent as LogoEvrika } from "assets/logos/Evrika.svg";
import styles from "./style.module.scss";
import { HEADER } from "modules/header/lang/lang";
import LanguageContext from "global/hooks/language/LanguageContext";
import CityContext from "global/hooks/city/CityContext";
import { useLocation, useNavigate } from "react-router-dom";
import getUrl from "global/hooks/getUrl";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { ICities } from "global/types/Cities";
import { ModalBox } from "global/components/modal/ModalBox";
import { t } from "i18next";
import { ReactComponent as Close } from "assets/icons/close.svg";
import Trigger from "./Trigger";
import { IDeliveryInfoAction } from "global/redux/reducers/deliveryInfo";
import { setDeliveryInfo } from "global/redux/actions/deliveryInfoActions";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { useAppSelector } from "global/hooks/reduxHooks";
import { AxiosResponse } from "axios";
import {
  IProductInfo,
  setProductInfo,
} from "global/redux/actions/productInfoActions";
import { IProductInfoAction } from "global/redux/reducers/productInfo";
import { useParams } from "react-router";
import selectCity from "global/hooks/city/SelectCity";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { ROUTE_HOME } from "router/routes";
import { useDispatch } from "react-redux";

interface IUpperHeader {
  city?: string;
  setLang: Dispatch<SetStateAction<string>>;
}

export default function UpperHeader({ setLang }: IUpperHeader) {
  const channelsActive = process.env.REACT_CHANNELS_ACTIVE || false
  const { slug, id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const cacheTime = 24; // Hours
  const lang = useContext(LanguageContext);
  const { city, setCity } = useContext(CityContext);
  const { channel } = useContext(ChannelContext);
  const { citySlug, setCitySlug } = useContext(CityContext);
  const [selectedCity, setSelectedCity] = useState(city);
  const [selectedCitySlug, setSelectedCitySlug] = useState(citySlug);
  const [modalOpen, setModalOpen] = useState(false);
  const [languageStrings, setlanguageStrings] = useState(
    HEADER[lang as keyof typeof HEADER]
  );

  const dispatch = useDispatch();
  const deliveryInfo = useAppSelector((state) => state.deliveryInfo[channel]);
  const productInfo = useAppSelector((state) => state.productInfo[channel]);

  const LOAD_PRODUCTS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const { data, isLoading, isFetching } = useQueryBuilder<{
    data: ICities[];
  }>({
    url: `cities`,
    cacheTime: cacheTime * 60 * 60 * 1000,
    staleTime: cacheTime * 60 * 60 * 1000,
  });

  useEffect(() => {
    setSelectedCity(city);
    setSelectedCitySlug(citySlug);
  }, [city, citySlug]);

  useEffect(() => {
    setlanguageStrings(HEADER[lang as keyof typeof HEADER]);
  }, [lang]);

  function currentLangueage(pickedLang: string) {
    if (pickedLang === lang) {
      return null;
    }
    setLang(lang);
    navigate(getUrl({ lang: pickedLang, currentUrl: location.pathname, channelToSet: channel }));
  }

  function getCitiesColumns() {
    if (data?.data === undefined) return [];

    let subarray = [];
    for (let i = 0; i < Math.ceil(data.data.length / 10); i++) {
      subarray[i] = data.data.slice(i * 10, i * 10 + 10);
    }

    return subarray;
  }

  let Logos;
  switch (channel) {
    case CHANNELS.SAMSUNG:
      Logos = [
        <Nav.Item key={CHANNELS.WEB} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.WEB}>
            <LogoEvrika fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.BOSCH} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.BOSCH}>
            <LogoBosch fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.SONY} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.SONY}>
            <LogoSony fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.UCB} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.UCB}>
            <LogoUCB fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
      ];
      break;
    case CHANNELS.WEB:
    default:
      Logos = [
        <Nav.Item key={CHANNELS.SAMSUNG} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.SAMSUNG}>
            <LogoSamsung fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.BOSCH} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.BOSCH}>
            <LogoBosch fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.SONY} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.SONY}>
            <LogoSony fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
        <Nav.Item key={CHANNELS.UCB} className={styles.channelsSwitch}>
          <Nav.Link eventKey={CHANNELS.UCB}>
            <LogoUCB fill="darkgray" />
          </Nav.Link>
        </Nav.Item>,
      ];
      break;
  }

  function changeDeliveryInfo() {
    dispatch(
      setDeliveryInfo({
        ...deliveryInfo,
        branchName: "",
        localityName: "",
        delivery_cost: 0,
        express_delivery_cost: 0,
      }) as IDeliveryInfoAction
    );
  }

  function collectData() {
    return {
      products: productInfo?.map((item) => item.id),
      locale: lang,
      city_id: city,
    };
  }

  const { mutate } = useMutation(
    (): Promise<void> =>
      postData({
        url: "cart/check",
        data: collectData(),
        onSuccess(e: AxiosResponse<{ data: IProductInfo[] }>) {
          const result =
            productInfo?.map((item) => {
              const [productAvailable] = e.data.data.filter(
                (product) => product.id === item.id
              );
              return {
                ...item,
                ...productAvailable,
                quantity: 1,
              };
            }) || [];

          dispatch(setProductInfo(result) as IProductInfoAction);
        },
        onError(e) {
          console.error(e);
        },
        channel
      })
  );
  const handleSelectChannel = (eventKey: any) => {
    switch (eventKey) {
      case CHANNELS.BOSCH: {
        window.open("https://boschcenter.kz/", "_blank");
        break;
      }
      case CHANNELS.SONY: {
        window.open("https://sonycenter.kz/", "_blank");
        break;
      }
      case CHANNELS.UCB: {
        window.open("https://ucb.kz/", "_blank");
        break;
      }
      default: {
        const urlToNavigate = getUrl({
          lang: lang,
          currentUrl: ROUTE_HOME,
          channelToSet: eventKey,
        });
        navigate(urlToNavigate);
        break;
      }
    }
  };

  return (
    <div className="bg-upper-header">
      <Container>
        <Row className="py-1" style={{ alignItems: "center" }}>
          <Col md={2}>
            <Row style={{ alignItems: "center" }}>
              <Col>
                <div
                  className={styles.upperHeader}
                  onClick={() => setModalOpen(true)}
                >
                  <span>
                    <LocationSvg className={styles.iconHeader} />
                  </span>
                  {isLoading || isFetching ? (
                    <Placeholder
                      as={Card.Title}
                      animation="wave"
                      className={styles.title_load}
                    >
                      <Placeholder xs={12} bg="secondary" />
                    </Placeholder>
                  ) : (
                    <span className=" unstyled-anchor hover-brand-color location-color txt-body-">
                      {data?.data?.find((item) => item.id === +city)?.name ||
                        ""}
                    </span>
                  )}
                </div>
                <ModalBox isOpen={modalOpen} onClose={(e) => setModalOpen(e)}>
                  <div className={styles.cities}>
                    <button
                      aria-label={t("ProductPage.Close")}
                      className={styles.closeBtn}
                      onClick={() => setModalOpen(false)}
                    >
                      <Close />
                    </button>
                    <h3 className={styles.cities__header}>
                      {t("Header.Choose your city")}:
                    </h3>
                    <div className={styles.cities__columns}>
                      {isLoading || isFetching ? (
                        <>
                          <Placeholder
                            as={Card.Title}
                            animation="wave"
                            className={styles.cities__column}
                          >
                            {LOAD_PRODUCTS.map((item, i) => (
                              <Placeholder key={i} xs={12} bg="secondary" />
                            ))}
                          </Placeholder>
                          <Placeholder
                            as={Card.Title}
                            animation="wave"
                            className={styles.cities__column}
                          >
                            {LOAD_PRODUCTS.map((item, i) => (
                              <Placeholder key={i} xs={12} bg="secondary" />
                            ))}
                          </Placeholder>
                          <Placeholder
                            as={Card.Title}
                            animation="wave"
                            className={styles.cities__column}
                          >
                            {LOAD_PRODUCTS.map((item, i) => (
                              <Placeholder key={i} xs={12} bg="secondary" />
                            ))}
                          </Placeholder>
                        </>
                      ) : (
                        getCitiesColumns().map((cityItem, i) => (
                          <div key={i} className={styles.cities__column}>
                            {cityItem.map((item) => (
                              <label
                                key={item.id}
                                className={styles.cities__item}
                              >
                                <input
                                  type="radio"
                                  name="cities"
                                  checked={item.id === +selectedCity}
                                  onClick={() => {
                                    setSelectedCity(`${item.id}`);
                                    setSelectedCitySlug(`${item.slug}`);
                                    mutate();
                                    changeDeliveryInfo();
                                  }}
                                />
                                <span>{item.name}</span>
                              </label>
                            ))}
                          </div>
                        ))
                      )}
                    </div>
                    <MainButton
                      className={styles.button}
                      onClick={() =>
                        selectCity({
                          setCity,
                          selectedCity,
                          location,
                          cityslug: selectedCitySlug,
                          navigate,
                          setModalOpen,
                          setCitySlug,
                          slug,
                          id,
                          channel
                        })
                      }
                    >
                      {t("Header.Confirm")}
                    </MainButton>
                    <div className={styles.cities__notice}>
                      {t(
                        "Header.Choosing a city will help provide up-to-date information about the availability of goods and delivery methods"
                      )}
                    </div>
                  </div>
                </ModalBox>
              </Col>
              <Col>
                <div className={styles.langSwitcher}>
                  <button
                    className={lang === "ru" ? styles.langSwitcherActive : ""}
                    onClick={() => currentLangueage("ru")}
                  >
                    Рус
                  </button>
                  <button
                    className={lang === "kk" ? styles.langSwitcherActive : ""}
                    onClick={() => currentLangueage("kk")}
                  >
                    Қаз
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          {channelsActive ? (<Col>
            {Array.isArray(Logos) && Logos.length > 0 ? (
              <Nav
                className={styles.channels}
                activeKey={channel}
                onSelect={handleSelectChannel}
              >
                {Logos.map((logo) => logo)}
                <Nav.Item>
                  <Nav.Link disabled className={styles.retailGroup}>
                    Retail Group
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            ) : null}
          </Col>):
          <Col></Col>}
          <Col className={styles.wrapper} md={"auto"}>
            <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover
                  id={`popover-positioned-"bottom"`}
                  style={{ maxWidth: "233px", width: "100%" }}
                >
                  <Popover.Body>
                    <Trigger />
                  </Popover.Body>
                </Popover>
              }
            >
              <Button variant="contacts-widget">
                <PhoneSvg className={styles.iconPhone} />
                <span className={styles.title}>{languageStrings.help}</span>
                <CurveDown className={styles.iconDown} />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
