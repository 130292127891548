import { useContext, useState } from "react";
import { ReactComponent as NotebookSvg } from 'assets/icons/installment-tab/notebook.svg';
import { ReactComponent as PaymentSvg } from 'assets/icons/installment-tab/payment.svg';
import { ReactComponent as TimeSvg } from 'assets/icons/installment-tab/time.svg';
import { ReactComponent as NewsSvg } from 'assets/icons/installment-tab/news.svg';
import styles from './styles.module.scss';
import AccordionCustom from 'global/components/accordionCustom/AccordionCustom';
import { accordionData } from '../../mock';
import { t } from "i18next";
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import { useCost } from "global/hooks/useCost";
import { useBankCoefficientCount } from "modules/product-page/hooks/useBankCoefficientCount";
import { useMediaQuery } from 'react-responsive';
import Freedom from "assets/product-page/freedom.png";
import Halyk from "assets/product-page/Halyk.png";
import Eurasian from "assets/product-page/eurasian.png";
import { breakpointMD } from "global/hooks/constants";
import { useNavigate } from "react-router";
import getUrl from "global/hooks/getUrl";
import i18next from "i18next";
import { ROUTE_CART } from "router/routes";
import { useAppSelector } from "global/hooks/reduxHooks";
import { IProductPage } from "modules/product-page/types/ProductPage";
import { setPaymentInfo } from "global/redux/actions/paymentInfoActions";
import { IPaymentInfoAction } from "global/redux/reducers/paymentInfo";
import { toast } from "react-toastify";
import { addToCart } from "global/scripts/addToCart";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

const images = [
  "https://cdn.evrika.com/storage/banks/medium/YBw1uFPWszoRna2CC9c0BLqYEvAvfN2tvkJ0TdVF.png.webp?v=1681901529/f_auto",
  // "https://cdn.evrika.com/storage/banks/medium/XqAUAivoeQdQeNGxoor9u4lY5ctC39VJjWGqdMjv.png.webp?v=1681901544/f_auto",
  "https://cdn.evrika.com/storage/banks/medium/wEhUCWTBXiYuqJxbiaAVbmudYiK0iOk49kgPSM2n.png.webp?v=1681901569/f_auto",
]

const images_mobile = [
  Halyk,
  // Eurasian,
  Freedom,
]

export default function InstallmentTab() {
  const { channel } = useContext(ChannelContext)
  const lang = i18next.language;
  const navigate = useNavigate();
  const isMobile = useMediaQuery(breakpointMD);
  const product = useContext(ProductPageContext) as Required<IProductPageContext>;

  // * Redux
  const dispatch = useDispatch();
  const productInfo = useAppSelector(state => state.productInfo[channel]);
  const paymentInfo = useAppSelector(state => state.paymentInfo[channel]);
  const userToken = useAppSelector(state => state.userToken);
  const isSelected = (Array.isArray(productInfo) && productInfo.some(item => item.id === product.id)) || false;

  const bankCoefficientCount = useBankCoefficientCount();
  const [instPrice, setInstPrice] = useState(
    (bankCoefficientCount(
      product.bank_coefficient && product.bank_coefficient || '0,0833333333',
      3,
      product.cost
    ))
  );
  const calcValues = [3, 6, 12, 24, 36, 48, 60];

  const costFn = function (this: any, str: number) {
    return useCost.apply(this, [str]);
  }

  function navigateCart(item: IProductPage | undefined) {
    if (!isSelected) {
      addToCart(item, productInfo, dispatch)
    }
    if (userToken?.token) {
      dispatch(setPaymentInfo({ ...paymentInfo, id: 3 }) as IPaymentInfoAction)
    } else {
      dispatch(setPaymentInfo({ ...paymentInfo, id: 1 }) as IPaymentInfoAction)
    }
    navigate(getUrl({ lang, currentUrl: `/${ROUTE_CART}`,channelToSet:channel }));
    dispatch(setPaymentInfo({ ...paymentInfo, id: 3 }) as IPaymentInfoAction)
  }

  return <>
    <div className={styles.installment}>
      <p className={styles.installment_Title}>{t("ProductPage.Payment Calculator")}</p>

      <span className={styles.installment_Description}>
        {t(`ProductPage.In the network of stores \"Evrika\" you can easily and conveniently make a purchase on credit! A loan can be issued for the entire range of products!`)}
      </span>

      <div className={styles.installment_Info}>
        <div className={styles.info}>
          <NotebookSvg className={styles.info_Icon} />
          <span className={styles.info_Text}>
            {t("ProductPage.Making a loan without going to the bank")}
          </span>
        </div>
        <div className={styles.info}>
          <PaymentSvg className={styles.info_Icon} />
          <span className={styles.info_Text}>
            {t("ProductPage.A minimum package of documents is required")}
          </span>
        </div>
        <div className={styles.info}>
          <TimeSvg className={styles.info_Icon} />
          <span className={styles.info_Text}>
            {t("ProductPage.Consideration of the loan application within 5-10 minutes")}
          </span>
        </div>
        <div className={styles.info}>
          <NewsSvg className={styles.info_Icon} />
          <span className={styles.info_Text}>
            {t(`ProductPage.Convenient conditions for you lending`)}
          </span>
        </div>
      </div>

      <div className={styles.installment_calc}>
        <p className={styles.calc_Title}>{t("ProductPage.Online Calculator")}:</p>
        <div className={styles.points}>
          <select onChange={(e) => {
            if (product && product.cost && product.bank_coefficient) {
              const value = +e.target.value;
              setInstPrice(bankCoefficientCount(product.bank_coefficient, value, product.cost));
            }
          }}>
            {calcValues.map((item, i) => (
              <option key={i} value={item}>{item} {t("ProductPage.month")}</option>
            ))}
          </select>
        </div>
        <span className={styles.calc_Offer}>{t("ProductPage.Favorable offers from the partner bank")}</span>
        <div className={styles.calc}>
          <div className={styles.calc_Banks}>
            {!isMobile
              ? images.map((item, i) => (
                <img key={i} src={item} alt='' />
              ))
              : images_mobile.map((item, i) => (
                <img key={i} src={item} alt='' />
              ))
            }
          </div>
          <div className={styles.calc_Price}>
            {
              product && product.bank_coefficient &&
              <span className={styles.calc_Cost}>от {costFn(instPrice || 0)} ₸ / {t("ProductPage.month")}</span>
            }
            <button onClick={() => navigateCart(product)} className={'button ' + styles.calc_ChooseBtn}>{t("ProductPage.Choose")}</button>
          </div>
        </div>
      </div>

      <div className={styles.installment_Steps}>
        {accordionData.map((item, i) => (
          <AccordionCustom
            key={i}
            type="product-page"
            title={item.title}
            children={item.children}
            eventKey={String(++i)}
            lastItemIcon={i === accordionData.length && '✓'}
          />
        ))}
      </div>
    </div>
  </>
}
