import { useContext, useEffect, useState } from 'react';
import { ReactComponent as ArrowSvg } from 'assets/icons/arrow.svg';
import styles from './styles.module.scss';
import { t } from "i18next";
import { useQueryBuilder } from 'global/scripts/useQueryBuilder';
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import { IAvailability } from 'modules/product-page/types/Availability';
import CityContext from 'global/hooks/city/CityContext';
import { Card, Placeholder } from 'react-bootstrap';
import { ICities } from 'global/types/Cities';
import { IProductBranch } from 'modules/product-page/types/ProductBranch';
import { TableItem } from './TableItem';
import { useMediaQuery } from 'react-responsive';
import { breakpointMD } from 'global/hooks/constants';

export default function AvailabilityTab() {
  const [active, setActive] = useState(false);
  const { city } = useContext(CityContext);
  const product = useContext(ProductPageContext);
  const isModile = useMediaQuery(breakpointMD);

  const cities = useQueryBuilder<{ data: ICities[] }>({
    url: `cities`,
    cacheTime: 10000,
    staleTime: 10000,
  });
  const cityName = cities.data?.data.find(item => item.id === +city) || null;
  const [currentCity, setCurrentCity] = useState<ICities | null>(cityName);

  const { data, isLoading, refetch } = useQueryBuilder<{ data: IAvailability }>({
    url: `products/available/${currentCity?.id || city || 1}/${product.id}`,
    cacheTime: 10000,
    staleTime: 10000,
  });

  const branches = useQueryBuilder<{ data: IProductBranch[] }>({
    url: `branches?city_id=${currentCity?.id || city}`,
    cacheTime: 10000,
    staleTime: 10000,
  });

  useEffect(() => {
    refetch();
    branches.refetch();
    cities.refetch();
    setCurrentCity(cities.data?.data.find(item => item.id === +city) || null);
  }, [city])


  if (isModile) {
    return (
      <>
        {(isLoading === false && data?.data) && data?.data.branches.map(item => {
          const [availability] = data?.data?.branches.filter(avail => avail.id === item.id) || [];

          return <TableItem
            key={item.id}
            branches={item}
            availability={availability}
          />
        })}
      </>
    )
  }

  return (
    <>
      <div className={styles.availability}>
        <div className={styles.availability_Head}>
          <p className={styles.availability_Title}>{t("ProductPage.Availability in stores")}</p>
          <div
            className={`${styles.availability_Select} ${active && styles.active}`}
            onClick={() => setActive(!active)}
          >
            {currentCity?.name || 'Алматы'}
            <ArrowSvg />
          </div>
          <div className={`${styles.availability_Container} ${active && styles.active}`}>
            {cities.data && cities.data.data?.map(item => (
              <div
                key={item.id}
                className={`${styles.availability_Option}`}
                onClick={() => {
                  setActive(!active);
                  setCurrentCity(item);
                }}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
        <div className={styles.table}>
          <div className={`${styles.table_String} ${styles.table_Head}`}>
            <div className={styles.table_Column}>{t("ProductPage.Address")}</div>
            <div className={styles.table_Column}>{t("Global.Telephone")}</div>
            <div className={styles.table_Column}>{t("ProductPage.Point of issue")}</div>
            <div className={styles.table_Column}>{t("ProductPage.Chart")}</div>
            <div className={styles.table_Column}>{t("ProductPage.Availability")}</div>
            <div className={styles.table_Column}>{t("ProductPage.On the map")}</div>
          </div>

          {isLoading && <>
            <Placeholder as={Card.Title} animation="wave">
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
              <Placeholder xs={12} bg="secondary" className={styles.placeholder} />
            </Placeholder>
          </>}

          {(isLoading === false && data?.data) && data?.data.branches.map(item => {
            const [availability] = data?.data.branches.filter(avail => avail.id === item.id) || [];

            return <TableItem
              key={item.id}
              branches={item}
              availability={availability}
            />
          })}
        </div>
      </div>
    </>
  )
}
