import { ReactComponent as ErrorIcon } from "assets/error-page/error-page-404.svg";
import styles from "./styles.module.scss";
import { t } from "i18next";
import { Helmet } from "react-helmet";

export default function ErrorPage() {

  return (
    <div className={styles.wrapper}>
       <Helmet>
        <title>Ошибка 404 - Страница не найдена</title>
        <meta name="robots" content="noindex, nofollow" />
        <meta name="description" content={t('Error.An incorrect address may have been entered or the page may have been deleted')} />
        <meta name="errorpage" content="true" />
        <meta name="errortype" content="404 - Not Found" />
        <meta name="prerender-status-code" content="404" />
      </Helmet>
      <ErrorIcon className={styles.icon} />
      <div className={styles.title}>{t('Error.the specified page was not found')}</div>
      <div className={styles.subtitle}>{t('Error.An incorrect address may have been entered or the page may have been deleted')}</div>
    </div>
  )
}
