import React, { useState, createContext, ReactNode } from 'react';
import { useTranslation } from "react-i18next";

// Определение типа для данных контекста и функции обновления
interface SeoDataType {
  title: string;
  description: string;
  keywords: string | null;
  h1: string | null;
  schemeOrg: string | null;
  updateContext: (newContext: Partial<SeoDataType>) => void;
}

// Создание контекста с начальным значением и функцией обновления
const SeoDataContext = createContext<SeoDataType | null>(null);

// Определение типа для пропсов MyProvider
interface SeoDataContextProviderProps {
  children: ReactNode;
}

// Компонент Provider
const SeoDataContextProvider: React.FC<SeoDataContextProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [contextValue, setContextValue] = useState<SeoDataType>({
    title: t("Analitycs.title"),
    description: t("Analitycs.description"),
    keywords: t("Analitycs.keyword"),
    h1: null,
    schemeOrg: null,
    updateContext: (newContext: Partial<SeoDataType>) => {
      setContextValue((currentContext) => ({ ...currentContext, ...newContext }));
    },
  });

  return <SeoDataContext.Provider value={contextValue}>{children}</SeoDataContext.Provider>;
};

export { SeoDataContext, SeoDataContextProvider };
