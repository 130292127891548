import { Container, Col, Row } from "react-bootstrap";
import styles from './styles.module.scss';
import SocialIcon from "global/components/icons/SocialIcon";
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as VkIcon } from 'assets/icons/vkontakte.svg';
import { ReactComponent as YoutubeIcon } from "assets/footer/youtube.svg";
import MenuFooter from "../menu-footer/MenuFooter";
import { t } from "i18next";
import { useQueryBuilder } from 'global/scripts/useQueryBuilder';
import { IFooterMenu } from 'modules/footer/types/FooterMenu';
import FormFooterModalView from '../form-footer/FormFooterModalView';
import { Link } from "react-router-dom";
import { ModalBox } from 'global/components/modal/ModalBox';
import DownloadAppCard from "global/components/download-app-link/DownloadAppCard";
import { useContext, useState } from "react";
import qr from "assets/footer/evrika-smart-qr-color.png"
import { ReactComponent as MarketIcons } from 'assets/footer/markets.svg';
import getUrl from "global/hooks/getUrl";
import LanguageContext from "global/hooks/language/LanguageContext";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export default function MainFooter() {
  const lang = useContext(LanguageContext)
  const {channel} = useContext(ChannelContext)
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);
  const { data } = useQueryBuilder<{ data: IFooterMenu[] }>({
    url: "menu",
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  })

  function handleSubscribeModal() {
    setShowSubscribeModal(true);
  }

  return (
    <>
      <Container className={styles.container}>
        <Row className={styles.row}>
          {/* <Col>
            <div className={styles.title}>{t("Footer.Subscribe to us")}</div>
            <div className={styles.wrapper}>
              <SocialIcon href="https://www.facebook.com/evrikacom">
                <FacebookIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/evrika.kazakhstan">
                <InstagramIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://twitter.com/Evrikacom">
                <TwitterIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://vk.com/evrikacom">
                <VkIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/channel/UCWagl_Sz3tU3IshjsXnhtlQ?sub_confirmation=1">
                <YoutubeIcon className={styles.icon} />
              </SocialIcon>
            </div>
            <div className={styles.descr}>{t("Footer.Get new promotions and discounts one of the first!")}</div>
            <FormFooter />
          </Col> */}
          <Col>
            <div className={styles.title}>{t("Contacts.Contacts")}</div>
            <div className={styles.contactWrapper}>
              <Link className={styles.telLink} to="tel:+77719365454">+7 (771) 936-54-54</Link>
              <div className={styles.textTime}>{t("Footer.Work Time")}</div>
              <Link className={styles.link} to="https://wa.me/77710170177">{t("Footer.Write on WhatsApp")}</Link>
              <Link className={styles.link} to="mailto:support@evrika.com">{t("Footer.Write on email")}</Link>
            </div>
            <div className={styles.descr}>{t("Footer.Get new promotions and discounts one of the first!")}</div>
            <a
              className={'button fullWidth ' + styles.submitBtn}
              href="#"
              rel="noopener"
              onClick={(e) => {
                e.preventDefault();
                handleSubscribeModal();
              }}
            >
              {t('Footer.Subscribe')}
            </a>
            <div className={styles.wrapper}>
              <SocialIcon href="https://www.facebook.com/evrikacom">
                <FacebookIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://www.instagram.com/evrika.kazakhstan">
                <InstagramIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://twitter.com/Evrikacom">
                <TwitterIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://vk.com/evrikacom">
                <VkIcon className={styles.icon} />
              </SocialIcon>
              <SocialIcon href="https://www.youtube.com/channel/UCWagl_Sz3tU3IshjsXnhtlQ?sub_confirmation=1">
                <YoutubeIcon className={styles.icon} />
              </SocialIcon>
            </div>
          </Col>
          {data?.data && data.data.map((item, i) => (
            <Col key={i}>
              <div className={styles.title}>{i === 0 ? "Компания" : item.name}</div>
              <ul className={styles.list}>
                {
                  item.data.map((item, i) => (
                    <MenuFooter
                      href={getUrl({currentUrl: item.url, lang:lang, channelToSet:channel})}
                      title={item.name}
                      key={i} />
                  ))
                }
              </ul>
            </Col>
          ))}
          <Col>
            <DownloadAppCard />
          </Col>
        </Row>
      </Container>
      <ModalBox
        isOpen={showSubscribeModal}
        onClose={() => null}
      >
        <FormFooterModalView onClose={setShowSubscribeModal} />
      </ModalBox>
    </>
  )
}
