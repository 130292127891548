import { legacy_createStore as createStore, applyMiddleware, compose } from "redux";
import allReducers from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import channelMiddleware from "./channelMiddlware";

// Подключаем Middleware
const middlewareEnhancer = applyMiddleware(channelMiddleware);

// Добавляем поддержку Redux DevTools, только если не production
const enhancers = process.env.NODE_ENV !== "production"
  ? composeWithDevTools(middlewareEnhancer)
  : compose(middlewareEnhancer);

// Создаём Store с Middleware
export const store = createStore(allReducers, enhancers);

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
