import { t } from "i18next";
import { ICallbackForm } from "../types/callbackForm";

export function useValidate() {
  const errors: ICallbackForm = {
    phone: '',
    locale: "",
    city: 0
  };

  return (values: ICallbackForm) => {
    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 XXX XXX XX XX';
    }
    return errors;
  }
};
