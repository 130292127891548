import { Link } from "react-router-dom";
import styles from './styles.module.scss';
import { useEffect, useState } from "react";
import CartPopup from "modules/cart/components/cart-popup/CartPopup";
import { useAppSelector } from "global/hooks/reduxHooks";

interface IBadgeOnIcons {
  type: "compare" | "favourite" | "cart";
  title?: string;
  icon: string;
  alt?: string;
  count?: number | string;
  activeBtn?: boolean;
  href: string;
  onClick?: () => void;
  className?: string;
  mutate?: () => void;
}

export default function BadgeOnIcons({ type, className, title, icon, alt = "Evrika-icon", count, activeBtn, href, onClick, mutate }: IBadgeOnIcons) {
  const [shake, setShake] = useState(false);
  useEffect(() => {
    setShake(true);
    setTimeout(() => {
      setShake(false);
    }, 1000);
  }, [count]);

  return (
    <div className={className}>
      <Link to={href} className={styles.link} onClick={onClick}>
        <div className={shake ? styles.shakeTop : styles.wrapper}>
          <span className={styles.container}>
            <img className={styles.icon} src={icon} alt={alt} />
            {count && <span className={styles.count}>{count}</span>}
          </span>
        </div>
        <span className={styles.title + ` ${activeBtn ? styles.removeBtn : styles.addBtn}`}>{title}</span>
      </Link>
      {
        type === "cart" &&
        <div className={styles.popup + ' popup'}>
          <CartPopup mutate={mutate} setShow={() => false} />
        </div>
      }
    </div>
  )
}
