import { IPersonalInfo } from "modules/user-page/types/personalInfo";
import { useQueryBuilder } from "./useQueryBuilder";
import logoutUserData from "./logoutUserData";
import { useLocation, useNavigate } from "react-router";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { useDispatch } from "react-redux";

export default function getUserData(token: any, channel:CHANNELS) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const navigate = useNavigate();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { data, refetch, isLoading, remove } = useQueryBuilder<IPersonalInfo>({
    url: `users/me`,
    isUserPage: true,
    token: token,
    enabled: token ? true : false,
    retry: 1,
    onError(err) {
      logoutUserData(dispatch, token, pathname, navigate, channel);
    },
  });
  return { data, isLoading, refetch, remove }
}
