import React from "react";
import { IBadges } from 'modules/catalog/types/ProductCartType';
import iconSRC from "assets/icons/gift.png";
import styles from "./styles.module.scss";
import { OverlayTrigger, Popover } from 'react-bootstrap';
import ShowcaseBadge from "./directive/showcase-badge/ShowcaseBadge";

interface IProps {
  withGift: boolean,
  badgeData: IBadges[] | [],
  stock?: number,
  quality?: Array<Record<string, number>> | null,
}

export default function CustomBadge({ withGift, badgeData = [], quality, stock }: IProps) {
  return (
    <>
      {badgeData.length > 0 ? badgeData.map((item, i) => {
        return (withGift && i === 0) ? <React.Fragment key={i}>
          <span
            className={styles.badgeItem + " " + styles.badgeImg}
          >
            <img
              className={styles.badgeImg}
              src={iconSRC}
              alt={item.name}
            />
          </span>
          <span style={{ backgroundColor: item.color }} className={styles.badgeItem}>{item.name}</span>
        </React.Fragment>
          : <>
            {item.rich_description ? <OverlayTrigger
              trigger="click"
              key="bottom"
              placement="bottom"
              rootClose={true}
              overlay={
                <Popover
                  id={`popover-positioned-"bottom"`}
                  style={{ maxWidth: "233px", width: '100%' }}
                >
                  <Popover.Body className={styles.questionTooltip}>
                    <div dangerouslySetInnerHTML={{ __html: item.rich_description }} />
                  </Popover.Body>
                </Popover>
              }
            >
              <span key={i} style={{ backgroundColor: item.color }} className={styles.badgeItem}>{item.name}</span>
            </OverlayTrigger>
              :
              <span key={i} style={{ backgroundColor: item.color }} className={styles.badgeItem}>{item.name}</span>
            }
          </>
      }
      ) : <></>}
      <ShowcaseBadge
        quality={quality}
        stock={stock}
      />
    </>
  )
}
