import { IAuthInfo } from "../actions/authInfoActions";

export interface IAuthInfoAction {
  type: "SET_AUTH" | "CLEAR_AUTH",
  user: IAuthInfo
}

const authInfoReducer = (state: IAuthInfo | null = null, action: IAuthInfoAction) => {
  switch (action.type) {
    case "SET_AUTH":
      return action.user;

    case "CLEAR_AUTH":
      return null;

    default:
      return state;
  }
}

export default authInfoReducer; 
