import { useEffect, useState, useContext } from 'react'
import { Modal } from 'react-bootstrap';
import { ReactComponent as NofiticationIcon } from "assets/modal/notification.svg";
import { ReactComponent as ErrorIcon } from "assets/modal/error.svg";
import { ReactComponent as SuccessIcon } from "assets/modal/success.svg";
import styles from "./styles.module.scss";
import Button from '../button/Button';
import { ModalBox } from './ModalBox';
import { AuthWindow } from 'modules/auth/AuthWindow';
import { useAppSelector } from 'global/hooks/reduxHooks';
import { addFavorites } from 'global/scripts/addFavoriteFn';
import { useDispatch, useSelector } from 'react-redux';
import { showAlert } from "global/redux/actions/messageModalActions";
import { ChannelContext } from 'global/hooks/channel/ChannelContext';

interface IAlertModal {
  type: "DIALOG_ALERT" | "DIALOG_SUCCESS" | "DIALOG_ERROR" | "DIALOG_HIDEN",
  show: boolean,
  closeModal: () => void,
  modalText: string,
  modalSubtext: string,
  buttonText: string
}

export default function AlertModal({ type, show, modalText, buttonText, modalSubtext, closeModal }: IAlertModal) {
  // * State
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const { channel } = useContext(ChannelContext)
  // * Redux
  const dispatch = useDispatch();
  const token = useAppSelector(state => state.userToken)?.token;
  const modal = useSelector((state: any) => state.messageModal);
  const favoritesInfo = useAppSelector(state => state.favoritesInfo[channel]);

  const getModalTitle = () => {
    let title;
    switch (type) {
      case "DIALOG_ERROR":
        title = <ErrorIcon />;
        break;
      case "DIALOG_ALERT":
        title = <NofiticationIcon />;
        break;
      case "DIALOG_SUCCESS":
        title = <SuccessIcon />;
        break;
      default:
        title = <NofiticationIcon />;
        break;
    }
    return (<Modal.Title>{title}</Modal.Title>);
  }

  useEffect(() => {
    if (token && authorized) {
      addFavorites(modal.id, modal.img, modal.slug, favoritesInfo, dispatch, token || '', showAlert);
      setAuthorized(false);
    }
  }, [authorized]);

  return (
    <>
      <Modal show={show} onHide={closeModal} centered className={styles.modal}>
        <Modal.Header closeButton className={styles.modal_header + ` ${styles.modal_header_center}`}>
          {getModalTitle()}
        </Modal.Header>
        <div className={styles.modal_body}>
          {
            modalText &&
            <div className={styles.modal_text + ` ${styles.modal_text_center}`}>{modalText}</div>
          }
          {
            modalSubtext &&
            <div className={styles.modal_text}>{modalSubtext}</div>
          }
          {
            buttonText
              ? <Modal.Footer className={styles.modal_footer}>
                <Button onClick={() => { closeModal(); setShowLoginModal(true); }} className={styles.modal_btn}>
                  {buttonText}
                </Button>
              </Modal.Footer>
              : <></>
          }
        </div>
      </Modal>
      <ModalBox
        isOpen={showLoginModal}
        onClose={() => null}
      >
        <AuthWindow
          onClose={setShowLoginModal}
          setAuthorized={setAuthorized}
        />
      </ModalBox>
    </>
  )
}
