import { useEffect } from "react";
import styles from "./styles.module.scss";

export interface IInputCode {
  type: "code",
  className?: string,
  code: any[],
  setCode: React.Dispatch<React.SetStateAction<any[]>>,
  inputRefs: any
}

export default function InputCode({ code, setCode, inputRefs, className }: IInputCode) {
  useEffect(() => {
    if (inputRefs.current) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (e: any, idx: number) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      const newCode = [...code];
      newCode[idx] = value;
      setCode(newCode);

      const nextIdx = newCode.findIndex((digit) => digit === '');
      if (nextIdx !== -1) {
        inputRefs.current[nextIdx].focus();
      }
    }
  };

  const handleKeyDown = (e: any, idx: number) => {
    if (e.key === 'Backspace') {
      const newCode = [...code];
      if (!newCode[idx] && idx > 0) {
        inputRefs.current[idx - 1].focus();
      } else {
        newCode[idx] = '';
        setCode(newCode);
      }
    }
  };

  return (
    <div className={styles.input_wrap}>
      {code.map((digit, idx) => (
        <input
          type="tel"
          name="code"
          key={idx}
          value={digit}
          maxLength={1}
          onChange={(e) => handleChange(e, idx)}
          onKeyDown={(e) => handleKeyDown(e, idx)}
          ref={(el) => inputRefs.current[idx] = el}
          className={styles.input_code}
        />
      ))}
    </div>
  )
}
