import { useState } from 'react';
import styles from './styles.module.scss';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useSearchParams } from 'react-router-dom';
import { breakpointDF } from 'global/hooks/constants';
import { useMediaQuery } from 'react-responsive';
import Checkbox from '../checkbox/Checkbox';

interface IDropdownCustom {
  data: {
    title: string,
    href: string
  }[],
  className?: string,
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

export default function DropdownCustom({ data, className, setModalOpen }: IDropdownCustom) {
  const [title, setTitle] = useState(data[0].title || "");
  const [search, setSearch] = useSearchParams();
  const isTablet = useMediaQuery(breakpointDF);

  function DropdownSort(title: string, href: string) {
    setTitle(title);
    setSearch(prev => {
      if (prev.has('sort')) {
        prev.set('sort', href)
        return prev;
      }

      prev.append('sort', href);
      return prev;
    });
    setModalOpen(false);
  }

  return (
    <>
      {
        isTablet
          ? <>
            {
              data.map((item, i) => (
                <Checkbox
                  key={i}
                  type="round"
                  value={`sort_${i}`}
                  onChange={() => DropdownSort(item.title, item.href)}
                  name="catalog_sort"
                  label={item.title}
                  checked={item.href === search.get('sort')}
                  className={`${className ? className : ''}`}
                />
              ))
            }</>
          : <DropdownButton
            id="dropdown-basic-button"
            title={title}
            flip={false}
            className={styles.button + ` ${className ? className : ''}`}
          >
            {data.map((item, i) => (
              <Dropdown.Item
                key={i}
                className={styles.item + ` ${(title === item.title) ? styles.active : ''}`}
                onClick={() => DropdownSort(item.title, item.href)}
              >
                {item.title}
              </Dropdown.Item>
            ))}
          </DropdownButton>
      }
    </>
  )
}
