export const accordionData = [
  {
    title: 'Выберите товары',
    children: 'Добавьте нужные товары в корзину. Сумма товаров не должна превышать 6 000 000 ₸.'
  },
  {
    title: 'Оформите заказ',
    children: 'Перейдите в раздел оформления заказов и авторизуйтесь через номер телефона. Заполните стандартную форму оформления заказа. Выберите способ оплаты - "Кредит или рассрочка", выберите способ доставки, выберите срок и заполните обязательное поле ИИН. Подтвердите покупку и введите код, который пришел в виде SMS сообщения.'
  },
  {
    title: 'Получите предложения от банков-партнеров',
    children: 'Информация о заказе передается кредитным партнерам, которые проведут процесс онлайн скоринга и дадут предложения в течении 5-10 минут. Если кредит одобрен – вы увидите все доступные предложения от банков-партнеров и выбираете подходящее для вас условие. Выберите предложение и подтвердите, далее следуйте инструкциям на странице завершения заказа. Если в кредите отказано – вы самостоятельно принимаете решение о заказе, можете изменить форму оплаты или отменить заказ.'
  },
  {
    title: 'Заберите товар',
    children: 'После успешного размещения заказа с вами свяжется представитель компании для дополнительного подтверждения. При доставке или самовывозе вам будет отправлено SMS с кодом активации для получения товара. Далее Вам остается только совершать платежи по кредиту, согласно условиям договора.'
  },
]
