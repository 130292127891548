import { Link } from "react-router-dom";
import styles from "./styles.module.scss";

interface INavLink {
  href?: string;
  onClick?: () => void;
  activeClassName?: boolean;
  children: React.ReactNode;
}

export default function NavLink({
  href,
  onClick,
  activeClassName,
  children,
}: INavLink) {
  return (
    <>
      {href ? (
        <Link
          className={styles.link + ` ${activeClassName && styles.activeClass}`}
          to={href}
          onClick={onClick}
        >
          {children}
        </Link>
      ) : (
        <div
          className={styles.link + ` ${activeClassName && styles.activeClass}`}
          onClick={onClick}
        >
          {children}
        </div>
      )}
    </>
  );
}
