import InputNumber, { IInputNumber } from './directive/input-number/InputNumber';
import InputPhone, { IInputPhone } from './directive/input-phone/InputPhone';
import InputEmail, { IInputEmail } from './directive/input-email/InputEmail';
import InputText, { IInputText } from './directive/input-text/InputText';
import InputPassword, { IInputPassword } from './directive/input-password/InputPassword';
import InputCode, { IInputCode } from './directive/input-code/InputCode';

export default function InputForm(props: IInputNumber | IInputPhone | IInputEmail | IInputText | IInputPassword | IInputCode) {
  switch (props.type) {
    case "number":
      return <InputNumber {...props} />

    case "tel":
      return <InputPhone {...props} />

    case "email":
      return <InputEmail {...props} />

    case "text":
      return <InputText {...props} />

    case "password":
      return <InputPassword {...props} />

    case "code":
      return <InputCode {...props} />
  }
}
