import { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CurveLeftSvg } from 'assets/icons/curve-left.svg';
import { ReactComponent as CurveRightSvg } from 'assets/icons/curve-right.svg';

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, Thumbs } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import ZoomSlide from "../../ZoomSlide";
import { SliderThumbs } from "../SliderThumbs";
import { IProductSlider } from "../../ProductSlider";

export function ModalSlider({ sliders, commonAlt }: IProductSlider) {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);

  return sliders ? (
    <div className={styles.sliderWrapper}>
      <Swiper
        className={styles.sliderContainer}
        mousewheel={true}
        keyboard={true}
        thumbs={{
          swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
          slideThumbActiveClass: styles.thumbsActive
        }}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Thumbs, FreeMode]}
      >
        {sliders.map((slide, i) =>
          <SwiperSlide key={i} style={{ display: "flex", justifyContent: 'center' }}>
            <ZoomSlide
              image={slide}
              alt={commonAlt + ' - ' + (i+1)}
              zoomSettings={{
                zoomWidth: 140,
                zoomHeight: 140,
                topPadding: 70
              }}
              className={styles.zoomSlide}
            />
          </SwiperSlide>
        )}
      </Swiper>

      <Swiper
        className={styles.thumbsContainer}
        cssMode={true}
        navigation={{
          enabled: true,
          prevEl: '.prev',
          nextEl: '.next',
          disabledClass: `${styles.thumbsDisabledBtn}`,
        }}
        slidesPerView={6}
        freeMode={true}
        watchSlidesProgress={true}
        onSwiper={setThumbsSwiper}
        modules={[FreeMode, Navigation, Thumbs]}
      >
        {sliders.map((slide, i) =>
          <SwiperSlide key={i} className={styles.thumbSlide}>
            <SliderThumbs
              image={slide}
              alt={commonAlt + ' thumbnail'}
            />
          </SwiperSlide>
        )}
        <div className={"navigation prev " + styles.prev}>
          <CurveLeftSvg />
        </div>
        <div className={"navigation next " + styles.next}>
          <CurveRightSvg />
        </div>
      </Swiper>
    </div>
  ) : <></>
}
