import { useContext, useEffect, useState } from 'react';
import styles from "./styles.module.scss";
import TabContent from "./directive/TabContent";
import { TabHead } from "./directive/TabHead";
import { useMediaQuery } from "react-responsive";
import { breakpointMD } from "global/hooks/constants";
import SpecificationTab from "./directive/specification-tab/SpecificationTab";
import AccoridionMethods from "../product-bar/directive/AccoridionMethods";
import ReviewsTab from "./directive/reviews-tab/ReviewsTab";
import { t } from "i18next";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AvailabilityTab from "./directive/availability-tab/AvailabilityTab";
import { tabType } from "modules/product-page/types/TabTypes";
import InstallmentTab from "./directive/installment-tab/InstallmentTab";
import { ProductPageContext } from "modules/product-page/services";
import { VideoTab } from './directive/video-tab/VideoTab';
import DescriptionTab from './directive/description-tab/DescriptionTab';
import ProductDocuments from '../product-documents/ProductDocuments';

interface IProductTabs {
  activeTab: tabType,
  setActiveTab: (e: tabType) => void,
  webview?: boolean
}

export default function ProductTabs({ activeTab, setActiveTab, webview }: IProductTabs) {
  const product = useContext(ProductPageContext);
  const isModile = useMediaQuery(breakpointMD);
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsHidden(!(!product?.richContent && !product?.text && !product?.html && !sessionStorage.getItem('TTLStream')));
    }, 4000);
  }, [product.name]);

  if (isModile) {
    return (
      <>
        {
          isHidden &&
          <AccoridionMethods
            eventKey="1"
            head={<span className={styles.Title}>{t("ProductPage.Description")}</span>}
            children={<DescriptionTab />}
          />
        }
        <AccoridionMethods
          eventKey="1"
          head={<span className={styles.Title}>{t("ProductPage.Reviews")}</span>}
          children={<ReviewsTab webview={webview} />}
        />
        <AccoridionMethods
          eventKey="1"
          head={<span className={styles.Title}>{t("ProductPage.Specifications")}</span>}
          children={<SpecificationTab />}
        />
        <AccoridionMethods
          eventKey="1"
          activeTab={activeTab}
          availability={true}
          head={<span className={styles.Title}>{t("ProductPage.Availability in stores")}</span>}
          children={<AvailabilityTab />}
        />
        {product.videos && product.videos.length > 0 && (
          <AccoridionMethods
            eventKey="1"
            activeTab={activeTab}
            head={<span className={styles.Title}>{t("ProductPage.Video")}</span>}
            children={<VideoTab />}
          />
        )}
        {
          !webview &&
          <AccoridionMethods
            eventKey="1"
            head={<span className={styles.Title}>{t("ProductPage.Credit/Installment")}</span>}
            children={<InstallmentTab />}
          />
        }
        {
          product.documents && product.documents.length > 0 && (
            <AccoridionMethods
              eventKey="1"
              activeTab={activeTab}
              availability={true}
              head={<span className={styles.Title}>{t("ProductPage.Documents")}</span>}
              children={<ProductDocuments
                data={product.documents}
              />}
            />
          )
        }
      </>
    )
  }

  const helmetContext = {};

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          <script src="https://api-maps.yandex.ru/2.1/?apikey=3a261bed-6cdc-4103-9fab-47ae21438bf1&lang=ru_RU"
            type="text/javascript">
          </script>
        </Helmet>
      </HelmetProvider>
      <div id="tabs" className={styles.tabContainer}>
        <TabHead
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          webview={webview}
        />
      </div>

      <div className={styles.tabContent}>
        <TabContent
          tabName={activeTab}
        />
      </div>
    </>
  )
}
