import styles from "./styles.module.scss";
import InputForm from "global/components/input/InputForm";
import { useContext } from "react";
import { useFormValidate } from "./useFormValidate";
import { useAppSelector } from "global/hooks/reduxHooks";
import { useFormik } from "formik";
import { t } from "i18next";
import { ReactComponent as Close } from "assets/icons/close.svg";
import CityContext from "global/hooks/city/CityContext";
import { toast } from "react-toastify";

interface IProductSubscribeForm {
  onClose: (isOpen: false) => void,
  productId?: number
}

export default function ProductSubscribeModalForm({ onClose, productId }: IProductSubscribeForm) {
  const validate = useFormValidate();
  const { city } = useContext(CityContext);
  const userToken = useAppSelector(state => state.userToken);

  const formik = useFormik({
    initialValues: {
      email: userToken?.email || '',
      phone: userToken?.phone || '',
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  function submitForm(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!formik.errors.phone && !formik.errors.email) {
      if (userToken?.token) { // user authorized
        window.r46("profile", "set", {
          id: userToken?.id.toString(),
          email: formik.values.email,
          phone: `+${formik.values.phone.replace(/\D/ig, '')}`,
          birthday: (userToken?.birthday !== '' && userToken?.birthday !== undefined) ? userToken?.birthday : '1970-01-01',
          gender: (userToken?.gender !== '' && userToken?.gender !== undefined) ? userToken?.gender : 'm',
          location: city,
        }, (response: any) => {
          subscribeTrigger(productId, formik.values.email, `+${formik.values.phone.replace(/\D/ig, '')}`);
        });
      } else { // guest user
        window.r46("profile", "set", {
          email: formik.values.email,
          phone: `+${formik.values.phone.replace(/\D/ig, '')}`,
          location: city
        }, (response: any) => {
          subscribeTrigger(productId, formik.values.email, `+${formik.values.phone.replace(/\D/ig, '')}`);
        });
      }
    }
  }

  function subscribeTrigger(pid: number | undefined, email: string, phone: string) {
    window.r46('subscribe_trigger', 'product_available', {
      email: email,
      phone: phone,
      item: pid
    });
    formik.resetForm();
    toast.success(t("Review.The form has been successfully submitted!"));
    onClose(false);
  }

  return (
    <>
      <div className={styles.modalBodyWrap}>
        <div className={styles.modalBodyWrap__head}>
          <h2 className={styles.modalBodyWrap__title}>{t('ProductPage.Remind me about availability')}</h2>
          <button
            className={styles.closeBtn}
            onClick={() => onClose(false)}
          >
            <Close />
          </button>
        </div>
        {
          <form onSubmit={submitForm}>
            <div className={styles.input__wrapper}>
              <div className={styles.item}>
                <label style={{ "display": "block" }}>
                  <InputForm
                    type="email"
                    label="Ваш E-mail"
                    name="email"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.email}
                    className={styles.input + ` ${formik.errors.email && formik.touched.email ? styles.errorInput : ''}`}
                  />
                </label>
                {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
              </div>
              <div className={styles.item}>
                <label style={{ "display": "block" }}>
                  <InputForm
                    type="tel"
                    mask='+7 999 999 99 99'
                    label={t("Global.Phone number")}
                    name="phone"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.phone}
                    className={styles.input + ` ${formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}`}
                  />
                </label>
                {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
              </div>

              <button type="submit" className={"button fullWidth orrange"}>{t('Global.Submit')}</button>
              {/* <button className={styles.input__icon} aria-label={t('Global.Submit')}><ButtonIcon /></button> */}
            </div>
          </form>
        }
      </div>
    </>
  )
}
