import { useLocation } from "react-router";

interface IUrlParser {
  location: string,
  toFilter?: boolean,
}

export function useUrlParser({location, toFilter = false}: IUrlParser) {
  return (): { [key: string]: string } => {
    if (location.indexOf('filter') !== -1) {
      const pathLocale = location.split("filter/")[1];
      // Decode the pathLocale part of the URL before splitting it into components
      const decodedPathLocale = decodeURIComponent(pathLocale);
      const pathArr = decodedPathLocale.split(";").map(item => {
        // Split each item into key-value pairs and decode both key and value
        const [key, value] = item.split("=");
        return [decodeURIComponent(key), decodeURIComponent(value)];
      });

      if (toFilter) {
        return pathArr.reduce((r, [k, v]) => ({ ...r, [k]: v.split(",") }), {})
      }

      return pathArr.reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
    }

    return {};
  }
}
