import InputMask from 'react-input-mask';
import { ReactComponent as EyeIcon } from "assets/user-page/eye.svg";
import styles from '../../styles.module.scss';
import { useState } from 'react';

export interface IInputPassword {
  type: "password",
  className?: string,
  mask: string,
  name: string,
  label: string,
  value: string,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  isError?: boolean
}

export default function InputPassword({ label, mask, name, onChange, onBlur, value, className, isError }: IInputPassword) {
  const [showPassword, setShowPassword] = useState(false)
  return (
    <div className={styles.wrapper}>
      <InputMask
        type={showPassword ? 'text' : 'password'}
        mask={mask}
        className={styles.input + ' ' + className}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        maskChar={''}
      />
      <label className={value.length > 0 ? styles.label : ''}>{label}</label>
        <EyeIcon className={' ' + styles.eyeIcon} onClick={() => setShowPassword(!showPassword)} />
    </div>
  )
}
