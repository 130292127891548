export const ROUTE_HELP = "help/";
export const ROUTE_HOME = "/";
export const ROUTE_ABOUT = "about/";
// export const ROUTE_OFFER = "page/publichnaya-oferta/"
// export const ROUTE_OFFER_FOR_SMS_CONFIRMATION = "page/usloviya-pokupki/"
export const ROUTE_SALES = "sales";
export const ROUTE_CREDIT_AND_INSTALLMENT = "credit-and-installment";
export const ROUTE_PRODUCT = "product/";
export const ROUTE_SALE_ITEM = ROUTE_SALES + "/:slug/";
export const ROUTE_CATALOG = "catalog/";
export const ROUTE_CATALOG_SLUG = ":slug/:id/";
export const ROUTE_CATALOG_SLUG_FILTERED = "filter/:filter";
export const ROUTE_CATALOG_WEBVIEW = ROUTE_CATALOG + "webview/" + ROUTE_CATALOG_SLUG;
export const ROUTE_CATALOG_WEBVIEW_WITH_CITY = ROUTE_CATALOG + "webview/:cityslug/" + ROUTE_CATALOG_SLUG;
export const ROUTE_CHECKOUT = "checkout/:slug";
export const ROUTE_BROKER_OTP = "checkout/broker-otp/:orderid";
export const ROUTE_BROKER_OFFERS = "checkout/broker-offers/:orderid";
export const ROUTE_CITY = ":cityslug/";
export const ROUTE_SEARCH = "search/";
export const ROUTE_THANKS = "thanks/:orderId";
export const ROUTE_CART = "checkout/cart";
export const ROUTE_CONTACT_INFO = "checkout/contact-info";
export const ROUTE_DELIVERY = "checkout/delivery";
export const ROUTE_PAYMENT = "checkout/payment";
export const ROUTE_COMPARE = "comparison/";
export const ROUTE_COMPARE_SLUG = ":categoryid";
export const ROUTE_VACANCIES = "vacancies/";
export const ROUTE_FAQ = "faq/";
export const ROUTE_CABINET = "cabinet/";
export const ROUTE_CABINET_SLUG = ":slug/";
export const ROUTE_ORDERS = "orders";
export const ROUTE_CABINET_FAVORITES = "cabinet/favorites";
export const ROUTE_CABINET_VIEWED = "cabinet/viewed";
export const ROUTE_CABINET_BONUSES = "cabinet/bonuses";
export const ROUTE_CABINET_MYREVIEWS = "cabinet/my-reviews";
export const ROUTE_CABIBET_MY_GIFT_CARDS = "cabinet/my-gift-cards";
export const ROUTE_ONLINE_PRODUCT_ORDERS = "online-product-orders";
export const ROUTE_TECHNICAL_QUESTIONS = "technical-questions";
export const ROUTE_TECHNICAL_QUESTIONS_SLUG = ROUTE_TECHNICAL_QUESTIONS + "/:slug/";
export const ROUTE_NEWS_PAGE = "blog/novosti";
export const ROUTE_ARTICLE_PAGE = "blog/stati-i-obzory";
export const ROUTE_NEWS_ITEM_PAGE = "news-page/:slug/";
export const ROUTE_BRANDS_ITEM_PAGE = "brands/:slug/";
export const ROUTE_SHOPS_PAGE = "shops";
export const ROUTE_BONUSES = "bonuses";
// export const ROUTE_SMART_PROTECT = "page/smart-protect";
// export const ROUTE_SMART_SERVICE = "page/smart-service";
export const ROUTE_SERVICE_PRICE = "service-price";
export const ROUTE_PRIVACY_POLICY = "privacy-policy";
export const ROUTE_RETURN_AND_EXCHANGE = "return-and-exchange";
export const ROUTE_SERVER_ERROR = "500";
export const ROUTE_SMART_MARKET = "smart-market";
export const ROUTE_SMART_GIFTS = "smart-gifts";
export const ROUTE_SMART_GIFTS_SLUG = "smart-gifts/receive/:guid";
export const ROUTE_SITEMAP = "sitemap";
export const ROUTE_DELIVERY_AND_PAYMENT = "delivery-and-payment";
export const ROUTE_RENTAL_OF_COMMERCIAL_PREMISES = "rental-of-commercial-premises";
export const ROUTE_SERVICE_CENTERS = "service-centers";
export const ROUTE_CONTACTS = "contacts";
export const ROUTE_CORPORATE_SALES = "corporate-sales";
export const ROUTE_BEST_PRICE_GUARANTEE = "best-price-guarantee";
export const ROUTE_HAPPY_BIRTHDAY = "promo/happy-birthday";
export const ROUTE_QUIZ = "quiz";
export const ROUTE_QUIZ_SLUG = ROUTE_QUIZ + "/:slug/";
export const ROUTE_QUIZ_SLUG_TARGET = ROUTE_QUIZ + "/:slug/:target";

export const ROUTE_CFO_PROMO_PAGE = "cfo-2024";
export const ROUTE_MARATHON_PROMO_PAGE = "ala-marathon-2024";
export const ROUTE_50000_PROMO_PAGE = "50";
export const ROUTE_50000_PROMO_PAGE_2 = "sm";
export const ROUTE_KARAGANDA_PROMO_1 = "07";
export const ROUTE_KARAGANDA_PROMO_2 = "12";
export const ROUTE_KARAGANDA_PROMO_3 = "20";
export const ROUTE_SYRYMOV_EVENT_PAGE = "srymof-event";
export const ROUTE_CONTENT_PAGES = "p";
export const ROUTE_CONTENT_PAGES_SLUG_OLD = "page/:slug";
export const ROUTE_CONTENT_PAGES_SLUG = ROUTE_CONTENT_PAGES + "/:slug";

const routes = {
  ROUTE_HELP,
  ROUTE_HOME,
  ROUTE_ABOUT,
  // ROUTE_OFFER,
  ROUTE_SALES,
  ROUTE_PRODUCT,
  ROUTE_SALE_ITEM,
  ROUTE_CATALOG,
  ROUTE_CATALOG_SLUG,
  ROUTE_CATALOG_SLUG_FILTERED,
  ROUTE_CHECKOUT,
  ROUTE_CITY,
  ROUTE_SEARCH,
  ROUTE_THANKS,
  ROUTE_BROKER_OTP,
  ROUTE_BROKER_OFFERS,
  ROUTE_COMPARE,
  ROUTE_COMPARE_SLUG,
  ROUTE_VACANCIES,
  ROUTE_CABINET,
  ROUTE_CABINET_SLUG,
  ROUTE_ORDERS,
  ROUTE_CABINET_FAVORITES,
  ROUTE_CABINET_VIEWED,
  ROUTE_CABINET_BONUSES,
  ROUTE_CABINET_MYREVIEWS,
  ROUTE_CABIBET_MY_GIFT_CARDS,
  ROUTE_ONLINE_PRODUCT_ORDERS,
  ROUTE_TECHNICAL_QUESTIONS,
  ROUTE_TECHNICAL_QUESTIONS_SLUG,
  ROUTE_NEWS_PAGE,
  ROUTE_ARTICLE_PAGE,
  ROUTE_NEWS_ITEM_PAGE,
  ROUTE_SHOPS_PAGE,
  ROUTE_BONUSES,
  // ROUTE_SMART_PROTECT,
  // ROUTE_SMART_SERVICE,
  ROUTE_SERVICE_PRICE,
  ROUTE_PRIVACY_POLICY,
  ROUTE_RETURN_AND_EXCHANGE,
  ROUTE_SERVER_ERROR,
  ROUTE_SMART_MARKET,
  ROUTE_SMART_GIFTS,
  ROUTE_SMART_GIFTS_SLUG,
  ROUTE_CREDIT_AND_INSTALLMENT,
  ROUTE_SITEMAP,
  ROUTE_DELIVERY_AND_PAYMENT,
  ROUTE_RENTAL_OF_COMMERCIAL_PREMISES,
  ROUTE_SERVICE_CENTERS,
  ROUTE_CONTACTS,
  ROUTE_CORPORATE_SALES,
  ROUTE_BEST_PRICE_GUARANTEE,
  ROUTE_HAPPY_BIRTHDAY,
  // ROUTE_CFO_PROMO_PAGE,
  // ROUTE_MARATHON_PROMO_PAGE,
  ROUTE_50000_PROMO_PAGE,
  ROUTE_50000_PROMO_PAGE_2,
  ROUTE_KARAGANDA_PROMO_1,
  ROUTE_KARAGANDA_PROMO_2,
  ROUTE_SYRYMOV_EVENT_PAGE,
  ROUTE_QUIZ,
  ROUTE_QUIZ_SLUG,
  ROUTE_CATALOG_WEBVIEW,
  ROUTE_CONTENT_PAGES,
  ROUTE_CONTENT_PAGES_SLUG_OLD
}
export default routes;
