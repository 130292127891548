import { useContext } from "react";
import { useFormik } from "formik";
import InputForm from "global/components/input/InputForm";
import { t } from "i18next";
import { useValidate } from "modules/contacts/hooks/useValidate";
import { Spinner } from "react-bootstrap";
import styles from "./styles.module.scss";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { ICallbackForm } from "modules/contacts/types/callbackForm";
import { useAppSelector } from "global/hooks/reduxHooks";
import i18next from "i18next";
import CityContext from "global/hooks/city/CityContext";
import { toast } from "react-toastify";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export default function CallbackForm() {
  const { channel } = useContext(ChannelContext);
  const validate = useValidate();
  const lang = i18next.language;
  const { city } = useContext(CityContext);
  const userToken = useAppSelector(state => state.userToken);

  const formik = useFormik({
    initialValues: {
      phone: userToken?.token ? userToken.phone : '',
      locale: lang,
      city: parseInt(city)
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: ICallbackForm) => postData({
      url: `callback`,
      data: data,
      onSuccess(e) {
        formik.resetForm();
        toast.success(t("Review.The form has been successfully submitted!"));
      },
      onError(e) {
        console.error(e);
      },
      channel
    })
  });

  function submit(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (!formik.errors.phone) {
      mutate({
        phone: '+' + formik.values.phone.replace(/\D/ig, ''),
        locale: lang,
        city: parseInt(city),
        read: "ok"
      });
    }
  }

  return (
    <form onSubmit={submit} className={styles.form}>
      <div>
        <InputForm
          type="tel"
          mask='+7 999 999 99 99'
          label={t("Global.Telephone")}
          name="phone"
          onChange={formik.handleChange}
          value={formik.values.phone.replace(/\D/ig, '')}
          className={formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}
        />
        {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
      </div>
      <button className={styles.button}>
        {isLoading ?
          <Spinner animation="border" variant="light" size="sm" />
          : <>
            {t("Contacts.Order a call")}
          </>
        }
      </button>
    </form>
  )
}
