import { useContext } from "react";
import { tabType } from "modules/product-page/types/TabTypes";
import AvailabilityTab from "./availability-tab/AvailabilityTab";
import DescriptionTab from "./description-tab/DescriptionTab";
import InstallmentTab from "./installment-tab/InstallmentTab";
import ReviewsTab from "./reviews-tab/ReviewsTab";
import SpecificationTab from "./specification-tab/SpecificationTab";
import { ProductPageContext } from "modules/product-page/services";
import { VideoTab } from "./video-tab/VideoTab";

interface ITabContent {
  tabName: tabType
}

export default function TabContent({ tabName }: ITabContent) {
  const product = useContext(ProductPageContext);

  switch (tabName) {
    case "description":
      return <DescriptionTab />

    case "specifications":
      return <SpecificationTab />

    case "reviews":
      return <ReviewsTab />

    case "video":
      return product.videos && product.videos.length > 0 ? <VideoTab /> : <></>;

    case "availability":
      return <AvailabilityTab />

    case "installment":
      return <InstallmentTab />

    default:
      return <></>
  }
}
