import React, { useState, useContext, useEffect } from "react";
import CustomBadge from "global/components/badge/CustomBadge";
import styles from "./styles.module.scss";
import ProductSlider from "../product-slider/ProductSlider";
import { ReactComponent as StarSVG } from "assets/icons/star.svg";
import { useNavigate, useParams } from "react-router-dom";
import Characteristic from "../characteristic/Characteristic";
import { breakpointLF, breakpointMD } from "global/hooks/constants";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as Basket } from 'assets/icons/basket.svg';
import CashbackBannerMob from "assets/banner/promocode-checkout.jpg";
import { Card, Placeholder } from "react-bootstrap";
import { useGetProcent } from "global/hooks/useGetProcent";
import { useCost } from "global/hooks/useCost";
import { ReactComponent as Bonus } from "assets/icons/bonus-icon.svg";
import { CreditAndInstallment } from "../product-bar/directive/CreditAndInstallment";
import { ProductPageContext } from "modules/product-page/services";
import ProductColor from "./directive/ProductColors";
import { ReactComponent as CurveRightSvg } from "assets/icons/curve-right.svg";
import { t } from "i18next";
import { ModalBox } from "global/components/modal/ModalBox";
import { FeedbackReview } from "../product-tabs/directive/reviews-tab/FeedbackReview";
import { IProductPage } from "modules/product-page/types/ProductPage";
import Loader from "global/components/loader/Loader";
import { useAppSelector } from "global/hooks/reduxHooks";
import { ROUTE_CART } from "router/routes";
import { Preorder } from "global/components/product-card/directive/preorder/Preorder";
import { Helmet } from "react-helmet";
import { SeoDataContext } from "global/hooks/seo/SeoDataContext";
import Widget from "../product-bar/directive/Widget";
import { addToCart } from "global/scripts/addToCart";
import { BackInStoreModal } from "./directive/out-of-stock/BackInStoreModal";
import { generateProductSchema } from "global/components/seo/ProductSchema";
import CityContext from "global/hooks/city/CityContext";
import { getBonusData } from "global/scripts/getBonusData";
import getUrl from "global/hooks/getUrl";
import LanguageContext from "global/hooks/language/LanguageContext";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

interface IMainContent {
  isLoading: boolean;
  resetReview: () => void;
  showAllSpecification: () => void;
  is_preorder?: boolean;
  data: any;
  webview?: boolean;
}

export default function MainContent({
  isLoading,
  resetReview,
  showAllSpecification,
  is_preorder,
  data,
  webview,
}: IMainContent) {
  const product = useContext(ProductPageContext);
  const { citySlug } = useContext(CityContext);
  const lang = useContext(LanguageContext);
  const { channel } = useContext(ChannelContext);
  const dispatch = useDispatch();
  const productInfo = useAppSelector((state) => state.productInfo[channel]);
  const inCart =
    (Array.isArray(productInfo) &&
      productInfo.some((item) => item.id === product.id)) ||
    false;
  const specialBonus: boolean = JSON.parse(
    process.env.REACT_APP_SPECIAL_BONUS_10 || "false"
  );
  const minCreditTotalAmount = process.env.REACT_APP_MIN_CREDIT_TOTAL_AMOUNT || 10000;
  const [reviewsModal, setReviewsModal] = useState(false);
  const [openPreOrder, setOpenPreOrder] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const isTablet = useMediaQuery(breakpointLF);
  const isModile = useMediaQuery(breakpointMD);
  const isLaptop = useMediaQuery(breakpointLF);

  const percent = useGetProcent(product.old_cost || 0, product.cost || 0);
  const oldCost = useCost(product.old_cost || 0);
  const currentCost = useCost(product.cost || 0);

  const isValidGift =
    new Date().getTime() <
    new Date(product?.gift_expires_at || "0-0-0").getTime();
  const costFn = function (this: any, str: number) {
    return useCost.apply(this, [str]);
  };

  const { mutate, isBonusLoading, cashback } = getBonusData(channel);
  const bonusVal = useCost(Math.floor((product.cost || 0) / 100 * cashback));

  useEffect(() => {
    if (!hasFetched && product?.vendor_code && !isLoading && cashback === 0) {
      mutate(product.vendor_code);
      setHasFetched(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFetched, product, isLoading, cashback]);

  const seoContext = useContext(SeoDataContext);
  useEffect(() => {
    if (!product.isLoad) {
      const seoDataTemp = {
        title: product.name,
        description: product.name,
      };
      if (seoContext) {
        const { updateContext } = seoContext;
        updateContext({ ...seoDataTemp });
      }
    }
  }, [product]);

  //SECTION - navigate
  function productNavigation({
    slug,
    id,
  }: {
    slug: string;
    id: number | string;
  }) {
    navigate(getUrl({currentUrl: `/catalog/${citySlug == "" ? "" : citySlug + "/"}${slug}/p${id}`, lang, channelToSet:channel}));
  }

  return (
    <>
      <div className={styles.mainContainer}>
        {isModile && specialBonus && (
          <img
            className={styles.banner_bonus}
            src={CashbackBannerMob}
            alt="cashback"
          />
        )}
        {!isTablet && (
          <div className={styles.mainContainer__upper}>
            {product.isLoad ? (
              <>
                <Placeholder
                  as={Card.Title}
                  animation="wave"
                  className={styles.placeholder}
                >
                  <Placeholder
                    xs={9}
                    bg="secondary"
                    className={styles.placeholder__Title}
                  />
                  <Placeholder
                    xs={3}
                    bg="secondary"
                    className={styles.placeholder__Title}
                  />
                </Placeholder>
              </>
            ) : (
              <>
                <h1 className={styles.mainContainer__title}>{product.name}</h1>
                {!isModile && product.brand?.image
                  ? (
                    <img
                      className={styles.mainContainer__brand}
                      alt={product.brand?.name}
                      src={product.brand?.image}
                    />
                  )
                  : <span className={styles.mainContainer__brand_name}>{product.brand?.name}</span>
                }
              </>
            )}
          </div>
        )}
        {!isModile && product.badges && product.badges.length > 0 && (
          <div className={styles.mainContainer__badges}>
            <CustomBadge
              badgeData={product.badges}
              withGift={
                (product.gifts && product.gifts?.length > 0 && isValidGift) ||
                false
              }
              stock={product.stock}
              quality={product.quality}
            />
          </div>
        )}
        <div
          className={
            styles.mainContainer__mainInfo +
            ` ${product.isLoad ? styles.mainContainer__mainInfo_loading : ""}`
          }
        >
          <div className={styles.slider}>
            {product.isLoad ? (
              <>
                <Placeholder
                  as={Card.Title}
                  animation="wave"
                  className={styles.placeholder__slider}
                >
                  <Placeholder
                    xs={12}
                    bg="secondary"
                    className={
                      "image-placeholder bg-50 " + styles.placeholder__slider
                    }
                  />
                </Placeholder>
                <Placeholder
                  as={Card.Title}
                  animation="wave"
                  className={styles.placeholder__badges}
                >
                  <Placeholder xs={2} bg="secondary" />
                  <Placeholder xs={2} bg="secondary" />
                  <Placeholder xs={2} bg="secondary" />
                </Placeholder>
              </>
            ) : (
              <>
                <ProductSlider
                  sliders={product?.images || []}
                  commonAlt={product?.name}
                />
                {!webview && <>{isLaptop && <Widget />}</>}
                {isModile &&
                  product &&
                  product.badges &&
                  product.badges.length > 0 && (
                    <div className={styles.mainContainer__badges}>
                      <CustomBadge
                        badgeData={product.badges}
                        withGift={
                          (product.gifts &&
                            product.gifts?.length > 0 &&
                            isValidGift) ||
                          false
                        }
                        stock={product.stock}
                        quality={product.quality}
                      />
                    </div>
                  )}
              </>
            )}
          </div>
          {isTablet && (
            <>
              <div className={styles.mainContainer__upper}>
                {product.isLoad ? (
                  <>
                    <Placeholder
                      as={Card.Title}
                      animation="wave"
                      className={styles.placeholder}
                    >
                      <Placeholder
                        xs={9}
                        bg="secondary"
                        className={styles.placeholder__Title}
                      />
                      <Placeholder
                        xs={3}
                        bg="secondary"
                        className={styles.placeholder__Title}
                      />
                    </Placeholder>
                  </>
                ) : (
                  <>
                    <h1 className={styles.mainContainer__title}>
                      {product.name}
                    </h1>
                  </>
                )}
              </div>
              <div className={styles.rewiewsContainer}>
                {product.isLoad ? (
                  <>
                    <Placeholder
                      as={Card.Title}
                      animation="wave"
                      className={styles.placeholder__left}
                    >
                      <Placeholder xs={9} bg="secondary" />
                    </Placeholder>
                  </>
                ) : (
                  <div className={styles.productCode}>
                    Код: {product.vendor_code}
                  </div>
                )}
                {product.isLoad ? (
                  <>
                    <Placeholder as={Card.Title} animation="wave">
                      <Placeholder xs={9} bg="secondary" />
                    </Placeholder>
                  </>
                ) : product.brand?.image ? (
                  <img
                    className={styles.mainContainer__brand}
                    src={product.brand?.image}
                    alt={product.brand?.name}
                  />
                ) : <span className={styles.mainContainer__brand_name}>{product.brand?.name}</span>}
              </div>
            </>
          )}
          <div className={styles.dataContainer}>
            {!isModile && (
              <div className={styles.rewiewsContainer}>
                {product.isLoad ? (
                  <>
                    <Placeholder as={Card.Title} animation="wave">
                      <Placeholder xs={9} bg="secondary" />
                    </Placeholder>
                  </>
                ) : product.review_count === null ? (
                  <>
                    <button
                      onClick={() => setReviewsModal(true)}
                      className={styles.writeReviews}
                    >
                      {t("Catalog.Leave a review")}
                    </button>
                    <ModalBox isOpen={reviewsModal} onClose={() => null}>
                      <FeedbackReview closeModal={setReviewsModal} />
                    </ModalBox>
                  </>
                ) : (
                  <div className={styles.rewiews}>
                    <StarSVG className={styles.rewiewsStar} />
                    {product.rating}
                    <button
                      onClick={resetReview}
                      className={styles.rewiewsLink}
                    >
                      ({t("Catalog.reviews", { count: product.review_count })})
                    </button>
                  </div>
                )}
                {product.isLoad ? (
                  <>
                    <Placeholder
                      as={Card.Title}
                      animation="wave"
                      className={styles.placeholder__left}
                    >
                      <Placeholder xs={9} bg="secondary" />
                    </Placeholder>
                  </>
                ) : (
                  <div className={styles.productCode}>
                    Код: {product.vendor_code}
                  </div>
                )}
              </div>
            )}

            {isTablet && (
              <>
                <div className={styles.priceMain}>
                  <div
                    className={
                      styles.priceContainer +
                      ` ${product.isLoad && styles.priceContainerLoad}`
                    }
                  >
                    {product.old_cost &&
                      percent > 5 &&
                      product.old_cost > (product.cost || 0) ? (
                      <span className={styles.oldPriceContainer}>
                        {product.isLoad ? (
                          <Placeholder as={Card.Text} animation="wave">
                            <Placeholder xs={12} bg="secondary" />
                          </Placeholder>
                        ) : (
                          <>
                            <span className={styles.oldPriceProcent}>
                              - {percent} %
                            </span>
                            <span className={styles.oldPrice}>{oldCost} ₸</span>
                          </>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                    {product.isLoad ? (
                      <Placeholder as={Card.Text} animation="wave">
                        <Placeholder xs={12} bg="secondary" />
                      </Placeholder>
                    ) : (
                      <span className={styles.currentPrice}>
                        {currentCost} ₸
                      </span>
                    )}
                  </div>
                  <div className={styles.line}></div>
                  <div className={styles.bonusesContent}>
                    <span className={styles.bonusTitle}>
                      {t("Global.Bonus")}:
                    </span>
                    {product.isLoad ? (
                      <Placeholder
                        as={Card.Text}
                        animation="wave"
                        className={styles.placeholder}
                      >
                        <Placeholder
                          xs={12}
                          bg="secondary"
                          className={styles.placeholder__cost}
                        />
                      </Placeholder>
                    ) : (
                      isLoading || isBonusLoading
                        ? <Placeholder as={Card.Text} animation="wave" className={styles.placeholder + ` ${styles.placeholder__bonus}`}>
                          <Placeholder xs={12} bg="secondary" className={styles.placeholder__cost} />
                        </Placeholder>
                        : bonusVal && (
                          <div className={styles.bonusContainer}>
                            <Bonus className={styles.bonusIcon} />
                            до
                            <span className={styles.bonuses}>
                              {bonusVal}
                            </span>
                          </div>
                        )
                    )}
                  </div>
                </div>
                {!webview && (
                  <>
                    {product.isLoad ? (
                      <>
                        <Placeholder as={Card.Text} animation="wave">
                          <Placeholder
                            xs={12}
                            bg="secondary"
                            className={styles.placeholder__Btn}
                          />
                        </Placeholder>
                      </>
                    ) : is_preorder ? (
                      <>
                        <button
                          className={"button " + styles.blueBtn}
                          onClick={() => {
                            setOpenPreOrder(true);
                          }}
                          title={t("Global.Preorder")}
                        >
                          {t("Preorder.Place a pre-order")}
                          <Basket />
                        </button>
                        <ModalBox isOpen={openPreOrder} onClose={() => null}>
                          <Preorder
                            {...data}
                            setOpenPreOrder={setOpenPreOrder}
                          />
                        </ModalBox>
                      </>
                    ) : product.availableForPurchase ||
                      product.availableForPurchaseFromDc ? (
                      <button
                        className={"button " + styles.blueBtn}
                        onClick={() => {
                          if (inCart) {
                            navigate(getUrl({currentUrl:ROUTE_CART, lang, channelToSet:channel}))
                            return;
                          }
                          addToCart(
                            product as IProductPage,
                            productInfo,
                            dispatch,
                            cashback
                          );
                        }}
                      >
                        {inCart
                          ? t("ProductPage.Go to cart")
                          : !product.availableForPurchase &&
                            !product.availableForPurchaseFromDc
                            ? t("Global.Out of stock")
                            : t("Global.Buy")}
                        <Basket />
                      </button>
                    ) : (
                      <BackInStoreModal />
                    )}
                  </>
                )}
              </>
            )}

            {isModile && (
              <>
                {
                  (product.cost || 0) > +minCreditTotalAmount &&
                  <CreditAndInstallment webview={webview} />
                }
              </>
            )}

            {product.isLoad ? (
              <>
                <Placeholder as={Card.Title} animation="wave">
                  <Placeholder
                    xs={2}
                    bg="secondary"
                    className={styles.placeholder__block}
                  />
                  <Placeholder
                    xs={2}
                    bg="secondary"
                    className={styles.placeholder__block}
                  />
                  <Placeholder
                    xs={2}
                    bg="secondary"
                    className={styles.placeholder__block}
                  />
                </Placeholder>
              </>
            ) : (
              product.variations &&
              product.variations.length > 0 &&
              product.variations.map((variation) => (
                <React.Fragment key={variation.specification_id}>
                  {!webview && variation.specification_type === "color" ? (
                    <div className={styles.colorContainer}>
                      <div className={styles.Title}>
                        {variation.specification_name}:
                      </div>
                      <div className={styles.colorBlocks}>
                        {/* SECTION - color */}
                        {variation.data.map((item) => (
                          <React.Fragment key={item.id}>
                            {item.active && (
                              <>
                                <ProductColor
                                  {...item}
                                  onClick={() =>
                                    productNavigation({ ...item.product })
                                  }
                                  isLoading={isLoading}
                                />
                              </>
                            )}
                          </React.Fragment>
                        ))}
                      </div>
                    </div>
                  ) : (
                    !webview && (
                      <div
                        className={styles.colorContainer}
                        key={variation.specification_id}
                      >
                        <div className={styles.Title}>
                          {variation.specification_name}:
                        </div>
                        <div className={styles.infoBlocks}>
                          {variation.data.map((item) => (
                            <div
                              key={item.id}
                              className={`${styles.infoBlock} ${item.checked && styles.infoBlockActive
                                } ${!item.active && styles.infoBlockDisabled}`}
                              onClick={() =>
                                !item.active
                                  ? null
                                  : productNavigation({ ...item.product })
                              }
                            >
                              {isLoading && (
                                <>
                                  <div
                                    className={
                                      styles.colorBlock__loading +
                                      ` ${isLoading && styles.loading}`
                                    }
                                  >
                                    <Loader size={18} />
                                  </div>
                                </>
                              )}
                              {item.name}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                  )}
                </React.Fragment>
              ))
            )}

            <div className={styles.characteristicsContainer}>
              {product.isLoad ? (
                <Placeholder as={Card.Title} animation="wave">
                  <Placeholder
                    xs={6}
                    bg="secondary"
                    className={styles.placeholder__Header}
                  />
                </Placeholder>
              ) : (
                !isModile &&
                product?.specifications?.[0] && (
                  <div className={styles.Title}>
                    {product.specifications[0].name}
                  </div>
                )
              )}
              <div className={styles.characteristics}>
                {product.isLoad ? (
                  <>
                    {Array.from("1234").map((item) => (
                      <Placeholder
                        key={item}
                        as={Card.Title}
                        animation="wave"
                        className={styles.placeholder__specification}
                      >
                        <Placeholder xs={9} bg="secondary" />
                        <Placeholder xs={2} bg="secondary" />
                      </Placeholder>
                    ))}
                  </>
                ) : (
                  !isModile &&
                  product?.specifications?.[0] &&
                  Array.isArray(product.specifications[0].specifications) &&
                  product.specifications[0].specifications.map((item, key) => (
                    <React.Fragment key={key}>
                      <Characteristic
                        title={item.specification.name}
                        values={item.values}
                      />
                    </React.Fragment>
                  ))
                )}
              </div>
              {!product.isLoad && (
                <button
                  className={styles.textWithIcon}
                  onClick={showAllSpecification}
                >
                  <span className={styles.text + ` ${styles.textBlue}`}>
                    {t("Comparison.All characteristics")}
                  </span>
                  <CurveRightSvg className={styles.iconRight} />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {!product.isLoad ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(generateProductSchema({ product }))}
          </script>
        </Helmet>
      ) : (
        <></>
      )}
    </>
  );
}
