import InputMask from 'react-input-mask';
import styles from '../../styles.module.scss';

export interface IInputPhone {
  type: "tel",
  className?: string,
  mask: string,
  name: string,
  label: string,
  value: string | number,
  onChange: React.ChangeEventHandler<HTMLInputElement>,
  onBlur?: React.FocusEventHandler<HTMLInputElement>,
  disabled?: boolean
}

export default function InputPhone({ type, label, mask, name, onChange,  onBlur, value, className, disabled }: IInputPhone) {
  return (
    <div className={styles.wrapper}>
      <InputMask
        type={type}
        maskChar={null}
        mask={mask}
        className={styles.input + ' ' + className}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      />
      <label className={value.toString().length > 0 ? styles.label : ''}>{label}</label>
    </div>
  )
}
