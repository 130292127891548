import { useMutation } from "@tanstack/react-query";
import { getBonus } from "./dataFetching";
import { useState } from "react";

export function getBonusData(channel:string) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [cashback, setCashback] = useState(0);
  function collectData(vendor_code: string) {
    return {
      shop: "HQ1000022",
      product: vendor_code // меняется только это артикул товара
    }
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading } = useMutation((vendor_code: string) =>
    getBonus({
      url: "bonuses/bonustype/search-max",
      data: collectData(vendor_code),
      onSuccess(e) {
        if (e.data.data.max_percent !== null) {
          setCashback(e.data.data.max_percent);
        }
      },
      onError(e) {
        console.log(e);
      },
      channel
    })
  );

  return { mutate, isBonusLoading: isLoading, cashback }
}
