import { useContext, useEffect, useState } from "react";
import Button from "global/components/button/Button";
import { ReactComponent as Close } from "assets/icons/close.svg";
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import styles from "./styles.module.scss";
import ProductCostPrice from "../ProductCostPrice";
import InputForm from "global/components/input/InputForm";
import { useFormik } from "formik";
import { usePreorderValidate } from "../../hooks/usePreorderValidate";
import i18next, { t } from "i18next";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { IAvailabilityBranch } from "modules/product-page/types/Availability";
import CityContext from "global/hooks/city/CityContext";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { AxiosResponse } from "axios";
import { clearOrderInfo, IOrderInfo, setOrderInfo } from "global/redux/actions/orderInfoActions";
import { useAppSelector } from "global/hooks/reduxHooks";
import { IUserInfoAction } from "global/redux/reducers/userInfo";
import { setUserInfo } from "global/redux/actions/userInfoActions";
import { IDeliveryInfoAction } from "global/redux/reducers/deliveryInfo";
import { setDeliveryInfo } from "global/redux/actions/deliveryInfoActions";
import { IPaymentInfoAction } from "global/redux/reducers/paymentInfo";
import { setPaymentInfo } from "global/redux/actions/paymentInfoActions";
import { CollectPaymentData } from "global/scripts/collectPaymentData";
import Loader from "global/components/loader/Loader";
import { IProductCost } from "../ProductCost";
import getUserData from "global/scripts/getUserData";
import { ROUTE_PRIVACY_POLICY } from "router/routes";
import { toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";
import { breakpointDF } from "global/hooks/constants";
import { paymentMethods } from "./data/paymentMethods";
import { IOrderInfoAction } from "global/redux/reducers/orderInfo";
import getUrl from "global/hooks/getUrl";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { useDispatch } from "react-redux";

interface IPreorder extends IProductCost {
  id: number,
  images: string[],
  name: string,
  setOpenPreOrder: (e: boolean) => void,
}

export function Preorder(props: IPreorder) {
  const {
    id,
    cost,
    images,
    name = "",
    old_cost = 0,
    isLoad,
    setOpenPreOrder,
    bank_coefficient
  } = props;
  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const { city } = useContext(CityContext);
  const { channel } = useContext(ChannelContext);
  const lang = i18next.language;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useAppSelector((state) => state);
  const paymentInfo = useAppSelector((state) => state.paymentInfo[channel]);
  const userInfo = useAppSelector(state => state.userInfo);
  const token = useAppSelector(state => state.userToken);
  const deliveryInfo = useAppSelector(state => state.deliveryInfo[channel]);
  const isTablet = useMediaQuery(breakpointDF);

  const [user, setUser] = useState(getUserData(token?.token,channel));
  const [shop, setShop] = useState<IAvailabilityBranch | undefined>();
  const [title, setTitle] = useState(paymentMethods.filter(item => item.isUsed)[0].title);

  const { data } = useQueryBuilder<IAvailabilityBranch[]>({
    url: `products/stocks`,
    dataJSON: {
      "product_id": id,
      "city_id": city || 1
    },
    cacheTime: 10000,
    staleTime: 10000,
  });

  const imageOnErrorHandler = () => {
    setImageIsLoaded(false);
  };
  const validate = usePreorderValidate();

  useEffect(() => {
    dispatch(setPaymentInfo({ ...paymentInfo, id: paymentMethods.filter(item => item.isUsed)[0].id }) as IPaymentInfoAction);
    setTitle(paymentMethods.filter(item => item.isUsed)[0].title);
  }, []);

  // Changing the payment methods
  function changePaymentMethod(title: string, id: number) {
    setTitle(title);
    dispatch(setPaymentInfo({ ...paymentInfo, id: id }) as IPaymentInfoAction);
  }

  //Bonus
  const [cashback, setCashback] = useState(0);

  const sendSms = useMutation(() =>
    postData({
      url: "sms/send",
      data: {
        phone: `+${formik.values.phone.replace(/\D/ig, '')}` || userInfo?.phone,
      },
      onSuccess(e) {
        dispatch(
          setUserInfo({
            ...userInfo,
            smsId: e.data.message_id,
          }) as IUserInfoAction
        );
      },
      onError(e) {
        console.log(e);
      },
      channel
    })
  );

  const { mutate, isLoading } = useMutation(
    () => postData({
      url: 'checkout/send',
      data: CollectPaymentData({
        city: +city,
        token: token,
        personalInfo: user ? user.data : undefined,
        deviceType: isTablet ? "mobile" : "desktop",
        ...state,
        productInfo: [{
          ...props,
          availableForPurchase: (props as any).availableForPurchase,
          availableForPurchaseFromDc: (props as any).availableForPurchaseFromDc,
          deliveryDate: '',
          pickUpDate: '',
          quantity: 1,
        }],
        userInfo
      }),
      onSuccess(e: { data: AxiosResponse<IOrderInfo, any> }) {
        dispatch(clearOrderInfo() as IOrderInfoAction);
        dispatch(setOrderInfo({ ...e.data.data }) as IOrderInfoAction);

        if ((paymentInfo?.id === 2 || paymentInfo?.id === 5) && typeof e.data.data.onlinePayment === "object") {
          window.location.href = e.data.data.onlinePayment.redirectUrl;
        }
        if (paymentInfo?.id === 3) {
          sendSms.mutate();
          navigate(getUrl({currentUrl:`/checkout/broker-otp/${e.data.data.id}`, lang:lang, channelToSet:channel}));
        } else if (paymentInfo?.id !== 2 && paymentInfo?.id !== 5 && paymentInfo?.id !== 6) {
          navigate(getUrl({currentUrl:`/thanks/${e.data.data.id}`, lang:lang, channelToSet:channel}));
        }
      },
      onError(e) {
        toast.error(e.message);
      },
      channel
    }));

  const formik = useFormik({
    initialValues: {
      firstName: token?.firstName || '',
      lastName: token?.lastName || '',
      email: token?.email || '',
      phone: token?.phone || '',
      ipn: paymentInfo?.ipn || ""
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  function submit(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (token) {
      dispatch(setUserInfo({
        ...userInfo,
        ...token,
        ...formik.values,
        phone: formik.values.phone.replace(/\D/ig, ''),
        useBonuses: false,
        bonus: 0
      }) as IUserInfoAction)
    } else {
      dispatch(setUserInfo({
        ...userInfo,
        ...formik.values,
        selectTab: "register",
        phone: `+${formik.values.phone.replace(/\D/ig, '')}`,
        useBonuses: false,
        bonus: 0
      }) as IUserInfoAction)
    }

    if (deliveryInfo?.date && new Date(deliveryInfo?.date?.split('.').reverse().join('-')).getTime() < new Date().getTime()) {
      const date = new Date(new Date().setDate((new Date().getDate()) + 1)).toLocaleDateString();
      dispatch(setDeliveryInfo({ ...deliveryInfo, ...formik.values, date: date, id: 1 }) as IDeliveryInfoAction)
    } else {
      dispatch(setDeliveryInfo({ ...deliveryInfo }) as IDeliveryInfoAction)
    }

    dispatch(setPaymentInfo({
      ...paymentInfo,
      ...formik.values,
    }) as IPaymentInfoAction);

    if (!Object.values(formik.errors).some(item => item.length > 1)) {
      mutate();
    }
  }
  return (
    //SECTION - preorder
    <div className={styles.preorder}>
      <div className={styles.preorder__head}>
        <span className={styles.preorder__title}>
          {t("Preorder.Making a pre-order")}
        </span>
        <Button
          className={styles.closeBtn}
          onClick={() => setOpenPreOrder(false)}
        >
          <Close />
        </Button>
      </div>
      <div className={styles.preorder__productInfo}>
        {(imageIsLoaded && !isLoad)
          ? <img className={styles.image} title={name} src={images[0]} alt={name} onError={imageOnErrorHandler} />
          : <img className={styles.image} title={name} src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt={name} />
        }
        <div className={styles.preorder__productContent}>
          <span className={styles.preorder__description}>{name}</span>
          <ProductCostPrice
            cost={cost}
            old_cost={old_cost}
            isLoad={isLoad}
            bank_coefficient={bank_coefficient}
            cachback_max_percent={cashback}
            className={styles.oldPriceProcent}
          />
        </div>
      </div>
      <div className={styles.preorder__info}>

      </div>
      <form
        className={styles.form}
        onSubmit={submit}
      >
        <DropdownButton
          id="dropdown-basic-button"
          title={title}
          className={`${styles.select + " " + styles.textGray}`}
        >
          {
            paymentMethods.map(paymentMethod => (
              paymentMethod.isUsed
                ? <Dropdown.Item
                  key={paymentMethod.id}
                  className={styles.option + ` ${title === paymentMethod.title && styles.active}`}
                  disabled={false}
                  onClick={() => changePaymentMethod(paymentMethod.title, paymentMethod.id)}
                >
                  {paymentMethod.title}
                </Dropdown.Item>
                : <></>
            ))
          }
        </DropdownButton>
        {/* <DropdownButton
          id="dropdown-basic-button"
          title="Полная стоимость"
          className={`${styles.select + " " + styles.textGray}`}
        >
          <Dropdown.Item
            className={styles.option + ` ${styles.active}`}
            disabled={false}
          >
            Полная стоимость
          </Dropdown.Item>
        </DropdownButton> */}
        {
          paymentInfo?.id === 3
            ? <div className={styles.ipnWrapper}>
              <InputForm
                type="number"
                label={t("Payment.IPN *")}
                mask='999999999999'
                name="ipn"
                onChange={(e) => { formik.handleChange(e); dispatch(setPaymentInfo({ ...paymentInfo, ipn: e.target.value, bank: "broker", repayment_period: 12 }) as IPaymentInfoAction); }}
                value={formik.values.ipn || ''}
                className={formik.errors.ipn && formik.touched.ipn ? styles.errorInput : ''}
              />
              {formik.errors.ipn && formik.touched.ipn ? <div className={styles.errorText}>{formik.errors.ipn}</div> : null}
            </div>
            : <></>
        }
        <div>
          <InputForm
            type="text"
            label={`${t("Global.Name")}*`}
            name="firstName"
            onChange={(e) => {
              formik.handleChange(e);
              // dispatch(setUserInfo({ ...userInfo, firstName: e.target.value }) as IUserInfoAction);
            }}
            value={formik.values.firstName.replace(/[^a-zа-я]/ig, '')}
            className={formik.errors.firstName && formik.touched.firstName ? styles.errorInput : ''}
          />
          {formik.errors.firstName && formik.touched.firstName ? <div className={styles.errorText}>{formik.errors.firstName}</div> : null}
        </div>
        <div>
          <InputForm
            type="text"
            label={`${t("Global.Surname")}*`}
            name="lastName"
            onChange={(e) => {
              formik.handleChange(e);
              // dispatch(setUserInfo({ ...userInfo, lastName: e.target.value }) as IUserInfoAction);
            }}
            value={formik.values.lastName.replace(/[^a-zа-я]/ig, '')}
            className={formik.errors.lastName && formik.touched.lastName ? styles.errorInput : ''}
          />
          {formik.errors.lastName && formik.touched.lastName ? <div className={styles.errorText}>{formik.errors.lastName}</div> : null}
        </div>
        <div>
          <InputForm
            type="tel"
            mask='+7 999 999 99 99'
            label={`${t("Global.Telephone")}*`}
            name="phone"
            onChange={(e) => {
              formik.handleChange(e);
              // dispatch(setUserInfo({ ...userInfo, phone: e.target.value.replace(/[\s,\(\)\-]/ig, '') }) as IUserInfoAction);
            }}
            value={formik.values.phone.replace(/\D/ig, '')}
            className={formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}
          />
          {formik.errors.phone && formik.touched.phone ? <div className={styles.errorText}>{formik.errors.phone}</div> : null}
        </div>
        <div className={styles.form__container}>
          <div>
            <InputForm
              type="email"
              label="Email*"
              name="email"
              onChange={(e) => {
                formik.handleChange(e);
                // dispatch(setUserInfo({ ...userInfo, email: e.target.value }) as IUserInfoAction);
              }}
              value={formik.values.email}
              className={formik.errors.email && formik.touched.email ? styles.errorInput : ''}
            />
            {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
          </div>
          {data && data.length > 0 && (
            <>
              <DropdownButton
                id="dropdown-basic-button"
                title={shop?.name || data[0]?.name}
                className={`${styles.select + " " + styles.textGray}`}
              >
                {data && data.map((item, i) => {
                  return <Dropdown.Item
                    key={i}
                    className={styles.option + ` ${(shop?.name === item.name) && styles.active}`}
                    onClick={() => setShop(item)}
                    disabled={false}
                  >
                    {item?.name}
                  </Dropdown.Item>
                })}
              </DropdownButton>
            </>
          )}
        </div>
        <Button type="submit" className={styles.orangeBtn}>
          {t("Cart.Place an order")}
          {isLoading ? <Loader color="#ffffffab" secondaryColor="#ffffff" size={18} /> : ''}
        </Button>
      </form>
      <span className={styles.preorder__termsOfUse}>{t("Preorder.By continuing, you accept the terms")} <Link to={`/${ROUTE_PRIVACY_POLICY}`}>{t("Preorder.of the user agreement")}</Link></span>
    </div>
  )
}
