import AlertModal from "global/components/modal/AlertModal";
import { useSelector, useDispatch, } from "react-redux";
import { hideMessageModal } from "global/redux/actions/messageModalActions";

interface WithMessageModalProps {
  messageModal:any
}

export const withMessageModals = (WrappedComponent:any) => {
    return (props:any) => {
        const modal = useSelector((state: WithMessageModalProps) => state.messageModal);
        const dispatch = useDispatch();

        function closeModal() {
            dispatch(hideMessageModal());
        }

        return <>
            <AlertModal show={modal.show} type={modal.dialogType} modalText={modal.text} modalSubtext={modal.subtext} buttonText={modal.buttonText} closeModal={closeModal}/>
            <WrappedComponent {...props} />
        </>
    };
};
