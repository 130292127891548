import styles from './styles.module.scss';
import { ReactComponent as Basket } from 'assets/icons/basket.svg';
import { ReactComponent as Bonus } from 'assets/icons/bonus-icon.svg';
import { Card, Placeholder } from 'react-bootstrap';
import { useGetProcent } from 'global/hooks/useGetProcent';
import { useCost } from 'global/hooks/useCost';
import { IProductCostPrice } from 'global/components/product-card/directive/ProductCostPrice';
import { CreditAndInstallment } from './directive/CreditAndInstallment';
import { DeliveryMethods } from './directive/DeliveryMethods';
import AccoridionMethods from './directive/AccoridionMethods';
import { ReactComponent as CoinStack } from 'assets/icons/coin-stack.svg';
import { ReactComponent as BankBuilding } from 'assets/icons/bank-building.svg';
import { ReactComponent as Money } from 'assets/icons/money.svg';
import { ReactComponent as CreditCart } from 'assets/icons/credit-cart.svg';
import { useContext, useEffect, useRef, useState } from 'react';
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { t } from "i18next";
import { useAppSelector } from 'global/hooks/reduxHooks';
import { useNavigate } from 'react-router';
import { ROUTE_BEST_PRICE_GUARANTEE, ROUTE_CART } from 'router/routes';
import { useMediaQuery } from 'react-responsive';
import { breakpointDF } from 'global/hooks/constants';
import { ModalBox } from 'global/components/modal/ModalBox';
import { Preorder } from 'global/components/product-card/directive/preorder/Preorder';
import IconContainer from './directive/Widget';
import { IProductPage } from 'modules/product-page/types/ProductPage';
import { addToCart } from 'global/scripts/addToCart';
import { BackInStoreModal } from '../main-container/directive/out-of-stock/BackInStoreModal';
import { getBonusData } from 'global/scripts/getBonusData';
import getUrl from 'global/hooks/getUrl';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';
import LanguageContext from 'global/hooks/language/LanguageContext';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface IProductBar extends IProductCostPrice {
  isActive?: boolean,
  resetReview: () => void,
  disabled: boolean,
  vendor_code: string,
  is_preorder?: boolean,
  data: any,
  webview?: boolean
}

export default function ProductBar({ cost, isLoad, old_cost = 0, isActive, resetReview, disabled, vendor_code, is_preorder, data, webview }: IProductBar) {
  const [hasFetched, setHasFetched] = useState(false);
  const product = useContext(ProductPageContext) as IProductPageContext;
  const category = product.breadcrumbs && product.breadcrumbs[product.breadcrumbs.length - 1];
  const minCreditTotalAmount = process.env.REACT_APP_MIN_CREDIT_TOTAL_AMOUNT || 10000;
  const { channel } = useContext(ChannelContext);
  const lang = useContext(LanguageContext);

  useEffect(() => {
    !isTablet && document.addEventListener('scroll', Scrolling);

    return () => {
      document.removeEventListener('scroll', Scrolling);
    }
  }, [])

  // * Цены, бонусы и акции
  const percent = useGetProcent(old_cost, cost);
  const oldCost = useCost(old_cost);
  const currentCost = useCost(cost);

  const navigate = useNavigate();
  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const [openPreOrder, setOpenPreOrder] = useState(false);

  // * Redux
  const dispatch = useDispatch();
  const productInfo = useAppSelector(state => state.productInfo[channel]);

  // * Проверки для кнопок
  const inCart = Array.isArray(productInfo) && productInfo.some(item => item.id === product.id) || false;

  // * Событие скролла
  const [deliveryScroll, setDeliveryScroll] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const deliveryRef = useRef<HTMLDivElement>(null);
  const isTablet = useMediaQuery(breakpointDF);

  // * Слушатель ошибки для картинки
  const imageOnErrorHandler = () => {
    setImageIsLoaded(false)
  };

  // * Слушатель скролла
  function Scrolling() {
    if ((deliveryRef.current?.getClientRects()[0].top ?? 0)
      < (contentRef.current?.getClientRects()[0].bottom ?? 0) - (deliveryRef.current?.clientHeight ?? 0)
    ) {
      setDeliveryScroll(true);
    }
    if ((deliveryRef.current?.getClientRects()[0].top ?? 0)
      > (contentRef.current?.getClientRects()[0].bottom ?? 0) - (deliveryRef.current?.clientHeight ?? 0)
    ) {
      setDeliveryScroll(false);
    }
  }

  const { mutate, isBonusLoading, cashback } = getBonusData(channel);
  const bonusVal = useCost(Math.floor(cost / 100 * cashback));

  useEffect(() => {
    if (!hasFetched && product?.vendor_code && !isLoad && cashback === 0) {
      mutate(product.vendor_code);
      setHasFetched(true);
    }
  }, [hasFetched, product, isLoad, cashback]);

  return (
    <>
      <div className={styles.priceContent + ` ${webview ? styles.priceContent__web : ""}`} ref={contentRef}>
        <div className={styles.priceHead}>
        <Link to={getUrl({ lang, currentUrl: ROUTE_BEST_PRICE_GUARANTEE, channelToSet:channel })} className={styles.priceGuarantee}>{t("ProductPage.Low price guarantee")}</Link>
          {
            !webview &&
            <IconContainer />
          }
        </div>

        {!isLoad &&
          <div className={`${styles.productPhoto} ${isActive && styles.productPhoto_Active}`}>
            {imageIsLoaded
              ? <img className={styles.productPhoto_Img} src={product.images && product.images[0]} alt={product.name} onError={imageOnErrorHandler} />
              : <img className={styles.productPhoto_Img} src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt={product.name} />
            }
            <div className={styles.productPhoto_Text}>
              {product.name}
            </div>
          </div>
        }

        <div className={styles.priceMain}>
          <div className={styles.priceContainer + ` ${isLoad && styles.priceContainerLoad}`}>
            {isLoad ?
              <Placeholder as={Card.Text} animation="wave" className={styles.placeholder}>
                <Placeholder xs={12} bg="secondary" className={styles.placeholder__cost} />
              </Placeholder>
              : <>
                {old_cost > 0 && percent > 5 && old_cost > cost && <span className={styles.oldPriceContainer}>
                  <span className={styles.oldPriceProcent}>
                    - {percent} %
                  </span>
                  <span className={styles.oldPrice}>{oldCost} ₸</span>
                </span>
                }
              </>
            }
            {isLoad ?
              <Placeholder as={Card.Text} animation="wave" className={styles.placeholder}>
                <Placeholder xs={12} bg="secondary" className={styles.placeholder__cost} />
              </Placeholder>
              : <span className={styles.currentPrice}>{currentCost} ₸</span>
            }
          </div>
          {bonusVal && <div className={styles.line}></div>}
          <div className={styles.bonusesContent}>
            <span className={styles.bonusTitle}>{t("Global.Bonus")}:</span>
            {isLoad || isBonusLoading ? <Placeholder as={Card.Text} animation="wave" className={styles.placeholder}>
              <Placeholder xs={12} bg="secondary" className={styles.placeholder__cost} />
            </Placeholder>
              : bonusVal && <div className={styles.bonusContainer}>
                <Bonus className={styles.bonusIcon} />
                до
                <span className={styles.bonuses}>{bonusVal}</span>
              </div>
            }
          </div>
        </div>

        {
          !webview &&
          <>
            {product.isLoad ? <>
              <Placeholder as={Card.Text} animation="wave">
                <Placeholder xs={12} bg="secondary" className={styles.placeholder__Btn} />
              </Placeholder>
            </>
              : is_preorder
                ? <>
                  <button
                    className={"button " + styles.blueBtn}
                    onClick={() => {
                      setOpenPreOrder(true);
                    }}
                    title={t("Global.Preorder")}
                  >
                    <Basket />
                    {t("Preorder.Place a pre-order")}
                  </button>
                  <ModalBox
                    isOpen={openPreOrder}
                    onClose={() => null}
                  >
                    <Preorder
                      {...data}
                      setOpenPreOrder={setOpenPreOrder}
                    />
                  </ModalBox>
                </>
                : (product.availableForPurchase || product.availableForPurchaseFromDc)
                  ? <button
                    className={"button " + styles.blueBtn}
                    onClick={() => {
                      if (inCart) {
                        navigate(getUrl({currentUrl:ROUTE_CART, lang, channelToSet:channel}))
                        return;
                      }
                      addToCart(product as IProductPage, productInfo, dispatch, cashback)
                    }}
                  >
                    <Basket />
                    {inCart
                      ? t("ProductPage.Go to cart")
                      : (!product.availableForPurchase && !product.availableForPurchaseFromDc)
                        ? t("Global.Out of stock")
                        : t("Global.Buy")
                    }
                  </button>
                  : <BackInStoreModal />
            }
          </>
        }

        {
          cost > +minCreditTotalAmount &&
          <CreditAndInstallment webview />
        }

        {!isLoad && !product.digital && <>
          <div className={styles.deliveryFooter + ` ${deliveryScroll ? styles.active : ''}`}>
            <span className={styles.deliveryFooter_content}>
              {t("Global.Delivery")}: <span>{product.deliveryDate}</span>
            </span>·
            <span className={styles.deliveryFooter_content}>
              {t("Global.Pickup")}: <span>{product.pickUpDate}</span>
            </span>·
            <span className={styles.deliveryFooter_content}>
              {t("ProductPage.Payment")}: <BankBuilding /><Money /><CreditCart />
            </span>
          </div>
        </>}
      </div>

      {
        !product.digital
          ? <div ref={deliveryRef}>
            <DeliveryMethods
              deliveryDate={product.deliveryDate || ''}
              pickupDate={product.pickUpDate || ''}
              resetReview={resetReview}
            />
          </div>
          : <></>
      }

      <AccoridionMethods
        eventKey="1"
        head={<>
          <CoinStack />
          {t("Global.Payment methods")}
        </>}
        children={<>
          <div className={styles.item}>
            <BankBuilding />
            {t("Global.Credit")}
          </div>
          <div className={styles.item}>
            <Money />
            {t("Global.Cash")}
          </div>
          <div className={styles.item}>
            <CreditCart />
            {t("Global.Cashless")}
          </div>
        </>}
      />

    </>
  )
}
