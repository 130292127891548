import React from "react";
import { Form } from "react-bootstrap";
import styles from "../styles.module.scss";

export interface ISquareCheck {
  disabled?: boolean,
  checked?: boolean,
  value: string | number,
  label?: string | React.ReactNode,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  name?: string,
  className?: string
}

export default function SquareCheck({ disabled = false, name, checked, value, label, onChange, className }: ISquareCheck) {

  if (disabled) {
    return (
      <Form.Check className={className} inline type="checkbox">
        <Form.Check.Input className={styles.radioBtn} defaultChecked={checked} disabled type="checkbox" value={value} onChange={onChange} name={name} />
        {label && <Form.Check.Label className={styles.labelRadioBtn}>{label}</Form.Check.Label>}
      </Form.Check>
    )
  }

  return (
    <Form.Check inline type="checkbox" id={`intermediate-checkbox${value}`} className={className}>
      <Form.Check.Input className={styles.radioBtn} defaultChecked={checked} type="checkbox" value={value} onChange={onChange} name={name} />
      {label && <Form.Check.Label className={styles.labelRadioBtn}>{label}</Form.Check.Label>}
    </Form.Check>
  )
}
