export const iObserver = new IntersectionObserver(function (entries) {
  if (entries[0].isIntersecting === true) {
    let cont = entries[0].target;
    if (!cont.classList.contains('rr-render')) {
      // @ts-ignore
      cont.innerHTML = '<div data-retailrocket-markup-block="' + cont.dataset.block + '" data-stock-id="' + cont.dataset.stockid + '"></div>'; // Добавляем блок
      cont.classList.add('rr-render');
      // @ts-ignore
      retailrocket.markup.render(cont.dataset.block); // Запускаем рендер
    }
    iObserver.unobserve(cont); // перестаём отслеживать видимость
  }
}, { threshold: [0] });
