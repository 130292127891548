import { PaymentMethods } from "../types/PaymentMethods";

export const paymentMethods: PaymentMethods[] = [
  {
    id: 1,
    title: "Оплата при получении",
    isUsed: false
  },
  {
    id: 5,
    title: "Картой онлайн",
    isUsed: true
  },
  {
    id: 3,
    title: "Кредит или рассрочка",
    isUsed: true
  },
  {
    id: 4,
    title: "Безналичными для юридических лиц",
    isUsed: false
  },
]
