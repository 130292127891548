import { Form } from "react-bootstrap";
import styles from "./../styles.module.scss";

export interface IRoundCheck {
  label?: string,
  checked?: boolean,
  value: string | number,
  name: string,
  disabled?: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  className?: string
}

export default function RoundCheck({ label, checked, value, name, disabled = false, onChange, className}: IRoundCheck) {
  return (
    <>
      <Form.Check
        type="radio"
        id={`default-radio${value}`}
        className={className}
      >
        <Form.Check.Input className={styles.radioBtn} name={name} defaultChecked={checked} disabled={disabled} type="radio" value={value} onChange={onChange}/>
        {label && <Form.Check.Label className={styles.labelRadioBtn}>{label}</Form.Check.Label>}
      </Form.Check>
    </>
  )
}
