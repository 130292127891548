import {
  DIALOG_ALERT,
  DIALOG_ERROR,
  DIALOG_HIDEN,
  DIALOG_SUCCESS
} from "../../constants/alertTypes";
import { IModalAlert } from "../actions/messageModalActions";

const messageModalReducer = (
  state = { show: false, text: "", dialogType: null },
  action: IModalAlert
) => {
  switch (action.type) {
    case DIALOG_ALERT:
      return {
        show: true,
        dialogType: DIALOG_ALERT,
        text: action.text,
        buttonText: action.buttonText,
        subtext: action.subtext,
        img: action.img,
        id: action.id,
        slug: action.slug
      };
    case DIALOG_SUCCESS:
      return {
        show: true,
        dialogType: DIALOG_SUCCESS,
        text: action.text,
        buttonText: action.buttonText,
        subtext: action.subtext,
        img: action.img,
        id: action.id,
        slug: action.slug
      };
    case DIALOG_ERROR:
      return {
        show: true,
        dialogType: DIALOG_ERROR,
        text: action.text,
        buttonText: action.buttonText,
        subtext: action.subtext,
        img: action.img,
        id: action.id,
        slug: action.slug
      };
    case DIALOG_HIDEN:
      return {
        ...state,
        dialogType: null,
        show: false,
      };
    default:
      return state;
  }
};

export default messageModalReducer;
