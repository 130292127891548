import { ICompareInfo } from "../actions/compareActions";
import { createChannelState } from "../utils/createChannelState";

export interface ICompareInfoAction {
  type: "SET_COMPARE" | "CLEAR_COMPARE" | "REMOVE_COMPARE",
  compare: ICompareInfo[],
  channel?: string; // Added by middleware
}

// Dynamically generate the initial state for all channels
const initialState = createChannelState<ICompareInfo[] | null>(null);

const compareInfoReducer = (state = initialState, action: ICompareInfoAction) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }

  switch (action.type) {
    case "SET_COMPARE":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.compare, // Update only the specified channel's state
      };

    case "REMOVE_COMPARE":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.compare, // Update only the specified channel's state
      };

    case "CLEAR_COMPARE":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state;
  }
}

export default compareInfoReducer; 
