import { useContext, useEffect, useRef, useState } from "react";
import { t } from "i18next";
import { ReactComponent as CoinStack } from "assets/icons/coin-stack.svg";
import { ReactComponent as BankBuilding } from "assets/icons/bank-building.svg";
import { ReactComponent as Money } from "assets/icons/money.svg";
import { ReactComponent as CreditCart } from "assets/icons/credit-cart.svg";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { useLocation, useParams } from "react-router";
import { Card, Placeholder } from "react-bootstrap";
import { iObserver } from "global/services/RRObserver";
import {
  IProductPageContext,
  ProductPageContext,
  initialProductPageContext,
} from "../services";
import { IProductPage } from "../types/ProductPage";
import { tabType } from "../types/TabTypes";
import { useMediaQuery } from "react-responsive";
import {
  breakpointDF,
  breakpointLF,
  breakpointMD,
} from "global/hooks/constants";
import { DeliveryMethods } from "../components/product-bar/directive/DeliveryMethods";
import CityContext from "global/hooks/city/CityContext";
import ErrorPage from "modules/errorPage/ErrorPage";
import MainContent from "../components/main-container/MainContent";
import AccoridionMethods from "../components/product-bar/directive/AccoridionMethods";
import ProductTabs from "../components/product-tabs/ProductTabs";
import ProductBar from "../components/product-bar/ProductBar";
import styles from "../styles.module.scss";
import deliveryDate from "global/scripts/deliveryDate";
import { useSearchParams } from "react-router-dom";
import { setCurrentLanguage } from "global/hooks/language/CurrentLanguage";
import { useTranslation } from "react-i18next";
import { ICities } from "global/types/Cities";

export default function ProductPage() {
  const ref = useRef<HTMLDivElement>(null);
  const tabRef = useRef<HTMLDivElement>(null);
  const { city, setCity } = useContext(CityContext);
  const [searchParams] = useSearchParams();
  const cityFromUrl = searchParams.get("setcity");
  const langFromUrl = searchParams.get("locale");
  const { i18n } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    setCurrentLanguage({ lang: langFromUrl || "ru" })
    i18n.changeLanguage(langFromUrl || "ru");
  }, [langFromUrl]);

  useEffect(() => {
    setCity(cityFromUrl || "1");
  }, [cityFromUrl]);

  let { id, slug } = useParams();
  const product = useContext(
    ProductPageContext
  ) as Required<IProductPageContext>;

  const [activeScroll, setActiveScroll] = useState(false);
  const [placeholderData, setPlaceholderData] = useState<
    { data: IProductPage } | undefined
  >();
  const [activeScrollBar, setActiveScrollBar] = useState(false);
  const [activeTab, setActiveTab] = useState<tabType>("description");
  const [isHidden, setIsHidden] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsHidden(
        !(
          !data?.data?.richContent &&
          !data?.data?.text &&
          !data?.data?.html &&
          !sessionStorage.getItem("TTLStream")
        )
      );
    }, 4000);
  }, [product.name]);

  useEffect(() => {
    setActiveTab(isHidden ? "description" : "specifications");
  }, [isHidden]);

  //City_id
  const cities = useQueryBuilder<{ data: ICities[] }>({
    url: `cities`,
    cacheTime: 10000,
    staleTime: 10000,
  });

  const getCitySlugFromUrl = () => {
    const pathParts = location.pathname.split("/");
    return pathParts[3] || null;
  };

  const getCityIdFromSlug = (slug: string | null) => {
    if (!slug || !cities?.data?.data) return null;
    const city = cities.data && cities.data?.data.find((c: ICities) => c.slug === slug);
    return city ? city.id : null;
  };

  const citySlug = getCitySlugFromUrl();
  const cityId = getCityIdFromSlug(citySlug) || city;

  //SECTION - query
  const { data, isLoading, isError, isPlaceholderData } = useQueryBuilder<{
    data: IProductPage;
  }>({
    url: `products/${id?.substring(1)}/${slug}?city_id=${cityId}`,
    cacheTime: 10000,
    staleTime: 10000,
    placeholderData: placeholderData,
    customLanguage: langFromUrl || 'ru'
  });

  function Scrolling() {
    // Событие скролла для product head
    if ((ref.current?.getClientRects()[0].bottom ?? 0) < 20) {
      setActiveScroll(true);
    }
    if ((ref.current?.getClientRects()[0].bottom ?? 0) > 20) {
      setActiveScroll(false);
    }

    // Событие скролла для product bar картинки с описанием
    if ((tabRef.current?.getClientRects()[0].top ?? 0) < 100) {
      setActiveScrollBar(true);
    }
    if ((tabRef.current?.getClientRects()[0].top ?? 0) > 100) {
      setActiveScrollBar(false);
    }
  }

  const isMobile = useMediaQuery(breakpointMD);
  const isTablet = useMediaQuery(breakpointDF);
  const isNoteBook = useMediaQuery(breakpointLF);

  useEffect(() => {
    !isTablet && document.addEventListener("scroll", Scrolling);

    const rr = document.querySelectorAll(".rr-product-page");
    if (rr) {
      rr.forEach((item) => {
        iObserver.observe(item);
      });
    }

    return () => {
      document.removeEventListener("scroll", Scrolling);
    };
  }, []);

  useEffect(() => {
    if (data?.data) {
      setPlaceholderData(data);
    }
  }, [data]);

  const productPageContext = {
    ...(data?.data ? data.data : initialProductPageContext),
    isLoad: isLoading,
  };

  // Якорь к табу отзывов
  function resetReview(activeTab: tabType) {
    setActiveTab(activeTab);

    if (tabRef !== null) {
      window.scrollTo({
        top: (tabRef.current?.scrollWidth || 0) + (isMobile ? 1000 : -150),
      });
    }
  }

  if (isError || (!data?.data && !isLoading)) {
    return <ErrorPage />;
  }

  return (
    <>
      <ProductPageContext.Provider value={productPageContext}>
        <div
          className={styles.content + ` ${styles.content__webview}`}
          ref={ref}
        >
          <div className={styles.rightContent}>
            <MainContent
              isLoading={isPlaceholderData}
              resetReview={() => resetReview("reviews")}
              is_preorder={data?.data?.is_preorder}
              data={data?.data}
              showAllSpecification={() => {
                setActiveTab("specifications");

                if (tabRef !== null) {
                  window.scrollTo({
                    top: (tabRef.current?.scrollWidth || 0) - 150,
                  });
                }
              }}
              webview={true}
            />
            {!isTablet && (
              <>
                {isLoading ? (
                  <>
                    <Placeholder
                      as={Card.Title}
                      animation="wave"
                      className={styles.placeholder_tabs}
                    >
                      <Placeholder xs={12} bg="secondary" />
                    </Placeholder>
                  </>
                ) : (
                  <div className={styles.tabContent} ref={tabRef}>
                    <ProductTabs
                      activeTab={activeTab}
                      setActiveTab={setActiveTab}
                      webview
                    />
                  </div>
                )}
              </>
            )}
          </div>

          {isTablet && (
            <>
              {!data?.data.digital ? (
                <DeliveryMethods
                  deliveryDate={data?.data.deliveryDate || '-'}
                  pickupDate={data?.data.pickUpDate || '-'}
                  resetReview={() => resetReview("availability")}
                  webview
                />
              ) : (
                <></>
              )}
              <AccoridionMethods
                eventKey="1"
                head={
                  <>
                    <CoinStack />
                    {t("Global.Payment methods")}
                  </>
                }
                children={
                  <>
                    <div className={styles.item}>
                      <BankBuilding />
                      {t("Global.Credit")}
                    </div>
                    <div className={styles.item}>
                      <Money />
                      {t("Global.Cash")}
                    </div>
                    <div className={styles.item}>
                      <CreditCart />
                      {t("Global.Cashless")}
                    </div>
                  </>
                }
              />

              <div
                className={styles.tabContent + ` ${styles.tabContent__web}`}
                ref={tabRef}
              >
                <div className={styles.mobileTabs}>
                  <ProductTabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    webview
                  />
                </div>
              </div>
            </>
          )}
          {!isNoteBook && (
            <div className={styles.leftContent}>
              <ProductBar
                vendor_code={data?.data?.vendor_code || ""}
                cost={data?.data?.cost || 0}
                old_cost={data?.data?.old_cost || undefined}
                isActive={activeScrollBar}
                isLoad={isLoading}
                resetReview={() => resetReview("availability")}
                disabled={
                  !data?.data?.availableForPurchase &&
                  !data?.data?.availableForPurchaseFromDc
                }
                bank_coefficient={data?.data?.bank_coefficient || ""}
                bonus={data?.data?.bonus}
                cachback_max_percent={0}
                is_preorder={data?.data?.is_preorder}
                data={data?.data}
                webview
              />
            </div>
          )}
        </div>
      </ProductPageContext.Provider>
    </>
  );
}
