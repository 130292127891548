import LowerFooter from "./components/lower-footer/LowerFooter";
import MainFooter from "./components/main-footer/MainFooter";
import { useMediaQuery } from 'react-responsive';
import { breakpointDF } from "global/hooks/constants";
import MainFooterMobile from "./components/main-footer/MainFooterMobile";
import LowerFooterMobile from "./components/lower-footer/LowerFooterMobile";
import Snowflakes from "global/hooks/decoration/snowflakes";

export default function AppFooter() {
  const showSnow = process.env.REACT_APP_SNOW || false;

  if (useMediaQuery(breakpointDF)) {
    return (
      <>
        <MainFooterMobile />
        <LowerFooterMobile />
        {!showSnow ? <Snowflakes /> : null}
      </>
    )
  }

  return (
    <>
      <MainFooter />
      <LowerFooter />
      {!showSnow ? <Snowflakes />: null}
    </>
  )
}
