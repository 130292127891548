import CityContext from "global/hooks/city/CityContext";
import { useAppSelector } from "global/hooks/reduxHooks";
import React, { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from 'react-router-dom'

declare global {
  interface Window {
    r46: (
      action?: string,
      code?: any,
      params?: any, // Adjust the type based on actual usage
      success?: (response: any) => void, // Specify a more precise type if known
      error?: (error: any) => void // Specify a more precise type if known
    ) => void;
  }
}


export default function Rees46() {
  const { city } = useContext(CityContext);
  const userToken = useAppSelector(state => state.userToken);
  const location = useLocation();

  useEffect(() => {
    const checkR46 = setInterval(() => {
      if (typeof window.r46 !== 'undefined') {
        clearInterval(checkR46);

        if(userToken?.token) {
          window.r46("profile", "set", {
            id: userToken?.id.toString(), 
            email: userToken?.email, 
            birthday: (userToken?.birthday!=='' && userToken?.birthday!==undefined) ? userToken?.birthday : '1970-01-01', 
            gender: (userToken?.gender!=='' && userToken?.gender!==undefined) ? userToken?.gender : 'm', 
            location: city 
          });
        } else {
          window.r46("profile", "set", { location: city });
        }
      }
    }, 100);
    return () => clearInterval(checkR46); // Cleanup on component unmount
  }, [userToken]);

  return (
    <Helmet>     
      {/* <!--REES46--> */}
      <script>{`
      (function (r) {
          window.r46 =
            window.r46 ||
            function () {
              (r46.q = r46.q || []).push(arguments);
            };
          var s = document.getElementsByTagName(r)[0],
            rs = document.createElement(r);
          rs.async = 1;
          rs.src = "//cdn.rees46.ru/v3.js";
          s.parentNode.insertBefore(rs, s);
        })("script");
        r46("init", "429dfc65243999b6741ea77310173e");
      `}</script>
      {/*<!--END REES46--*/}
      
    </Helmet>
  );
}
