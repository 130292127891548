import qr from "assets/footer/evrika-smart-qr-color.png"
import { ReactComponent as MarketIcons } from 'assets/footer/markets.svg';
import styles from "./styles.module.scss";
import { t } from "i18next";

export default function DownloadAppCard({ className }: { className?: string }) {
  return (
    <>
      <div className={styles.title}>Evrika Smart <MarketIcons className={className} /></div>
      <div className={styles.qrWrap + ' br-8'}>
        <img src={qr} alt="qr" />
      </div>
      <div className={styles.downloadAppTxt}>{t('Footer.Scan to download moile application')}</div>
    </>
  )
}
