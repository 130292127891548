import { IProductInfo, setProductInfo } from "global/redux/actions/productInfoActions";
import { IProductInfoAction } from "global/redux/reducers/productInfo";
import { t } from "i18next";
import { Dispatch } from "react";
import { toast } from "react-toastify";

export function addToCart(
  item: any,
  productInfo: IProductInfo[] | null,
  dispatch: Dispatch<IProductInfoAction>,
  cashback?: number,
) {
  const result: Partial<IProductInfo> = {
    ...item,
    quantity: 1,
    cashback: cashback,
    bonus: Math.floor(item.cost / 100 * (cashback || 0))
  };
  Array.isArray(productInfo) ?
    dispatch(setProductInfo([...productInfo, result as IProductInfo]) as IProductInfoAction) :
    dispatch(setProductInfo([result as IProductInfo]) as IProductInfoAction);

  window.r46("track", "cart", item.id?.toString());

  toast.success(t("Notification.The product has been added to the cart"));
}
