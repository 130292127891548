import LanguageContext from "global/hooks/language/LanguageContext";
import { useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";

export default function AlternateTag() {
  const appUrl = process.env.REACT_APP_URL || "https://evrika.com/";
  const location = useLocation();
  const lang = useContext(LanguageContext);
  let pathname = location.pathname.substring(1);

  if(lang === 'kk') {
    pathname = pathname.substring(3)
  }
  const searchParams = location.search;
  
  return (
    <Helmet>
      <link href={appUrl + pathname + searchParams} rel="alternate" hrefLang="ru"></link>
      <link href={appUrl + "kk/" + pathname + searchParams} rel="alternate" hrefLang="kk"></link>
    </Helmet>
  );
}
