import { IUserData } from "modules/cart/types/sendData"

export interface IAuthInfo {
  phone?: string,
  email?: string,
  password?: string,
  checked?: boolean
}

export const setAuthInfo = (data: IAuthInfo) => {
  return {
    type: "SET_AUTH",
    user: data
  }
}

export const clearAuthInfo = () => ({
  type: "CLEAR_AUTH",
})
