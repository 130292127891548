import { ICompareInfoAction } from "../reducers/compareInfo"

export interface ICompareInfo {
  category_id: number,
  category_name: string,
  product_ids: number[],
}

type setCompareFun = (compare: ICompareInfo, compareInfo?: ICompareInfo[]) => ICompareInfoAction

export const setCompare: setCompareFun = (compare, compareInfo) => {
  if (compareInfo === undefined) {
    return {
      type: "SET_COMPARE",
      compare: [compare]
    }
  }

  if (compareInfo.some(item => item.category_id === compare.category_id)) {
    return {
      type: "SET_COMPARE",
      compare: compareInfo.map(item =>
      ({
        ...item,
        product_ids: (item.category_id === compare.category_id)
          ? [...item.product_ids, ...compare.product_ids]
          : item.product_ids
      })
      )
    }
  }

  return {
    type: "SET_COMPARE",
    compare: [...compareInfo, compare]
  }
}

export const removeCompare = (compareInfo: ICompareInfo[], category_id: number, id: number) => {  
  return {
    type: "REMOVE_COMPARE",
    compare: compareInfo.map(item => ({
      ...item,
      product_ids: item.category_id === category_id ? item.product_ids.filter(product_id => product_id !== id) : item.product_ids
    })).filter(item => item.product_ids.length > 0)
  }
}

export const clearCompare = () => ({
  type: "CLEAR_COMPARE",
})
