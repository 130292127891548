import { Link } from "react-router-dom";
import CategoriesMenuLink from "./directive/CategoriesMenuLink";
import styles from "./styles.module.scss";
import { createPortal } from "react-dom";
import { useContext, useEffect, useState } from "react";
import { ROUTE_CATALOG } from "router/routes";
import { Placeholder } from "react-bootstrap";
import getImageUrl from "global/scripts/getImageUrl";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { ICategoriesMenu } from "global/types/CategoriesMenu";
import { getMenuTree } from "global/scripts/getMenuTree";
import { t } from "i18next";
import { ICities } from "global/types/Cities";
import CityContext from "global/hooks/city/CityContext";

export default function CategoriesMenu({
  isHovered = false,
}: {
  isHovered?: boolean;
}) {
  const [active, setActive] = useState(isHovered);
  const [hidden, setHidden] = useState(false);
  const { citySlug } = useContext(CityContext);

  const { status, data, isFetching } = useQueryBuilder<{
    data: ICategoriesMenu[];
  }>({ url: "categories/menutree" });
  let menuTree: ICategoriesMenu[] | null = null;
  if (status === "success") menuTree = getMenuTree(data?.data);

  return (
    <>
      <div
        className={styles.container + ` ${hidden && styles.hidden}`}
        onClick={() => setHidden(true)}
        onMouseOver={() => setActive(true)}
        onMouseLeave={() => {
          setActive(false);
          setHidden(false);
        }}
      >
        <div className={styles.linkContent}>
          {!isFetching &&
          status === "success" &&
          Array.isArray(menuTree) &&
          menuTree !== null ? (
            menuTree.map((e, i) => (
              <CategoriesMenuLink
                key={i}
                categoryIcon={getImageUrl(
                  "categories/menu/",
                  e.menu_icon,
                  "medium/"
                )}
                categoryName={e.name}
                categoryHref={`catalog/${
                  citySlug == "" ? "" : citySlug + "/"
                }${e.slug ? e.slug : ""}/c${e.id}`}
                categoryTitle={e.name}
                categoryLinks={e.children}
                hidden={hidden}
                setHidden={setHidden}
                selectedCity={citySlug}
              />
            ))
          ) : (
            <Placeholder
              animation="wave"
              className={styles.placeholderContainer}
            >
              {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((e, i) => (
                <Placeholder
                  xs={12}
                  key={i}
                  bg="secondary"
                  className={styles.placeholder}
                />
              ))}
            </Placeholder>
          )}
        </div>
        <div className={styles.allCategoriesContainer}>
          <Link to={ROUTE_CATALOG + citySlug} className={styles.allCategories}>
            {t("CategoriesMenu.All categories")}
          </Link>
        </div>
      </div>
      {active &&
        createPortal(
          <div className={styles.background} />,
          document.querySelector("div.App") as HTMLElement
        )}
    </>
  );
}
