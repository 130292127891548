import { IBrokerInfo } from "../actions/brokerInfoActions";
import { createChannelState } from "../utils/createChannelState";

export interface IBrokerInfoAction {
  type: "SET_BROKER" | "CLEAR_BROKER",
  broker: IBrokerInfo,
  channel?: string; // Added by middleware
}

// Dynamically generate the initial state for all channels
const initialState = createChannelState<IBrokerInfo | null>(null);

const brokerInfoReducer = (state = initialState, action: IBrokerInfoAction) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }
  
  switch (action.type) {
    case "SET_BROKER":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.broker, // Update only the specified channel's state
      };

    case "CLEAR_BROKER":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state;
  }
}

export default brokerInfoReducer; 
