import { useContext } from 'react';
import { useMediaQuery } from "react-responsive";
import styles from "./styles.module.scss";
import { breakpointMD } from "global/hooks/constants";
import { ProductPageContext } from "modules/product-page/services";
import { t } from 'i18next';
import { getYoutubeSrc } from 'global/scripts/getYoutubeSrc';
import Youtube from 'assets/youtube.png';
import Button from 'global/components/button/Button';
import { Link } from 'react-router-dom';

export function VideoTab() {
  const isModile = useMediaQuery(breakpointMD);
  const product = useContext(ProductPageContext);

  return <div className={styles.video}>
    {!isModile && (
      <span className={styles.video_title}>{t("ProductPage.Video review of the", {
        video: product.name
      })}</span>
    )}
    {product.videos?.map((item, i) => (
      <div
        key={i}
        className={styles.video__container}
      >
        <iframe
          src={getYoutubeSrc(item)}
          allowFullScreen
        >
        </iframe>
      </div>
    ))}
    <div className={styles.video__channel}>
      <div>
        <img src={Youtube} alt="" />
        <span>{t("ProductPage.Watch more reviews on our YouTube channel")}</span>
      </div>
      <Link
        to={'https://www.youtube.com/channel/UCWagl_Sz3tU3IshjsXnhtlQ?sub_confirmation=1'}
        className={"button " + styles.orangeBtn}
      >
        {t("ProductPage.Subscribe to the channel")}
      </Link>
    </div>
  </div>
}
