import { useContext, useState } from "react";
import { ReactComponent as MainIcon } from "assets/mobile-navbar/main.svg";
import { ReactComponent as PhoneIcon } from "assets/mobile-navbar/phone.svg";
import { ReactComponent as CatalogIcon } from "assets/mobile-navbar/catalog.svg";
import { ReactComponent as CartIcon } from "assets/mobile-navbar/cart.svg";
import { ReactComponent as CabinetIcon } from "assets/mobile-navbar/cabinet.svg";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import { ROUTE_CABINET, ROUTE_HOME } from "router/routes";
import getUrl from "global/hooks/getUrl";
import i18next from "i18next";
import { OverlayTrigger, Popover } from "react-bootstrap";
import Trigger from "modules/header/components/upper-header/Trigger";
import CartPopup from "modules/cart/components/cart-popup/CartPopup";
import { t } from "i18next";
import { useAppSelector } from "global/hooks/reduxHooks";
import { ModalBox } from "global/components/modal/ModalBox";
import { ICategoriesMenu } from "global/types/CategoriesMenu";
import { getMenuTree } from "global/scripts/getMenuTree";
import { CatalogModal } from "./components/catalog-modal/CatalogModal";
import { AuthWindow } from "modules/auth/AuthWindow";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { ICities } from "global/types/Cities";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export default function MobileNavbar() {
  const { channel } = useContext(ChannelContext)
  const { data } = useQueryBuilder<{ data: ICategoriesMenu[] }>({ url: "categories/menutree" });
  let menuTree: ICategoriesMenu[] | null = null
  menuTree = getMenuTree(data?.data || [])

  const [isOpenCatalog, setIsOpenCatalog] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [show, setShow] = useState(false);
  const lang = i18next.language;
  const userToken = useAppSelector(state => state.userToken);
  const productInfo = useAppSelector(state => state.productInfo[channel]);
  function login() {
    setShowLoginModal(!showLoginModal);
  }

  function scroll() {
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.navbarContainer}>
      <Link to={getUrl({ lang: lang, currentUrl: ROUTE_HOME, channelToSet:channel })} className={styles.wrapper} onClick={() => { scroll(); setIsOpenCatalog(false); setShowLoginModal(false) }}>
        <MainIcon className={styles.icon } />
        <div className={styles.title}>{t("NavBar.Main")}</div>
      </Link>
      <OverlayTrigger
        trigger="click"
        key="top"
        placement="top"
        rootClose={true}
        overlay={
          <Popover
            id={`popover-positioned-"top"`}
            style={{ maxWidth: "220px" }}
          >
            <Popover.Body>
              <Trigger />
            </Popover.Body>
          </Popover>
        }
      >
        <div className={styles.wrapper + ` ${styles.contactWrapper}`} onClick={() => setShowLoginModal(false)}>
          <PhoneIcon className={styles.icon } />
          <div className={styles.title}>{t("NavBar.Contact")}</div>
        </div>
      </OverlayTrigger>
      <div className={styles.wrapper} onClick={() => { setIsOpenCatalog(!isOpenCatalog); setShowLoginModal(false); }}>
        <CatalogIcon className={styles.icon + ` ${isOpenCatalog && styles.active}` } />
        <div className={styles.title}>{t("NavBar.Catalog")}</div>
      </div>
      <ModalBox
        backgroundClassName={styles.modal}
        isOpen={isOpenCatalog}
        onClose={() => setIsOpenCatalog(false)}
      >
        <CatalogModal
          menuTree={menuTree}
          setIsOpen={setIsOpenCatalog}
        />
      </ModalBox>
      <OverlayTrigger
        trigger={"click"}
        placement="top"
        rootClose={true}
        show={show}
        onToggle={() => setShow(!show)}
        overlay={
          <Popover
            id={`popover-cart`}
            style={{ maxWidth: "320px" }}
            className={styles.overlayWrapper}
          >
            <Popover.Body>
              <CartPopup setShow={setShow} />
            </Popover.Body>
          </Popover>
        }
      >
        <div className={styles.wrapper + ` ${styles.triggerWrapper}`} onClick={() => { setShow(true); setShowLoginModal(false); }}>
          <div className={styles.imageWrapper}>
            <CartIcon className={styles.icon } />
            {
              productInfo && productInfo?.length > 0 &&
              <div className={styles.cartCount}>{productInfo?.length}</div>
            }
          </div>
          <div className={styles.title}>{t("NavBar.Basket")}</div>
        </div>
      </OverlayTrigger>
      {userToken?.token
        ? <Link to={ROUTE_CABINET} className={styles.wrapper} rel="nofollow">
          <CabinetIcon className={styles.icon } />
          <div className={styles.title}>{t("NavBar.Profile")}</div>
        </Link>
        : <button className={styles.wrapper} onClick={login}>
          <CabinetIcon className={styles.icon + ` ${showLoginModal && styles.active}` } />
          <div className={styles.title}>{t("NavBar.Profile")}</div>
        </button>
      }
      <ModalBox
        backgroundClassName={styles.modal + ` ${styles.modal__auth}`}
        isOpen={showLoginModal}
        onClose={e => setShowLoginModal(e)}
      >
        <AuthWindow
          onClose={setShowLoginModal}
        />
      </ModalBox>
    </div>
  )
}
