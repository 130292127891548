import { t } from 'i18next';
import { useState, useEffect } from 'react';
import styles from "./styles.module.scss";

interface ITimer {
  targetDate: number,
}

export default function Timer({ targetDate }: ITimer) {
  const [remainingTime, setRemainingTime] = useState(getRemainingTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime(getRemainingTime());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  function getRemainingTime() {
    const now = new Date().getTime();
    const target = targetDate;
    const distance = target - now;

    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      minutes,
      seconds,
    };
  }



  return (
    <div className={styles.timerText}>{t("BrokerOtp.Send SMS again")} {remainingTime.minutes < 10 ? '0' + remainingTime.minutes : remainingTime.minutes} : {remainingTime.seconds < 10 ? '0' + remainingTime.seconds : remainingTime.seconds} {t("BrokerOtp.sec")}.</div>
  )
}
