import { IGiftCardsForCheckout } from "../actions/giftCardsAction";

export interface IGiftCardsAction {
  type: "SET_GIFT_CARDS" | "CLEAR_GIFT_CARDS",
  card: IGiftCardsForCheckout[]
}

const giftCardsReducer = (state: IGiftCardsForCheckout[] | null = null, action: IGiftCardsAction) => {
  switch (action.type) {
    case "SET_GIFT_CARDS":
      return action.card;

    case "CLEAR_GIFT_CARDS":
      return null;

    default:
      return state;
  }
}

export default giftCardsReducer; 
