import { IPaymentInfo } from "../actions/paymentInfoActions";
import { createChannelState } from "../utils/createChannelState";

export interface IPaymentInfoAction {
  type: "SET_PAYMENT" | "CLEAR_PAYMENT",
  payment: IPaymentInfo,
  channel?: string; // Added by middleware
}
// Dynamically generate the initial state for all channels
const initialState = createChannelState<IPaymentInfo | null>(null);

const paymentInfoReducer = (state = initialState, action: IPaymentInfoAction) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }

  switch (action.type) {
    case "SET_PAYMENT":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.payment, // Update only the specified channel's state
      };

    case "CLEAR_PAYMENT":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state;
  }
}

export default paymentInfoReducer; 
