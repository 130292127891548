import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import getUrl from "global/hooks/getUrl";
import { getCurrentLanguage } from "global/hooks/language/CurrentLanguage";
import { clearGiftCards } from "global/redux/actions/giftCardsAction";
import { showAlert } from "global/redux/actions/messageModalActions";
import { clearUserInfo } from "global/redux/actions/userInfoActions";
import { clearUserToken } from "global/redux/actions/userTokenActions";
import { IGiftCardsAction } from "global/redux/reducers/giftCards";
import { IUserInfoAction } from "global/redux/reducers/userInfo";
import { IUserTokenAction } from "global/redux/reducers/userToken";
import { t } from "i18next";
import { Dispatch } from "redux";
import { ROUTE_CART } from "router/routes";

export default function logoutUserData(dispatch: Dispatch<any>, token: any, pathname: any, navigate: any, channel: CHANNELS) {
  const lang = getCurrentLanguage()
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (token) {
    dispatch(clearUserToken() as IUserTokenAction);
    dispatch(clearUserInfo() as IUserInfoAction);
    dispatch(clearGiftCards() as IGiftCardsAction);
    dispatch(showAlert(t("Modal.The session is outdated!"), t("Cart.Log in"), t("Modal.Your session is outdated, Log in again!")));

    if (pathname.includes("cabinet")) {
      navigate(getUrl({currentUrl: "/", channelToSet:channel, lang}));
    } else if (pathname.includes("checkout")) {
      navigate(getUrl({currentUrl: `/${ROUTE_CART}`, channelToSet:channel, lang}));
    }
    return;
  }

  return;
}
