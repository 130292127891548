import { CHANNELS } from "./ChannelTypes";
const LOCAL_STORAGE_ITEM_NAME = 'app-channel';

export function setCurrentChannel(channel: CHANNELS) {
  if (channel!==undefined) {
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, channel);
  }
  else{
    localStorage.setItem(LOCAL_STORAGE_ITEM_NAME, CHANNELS.WEB);
  }
}

export function getCurrentChannel() {
  return localStorage.getItem(LOCAL_STORAGE_ITEM_NAME) || CHANNELS.WEB;
}


