import axios, { AxiosError, AxiosResponse } from "axios";
import { getCurrentChannel } from "global/hooks/channel/ChannelStore";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { useEffect } from "react";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
const TOKEN = process.env.REACT_APP_TOKEN_SECRET;
const BONUS_BACKEND_URL = process.env.REACT_APP_BONUS_BACKEND_URL;
// const BONUS_TOKEN = process.env.REACT_APP_BONUS_TOKEN_SECRET;

interface DataFetching<T = any> {
  url: string;
  version?: string;
  data?: object;
  dataJSON?: object;
  token?: string;
  isUserPage?: boolean;
  customUrl?: boolean;
  onSuccess: (e: AxiosResponse<T>) => void;
  onError: (err: AxiosError) => void;
  channel: string;
}

let config: { [key: string]: any } = {};

export async function getData({
  url,
  version = "v1",
  dataJSON,
  onSuccess,
  onError,
  token,
  isUserPage,
  customUrl = false,
  channel = "web"
}: DataFetching) {  
  config = {
    headers: {},
    method: "GET",
  };
  if (!url) return;
  if (dataJSON && !customUrl) {
    config.params = dataJSON;
    config.headers.Authorization = `Bearer ${isUserPage ? token : TOKEN}`;
  } else if (dataJSON && customUrl) {
    config.data = dataJSON;
    delete config.headers;
    config.mode = "no-cors";
    config.crossdomain = true;
  } else {
    config.headers.Authorization = `Bearer ${isUserPage ? token : TOKEN}`;
  }
  url = appendChannel(url, channel)
  let axiosUrl = customUrl ? url : BACKEND_URL + "api/" + version + "/" + url;

  await axios
    .get(axiosUrl, config)
    .then((result) => {
      onSuccess(result.data);
    })
    .catch((err: AxiosError) => {
      console.error("[AXIOS]", err.message);
      onError(err);
    });
}
export async function updateData<T = any>({
  url,
  version = "v1",
  data,
  onSuccess,
  onError,
  channel = "web"
}: DataFetching<T>) {
  config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
    method: "PUT",
    data: data,
  };
  config.data.channel = channel;
  await axios(BACKEND_URL + "api/" + version + "/" + url, config)
    .then((result) => {
      onSuccess(result);
    })
    .catch((err: AxiosError) => {
      onError(err);
    });
}

export async function postData<T = any>({
  url,
  version = "v1",
  data,
  dataJSON,
  onSuccess,
  onError,
  token,
  isUserPage,
  customUrl,
  channel = "web"
}: DataFetching<T>) {
  config = {
    headers: {
      Authorization: `Bearer ${isUserPage ? token : TOKEN}`,
    },
    method: "POST",
    data: data ? data : {},
  };
  if (customUrl && dataJSON) {
    config.data = dataJSON;
    delete config.headers;
    config.mode = "no-cors";
    config.crossdomain = true;
  }
  console.log("🚀 ~ config.data:", config.data)
  config.data.channel = channel;
  // url = appendChannel(url, channel)
  let axiosUrl = customUrl ? url : BACKEND_URL + "api/" + version + "/" + url;

  await axios(axiosUrl, config)
    .then((res) => {
      if (customUrl && dataJSON) {
        onSuccess(res?.data);
      } else {
        // if (
        //   url === "checkout/send" &&
        //   Boolean(res.data.success) &&
        //   !res.data.success
        // ) {
        //   onError(res?.data);

        // } else {
        //   onSuccess(res);
        // }
        onSuccess(res);
      }
    })
    .catch((err: AxiosError) => {
      onError(err);
    });
}

export async function deleteData({
  url,
  version = "v1",
  onSuccess,
  onError,
  channel = "web"
}: DataFetching) {
  config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
    method: "DELETE",
  };
  url = appendChannel(url, channel)
  await axios(BACKEND_URL + "api/" + version + "/" + url, config)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err: AxiosError) => {
      console.error("[AXIOS]", err.message);
      onError(err);
    });
}

export async function getBonus({
  url = "bonuses/bonustype/search-max",
  version = "v1",
  data,
  onSuccess,
  onError,
  channel = "web"
}: DataFetching) {
  config = {
    headers: {
      Authorization: `Bearer ${TOKEN}`,
    },
    method: "POST",
    data: data,
  };
  url = appendChannel(url, channel)
  await axios(BACKEND_URL + "api/" + version + "/" + url, config)
    .then((res) => {
      onSuccess(res);
    })
    .catch((err: AxiosError) => {
      onError(err);
    });
}

function appendChannel(url:string, channel:string) {
  if (url.includes('?')) {
    return `${url}&channel=${channel}`;
  } else {
    return `${url}?channel=${channel}`;
  }
}
