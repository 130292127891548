import { ISmartGiftInfo } from "../actions/smartGiftActions";

export interface ISmartGiftInfoAction {
  type: "SET_GIFT" | "CLEAR_GIFT",
  card: ISmartGiftInfo
}

const productInfoReducer = (state: ISmartGiftInfo | null = null, action: ISmartGiftInfoAction) => {
  switch (action.type) {
    case "SET_GIFT":
      return action.card;

    case "CLEAR_GIFT":
      return null;

    default:
      return state;
  }
}

export default productInfoReducer; 
