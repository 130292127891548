import React from "react";
import { Helmet } from "react-helmet";

export default function Favicons() {
  const url = process.env.REACT_APP_URL
  return (
    <Helmet>
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={ url + "favicons/apple-icon-57x57.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={ url + "favicons/apple-icon-60x60.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={ url + "favicons/apple-icon-72x72.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={ url + "favicons/apple-icon-76x76.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={ url + "favicons/apple-icon-114x114.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={ url + "favicons/apple-icon-120x120.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={ url + "favicons/apple-icon-144x144.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={ url + "favicons/apple-icon-152x152.png"}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={ url + "favicons/apple-icon-180x180.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="192x192"
        href={ url + "favicons/android-icon-192x192.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={ url + "favicons/favicon-32x32.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href={ url + "favicons/favicon-96x96.png"}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={ url + "favicons/favicon-16x16.png"}
      />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content={ url + "favicons/ms-icon-144x144.png"}
      />
    </Helmet>
  );
}
