export const breakpointS = { query: '(max-width: 345px)' };
export const breakpointXXS = { query: '(max-width: 375px)' };
export const breakpointXS = { query: '(max-width: 480px)' };
export const breakpointSM = { query: '(max-width: 640px)' };
export const breakpointMD = { query: '(max-width: 768px)' };
export const breakpointDF = { query: '(max-width: 1080px)' };
export const breakpointLF = { query: '(max-width: 1150px)' };
export const breakpointLG = { query: '(max-width: 1280px)' };
export const breakpointXL = { query: '(max-width: 1366px)' };
export const breakpointXXL = { query: '(max-width: 1440px)' };
export const breakpoint3XL = { query: '(max-width: 1660px)' };
