import { useContext } from "react"
import { t } from "i18next";
import { IPreorderValidate } from "../types/IPreorderValidate";
import { useAppSelector } from "global/hooks/reduxHooks";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export function usePreorderValidate() {
  const { channel } = useContext(ChannelContext)
  const paymentInfo = useAppSelector(state => state.paymentInfo[channel]);

  const errors = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    ipn: '',
  };

  return (values: IPreorderValidate) => {
    if (!values.firstName) {
      errors.firstName = t("Validation.The Name field is required");
    } else if (values.firstName.replace(/[а-яё]/gi, '')) {
      errors.firstName = "Поле Имя должно содержать только кириллицу.";
    }

    if (!values.lastName) {
      errors.lastName = t("Validation.The Last name field is required");
    } else if (values.lastName.replace(/[а-яё]/gi, '')) {
      errors.lastName = "Поле Имя должно содержать только кириллицу.";
    }

    if (!values.email) {
      errors.email = t("Validation.The E-mail field is required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = t("Validation.Invalid email address");
    }

    if (!values.phone) {
      errors.phone = t("Validation.The Telephone field is required");
    } else if (values.phone.replace(/\D/ig, '').length !== 11) {
      errors.phone = t("Validation.The phone number is entered incorrectly") + ': +7 XXX XXX XX XX';
    }
    if (paymentInfo?.id === 3) {
      if (!values.ipn) {
        errors.ipn = t("Validation.The IIN field is required");
      } else if (values.ipn.replace(/\D/ig, '').length !== 12) {
        errors.ipn = 'Количество символов в поле ИИН не может быть более 12.';
      }
    }

    return errors;
  }
};
