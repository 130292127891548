import { useState } from "react";
import SocialWidgetsSlider from "./directive/SocialWidgetsSlider";
import SocialWidgetActive from "./directive/SocialWidgetActive";
import styles from './styles.module.scss';
import { Link } from "react-router-dom";
import { ReactComponent as WhatsappIconSvg } from 'assets/icons/whatsapp-icon.svg';
import { t } from "i18next";

export default function SocialWidget() {
  const [loop, setLoop] = useState(true);
  function click() {
    setLoop(!loop)
  }

  return (
    // <div className={styles.container}>
    //   <SocialWidgetActive isOpen={loop} />
    //   <SocialWidgetsSlider toggle={loop} onClick={click} />
    // </div>
    <div className={styles.container + ` ${styles.vertical} ${styles.mount}`}>
      <div className={styles.svg + ` ${styles.icon} ${styles.whatsapp}`} >
        <Link to="https://api.whatsapp.com/send?phone=77710170177" title="Write to WhatsApp" data-tooltip={t("SocialWidget.Write to WhatsApp")} target="_blank">
          <WhatsappIconSvg className={styles.waIcon} />
        </Link>
      </div>
    </div >
  )
}
