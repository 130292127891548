import { IFavoritesInfoAction } from "../reducers/favoritesInfo"

export interface IFavoritesInfo {
  id: number,
  img: string,
  slug: string
}

export const setFavoritesInfo = (favorites: IFavoritesInfo[]): IFavoritesInfoAction => {
  if(favorites.length > 0) {
    window.r46("track", "wish", favorites[favorites.length-1].id.toString());
  }
  return {
    type: "SET_FAVORITES",
    favorites: favorites
  }
}

export const removeFavoritesInfo = (favorites: IFavoritesInfo[], id: number): IFavoritesInfoAction => {
  window.r46("track", "remove_wish", id.toString());
  return {
    type: "REMOVE_FAVORITES",
    favorites: favorites.filter(item => item.id !== id)
  }
}

export const clearFavoritesInfo = (): Pick<IFavoritesInfoAction, "type"> => ({
  type: "CLEAR_FAVORITES",
})
