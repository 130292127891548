import AccordionFAQ, { IAccordionFAQ } from './directive/AccordionFAQ';
import AccordionProductPage, { IAccordionProps } from './directive/AccordionProductPage';
import AccordionFooter, { IAccordionFooter } from './directive/AccordionFooter';
import AccordionFilter, { IAccordionFilter } from './directive/AccordionFilter';
import AccordionCompare, { IAccordionCompare } from './directive/AccordionCompare';
import AccordionQuestion, { IAccordionQuestion } from './directive/AccordionQuestion';
import AccordionOrder, { IAccordionOrder } from './directive/AccordionOrder';
import AccordionAdaptive, { IAccordionAdaptive } from './directive/AccordionAdaptive';

type ACTION_TYPE = {
    type: "faq"
  } & IAccordionFAQ
  | {
    type: "product-page",
  } & IAccordionProps
  | {
    type: "footer",
  } & IAccordionFooter
  | {
    type: "filter",
  } & IAccordionFilter
  | {
    type: "compare",
  } & IAccordionCompare
  | {
    type: "question",
  } & IAccordionQuestion
  | {
    type: "order",
  } & IAccordionOrder
  | {
    type: "adaptive",
  } & IAccordionAdaptive

export default function AccordionCustom(props: ACTION_TYPE) {
  switch (props.type) {
    case "faq":
      return <AccordionFAQ {...props} />

    case "product-page":
      return <AccordionProductPage {...props} />

    case "footer":
      return <AccordionFooter {...props} />

    case "filter":
      return <AccordionFilter {...props} />

    case "compare":
      return <AccordionCompare {...props} />

    case "question":
      return <AccordionQuestion {...props} />

    case "order":
      return <AccordionOrder {...props} />

    case "adaptive":
      return <AccordionAdaptive {...props} />

    default:
      return <></>
  }
}
