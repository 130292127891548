import { Link } from "react-router-dom";

interface ISocialIcons {
  href: string;
  children: React.ReactNode;
}

export default function SocialIcon({href, children}: ISocialIcons) {
  return (
    <div>
      <Link to={href} target="_blank" rel="noopener noreferrer nofollow">
        {children}
      </Link>
    </div>
  )
}
