import { ReactComponent as FileIcon } from "assets/product-page/documents/file-icon.svg";
import { ReactComponent as DownloadIcon } from "assets/product-page/documents/download.svg";
import styles from "./styles.module.scss";
import { t } from "i18next";
import { IDocument } from "modules/product-page/types/ProductPage";
import { Link } from "react-router-dom";

interface IProductDocuments {
  data: IDocument[]
}

export default function ProductDocuments({ data }: IProductDocuments) {
  return (
    <div className={styles.document}>
      {
        data.map((item, index) => (
          <div key={index} className={styles.document__wrapper}>
            <div className={styles.document__icon}>
              <FileIcon />
            </div>
            <div>
              <div className={styles.document__title}>{item.name}</div>
              <a
                href={item.path}
                target="_blank"
                download
                className={styles.document__download}
              >
                <span>{t("ProductPage.Download")}</span>
                <DownloadIcon />
              </a>
            </div>
          </div>
        ))
      }
    </div>
  )
}
