import { Container } from "react-bootstrap";
import { ReactComponent as FooterIcon } from "assets/icons/footer-icon.svg";
import { ReactComponent as MastercardIcon } from "assets/footer/mastercard.svg";
import { ReactComponent as VisaIcon } from "assets/footer/visa.svg";
import { ReactComponent as AmericanExpressIcon } from "assets/footer/americanexpress.svg";
import styles from "./styles.module.scss";
import { t } from "i18next";
import { ROUTE_PRIVACY_POLICY, ROUTE_SITEMAP } from "router/routes";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useContext } from "react";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { ReactComponent as SamsungLogo } from "assets/logos/samsung_sis_white.svg";
import { ReactComponent as SamsungLogoKK } from "assets/logos/samsung_sis_white_kk.svg";

export default function LowerFooter() {
  const lang = i18next.language;
  const slugLang = lang === "kk" ? "/kk" : "";
  const { channel } = useContext(ChannelContext);

  let Logo;
  switch (channel) {
    case CHANNELS.SAMSUNG:
      Logo = lang==="kk"?<SamsungLogoKK />:<SamsungLogo />;
      break;

    case CHANNELS.WEB:
    default:
      Logo = <FooterIcon />;
      break;
  }

  return (
    <div className="footer-bottom">
      <Container className={styles.container}>
        {Logo}
        <div className={styles.nav}>
          <div className={styles.iconWrapper}>
            <MastercardIcon />
            <VisaIcon />
            <AmericanExpressIcon />
          </div>
          <div className={styles.navWrapper}>
            <span className={styles.text}>
              <Link
                className={styles.link + ` ${styles.hover}`}
                to={ROUTE_PRIVACY_POLICY}
              >
                {t("Footer.Privacy policy")}
              </Link>
            </span>
            <span className={styles.text}>
              <Link
                className={styles.link + ` ${styles.hover}`}
                to={ROUTE_SITEMAP}
              >
                {t("Footer.Site map")}
              </Link>
            </span>
          </div>
          <span className={styles.link}>
            {t("Footer.Copyright © 2013 - 2024 Evrika Company LLP", {
              year: new Date().getFullYear()
            })}
          </span>
        </div>
      </Container>
    </div>
  );
}
