import { memo, useEffect, useState } from "react"
import { Helmet, HelmetProvider } from "react-helmet-async";
import { YMaps, Map, FullscreenControl, ZoomControl, Placemark } from '@pbe/react-yandex-maps';
interface IYandexMap {
  containerSize: {
    width: string,
    height: string,
  },
  zoom: number,
  // Хинт показывается при наведении мышкой на иконку метки.
  hintContents: string[],
  // Балун откроется при клике по метке.
  balloonTitles: string[],
  balloonBodies: string[],
  balloonFooters: string[],
  // Бренчи
  coordinates?: any,
}

type MapControlsType = 'zoomControl' // Ползунок масштаба
  | 'rulerControl' // Линейка
  | 'routeButtonControl' // Панель маршрутизации
  | 'trafficControl' // Пробки
  | 'typeSelector' // Переключатель слоев карты
  | 'fullscreenControl'; // Полноэкранный режим

export default function YandexMap({ containerSize, zoom, balloonTitles, balloonBodies, balloonFooters, hintContents, coordinates }: IYandexMap) {

  return <>
    <YMaps>
        <Map
          state={{
            center: coordinates[0],
            zoom: zoom,
            controls: [],
          }}
          style={containerSize}
          modules={ [ 'geoObject.addon.balloon', 'geoObject.addon.hint' ] }
        >
          <FullscreenControl />
          <ZoomControl />
          {coordinates.map((marker: [number, number], index: number) => (
            <Placemark 
              key={index} 
              geometry={marker} 
              properties={{
                balloonContentHeader: balloonTitles[index],
                balloonContentBody: balloonBodies[index],
                balloonContentFooter: balloonFooters[index],
                hintContent: hintContents[index],
              }}
            />
          ))}
        </Map>
    </YMaps>
  </>
}

//export default memo(YandexMap, arePropsEquals);

function arePropsEquals(oldProps: IYandexMap, newProps: IYandexMap) {
  return oldProps.zoom == newProps.zoom
    && oldProps.hintContents == newProps.hintContents
    && oldProps.coordinates == newProps.coordinates
}
