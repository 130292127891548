import { IProductBranch } from 'modules/product-page/types/ProductBranch';
import styles from './styles.module.scss';
import { IAvailabilityBranch } from 'modules/product-page/types/Availability';
import { ReactComponent as MapSvg } from 'assets/icons/map.svg';
import { useState, useContext } from 'react';
import { breakpointMD } from 'global/hooks/constants';
import { useMediaQuery } from 'react-responsive';
import LanguageContext from 'global/hooks/language/LanguageContext';
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import { t } from 'i18next';
import YandexMap from 'global/components/yandex-map/YandexMap';
import { Link } from 'react-router-dom';

interface ITableItem {
  branches: IProductBranch,
  availability: IAvailabilityBranch
}

export function TableItem({ branches, availability }: ITableItem) {
  const product = useContext(ProductPageContext) as Required<IProductPageContext>;
  const [showMap, setShowMap] = useState(false);
  const isModile = useMediaQuery(breakpointMD);
  const lang = useContext(LanguageContext);
  const address = branches.name || false;

  function mapClick() {
    setShowMap(!showMap)
  }

  return <>
    <div className={styles.table_String}>
      <div className={styles.table_Column}>
        <span className={styles.table_TopTitle}>{branches.name}</span>
        {/* <span className={styles.table_BotTitle}>{branches}</span> */}
        {isModile
          ? availability === undefined
            || product.stocks && Object.values(product.stocks).find(item => item.branch === branches.guid)?.quantity === (0 || undefined)
            ? <span className={styles.available}>-</span>
            : <span className={styles.available}>{t("ProductPage.Available count",
              { count: product.stocks && Object.values(product.stocks).find(item => item.branch === branches.guid)?.quantity }
            )}</span>
          : <></>
        }
      </div>
      <div className={styles.table_Column}>
        {branches.phones && branches.phones.map((phone, key) => (
          <Link
            key={key}
            to={`tel:${phone.replace(/\s/g, '')}`}
            className={styles.table_Phone}
          >
            {phone}
          </Link>
        ))}
      </div>
      {!isModile ?
        <>
          <div className={styles.table_Column}>
            {branches.is_pvz === 1 ? 'Пункт выдачи заказов' : 'Магазин'}
          </div>
          <div className={styles.table_Column}>
            {/* <span className={styles.table_TopTitle}>{branches.work_time.slice(0, 6)}</span> */}
            <span className={styles.table_BotTitle}>{branches.work_time}</span>
          </div>
          <div className={`${styles.table_Column}`}>
            {availability === undefined
              || product.stocks && Object.values(product.stocks).find(item => item.branch === branches.guid)?.quantity === (0 || undefined)
              ? <span className={styles.noAvailable}>-</span>
              : t("ProductPage.Available count",
                { count: product.stocks && Object.values(product.stocks).find(item => item.branch === branches.guid)?.quantity }
              )
            }
          </div>
          <div className={styles.table_Column}>
            <MapSvg
              className={styles.geographyIcon}
              onClick={mapClick}
            />
          </div>
        </>
        : <>
          <div className={styles.table_Column}>
            <span className={styles.table_WorkTime}>{branches.work_time}</span>
          </div>
          <div className={styles.table_Column}>
            <div className={styles.table_ColumnPlace}>
              <div className={styles.table_ColumnMap}>
                <MapSvg
                  className={styles.geographyIcon}
                  onClick={mapClick}
                />
                <span className={styles.table_WorkTime}>
                  {branches.is_pvz === 1 ? 'Пункт выдачи заказов' : 'Магазин'}
                </span>
              </div>
              {address ?
                <>
                  |
                  <span className={styles.table_ColumnAddress}>
                    {address}
                  </span>
                </>
                : <></>}
            </div>
          </div>
        </>
      }
    </div>

    <div className={styles.mapContainer + ` ${showMap ? styles.active : ''}`}>
      <YandexMap
        balloonTitles={[branches?.name]}
        balloonBodies={[branches?.name]}
        balloonFooters={[branches?.work_time]}
        hintContents={[branches?.name]}
        containerSize={{
          width: "100%",
          height: "500px"
        }}
        coordinates={[[branches?.map?.marker?.lat || 0, branches?.map?.marker?.lng || 0]]}
        zoom={15}
      />
    </div>
  </>
}
