import { memo, useRef, useEffect, useContext, useState } from 'react';
import styles from './styles.module.scss';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import i18next from 'i18next';

const helmetContext = {};

function DescriptionTab() {
  const richWrapperContentRef = useRef<HTMLDivElement>(null);
  const product = useContext(ProductPageContext) as Required<IProductPageContext>;
  const lang = i18next.language;
  const [openMore, setOpenMore] = useState(false);

  useEffect(() => {
    const excludedBrands = ['Asus', 'Sony'];
    const TTLStreamItem = sessionStorage.getItem('TTLStream');

    if (TTLStreamItem && richWrapperContentRef.current && !excludedBrands.includes(product.brand?.name || '')) {
      sessionStorage.removeItem('TTLStream');
    }

    const loadTTLStream = async () => {
      if (!TTLStreamItem && !excludedBrands.includes(product.brand?.name || '')) {
        try {
          await (window as any).TTLStreamReady;
          const richContentEl = document.querySelector('.richWrapperContent') as HTMLElement;
          if (richContentEl) {
            await (window as any).ttlStream.findAndInsert({
              brand: product.brand?.name,
              productId: product.vendor_code,
              retailerDomain: "evrika.com",
              templateType: "master_template",
              resultType: "html",
              contentType: "minisite",
              el: ".richWrapperContent",
              language: lang === 'ru' ? 'kz_ru' : 'kz_kz',
            });
            sessionStorage.setItem('TTLStream', richContentEl.innerHTML);
          } else {
            console.warn("Rich content element not found.");
          }
        } catch (error) {
          console.error("TTLStream error:", error);
        }
      }
    };

    loadTTLStream();
  }, [product, lang]);

  function replaceImagePath(txt: string | undefined): string | undefined {
    return txt?.replaceAll('//evrika.com/storage', '//cdn.evrika.com/storage')
      .replaceAll('//evrika.com/brands', '//cdn.evrika.com/brands')
      .replaceAll('//evrika.com/promotion', '//cdn.evrika.com/promotion');
  }

  return (
    <>
      {product && (
        <div className={`${styles.container} ${openMore ? styles.more : styles.descr}`}>
          <div
            id="flix-inpage"
            className='richWrapperContent'
            ref={richWrapperContentRef}
            dangerouslySetInnerHTML={{ __html: replaceImagePath(product.html || "") || replaceImagePath(product.text || "") || '' }}
          />

          <HelmetProvider context={helmetContext}>
            <Helmet>
              {product.brand?.name === 'Asus' || product.brand?.name === 'Sony' ? (
                <script
                  type="text/javascript"
                  src="//media.flixfacts.com/js/loader.js"
                  data-flix-distributor="13716"
                  data-flix-language="kk"
                  data-flix-brand={product.brand?.name}
                  data-flix-mpn={product.mpn}
                  data-flix-ean={product.ean}
                  data-flix-inpage="flix-inpage"
                  data-flix-button-image=""
                  data-flix-fallback-language="ru"
                  data-flix-price=""
                  async
                />
              ) : (
                <script>
                  {`(function(w,d,s,o){var f=d.getElementsByTagName(s)[0];var j=d.createElement(s);
                  w.TTLStreamReady = new Promise((resolve) => {
                    j.async = true;j.src= 'https://content.24ttl.stream/widget.js';f.parentNode.insertBefore(j,f);
                    j.onload = function () {
                      w.ttlStream = new TTLStream(o); resolve(w.ttlStream);
                    };
                  });
                  })(window,document,'script',{ });`}
                </script>
              )}
            </Helmet>
          </HelmetProvider>
        </div>
      )}
      {!openMore && (
        <button onClick={() => setOpenMore(true)} className={styles.more_btn}>
          <span>Читать дальше</span>
        </button>
      )}
    </>
  );
}

export default memo(DescriptionTab);
