import { useState, useEffect, useContext } from "react";
import "App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import seoRedirects from "assets/seo_redirects.json";
import { useLocation } from "react-router-dom";
import { GetRouter } from "router/MainRouter";
import { SeoRedirect } from "global/types/SeoRedirect";
import LoadingScreen from "modules/loading/LoadingScreen";
import { SeoRedirectsData } from "global/types/SeoRedirectsData";
import LanguageContext from "global/hooks/language/LanguageContext";
import {
  getCurrentCity,
  getCurrentCitySlug,
  setCurrentCity,
  setCurrentCitySlug,
} from "global/hooks/city/CurrentCity";
import {
  getCurrentLanguage,
  setCurrentLanguage,
} from "global/hooks/language/CurrentLanguage";
import CityContext from "global/hooks/city/CityContext";
import { withConfirmationModals } from "global/hoc/withConfirmationModals";
import { withMessageModals } from "global/hoc/withMessageModals";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Base from "global/metrics/Base";
import Favicons from "global/metrics/Favicons";
import Analytics from "global/metrics/Analytics";
import DomainVerification from "global/metrics/DomainVerification";
import Rees46 from "global/metrics/Rees46";
import {
  SeoDataContextProvider,
} from "global/hooks/seo/SeoDataContext";
import { getData } from "global/scripts/dataFetching";
import { useDispatch, useSelector } from "react-redux";
import {
  clearUtmParam,
  IUTMParam,
  setUtmParam,
} from "global/redux/actions/utmParamActions";
import { RootState } from "global/redux";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

function useNormalizePath() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const normalizedPath = location.pathname.replace(/\/{2,}/g, "/");
    if (location.pathname !== normalizedPath) {
      navigate(normalizedPath, { replace: true });
    }
  }, [location.pathname]);
}

function App() {
  useNormalizePath();
  const { i18n } = useTranslation();
  const { channel } = useContext(ChannelContext);
  const [lang, setLang] = useState(getCurrentLanguage());
  const [city, setCity] = useState(getCurrentCity() || "1");
  const [citySlug, setCitySlug] = useState(getCurrentCitySlug() || "");
  const location = useLocation();
  const [isRedirectChecked, setIsRedirectChecked] = useState(false);
  const redirects: SeoRedirect[] = (seoRedirects as SeoRedirectsData)
    .seo_redirects;
  const getRedirectPath = async (currentPath: string) => {
    let redirect = null;

    if (process.env.REACT_APP_REDIRECT_TYPE === "file") {
      redirect = redirects.find(
        (r) => r.link_from === currentPath && r.published === 1
      );
      if (redirect) {
        redirect = redirect.link_to;
      }
    } else if (process.env.REACT_APP_REDIRECT_TYPE === "api") {
      await getData({
        url: `seo/redirect?uri=${currentPath}`,
        onSuccess: (e) => {
          const data = e.data;
          redirect = data["link_to "] || null;
        },
        onError: (e) => {},
        isUserPage: false,
        channel
      });
    }

    return redirect;
  };

  const handleRedirect = async () => {
    const redirectUrl = await getRedirectPath(location.pathname);
    if (redirectUrl) {
      navigate(redirectUrl as unknown as string, { replace: true });
    } else {
      setIsRedirectChecked(true); // Only set to true if no redirect is needed
    }
  };

  let languageFromUrl = "ru";
  let navigate = useNavigate();
  const node_env_param = process.env.NODE_ENV || "production";

  // Check for url params and change the current language accordingly
  if (location.pathname.includes("/kk/")|| (location.pathname[location.pathname.length]!=='/') && location.pathname.includes("/kk")) {
    languageFromUrl = "kk";
  }

  useEffect(() => {
    const initialize = async () => {
      await handleRedirect();
    };
    initialize();

    if (citySlug !== getCurrentCitySlug()) {
      setCurrentCitySlug(citySlug);
    }

    if (city !== getCurrentCity()) {
      setCurrentCity(city);
    }
    
    if (languageFromUrl !== lang && !location.pathname.includes("/webview/")) {
      setLang(languageFromUrl);
    }
    setCurrentLanguage({ lang: lang || "ru" });
    i18n.changeLanguage(lang || "ru");

  }, [lang, languageFromUrl, i18n, city,citySlug, navigate, location]);

  /*==== Save UTM params to redux ====*/
  const dispatch = useDispatch();
  const utm = useSelector((state: RootState) => state.utmParam);
  useEffect(() => {
    if (utm && new Date(utm.expiration) < new Date()) {
      dispatch(clearUtmParam());
    }
    const urlParams = new URLSearchParams(location.search);
    const dateNow = Date.now() + 3 * 60 * 60 * 1000;
    const utmParams = {
      utm_source: urlParams.get("utm_source"),
      utm_medium: urlParams.get("utm_medium"),
      utm_campaign: urlParams.get("utm_campaign"),
      utm_term: urlParams.get("utm_term"),
      utm_content: urlParams.get("utm_content"),
      expiration: dateNow,
    };

    if (utmParams.utm_source) {
      // Сохраняем UTM-метки в Redux
      dispatch(setUtmParam(utmParams as IUTMParam));
    }
  }, [dispatch]);

  if (!isRedirectChecked) {
    return <LoadingScreen />;
  }

  return ( 
      <SeoDataContextProvider>
        <CityContext.Provider value={{ city, setCity, citySlug, setCitySlug }}>
          <LanguageContext.Provider value={lang}>
            <div className="App">
              {node_env_param === "production" ? (
                <>
                  <DomainVerification />
                  <Analytics />
                </>
              ) : (
                <></>
              )}
              <Base />
              <Favicons />
              <Rees46 />
              <GetRouter />
            </div>
          </LanguageContext.Provider>
        </CityContext.Provider>
      </SeoDataContextProvider>
  );
}

export default withConfirmationModals(withMessageModals(App));
