import { useState, useContext } from "react";
import Rating from "global/components/rating/Rating";
import styles from "./feedback.module.scss";
import { ReactComponent as Close } from "assets/icons/close.svg";
import InputForm from "global/components/input/InputForm";
import { useFormik } from "formik";
import { t } from "i18next";
import Button from "global/components/button/Button";
import { useFeedbackReviewValidate } from "modules/product-page/hooks/useFeedbackReviewValidate";
import { useMutation } from "@tanstack/react-query";
import { postData } from "global/scripts/dataFetching";
import { IProductPageContext, ProductPageContext } from "modules/product-page/services";
import { useAppSelector } from "global/hooks/reduxHooks";
import { ROUTE_CONTENT_PAGES } from "router/routes";
import { Spinner } from "react-bootstrap";
import getUrl from "global/hooks/getUrl";
import i18next from "i18next";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

interface IFeedbackReview {
  closeModal: (isOpen: false) => void,
  isAnswer?: boolean,
  name?: string
}

interface IFeedbackReviewPost {
  rating: number,
  name: string,
  email: string,
  text: string
}

export function FeedbackReview({ closeModal, isAnswer, name }: IFeedbackReview) {
  const lang = i18next.language;
  const product = useContext(ProductPageContext) as Required<IProductPageContext>;
  const userToken = useAppSelector(state => state.userToken);
  const { channel } = useContext(ChannelContext)
  const [star, setStar] = useState(5);
  const validate = useFeedbackReviewValidate();

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: IFeedbackReviewPost) => postData({
      url: `products/reviews/${product.id}`,
      data: data,
      onSuccess(e) {
        toast.success(t("Review.The form has been successfully submitted!"));
        closeModal(false);
      },
      onError(e) {
        console.error('error: ' + e.message);
      },
      channel
    })
  });

  const formik = useFormik({
    initialValues: {
      name: userToken ? userToken.firstName ?? '' : '',
      email: userToken ? userToken.email ?? '' : '',
      text: '',
      isValid: false
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  function submit(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);

    if (formik.errors.isValid) {
      mutate({
        rating: star,
        email: formik.values.email,
        name: formik.values.name,
        text: formik.values.text
      })
    }
  }

  return (
    <>
      <div className={styles.feedback}>
        <div className={styles.feedback__head}>
          <h2 className={styles.feedback__title}>{isAnswer ? `Ответить на отзыв: ${name}` : t("ProductPage.Write a review")}</h2>
          <button
            className={styles.closeBtn}
            onClick={() => closeModal(false)}
          >
            <Close />
          </button>
        </div>
        {
          !isAnswer &&
          <div className={styles.feedback__container}>
            <span className={styles.feedback__grade}>{t("Catalog.Your rating")}</span>
            <Rating
              name="your-grade"
              checked={star}
              onChange={e => setStar(+e.target.value)}
            />
          </div>
        }
        <form
          className={styles.feedback__form}
          onSubmit={submit}
        >
          <div className={styles.feedback__userInfo}>
            <div>
              <InputForm
                type="text"
                label={t("Global.Name") + "*"}
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name.replace(/\d/ig, '')}
                className={`${styles.inputs} ${formik.errors.name && formik.touched.name && styles.errorInput}`}
              />
              {formik.errors.name && formik.touched.name ? <div className={styles.errorText}>{formik.errors.name}</div> : null}
            </div>
            <div>
              <InputForm
                type="email"
                label="Email*"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                className={`${styles.inputs} ${formik.errors.email && formik.touched.email && styles.errorInput}`}
              />
              {formik.errors.email && formik.touched.email ? <div className={styles.errorText}>{formik.errors.email}</div> : null}
            </div>
          </div>
          <div className={styles.textareaWrapper}>
            <textarea
              className={styles.commentTextField + ` ${formik.values.text.replace(/^\s+/gm, '').length > 0 ? styles.active : ''} ${formik.errors.text && formik.touched.text && styles.errorInput}`}
              onChange={formik.handleChange}
              value={formik.values.text}
              name="text"
            />
            {formik.errors.text && formik.touched.text ? <div className={styles.errorText}>{formik.errors.text}</div> : null}
            <label className={styles.label}>{t("Delivery.Comment")}</label>
          </div>
          <span className={styles.feedback__rools}>
            {t("Review.In order for your review or comment to pass moderation and be published, please read the")}
            <a href={getUrl({ lang, currentUrl: `${ROUTE_CONTENT_PAGES}/publichnaya-oferta`, channelToSet:channel })} target="_blank" className={styles.feedback__roolsLink} >&nbsp;{t("Review.rules for commenting")}</a>
          </span>
          <Button
            type="submit"
            className={styles.button}
          >
            {isLoading ?
              <Spinner animation="border" variant="light" size="sm" />
              : <>
                {t('Catalog.Leave a review')}
              </>
            }
          </Button>
        </form>
      </div>
    </>
  )
}
