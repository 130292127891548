import { useCost } from "global/hooks/useCost";
import styles from "../styles.module.scss";
import { ReactComponent as Basket } from 'assets/icons/basket.svg';
import { useGetProcent } from "global/hooks/useGetProcent";
import { Card, Placeholder } from "react-bootstrap";
import { CostType } from "global/types/CostType";
import { ReactComponent as Bonus } from 'assets/icons/bonus-icon.svg';
import { t } from "i18next";
import { useBankCoefficientCount } from "modules/product-page/hooks/useBankCoefficientCount";
import { breakpointMD } from "global/hooks/constants";
import { useMediaQuery } from "react-responsive";

export interface IProductCostPrice extends CostType {
  isLoad?: boolean,
  compare?: boolean,
  className?: string,
  isBonusLoading?: boolean,
  cachback_max_percent?: number,
}

export default function ProductCostPrice({ old_cost = 0, cost, isLoad, bank_coefficient, cachback_max_percent = 0, compare, className, isBonusLoading }: IProductCostPrice) {
  const installmentTerm = process.env.REACT_APP_INSTALLMENT_TERM || '12'
  const percent = useGetProcent(old_cost, cost);
  const oldCost = useCost(old_cost);
  const currentCost = useCost(cost);
  const bankCoefficientCount = useBankCoefficientCount();
  const bankCount = useCost(bankCoefficientCount((bank_coefficient || '0'), parseInt(installmentTerm), (cost || 0)));
  const isMobile = useMediaQuery(breakpointMD);
  const bonus = useCost(Math.floor(cost / 100 * cachback_max_percent));

  return (
    <div className={styles.priceContainer + ` ${isLoad && styles.priceContainerLoad}`}>
      {isLoad ?
        <Placeholder as={Card.Text} animation="wave" className={styles.placeholder}>
          <Placeholder xs={10} bg="secondary" />
          <Placeholder xs={10} bg="secondary" />
        </Placeholder> :
        <>
          {
            !compare &&
            <span>
              {
                isBonusLoading
                  ? <Placeholder as={Card.Text} animation="wave" className={styles.placeholder}>
                    <Placeholder xs={10} bg="secondary" />
                  </Placeholder>
                  : (bonus && cachback_max_percent > 0) && <div className={styles.oldPriceContainer}>
                    <Bonus className={styles.bonusIcon} />
                    <span className={styles.bonuses}> до <strong>{bonus}</strong></span>
                  </div>
              }
              {
                !isMobile &&
                <div className={styles.creditAndInstallmentInfo}>
                  <span
                    className={styles.creditAndInstallmentPrice}
                  >
                    {bankCount} ₸&nbsp;
                  </span>
                  {t("ProductPage.credit count", { month: installmentTerm })}
                </div>
              }
            </span>
          }
        </>
      }
      {
        isLoad
          ? !isMobile &&
          <Placeholder as={Card.Text} animation="wave" className={styles.placeholderWrapper}>
            <Placeholder xs={10} bg="secondary" />
            <Placeholder xs={10} bg="secondary" />
          </Placeholder>
          : <div className={styles.costContainer + ` ${compare && styles.costCompare}`}>
            {
              old_cost > 0 && percent > 5 && old_cost > cost && <>
                <span className={styles.oldPrice}>{oldCost} ₸</span>
              </>
            }
            <span className={styles.currentPrice}>{currentCost} ₸</span>
          </div>
      }
    </div >
  )
}
