import styles from '../styles.module.scss';
import { ReactComponent as Truck } from 'assets/icons/truck.svg';
import { ReactComponent as Question } from 'assets/icons/delivery-question.svg';
import { ReactComponent as GeographyPoint } from 'assets/icons/geography-point.svg';
import { t } from 'i18next';
import { ProductPageContext } from 'modules/product-page/services';
import { useContext, useState } from 'react';
import { Card, OverlayTrigger, Placeholder, Popover } from 'react-bootstrap';
import { ModalBox } from 'global/components/modal/ModalBox';
import Button from 'global/components/button/Button';
import { ReactComponent as Close } from "assets/icons/close.svg";
import { AreaYandexMap } from 'global/components/yandex-map/AreaYandexMap';

interface IDeliveryMethods {
  deliveryDate: string,
  pickupDate: string,
  resetReview: () => void,
  webview?: boolean
}

export function DeliveryMethods({ deliveryDate, pickupDate, resetReview, webview }: IDeliveryMethods) {
  const [openMapModal, setOpenMapModal] = useState(false);
  const product = useContext(ProductPageContext);

  return (
    <div className={styles.deliveryMethods}>
      <div className={styles.deliveryMethod}>
        <Truck />
        <div className={styles.delivery}>
          <div className={styles.deliveryTitle}>
            {t("Global.Delivery")}
            <div className={styles.deliveryTooltips} >
              <OverlayTrigger
                trigger="click"
                key="bottom"
                placement="bottom"
                rootClose={true}
                overlay={
                  <Popover
                    id={`popover-positioned-"bottom"`}
                    style={{ maxWidth: "233px", width: '100%' }}
                  >
                    <Popover.Body className={styles.questionTooltip}>
                      {t("Delivery.Arrange delivery by courier, and we will bring the goods to your home, agreeing on the date and time")}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Question />
              </OverlayTrigger>
            </div>
          </div>

          {
            !webview &&
            <button
              onClick={() => setOpenMapModal(true)}
              className={styles.deliveryBtn}
            >
              {t("ProductPage.Free Shipping Card")}
            </button>
          }
        </div>
        <ModalBox
          isOpen={openMapModal}
          onClose={setOpenMapModal}
        >
          <div className={styles.deliveryMap}>
            <div className={styles.deliveryMap__head}>
              {t("ProductPage.Free Shipping Card")}
              <Button
                className={styles.closeBtn}
                onClick={() => setOpenMapModal(false)}
              >
                <Close />
              </Button>
            </div>
            <div className={styles.deliveryMap__body}>
              <AreaYandexMap
                containerSize={{
                  width: "100%",
                  height: "100%"
                }}
                mainCoords={product.freeShippingCoordinates?.[0] as [number, number] || [0, 0]}
                zoom={10}
                hintContent='asd'
                cityCoordinates={product.freeShippingCoordinates || []}
              />
            </div>
          </div>
        </ModalBox>
        {product.isLoad ?
          <Placeholder as={Card.Text} animation="wave" className={styles.placeholder__delivery}>
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
          : <>
            {deliveryDate}
          </>}
      </div>
      <div className={styles.deliveryMethod}>
        <GeographyPoint />
        <div className={styles.delivery}>
          <div className={styles.deliveryTitle}>
            {t("Global.Pickup")}
            <div className={styles.deliveryTooltips} >
              <OverlayTrigger
                trigger="click"
                key="bottom"
                placement="bottom"
                rootClose={true}
                overlay={
                  <Popover
                    id={`popover-positioned-"bottom"`}
                    style={{ maxWidth: "233px", width: '100%' }}
                  >
                    <Popover.Body className={styles.questionTooltip}>
                      {t("ProductPage.Pickup is an opportunity to receive goods in the store day-to-day, subject to placing an order on the website")}
                    </Popover.Body>
                  </Popover>
                }
              >
                <Question />
              </OverlayTrigger>
            </div>
          </div>

          <button
            onClick={resetReview}
            className={styles.deliveryBtn}
          >
            {t("ProductPage.Check availability")}
          </button>
        </div>
        {product.isLoad ?
          <Placeholder as={Card.Text} animation="wave" className={styles.placeholder__delivery}>
            <Placeholder xs={12} bg="secondary" />
          </Placeholder>
          : <>
            {pickupDate}
          </>}
      </div>
    </div >
  )
}
