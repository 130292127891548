import styles from "./styles.module.scss";

export default function DownloadAppCardMob() {
  return (
    <div className={styles.downloadAppMobile}>
      <a href="https://play.google.com/store/apps/details?id=com.evrika&amp;utm_source=website&amp;utm_campaign=bonuspage&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank" rel="noopener noreferrer nofollow">
        <img src="https://back.evrika.com/images/evrika-smart/ru-download-play-market.png" alt="Get it on Google Play" rel="nofollow" width="200" />
      </a>
      <a href="https://apps.apple.com/kz/app/evrika-smart/id1631810749?utm_source=website&amp;utm_campaign=bonuspage" target="_blank" rel="noopener noreferrer nofollow">
        <img src="https://back.evrika.com/images/evrika-smart/Download_on_the_App_Store_Badge_RU.svg" alt="Download on the AppStore" rel="nofollow" />
      </a>
    </div>
  )
}
