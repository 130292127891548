import { ReactComponent as BoxIcon } from "assets/global/box.svg";
import styles from "./styles.module.scss";
import { t } from "i18next";

interface IShowcaseBadge {
  stock?: number,
  quality?: Array<Record<string, number>> | null,
  className?: string
}

export default function ShowcaseBadge({ quality, stock, className }: IShowcaseBadge) {
  return (
    <>
      {
        stock === 1 && quality && quality !== null && quality?.some(item => item !== null && "Распакован" in item)
          ? <div className={styles.showcase + ` ${className}`}>
            <BoxIcon className={styles.showcase__icon} />
            <span className={styles.showcase__text}>{t("Badge.Showcase")}</span>
          </div>
          : <></>
      }
    </>
  )
}
