import { ICharacteristicValues } from "modules/product-page/types/ProductPage";
import styles from "./styles.module.scss";
import React from "react";

interface ICharacteristic {
  title: string,
  values: ICharacteristicValues[],
}

export default function Characteristic({ title, values }: ICharacteristic) {
  return (
    <>
      <div className={styles.characteristic}>
        <span className={styles.characteristicKey}>{title}</span>
        <div className={styles.characteristicContainer}>
          {values.map((item, i) => (
            <React.Fragment key={'cho_'+i}>
              {i > 0 && <span className={styles.characteristicValue}>,&nbsp;</span>}
              <span key={item.id} className={styles.characteristicValue}>{item.name}</span>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  )
}
