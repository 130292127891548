import { IPaymentData } from "modules/cart/types/sendData"

export interface IPaymentInfo extends IPaymentData {
  cardNumber?: string,
  cardDate?: string,
  cvv?: string,
  selectTab?: "1" | "2" | "3" | "4",
}

export const setPaymentInfo = ({ bank = null, bik = null, bin = null, companyAddress = null, companyName = null, iik = null, ipn = null, repayment_period = 0, selectTab = "1", id = 1 }: IPaymentInfo) => {

  return {
    type: "SET_PAYMENT",
    payment: { bank, bik, bin, companyName, companyAddress, iik, repayment_period, ipn, selectTab, id }
  }
}

export const clearPaymentInfo = () => ({
  type: "CLEAR_PAYMENT",
})
