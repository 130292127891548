import { useLocation } from 'react-router';
import styles from './styles.module.scss';
import { Link } from 'react-router-dom';

interface IMenuFooter {
  href: string;
  title: string;
}

export default function MenuFooter({ href, title }: IMenuFooter) {
  const location = useLocation();
  function addActiveClass(pathname: string) {
    const locationPath = location.pathname;

    return locationPath == pathname;
  }

  return (
    <li className={styles.item}>
      <Link className={styles.link + ` ${addActiveClass(href) && styles.activeItem}`} to={href}>{title}</Link>
    </li>
  )
}
