import AccordionCustom from "global/components/accordionCustom/AccordionCustom";
import MenuFooter from "../menu-footer/MenuFooter";
import SocialIcon from "global/components/icons/SocialIcon";
import { ReactComponent as FacebookIcon } from 'assets/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from 'assets/icons/twitter.svg';
import { ReactComponent as VkIcon } from 'assets/icons/vkontakte.svg';
import { ReactComponent as YoutubeIcon } from "assets/footer/youtube.svg";
import { t } from "i18next";
import FormFooter from "../form-footer/FormFooter";
import { Link } from "react-router-dom";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { IFooterMenu } from "modules/footer/types/FooterMenu";
import styles from "./styles.module.scss";
import DownloadAppCardMob from "global/components/download-app-link/DownloadAppCardMob";

export default function MainFooterMobile() {
  const { data } = useQueryBuilder<{ data: IFooterMenu[] }>({
    url: "menu",
    staleTime: 60 * 60 * 1000,
    cacheTime: 60 * 60 * 1000,
  })

  return (
    <div className={styles.containerMob}>
      {data?.data && data.data.map((item, i) => (
        <AccordionCustom type="footer" title={item.name} eventKey={i.toString()} key={i} >
          <ul className={styles.list}>
            {
              item.data.map((item, i) => (
                <MenuFooter
                  href={item.url}
                  title={item.name}
                  key={i} />
              ))
            }
          </ul>
        </AccordionCustom>
      ))}

      <DownloadAppCardMob />

      <div className={styles.descr}>{t("Footer.Get new promotions and discounts one of the first!")}</div>
      <FormFooter />
      <div className={styles.wrapper}>
        <SocialIcon href="https://www.facebook.com/evrikacom">
          <FacebookIcon className={styles.icon } />
        </SocialIcon>
        <SocialIcon href="https://www.instagram.com/evrika.kazakhstan">
          <InstagramIcon className={styles.icon } />
        </SocialIcon>
        <SocialIcon href="https://twitter.com/Evrikacom">
          <TwitterIcon className={styles.icon } />
        </SocialIcon>
        <SocialIcon href="https://vk.com/evrikacom">
          <VkIcon className={styles.icon } />
        </SocialIcon>
        <SocialIcon href="https://www.youtube.com/channel/UCWagl_Sz3tU3IshjsXnhtlQ?sub_confirmation=1">
          <YoutubeIcon className={styles.icon } />
        </SocialIcon>
      </div>

      <div className={styles.linkWrapper}>
        <Link className={styles.link} to="https://wa.me/77710170177">{t("Footer.Write on WhatsApp")}</Link>
        <Link className={styles.link} to="mailto:support@evrika.com">{t("Footer.Write on email")}</Link>
      </div>
    </div>
  )
}
