import { ReactComponent as Compare } from 'assets/icons/compareOnProduct.svg';
import { ReactComponent as Wish } from 'assets/product-page/wish.svg';
import { ReactComponent as Share } from 'assets/icons/share.svg';
import styles from "./../styles.module.scss";
import { useAppSelector } from 'global/hooks/reduxHooks';
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import { useContext } from 'react';
import { removeCompare, setCompare } from 'global/redux/actions/compareActions';
import { ICompareInfoAction } from 'global/redux/reducers/compareInfo';
import { removeFavoritesInfo } from 'global/redux/actions/favoritesInfoActions';
import { IFavoritesInfoAction } from 'global/redux/reducers/favoritesInfo';
import { showAlert } from 'global/redux/actions/messageModalActions';
import { SocialPopapWidget } from 'global/components/socialWidget/SocialPopapWidget';
import { addFavorites } from 'global/scripts/addFavoriteFn';
import { toast } from 'react-toastify';
import { t } from 'i18next';
import { useDispatch } from 'react-redux';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';

export default function Widget() {
  const product = useContext(ProductPageContext) as IProductPageContext;
  const { channel } = useContext(ChannelContext);
  const category = product.breadcrumbs && product.breadcrumbs[product.breadcrumbs.length - 1];

  // * Redux
  const dispatch = useDispatch();
  const compareInfo = useAppSelector(state => state.compareInfo[channel]);
  const favoritesInfo = useAppSelector(state => state.favoritesInfo[channel]);
  const token = useAppSelector(state => state.userToken)?.token;

  // * Проверки для кнопок
  const isSelected = (Array.isArray(compareInfo) && category) && compareInfo.find(value => value.category_id === category.id)?.product_ids.some(item => item === product.id) || false;
  const isFavorites = favoritesInfo && favoritesInfo.some(item => item.id === product.id) || false;

  // * Добавление в сравнения
  function addCompare(productId?: number) {
    if (productId && category) {
      compareInfo ?
        dispatch(setCompare({ category_id: category.id, category_name: category.name, product_ids: [productId] }, compareInfo) as ICompareInfoAction) :
        dispatch(setCompare({ category_id: category.id, category_name: category.name, product_ids: [productId] }) as ICompareInfoAction)

      toast.success(t("Notification.The product was added for comparison"));
    }
  }

  // * Удаление из сравнений
  function deleteCompare(categoryId: number, id: number) {
    compareInfo && dispatch(removeCompare(compareInfo, categoryId, id) as ICompareInfoAction);
  }

  // * Удаление из избранных
  function deleteFavorite(id?: number) {
    (favoritesInfo && id) && dispatch(removeFavoritesInfo(favoritesInfo, id) as IFavoritesInfoAction);
  }

  return (
    <div className={styles.iconContainer}>
      <Wish
        onClick={() => {
          if (isFavorites) {
            deleteFavorite(product.id)
            return;
          }
          addFavorites(product.id || 0, product.images && product.images[0] || "", product.slug || "", favoritesInfo, dispatch, token || '', showAlert)
        }}
        className={isFavorites ? styles.activeBtn__fav : ''}
      />
      <Compare
        onClick={() => {
          if (isSelected && category && product.id) {
            deleteCompare(category.id, product.id)
            return;
          }
          addCompare(product.id)
        }}
        className={isSelected ? styles.activeBtn : ''}
      />
      <div className={styles.socialPopapWidget}>
        <Share />
        <div className={`${styles.socialPopapWidget__content}`}>
          <SocialPopapWidget type='share'/>
        </div>
      </div>
    </div>
  )
}
