// ChannelProvider.tsx
import React, { createContext, useState, useEffect, ReactNode } from "react";
import { useLocation } from "react-router-dom";
import { setCurrentChannel } from "./ChannelStore";
import { CHANNELS } from "./ChannelTypes";

type Channel =
  | CHANNELS.WEB
  | CHANNELS.SAMSUNG
  | CHANNELS.BOSCH
  | CHANNELS.SONY
  | CHANNELS.UCB; // Добавьте дополнительные каналы по мере необходимости

interface ChannelContextProps {
  channel: Channel;
}

interface ChannelProviderProps {
  children: ReactNode;
}

export const ChannelContext = createContext<ChannelContextProps>({
  channel: CHANNELS.WEB
});

export const ChannelProvider: React.FC<ChannelProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const [channel, setChannelState] = useState<Channel>(CHANNELS.WEB);

  // Функция для определения канала на основе URL
  const determineChannelFromURL = (pathname: string): Channel => {
    if (
      pathname.includes("/samsung/") ||
      pathname === "/samsung" ||
      pathname === "/kk/samsung"
    ) {
      return CHANNELS.SAMSUNG;
    }
    // Добавьте дополнительные условия для других каналов
    return CHANNELS.WEB; // Значение по умолчанию
  };

  // Устанавливаем канал при загрузке и при изменении URL
  useEffect(() => {
    const newChannel = determineChannelFromURL(location.pathname);
    if (newChannel !== channel) {
      setChannelState(newChannel);
    }
  }, [location.pathname]);

  // Обновляем атрибут data-channel
  useEffect(() => {
    document.documentElement.setAttribute("data-channel", channel);
    setCurrentChannel(channel);
  }, [channel]);

  return (
    <ChannelContext.Provider value={{ channel }}>
      {children}
    </ChannelContext.Provider>
  );
};
