import { ICategoriesMenu } from "global/types/CategoriesMenu";

export function getMenuTree(data: ICategoriesMenu[]): ICategoriesMenu[] {
  const map = new Map<number, ICategoriesMenu>();
  const tree: ICategoriesMenu[] = [];

  // Создаем хэш-таблицу и одновременно определяем корневые элементы
  data.forEach((item) => {
    map.set(item.id, { ...item, children: [] });
  });

  // Строим дерево
  data.forEach((item) => {
    const node = map.get(item.id)!;
    if (item.parent_id === null) {
      tree.push(node);
    } else {
      map.get(item.parent_id)?.children.push(node);
    }
  });

  return tree;
}
