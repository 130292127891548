import "./bugsnag";
import React from "react";
import "index.css";
import "global/styles/index.scss";
import App from "App";
import "fonts/Inter/Inter-VariableFont_slnt,wght.ttf";
import "fonts/Inter/static/Inter-Black.ttf";
import "fonts/Inter/static/Inter-Bold.ttf";
import "fonts/Inter/static/Inter-ExtraBold.ttf";
import "fonts/Inter/static/Inter-ExtraLight.ttf";
import "fonts/Inter/static/Inter-Light.ttf";
import "fonts/Inter/static/Inter-Medium.ttf";
import "fonts/Inter/static/Inter-Regular.ttf";
import "fonts/Inter/static/Inter-SemiBold.ttf";
import "fonts/Inter/static/Inter-Thin.ttf";
import reportWebVitals from "reportWebVitals";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import { store } from "global/redux";
import "global/scripts/i18n/i18n";
import { Helmet } from "react-helmet";
import { HelmetProvider } from "react-helmet-async";
import { ErrorBoundary } from "./bugsnag";
import { ChannelProvider } from "global/hooks/channel/ChannelContext";

const node_env_param = process.env.NODE_ENV || "production";

const queryClient = new QueryClient();

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const persistor = persistStore(store);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ErrorBoundary>
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{ persister }}
            >
              <BrowserRouter basename="/">
                <ChannelProvider>
                  <App />
                </ChannelProvider>
              </BrowserRouter>
              {node_env_param === "development" ? (
                <>
                  <ReactQueryDevtools position="bottom-right" />
                  <Helmet>
                    <meta name="robots" content="noindex, nofollow" />
                  </Helmet>
                </>
              ) : null}
            </PersistQueryClientProvider>
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
