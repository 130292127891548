import { useMutation } from "@tanstack/react-query";
import CartSvg from "assets/icons/cart.svg";
import { ReactComponent as CatalogLogo } from "assets/icons/catalog-icon.svg";
import CompareSvg from "assets/icons/compare.svg";
import HeartSvg from "assets/icons/heart.svg";
import { ReactComponent as MainLogo } from "assets/icons/main-logo.svg";
import { ReactComponent as SamsungLogo } from "assets/logos/samsung_sis_white.svg";
import { ReactComponent as SamsungLogoKK } from "assets/logos/samsung_sis_white_kk.svg";
import { ReactComponent as UserSvg } from "assets/icons/user-icon.svg";
import { AxiosResponse } from "axios";
import CategoriesMenu from "global/components/categories-menu/CategoriesMenu";
import BadgeOnIcons from "global/components/icons/BadgeOnIcons";
import CityContext from "global/hooks/city/CityContext";
import getUrl from "global/hooks/getUrl";
import { useAppSelector } from "global/hooks/reduxHooks";
import {
  IProductInfo,
  setProductInfo,
} from "global/redux/actions/productInfoActions";
import { IProductInfoAction } from "global/redux/reducers/productInfo";
import { postData } from "global/scripts/dataFetching";
import i18next, { t } from "i18next";
import { useContext, useRef, useState } from "react";
import { Container, Navbar } from "react-bootstrap";
import {
  Link,
  useNavigate,
} from "react-router-dom";
import {
  ROUTE_CABINET,
  ROUTE_CABINET_FAVORITES,
  ROUTE_CART,
  ROUTE_HOME,
} from "router/routes";
import styles from "./styles.module.scss";
import SearchInput from "../search-input/SearchInput";
import { setAuthInfo } from "global/redux/actions/authInfoActions";
import { IAuthInfoAction } from "global/redux/reducers/authInfo";
import { ModalBox } from "global/components/modal/ModalBox";
import { AuthWindow } from "modules/auth/AuthWindow";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as IconSuccess } from "assets/global/success.svg";
import { ReactComponent as IconWarning } from "assets/modal/warning.svg";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { useDispatch } from "react-redux";

export default function MainHeader() {
  const lang = i18next.language;
  const slugLang = lang === "kk" ? "/kk" : "";
  const { city } = useContext(CityContext);
  const { channel } = useContext(ChannelContext);
  const [activeBtn, setActiveBtn] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [hover, setHover] = useState(false);
  const ref = useRef<HTMLElement>(null);
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const productInfo = useAppSelector((state) => state.productInfo[channel]);
  const compareInfo = useAppSelector((state) => state.compareInfo[channel]);
  const favoritesInfo = useAppSelector((state) => state.favoritesInfo[channel]);
  const authInfo = useAppSelector((state) => state.authInfo);
  const token = useAppSelector((state) => state.userToken);

  const countCompare =
    compareInfo
      ?.map((item) => item.product_ids.length)
      .reduce((acc, number) => acc + number, 0) || "";
  document.addEventListener("scroll", (e) => {
    if (ref.current?.getClientRects()[0].top === 0) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  });

  function login() {
    setShowLoginModal(true);
    dispatch(setAuthInfo({ ...authInfo, checked: false }) as IAuthInfoAction);
  }

  function collectData() {
    return {
      products: productInfo?.map((item) => item.id),
      locale: lang,
      city_id: city,
    };
  }

  const { mutate } = useMutation(
    (): Promise<void> =>
      postData({
        url: "cart/check",
        data: collectData(),
        onSuccess(e: AxiosResponse<{ data: IProductInfo[] }>) {
          const result =
            productInfo?.map((item) => {
              const [productAvailable] = e.data.data.filter(
                (product) => product.id === item.id
              );
              return {
                ...item,
                ...productAvailable,
              };
            }) || [];
          dispatch(setProductInfo(result) as IProductInfoAction);
        },
        onError(e) {
          console.error(e);
        },
        channel
      })
  );

  return (
    <Navbar
      sticky="top"
      className={styles.navbar + ` ${activeBtn && styles.sticky}`}
      ref={ref}
    >
      <Container className={styles.container}>
        <div className={styles.logoContainer}>
          {channel === CHANNELS.SAMSUNG ? (
            <Link to={getUrl({ lang: lang, currentUrl: ROUTE_HOME, channelToSet:CHANNELS.SAMSUNG })}>
              {lang ==="kk"?
              <SamsungLogoKK className={styles.mainIcon} />
              :
              <SamsungLogo className={styles.mainIcon} />
              }
            </Link>
          ) : (
            <Link to={getUrl({ lang: lang, currentUrl: ROUTE_HOME, channelToSet:CHANNELS.WEB })}>
              <MainLogo className={styles.mainIcon} />
            </Link>
          )}
          <div className={styles.catalogContainer}>
            <CatalogLogo
              className={
                styles.catalogBtn +
                " " +
                (activeBtn ? styles.addBtn : styles.removeBtn)
              }
              onMouseOver={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            />
            {activeBtn && (
              <div className={styles.catalogMenu}>
                <CategoriesMenu isHovered={hover} />
              </div>
            )}
          </div>
        </div>
        <SearchInput />
        <div className={styles.wrapperIcon + ` ${styles.logoContainer}`}>
          {activeBtn && (
            <>
              {token?.token ? (
                <UserSvg
                  onClick={() => navigate(getUrl({
                    lang,
                    currentUrl: ROUTE_CABINET,
                    channelToSet: channel
                  }))}
                  className={styles.user__icon}
                />
              ) : (
                <UserSvg onClick={login} className={styles.user__icon} />
              )}
            </>
          )}
          <BadgeOnIcons
            href={
              compareInfo?.[0]
                ? getUrl({
                    lang,
                    currentUrl: 'comparison/'+ compareInfo[0].category_id,
                    channelToSet: channel
                  })
                : getUrl({ lang, currentUrl: `comparison`,
                channelToSet: channel })
            }
            activeBtn={activeBtn}
            title={t("Header.Comparison")}
            icon={CompareSvg}
            count={countCompare}
            type="compare"
          />
          <BadgeOnIcons
            href={getUrl({
              lang,
              currentUrl: ROUTE_CABINET_FAVORITES,
              channelToSet: channel
            })}
            activeBtn={activeBtn}
            title={t("Header.Favourites")}
            icon={HeartSvg}
            count={
              token
                ? favoritesInfo && favoritesInfo?.length > 0
                  ? favoritesInfo?.length
                  : ""
                : ""
            }
            type="favourite"
          />
          <BadgeOnIcons
            type="cart"
            href={getUrl({ lang, currentUrl: `${slugLang}/${ROUTE_CART}`,
            channelToSet: channel })}
            activeBtn={activeBtn}
            title={t("Header.Basket")}
            icon={CartSvg}
            count={
              productInfo && productInfo?.length > 0 ? productInfo?.length : ""
            }
            onClick={() => mutate()}
            className={styles.cartPopup + " cartPopup"}
            mutate={mutate}
          />
        </div>
        <ModalBox isOpen={showLoginModal} onClose={() => null}>
          <AuthWindow onClose={setShowLoginModal} />
        </ModalBox>
      </Container>

      <ToastContainer
        draggable
        autoClose={3000}
        newestOnTop={false}
        toastStyle={activeBtn ? { top: "70px" } : {top: "180px"}}
        icon={({ type }) => {
          if (type === "success") return <IconSuccess />;
          if (type === "error") return <IconWarning />;
          else return "ℹ️";
        }}
        onClick={() => toast.dismiss()}
      />
    </Navbar>
  );
}
