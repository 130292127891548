import { Helmet, HelmetProvider } from "react-helmet-async";

interface AreaYandexMap {
  containerSize: {
    width: string,
    height: string,
  },
  mainCoords: [number, number],
  zoom: number,
  // Хинт показывается при наведении мышкой на иконку метки.
  hintContent: string,
  cityCoordinates: Array<string | number>[],
}

export function AreaYandexMap({ containerSize, mainCoords, zoom, hintContent, cityCoordinates }: AreaYandexMap) {
  const id = Math.round((Math.random() * 1000000) * (Math.random() * 21));

  return (
    <>
      <div id={"map-" + id} style={containerSize}></div>

      <HelmetProvider context={{}}>
        <Helmet>
          <script type="text/javascript" onError={(e) => console.log(e)}>
            {`
              window.initAreaCount = 0;
              function initAreaMap() {
                try{
                  document.querySelector('#map-${id}').innerHTML = '';
                  // Функция ymaps.ready() будет вызвана, когда
                  // загрузятся все компоненты API, а также когда будет готово DOM-дерево.
                  ymaps.ready(init);

                  function init() {
                    var myMap = new ymaps.Map(("map-" + ${id}), {
                      // Координаты центра карты.
                      // Порядок по умолчнию: «широта, долгота».
                      center: '${mainCoords}'.split(','),
                      // Уровень масштабирования. Допустимые значения:
                      // от 0 (весь мир) до 19.
                      zoom: ${zoom}
                    }, {
                        searchControlProvider: 'yandex#search'
                    });

                    // Создаем многоугольник, используя вспомогательный класс Polygon.
                    var myPolygon = new ymaps.Polygon([
                        // Указываем координаты вершин многоугольника.
                        // Координаты вершин внешнего контура.
                        '${cityCoordinates.join('/')}'.split('/').map(item => item.split(',')),
                        // Координаты вершин внутреннего контура.
                        []
                    ], {
                        // Описываем свойства геообъекта.
                        // Содержимое балуна.
                        hintContent: '${hintContent}'
                    }, {
                        // Задаем опции геообъекта.
                        // Цвет заливки.
                        fillColor: '#aba7e988',
                        strokeColor: '#d2564e88',
                        // Ширина обводки.
                        strokeWidth: 2
                    });
                
                    // Добавляем многоугольник на карту.
                    myMap.geoObjects.add(myPolygon);
                  }
                } catch (e) {
                  if(window.initAreaCount > 5) {
                    console.error(e);
                    return;
                  }
                  setTimeout(() => {
                    initAreaMap();
                    window.initAreaCount += 1;
                  }, 1000)
                }
              }
              initAreaMap();
            `}
          </script>
        </Helmet>
      </HelmetProvider>
    </>
  )
}
