import { IProductCart } from "modules/catalog/types/ProductCartType"
import { IProductInfoAction } from "../reducers/productInfo"
import { IProductPage } from "modules/product-page/types/ProductPage"

export interface IProductInfo extends IProductCart {
  availableForPurchase: boolean,
  availableForPurchaseFromDc: boolean,
  pickUpDate: string | null,
  deliveryDate: string | null,
  quantity: number,
  isExpressDelivery?: boolean,
  cashback?: number,
  purchase_price?: number,
}

export const setProductInfo = (product: IProductInfo[]): IProductInfoAction => {
  return {
    type: "SET_PRODUCT",
    product: product
  }
}

export const removeProductInfo = (product: IProductInfo[], id: number): IProductInfoAction => {
  return {
    type: "REMOVE_PRODUCT",
    product: product.filter(item => item.id !== id)
  }
}

export const clearProductInfo = (): Pick<IProductInfoAction, "type"> => ({
  type: "CLEAR_PRODUCT",
})
