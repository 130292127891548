import { IUTMParam } from "../actions/utmParamActions";

export interface IUTMAction {
  type: "SET_UTM" | "CLEAR_UTM",
  utm: IUTMParam
}

const utmParamReducer = (state: IUTMParam | null = null, action: IUTMAction) => {
  switch (action.type) {
    case "SET_UTM":
      return action.utm


    case "CLEAR_UTM":
      return null;

    default:
      return state;
  }
}

export default utmParamReducer; 
