import { useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Mousewheel, Keyboard, FreeMode, Thumbs } from "swiper";
import { ReactComponent as CurveLeftSvg } from 'assets/icons/curve-left.svg';
import { ReactComponent as CurveRightSvg } from 'assets/icons/curve-right.svg';
import { ReactComponent as Close } from "assets/icons/close.svg";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import styles from './styles.module.scss';
import ZoomSlide from "./ZoomSlide";
import { useMediaQuery } from "react-responsive";
import { breakpointMD } from "global/hooks/constants";
import { SliderThumbs } from "./directive/SliderThumbs";
import { ModalBox } from "global/components/modal/ModalBox";
import { ModalSlider } from "./directive/modal-slider/ModalSlider";
import noImage from "assets/200x200-no-image.png";
import noImageSamsung from "assets/200x200-no-image-samsung.webp";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { ChannelContext } from "global/hooks/channel/ChannelContext";

export interface IProductSlider {
  sliders: string[],
  commonAlt?: string
}

export default function ProductSlider({ sliders, commonAlt }: IProductSlider) {
  const { channel } = useContext(ChannelContext);
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperCore | null>(null);
  const [isModal, setIsModal] = useState(false);
  const isModile = useMediaQuery(breakpointMD);
  const [imageIsLoaded, setImageIsLoaded] = useState(true);
  const imageOnErrorHandler = () => {
    setImageIsLoaded(false)
  };

  return !isModile
    ? (
      <div className={styles.sliderWrapper}>
        <Swiper
          className={styles.sliderContainer}
          mousewheel={true}
          keyboard={true}
          thumbs={
            {
              swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              slideThumbActiveClass: styles.thumbsActive
            }
          }
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Thumbs, FreeMode]}
        >
          {sliders.map((slide, i) =>
            <SwiperSlide key={i}>
              {imageIsLoaded
                ? <img src={slide} className={styles.zoomSlide} alt={commonAlt + ' - ' + (i+1)} onError={imageOnErrorHandler} onClick={() => setIsModal(true)} />
                : <img className={styles.image} src={ channel !== CHANNELS.SAMSUNG ? noImage : noImageSamsung } alt={commonAlt + ' - ' + (i+1)} />
              }
              {/* <ZoomSlide
                image={slide}
                alt={commonAlt + ' - ' + (i+1)}
                onClick={() => setIsModal(true)}
                zoomSettings={{
                  zoomWidth: 80,
                  zoomHeight: 100,
                  topPadding: 200
                }}
                className={styles.zoomSlide}
              /> */}
            </SwiperSlide>
          )}
        </Swiper>

        <ModalBox
          isOpen={isModal}
          onClose={() => setIsModal(false)}
        >
          <div className={styles.sliderModal}>
            <div className={styles.sliderModal__head}>
              <button
                className={styles.closeBtn}
                onClick={() => setIsModal(false)}
              >
                <Close />
              </button>
            </div>
            <div>
              <ModalSlider
                sliders={sliders}
                commonAlt={commonAlt}
              />
            </div>
          </div>
        </ModalBox>

        <Swiper
          className={styles.thumbsContainer}
          cssMode={true}
          navigation={{
            enabled: true,
            prevEl: '.prev',
            nextEl: '.next',
            disabledClass: styles.thumbsDisabledBtn,
          }}
          slidesPerView={6}
          freeMode={true}
          watchSlidesProgress={true}
          onSwiper={setThumbsSwiper}
          modules={[FreeMode, Navigation, Thumbs]}
        >
          {sliders.map((slide, i) =>
            <SwiperSlide key={i} className={styles.thumbSlide}>
              <SliderThumbs
                image={slide}
                alt={commonAlt + ' thumbnail'}
              />
            </SwiperSlide>
          )}
          <div className={"navigation prev " + styles.prev}>
            <CurveLeftSvg />
          </div>
          <div className={"navigation next " + styles.next}>
            <CurveRightSvg />
          </div>
        </Swiper>
      </div>
    )
    : (
      <div className={styles.sliderWrapper}>
        <Swiper
          className={styles.sliderContainer}
          pagination={{
            type: 'bullets',
            bulletActiveClass: styles.bullet_Active,
            bulletClass: styles.bullet,
            horizontalClass: styles.bullet_Horisontal
          }}
          navigation={{
            enabled: true,
            prevEl: '.prev',
            nextEl: '.next',
            disabledClass: styles.thumbsDisabledBtn,
          }}
          modules={[Pagination, Navigation]}
        >
          {sliders.map((slide, i) =>
            <SwiperSlide key={i}>
              <ZoomSlide
                image={slide}
                zoomSettings={{
                  zoomWidth: 80,
                  zoomHeight: 100,
                  topPadding: 200
                }}
              />
            </SwiperSlide>
          )}

          <div className={"navigation prev " + styles.prev}>
            <CurveLeftSvg />
          </div>
          <div className={"navigation next " + styles.next}>
            <CurveRightSvg />
          </div>
        </Swiper>
      </div>
    )
}
