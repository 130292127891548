import { Dispatch, SetStateAction } from "react";
import MainHeader from "modules/header/components/main-header/MainHeader";
import UpperHeader from "modules/header/components/upper-header/UpperHeader";
import { breakpointDF } from "global/hooks/constants";
import MobileHeader from "./components/mobile-header/MobileHeader";
import { useMediaQuery } from 'react-responsive';
import LowerHeader from "./components/lower-header/LowerHeader";

interface IHeader {
  setLang: Dispatch<SetStateAction<string>>;
}

export default function AppHeader({ setLang }: IHeader) {
  if (useMediaQuery(breakpointDF)) {
    return (
      <>
        <MobileHeader setLang={setLang} />
      </>
    )
  }

  return (
    <>
      <UpperHeader setLang={setLang} city="Алматы" />
      <MainHeader />
      <LowerHeader />
    </>
  );
}
