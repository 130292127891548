import React, { useContext, useState } from "react";
import { Helmet } from "react-helmet";
import { t } from "i18next";
import LanguageContext from "global/hooks/language/LanguageContext";
import { SeoDataContext } from "global/hooks/seo/SeoDataContext";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import { CHANNELS } from "global/hooks/channel/ChannelTypes";

export default function Base() {
  const lang = useContext(LanguageContext);
  const { channel } = useContext(ChannelContext);
  const context = useContext(SeoDataContext);
  const url = process.env.REACT_APP_URL;

  //ToDO: объеденить контекст и стандартный хелмет + разделить хелмет на каналы
  let faviconUrl;

  switch (channel) {
    case CHANNELS.SAMSUNG:
      faviconUrl = `${url}favicons/Samsung/favicon.ico`;
      break;
    case CHANNELS.WEB:
    default:
      faviconUrl = `${url}favicons/favicon.ico`;
      break;
  }
  // console.log("🚀 ~ Base ~ faviconUrl:", faviconUrl);

  if (!context) {
    return (
      <Helmet>
        <html lang={lang} />
        <link rel="icon" href={faviconUrl} />
        <meta name="description" content={t("Analitycs.description")} />
        <meta name="keywords" content={t("Analitycs.keyword")} />

        <meta property="og:title" content={t("Analitycs.title")} />
        <meta property="og:description" content={t("Analitycs.description")} />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={t("Global.Site title")} />
        <meta property="og:locale" content={lang + "_KZ"} />
        <meta
          property="og:image"
          content="https://evrika.com/favicons/android-icon-192x192.png"
        />

        <meta name="twitter:title" content={t("Analitycs.title")} />
        <meta name="twitter:description" content={t("Analitycs.description")} />
        <meta
          name="twitter:image"
          content="https://evrika.com/favicons/android-icon-192x192.png"
        />

        <title>{t("Analitycs.title")}</title>
      </Helmet>
    );
  }

  const { title, description, keywords } = context;
  return (
    <Helmet>
      <html lang={lang} />
      <link rel="icon" href={faviconUrl} />
      <meta name="description" content={description} />
      {keywords ? (
        <meta name="keywords" content={keywords} />
      ) : (
        <meta name="keywords" content={t("Analitycs.keyword")} />
      )}

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={t("Global.Site title")} />
      <meta property="og:locale" content={lang + "_KZ"} />
      <meta
        property="og:image"
        content="https://evrika.com/favicons/android-icon-192x192.png"
      />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta
        name="twitter:image"
        content="https://evrika.com/favicons/android-icon-192x192.png"
      />

      <title>{title}</title>
    </Helmet>
  );
}
