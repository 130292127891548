import { Modal, Button, } from 'react-bootstrap'
import React from 'react'

interface IConfirmationModal {
  modalText: string;
  show: boolean;
  onConfirm: () => void;
  close: () => void;
}

export default function ConfirmationModal({ modalText, onConfirm, show, close }: IConfirmationModal) {
  return (
    <Modal show={show} onHide={close}>
      <Modal.Header className="txt-bold-4" closeButton>Подтверждение</Modal.Header>
      <Modal.Body>{modalText}</Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={close}>Отмена</Button>
        <Button variant="outline-success" onClick={() => {
          onConfirm()
          close();
        }}>Подтвердить</Button>
      </Modal.Footer>
    </Modal>
  )
}
