import styles from "../styles.module.scss";

export interface IRadioWithImg {
  name: string,
  value: string | number,
  label: string,
  image: string
  disabled?: boolean,
  altImage?: string,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

export default function RadioWithImg({name, image, label, value, disabled, altImage = "Evrika image", onChange}: IRadioWithImg) {
  if(disabled) return (
    <span className={styles.labelDisabled}>
      <span>{label}</span>
      <img src={image} alt={altImage} />
    </span>
  )

  return (
    <label className={styles.label}>
      <input className={styles.hidden} name={name} type="radio" value={value} onChange={onChange}/>
      <span className={styles.container}>
        <span>{label}</span>
        <img src={image} alt={altImage} />
      </span>
    </label>
  )
}
