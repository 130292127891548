import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import intervalPlural from 'i18next-intervalplural-postprocessor';

let ruData = require('locales/ru/translation.json')
let kkData = require('locales/kk/translation.json')

let resources = {
  ru: {
    translation: ruData
  },
  kk: {
    translation: kkData
  },
}

i18n
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    returnEmptyString: false,
    debug: false,
    lng: "ru",
    fallbackLng: "ru",
    supportedLngs: ["ru", "kk"],
    resources,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
