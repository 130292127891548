import SocialIcon from "global/components/icons/SocialIcon";
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter.svg";
import { ReactComponent as VkIcon } from "assets/icons/vkontakte.svg";
import { ReactComponent as WhatsappIcon } from "assets/icons/whatsapp1.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import styles from "./styles.module.scss";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";

interface ISocialPopapwidget {
  type?: string;
}

export function SocialPopapWidget({
  type = "company",
}: ISocialPopapwidget) {

  const location = useLocation();
  const APP_URL = process.env.REACT_APP_URL || "https://evrika.com"
  const url = APP_URL + location.pathname.substring(1);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url ?? ""); // Копируем текст в буфер обмена
      toast.success("Ссылка скопирована");
    } catch (error) {
      console.error("Failed to copy text:", error);
    }
  };

  switch (type) {
    case "share":
      return (
        <>
          <div className={styles.popap}>
            <SocialIcon
              href={`https://www.facebook.com/share.php?u=${ url }`}
            >
              <FacebookIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href={`https://twitter.com/share?url=${ url }`}>
              <TwitterIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href={`https://vk.com/share.php?url=${ url }`}>
              <VkIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href={`https://api.whatsapp.com/send/?text=${ url }`}>
              <WhatsappIcon className={styles.popap__icon}/>
            </SocialIcon>
            <CopyIcon className={styles.popap__icon} onClick={handleCopy} />
          </div>
        </>
      );
    case "company":
      return (
        <>
          <div className={styles.popap}>
            <SocialIcon href="https://www.facebook.com/evrikacom">
              <FacebookIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/evrika.kazakhstan">
              <InstagramIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/Evrikacom">
              <TwitterIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://vk.com/evrikacom">
              <VkIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://api.whatsapp.com/send?phone=77710170177">
              <WhatsappIcon className={styles.popap__icon} />
            </SocialIcon>
          </div>
        </>
      );
    default:
      return (
        <>
          <div className={styles.popap}>
            <SocialIcon href="https://www.facebook.com/evrikacom">
              <FacebookIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://www.instagram.com/evrika.kazakhstan">
              <InstagramIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://twitter.com/Evrikacom">
              <TwitterIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://vk.com/evrikacom">
              <VkIcon className={styles.popap__icon} />
            </SocialIcon>
            <SocialIcon href="https://api.whatsapp.com/send?phone=77710170177">
              <WhatsappIcon className={styles.popap__icon} />
            </SocialIcon>
          </div>
        </>
      );
  }
}
