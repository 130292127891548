import { useState } from 'react';
import styles from '../styles.module.scss';
import { ReactComponent as CurveDown } from "assets/icons/CurveDown.svg";
import { tabType } from 'modules/product-page/types/TabTypes';
import { Accordion } from 'react-bootstrap';

interface IAccoridionMethods {
  head: JSX.Element,
  children: JSX.Element,
  activeTab?: tabType,
  availability?: boolean,
  eventKey: string,
  className?: string
}

export default function AccoridionMethods({ head, children, activeTab, availability, eventKey, className }: IAccoridionMethods) {
  const [active, setActive] = useState(availability);
  const [openMethod, setOpenMethod] = useState(false);

  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item className={styles.accoridionMethods + ` ${className}`} eventKey={eventKey}>
        <Accordion.Header onClick={() => { setActive(!active || !openMethod); setOpenMethod(!openMethod); }}>
          {head}
          <CurveDown className={`${styles.accoridionMethods_Arrow} ${((active && activeTab === "availability") || openMethod) && styles.accoridionMethods_ArrowActive}`} />
        </Accordion.Header>
        <Accordion.Body className={styles.accoridionMethods_Body}>
          {children}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
