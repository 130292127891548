import InputForm from 'global/components/input/InputForm';
import styles from './styles.module.scss';
import { ReactComponent as Close } from "assets/icons/close.svg";
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useOrderStatusValidate } from 'modules/header/hooks/useOrderStatusValidate';
import Button from 'global/components/button/Button';
import { useMutation } from '@tanstack/react-query';
import { postData } from 'global/scripts/dataFetching';
import { IOrderStatusResponce } from 'modules/header/types/OrderStatus';
import { useState, useContext } from 'react';
import { useCost } from 'global/hooks/useCost';
import { useAppSelector } from 'global/hooks/reduxHooks';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';

interface IOrderStatus {
  setShowOrderStatusModal: (e: boolean) => void,
}

export function OrderStatus({ setShowOrderStatusModal }: IOrderStatus) {
  const { channel } = useContext(ChannelContext);
  const validate = useOrderStatusValidate();
  const [orderData, setOrderData] = useState<IOrderStatusResponce | null>(null);
  const token = useAppSelector(state => state.userToken);
  const costFn = function (this: any, str: number) {
    return useCost.apply(this, [str]);
  }

  const formik = useFormik({
    initialValues: {
      phone: token?.token ? token.phone : '',
      orderId: '',
      isValid: false
    },
    validate,
    onSubmit: values => {
      console.log(values);
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (data: { phone: string, number: string }) => postData<{ data: IOrderStatusResponce }>({
      url: `order/status`,
      data: data,
      onSuccess(e) {
        setOrderData(e.data.data)
      },
      onError(e: any) {
        formik.errors.orderId = e.response?.data?.message || '';
      },
      channel
    })
  });

  function send(e: React.FormEvent<HTMLFormElement>) {
    formik.handleSubmit(e);
    if (formik.errors.isValid) {
      mutate({
        phone: formik.values.phone.replace(/[\s,\(\)\-]/ig, ''),
        number: formik.values.orderId
      });
    }
  }

  if (orderData !== null) {
    return (
      <div className={styles.orderStatus}>
        <div className={styles.orderStatus__head}>
          <h2 className={styles.orderStatus__title}>
            {t('Header.Order status')}
          </h2>
          <button
            className={styles.closeBtn}
            onClick={() => setShowOrderStatusModal(false)}
          >
            <Close />
          </button>
        </div>
        <span className={styles.orderStatus__desc}>
          {t("Global.Order status date", {
            orderId: orderData.id,
            deliveryDate: (new Date(orderData.status.updated_at)).toLocaleString('default', {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            })
          })}
        </span>
        <div className={styles.success}>
          <div className={styles.success__item + " " + styles.success__bold}>
            <div className={styles.success__title}>
              <div className={styles.success__round}
                style={{
                  backgroundColor: `${orderData.status.color}`
                }}
              ></div>
            </div>
            <div className={styles.success__status}>
              {orderData.status.name}
            </div>
          </div>
          <div className={styles.success__item}>
            <div className={styles.success__title}>Состав:</div>
            <div className={styles.success__status}>
              <div className={styles.success__count}>
                {t("Global.Order items count", {
                  count: orderData.items.reduce((acc, item) => acc += item.quantity, 0),
                })}
              </div>
              {orderData.items.map(item => (
                <div>{item.name}</div>
              ))}
            </div>
          </div>
          <div className={styles.success__item}>
            <div className={styles.success__title}>Доставка:</div>
            <div className={styles.success__status}>
              {orderData.delivery + ". " + orderData.branch}
            </div>
          </div>
          <div className={styles.success__item}>
            <div className={styles.success__title}>Сумма:</div>
            <div className={styles.success__status}>
              {costFn(orderData.wholePurchasePrice)}
            </div>
          </div>
        </div>
        <Button
          className={styles.orangeBtn}
          onClick={() => setShowOrderStatusModal(false)}
        >
          Ok
        </Button>
      </div>
    )
  }

  return (
    <form
      onSubmit={send}
      className={styles.orderStatus}
    >
      <div className={styles.orderStatus__head}>
        <h2 className={styles.orderStatus__title}>
          {t('Header.Order status')}
        </h2>
        <button
          className={styles.closeBtn}
          onClick={() => setShowOrderStatusModal(false)}
        >
          <Close />
        </button>
      </div>
      <span className={styles.orderStatus__desc}>
        {t("Global.Order status description")}
      </span>
      <div className={styles.orderStatus__content}>
        <div>
          <InputForm
            type="text"
            label={t('Thanks.Order number')}
            name="orderId"
            value={formik.values.orderId}
            onChange={formik.handleChange}
            className={formik.errors.orderId && formik.touched.orderId ? styles.errorInput : ''}
          />
          {formik.errors.orderId && formik.touched.orderId
            ? <div className={styles.errorText}>{formik.errors.orderId}</div>
            : null
          }
        </div>
        <div>
          <InputForm
            type="tel"
            label={t('Global.Telephone')}
            mask='+7 999 999 99 99'
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            className={formik.errors.phone && formik.touched.phone ? styles.errorInput : ''}
          />
          {formik.errors.phone && formik.touched.phone
            ? <div className={styles.errorText}>{formik.errors.phone}</div>
            : null
          }
        </div>
        <Button
          className={styles.orangeBtn}
        >
          {t('Header.Check the order')}
        </Button>
      </div>
    </form>
  )
}
