import { useState, useEffect, Suspense } from "react";
import SocialWidget from "global/components/socialWidget/SocialWidget";
import AppHeader from "modules/header/AppHeader";
import LoadingScreen from "modules/loading/LoadingScreen";
import { Outlet, useLocation } from "react-router-dom";
import AppFooter from "modules/footer/AppFooter";
import { Container } from "react-bootstrap";
import { setCurrentLanguage, getCurrentLanguage } from "global/hooks/language/CurrentLanguage";
import { useMediaQuery } from "react-responsive";
import { breakpointDF } from "global/hooks/constants";
import MobileNavbar from "modules/mobile-navbar/MobileNavbar";
import SearchInput from "modules/header/components/search-input/SearchInput";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as IconSuccess } from "assets/global/success.svg";
import { ReactComponent as IconWarning } from "assets/modal/warning.svg";
import Seo from "global/seo/Seo";

export default function Layout() {
  const [lang, setLang] = useState(getCurrentLanguage());
  const { pathname } = useLocation();
  const isTablet = useMediaQuery(breakpointDF);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setCurrentLanguage({ lang: lang || "ru" });
  }, [lang]);

  return (
    <>
      <Seo />
      {
        !pathname.includes("webview") &&
        <AppHeader setLang={setLang} />
      }
      <div className="bg-upper-header body-padding">
        <Suspense fallback={<LoadingScreen />}>
          <Container>
            <Outlet />
          </Container>
        </Suspense>
      </div>
      {
        isTablet && !pathname.includes("webview") &&
        <MobileNavbar />
      }
      {
        !pathname.includes("webview") &&
        <SocialWidget />
      }
      {
        !pathname.includes("webview") &&
        <AppFooter />
      }
    </>
  );
}
