import { useContext, useEffect, useState } from 'react';
import { t } from 'i18next';
import styles from '../styles.module.scss';
import { ProductPageContext } from 'modules/product-page/services';
import { Card, Placeholder } from 'react-bootstrap';
import { useBankCoefficientCount } from 'modules/product-page/hooks/useBankCoefficientCount';
import { useCost } from 'global/hooks/useCost';
import { ReactComponent as Discount } from 'assets/icons/discount.svg';
import { ReactComponent as HalykMarket } from "assets/icons/halyk-market.svg";
import { ReactComponent as CurveRight } from "assets/icons/curve-right.svg";
import { Helmet } from 'react-helmet';
import CityContext from 'global/hooks/city/CityContext';
import KaspiCities from 'assets/kaspi_city_map.json';
import { useMediaQuery } from 'react-responsive';
import { breakpointMD } from 'global/hooks/constants';
import { ReactComponent as CreditIcon } from 'assets/icons/credit-icon-white.svg';
import { IProductPage } from 'modules/product-page/types/ProductPage';
import { useAppSelector } from 'global/hooks/reduxHooks';
import { useNavigate } from 'react-router';
import { ROUTE_CART } from 'router/routes';
import { addToCart } from 'global/scripts/addToCart';
import getUrl from 'global/hooks/getUrl';
import { ChannelContext } from 'global/hooks/channel/ChannelContext';
import LanguageContext from 'global/hooks/language/LanguageContext';
import { useDispatch } from 'react-redux';

export function CreditAndInstallment({ webview }: { webview?: boolean }) {
  const { city } = useContext(CityContext);
  const { channel }  = useContext(ChannelContext);
  const lang  = useContext(LanguageContext);
  const isMobile = useMediaQuery(breakpointMD);
  const navigate = useNavigate();
  const installmentTerm = process.env.REACT_APP_INSTALLMENT_TERM || '12'
  const installmentTermHalyk = '24'
  const product = useContext(ProductPageContext);
  const bankCoefficientCount = useBankCoefficientCount();
  const bankCount = useCost(bankCoefficientCount((product.bank_coefficient || '0'), 60, (product.cost || 0)));
  const installmentPrice = useCost(bankCoefficientCount((product.bank_coefficient || '0'), parseInt(installmentTerm), (product.cost || 0)));
  const installmentPriceHalyk = useCost(bankCoefficientCount((product.bank_coefficient || '0'), parseInt(installmentTermHalyk), (product.cost || 0)));
  const disabled = !product.availableForPurchase && !product.availableForPurchaseFromDc;
  const kaspiCities: Record<string, string> = KaspiCities;
  const [halykExist = false, setHalykExist] = useState([]);
  const [hideCreditInstallment, setHideCreditInstallment] = useState(true);
  const dispatch = useDispatch();
  const productInfo = useAppSelector(state => state.productInfo[channel]);
  const inCart = Array.isArray(productInfo) && productInfo.some(item => item.id === product.id) || false;

  useEffect(() => {
    if (!product.isLoad) {
      fetch(`https://eh.evrika.com/halyk/check-product/${product.vendor_code}/${city}`)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          setHalykExist(data.productExist);
        });
    }
  }, [product, city]);

  return <>
    <div className={styles.creditAndInstallment}>
      {hideCreditInstallment ? <>
        <div className={styles.creditAndInstallmentContent}>
          <span className={styles.creditAndInstallmentTitle}>{t("ProductPage.Installment plan")}</span>
          {product.isLoad ?
            <>
              <Placeholder as={Card.Text} animation="wave" className={styles.placeholder__Left}>
                <Placeholder xs={6} bg="secondary" className={styles.placeholder__Title} />
              </Placeholder>
            </>
            : <span className={styles.creditAndInstallmentInfo}>
              <span
                className={styles.creditAndInstallmentPrice}
              >
                {installmentPrice} ₸
              </span>
              {t("ProductPage.credit count", { month: installmentTerm })}
            </span>
          }
        </div>
        <div className={styles.creditAndInstallmentContent}>
          <span className={styles.creditAndInstallmentTitle}>{t("Global.Credit")}</span>
          {product.isLoad ?
            <>
              <Placeholder as={Card.Text} animation="wave" className={styles.placeholder__Left}>
                <Placeholder xs={6} bg="secondary" className={styles.placeholder__Title} />
              </Placeholder>
            </>
            : <span className={styles.creditAndInstallmentInfo}>
              <span
                className={styles.creditAndInstallmentPrice}
              >
                {bankCount} ₸
              </span>
              {t("ProductPage.credit count", { month: 60 })}
            </span>
          }
        </div>
      </> : <></>
      }

      {
        !webview &&
        <>
          {!product.isLoad ? <>
            {!disabled ?
              <>
                {hideCreditInstallment ? <>
                  <button
                    className={"button " + styles.orangeBtn}
                    onClick={() => {
                      hideCreditInstallment ? setHideCreditInstallment(false) : setHideCreditInstallment(true);
                    }}
                  >
                    <Discount />
                    {t("ProductPage.Buy with credit")}
                  </button>
                </> : <></>
                }
                <div className={styles.calculateCreditWrap + ' ' + (hideCreditInstallment ? styles.hide : '')}>
                  <Helmet>
                    <script>
                      {`(function(d, s, id) {
                    var js, kjs;
                    if (d.getElementById(id)) return;
                    js = d.createElement(s);js.id = id;
                    js.src = "https://kaspi.kz/kaspibutton/widget/ks-wi_ext.js";
                    kjs = document.getElementsByTagName(s)[0]
                    kjs.parentNode.insertBefore(js, kjs); } (document, "script", "KS-Widget"));`}
                    </script>
                  </Helmet>
                  <a href="#" className={"button partners-installment " + styles.partnersBtn} onClick={() => {
                    if (!inCart) { addToCart(product as IProductPage, productInfo, dispatch) }
                    navigate(getUrl({currentUrl:ROUTE_CART, lang, channelToSet:channel}))
                  }}>
                    <CreditIcon />
                    <div className={styles.partnersTxtAndLogo}>
                      <span>В рассрочку от партнеров</span>
                      <div>{installmentPrice} <span> {t("ProductPage.credit count", { month: installmentTerm })}</span></div>
                    </div>
                    <CurveRight className={styles.curveRight} />
                  </a>
                  {halykExist ?
                    <a
                      target='_blank' rel="noopener noreferrer"
                      href={isMobile
                        ? 'https://homebank.kz/partners/halykmarket?url=https://m.halykmarket.kz?fromMerchant=true&skuCode=' + product.vendor_code + '&merchantName=Evrika&utm_source=evrika_website&utm_medium=referral&utm_campaign=hm_evrika'
                        : 'https://halykmarket.kz/?fromMerchant=true&skuCode=' + product.vendor_code + '&merchantName=Evrika&utm_source=evrika_website&utm_medium=referral&utm_campaign=hm_evrika'
                      }
                      className={"button halyk-installment " + styles.halykBtn}>
                      <HalykMarket />
                      <div className={styles.partnersTxtAndLogo}>
                        <span>В рассрочку</span>
                        <div>{installmentPriceHalyk} <span> {t("ProductPage.credit count", { month: installmentTermHalyk })}</span></div>
                      </div>
                      <CurveRight className={styles.curveRight} />
                    </a>
                    :
                    <></>
                  }
                  <div className={'ks-widget kaspi-installment ' + styles.kaspiWidget}
                    data-template="flatButton"
                    data-merchant-sku={product.vendor_code}
                    data-merchant-code="Evrika"
                    data-city={kaspiCities[city]}
                    data-style="mobile"
                  ></div>
                </div>
              </>
              :
              <></>
            }</>
            : <>
              <Placeholder as={Card.Text} animation="wave">
                <Placeholder xs={12} bg="secondary" className={styles.placeholder__Btn} />
              </Placeholder>
            </>
          }
        </>
      }
    </div>
  </>
}
