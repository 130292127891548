import { useContext, useEffect, useState } from "react";
import styles from "./styles.module.scss";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTE_HOME, ROUTE_SEARCH } from "router/routes";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { t } from "i18next";
import getUrl from "global/hooks/getUrl";
import LanguageContext from "global/hooks/language/LanguageContext";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import CityContext from "global/hooks/city/CityContext";
import { breakpointDF } from "global/hooks/constants";
import { useMediaQuery } from "react-responsive";

interface ISearchInput {
  className?: string,
  onClose?: (isOpen: false) => void,
  inputRef?: React.RefObject<HTMLInputElement>
}

export default function SearchInput({ className, onClose, inputRef }: ISearchInput) {
  const lang = useContext(LanguageContext);
  const { channel } = useContext(ChannelContext);
  const { city, citySlug } = useContext(CityContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get("search") || ""
  );
  const navigate = useNavigate();
  const isTablet = useMediaQuery(breakpointDF);
  const { pathname } = useLocation();

  const updateCatalogLinks = () => {
    document.querySelectorAll("a.rees46-search-product").forEach((anchor) => {
      const a = anchor as HTMLAnchorElement;
      try {
        // Получаем только относительный путь без доменного имени
        const urlObj = new URL(a.href, window.location.origin);
        const relativePath = urlObj.pathname + urlObj.search;

        // Применяем getUrl к относительному пути
        let newPath = getUrl({
          currentUrl: relativePath,
          lang,
          channelToSet: channel,
        });

        // Добавляем citySlug после /catalog/, если он был в исходном URL
        if (city != '1') {
          newPath = newPath.replace("/catalog/", `/catalog/${citySlug}/`);
        }

        if (newPath !== relativePath) {
          console.log(`Заменяем URL: ${relativePath} → ${newPath}`);
          a.href = newPath;
        }
        
      } catch (error) {
        console.error("Ошибка обработки URL:", a.href);
      }
    });
  };

  const observeDomChanges = () => {
    const observer = new MutationObserver(() => {
      updateCatalogLinks();
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return observer;
  };

  useEffect(() => {
    updateCatalogLinks(); // Обновляем ссылки сразу при загрузке
    const observer = observeDomChanges(); // Запускаем слежение за DOM

    return () => {
      observer.disconnect(); // Отключаем observer при размонтировании
    };
  }, [channel,city,lang]);

  function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    onClose && onClose(false);
    if (searchValue.length > 0) {
      setSearchParams({ search: searchValue });
      // navigate('https://evrika.com/search?search=' + searchValue + '&r46_search_query=' + searchValue + '&r46_input_query=' + searchValue);
      return navigate({
        pathname: getUrl({
          currentUrl: ROUTE_SEARCH,
          lang,
          channelToSet: channel,
        }),
        search: createSearchParams({
          search: searchValue,
          r46_search_query: searchValue,
          r46_input_query: searchValue,
        }).toString(),
      });
    } else {
      setSearchParams({});
      return navigate({
        pathname: getUrl({
          currentUrl: ROUTE_HOME,
          lang,
          channelToSet: channel,
        }),
      });
    }
  }

  function closeModal() {
    const searchOverlay = document.querySelector('.rees46-search-overlay');
    if (searchOverlay instanceof HTMLElement) {
        searchOverlay.click();
    }
  }

  useEffect(() => {
    if (!pathname.includes("search")) {
      setSearchValue("");
    }
  }, [pathname]);

  return (
    <form className={styles.searchContainer + ` ${pathname.includes("catalog") ? styles.searchContainer__wrapper : ''}` + ` ${className}`} onSubmit={submit}>
      <div className={styles.wrapper}>
        <input
          type="text"
          className={styles.input + ` rees46-instant-search `}
          name="search"
          ref={inputRef}
          placeholder={!isTablet ? t("Header.Search among 10,000 products") : ""}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <button
          type="submit"
          className={styles.button}
          aria-label={t("Global.Search")}
        >
          <SearchIcon />
        </button>
        {
          isTablet && searchValue?.length > 0 &&
          <CloseIcon
            className={styles.close__icon}
            onClick={() => {
              setSearchValue(""); 
              if (inputRef?.current) {
                inputRef.current.focus();
              }
            }}
          />
        }
      </div>
      {
        isTablet &&
        <div
          onClick={() => { onClose && onClose(false); closeModal() }}
          className={styles.close__btn}
        >
          Отменить
        </div>
      }
    </form>
  );
}
