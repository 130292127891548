import { Link } from "react-router-dom";
import styles from "./styles.module.scss";
import i18next, { t } from "i18next";
import { ROUTE_PRIVACY_POLICY, ROUTE_SITEMAP } from "router/routes";

export default function LowerFooterMobile() {
  const lang = i18next.language;
  const slugLang = lang === "kk" ? "/kk" : '';

  return (
    <div className={styles.footerBottom}>
      <div>
        <span className={styles.text}>
          <Link className={styles.link + ` ${styles.hover}`} to={ROUTE_PRIVACY_POLICY} rel="nofollow">{t("Footer.Privacy policy")}</Link>
        </span>
        <span className={styles.text}>
          <Link className={styles.link + ` ${styles.hover}`} to={ROUTE_SITEMAP}>{t("Footer.Site map")}</Link>
        </span>
      </div>
      <div>
        <span className={styles.link}>{t("Footer.Copyright © 2013 - 2024 Evrika Company LLP", { year: new Date().getFullYear() })}</span>
      </div>
    </div>
  )
}
