import Rating from 'global/components/rating/Rating';
import styles from './styles.module.scss';
import { useContext, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { breakpointMD } from 'global/hooks/constants';
import { t } from "i18next";
import { useQueryBuilder } from 'global/scripts/useQueryBuilder';
import { IProductPageContext, ProductPageContext } from 'modules/product-page/services';
import { IMainReviews } from 'modules/product-page/types/Reviews';
import { Review } from './Review';
import { ModalBox } from 'global/components/modal/ModalBox';
import { FeedbackReview } from './FeedbackReview';

export default function ReviewsTab({ webview }: { webview?: boolean }) {
  const [reviewsModal, setReviewsModal] = useState(false);
  const isModile = useMediaQuery(breakpointMD);
  const product = useContext(ProductPageContext) as Required<IProductPageContext>;

  const { data, refetch } = useQueryBuilder<{ data: IMainReviews }>({
    url: `products/reviews/${product.id}`,
    cacheTime: 10000,
    staleTime: 10000,
  });

  return (
    <>
      <div className={styles.reviews}>
        {!isModile && (
          <p className={styles.reviews_title}>{t("ProductPage.Reviews")}</p>
        )}
        <div className={styles.reviews_rating}>
          <div className={styles.reviews_starRating}>
            {(Math.round(product.rating)) || 0} из 5
            <Rating
              name="tab-rating"
              onChange={() => null}
              checked={(Math.round(product.rating)) || 0}
              styleClass={styles.reviews_stars}
              disabled
            />
          </div>
          {
            !webview &&
            <>
              {!isModile && (
                <button
                  className={'button ' + styles.reviews_btn}
                  onClick={() => setReviewsModal(true)}
                >
                  {t("ProductPage.Write a review")}
                </button>
              )}
            </>
          }
        </div>
        {(data && data.data?.reviews.root_reviews.length > 0) &&
          <div className={styles.messages}>
            {data.data.reviews.root_reviews.map(review => (
              <Review
                key={review.id}
                {...review}
                data={data?.data}
                refetch={refetch}
              />
            ))}
          </div>
        }

        <ModalBox
          isOpen={reviewsModal}
          onClose={() => null}
        >
          <FeedbackReview
            closeModal={setReviewsModal}
          />
        </ModalBox>

        {
          !webview &&
          <>
            {isModile && (
              <button
                className={'button ' + styles.reviews_btn}
                onClick={() => setReviewsModal(true)}
              >
                {t("ProductPage.Write a review")}
              </button>
            )}
          </>
        }
      </div>
    </>
  )
}
