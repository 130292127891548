import React, { useContext, useState, SetStateAction, useEffect, useRef } from "react";
import { t } from "i18next";
import i18next from "i18next";
import getUrl from "global/hooks/getUrl";
import CityContext from "global/hooks/city/CityContext";
import { ReactComponent as LocationSvg } from "assets/header/location.svg";
import { ReactComponent as MainHeader } from "assets/icons/mobile-header.svg";
import { ReactComponent as Hamburger } from "assets/icons/hamburger.svg";
import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import { ReactComponent as HeartIcon } from "assets/icons/mob-menu/heart.svg";
import { ReactComponent as CompareIcon } from "assets/icons/mob-menu/compare.svg";
import { ReactComponent as SaleIcon } from "assets/icons/sale-icon.svg";
import { ReactComponent as ShopIcon } from "assets/icons/shop-icon.svg";
import { ReactComponent as ReturnIcon } from "assets/icons/credit-icon.svg";
import { ReactComponent as OrderIcon } from "assets/icons/order-icon.svg";
import { ReactComponent as BonusIcon } from "assets/icons/bonus-icon.svg";
import { ReactComponent as SearchIcon } from 'assets/icons/search.svg';
import { ROUTE_BONUSES, ROUTE_CABINET_BONUSES, ROUTE_CABINET_FAVORITES, ROUTE_CREDIT_AND_INSTALLMENT, ROUTE_HOME, ROUTE_SALES, ROUTE_SHOPS_PAGE } from "router/routes";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { ModalBox } from "global/components/modal/ModalBox";
import { useQueryBuilder } from "global/scripts/useQueryBuilder";
import { ICities } from "global/types/Cities";
import { useAppSelector } from "global/hooks/reduxHooks";
import { OrderStatus } from "../lower-header/directive/order-status/OrderStatus";
import { Card, Modal, Placeholder } from "react-bootstrap";
import { ReactComponent as ArrowSvg } from 'assets/cart/arrow-left.svg';
import CityModal from "../modal-city/CityModal";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ReactComponent as IconSuccess } from "assets/global/success.svg";
import { ReactComponent as IconWarning } from "assets/modal/warning.svg";
import { ChannelContext } from "global/hooks/channel/ChannelContext";
import SearchInput from "../search-input/SearchInput";
import styles from "./styles.module.scss";
import LanguageContext from "global/hooks/language/LanguageContext";

export interface IMobileHeader {
  setLang: React.Dispatch<SetStateAction<string>>;
}

export default function MobileHeader({ setLang }: IMobileHeader) {
  const lang = useContext(LanguageContext)
  const location = useLocation();
  const cacheTime = 24; // Hours
  const navigate = useNavigate();
  const { channel } = useContext(ChannelContext);
  const { city, setCity } = useContext(CityContext);
  let { id, cityslug } = useParams();
  const ref = useRef<HTMLDivElement>(null);

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // * State
  const [menu, setMenu] = useState(false);
  const [activeBtn, setActiveBtn] = useState(false);
  const [showOrderStatusModal, setShowOrderStatusModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);

  // * Redux
  const compareInfo = useAppSelector(state => state.compareInfo[channel]);
  const userToken = useAppSelector(state => state.userToken);

  // * Request
  const { data, isLoading, isFetching, isError } = useQueryBuilder<{ data: ICities[] }>({
    url: `cities`,
    cacheTime: cacheTime * 60 * 60 * 1000,
    staleTime: cacheTime * 60 * 60 * 1000,
  });

  function currentLangueage(pickedLang: string) {
    if (pickedLang === lang) {
      return null;
    }
    setLang(lang);
    navigate(getUrl({ lang: pickedLang, currentUrl: location.pathname, channelToSet:channel }));
  }

  const getPreviousPath = (currentPath: string): string => {
    const pathMap: Record<string, string> = {
      '/checkout/cart': '/',
      '/checkout/contact-info': '/checkout/cart',
      '/checkout/delivery': '/checkout/contact-info',
      '/checkout/payment': '/checkout/delivery',
    };

    return pathMap[currentPath] || '/';
  };

  function openModal() {
    const searchOverlay = document.querySelector('.rees46-instant-search');
    if (searchOverlay instanceof HTMLElement) {
      searchOverlay.click();
    }
  }

  useEffect(() => {
    const applyStyles = (selector: string, styles: Partial<CSSStyleDeclaration>) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        Object.assign((element as HTMLElement).style, styles);
      });
    };

    const observer = new MutationObserver(() => {
      applyStyles('.rees46-search-box', {
        width: '100%',
        left: '0px',
        top: '55px',
      });
      applyStyles('.rees46-search-wrapper', {
        border: 'none',
      });

      const closeElement = document.querySelector('.rees46-search-close');
      if (closeElement) {
        closeElement.remove();
      }

      const searchBox = document.querySelector('.rees46-search-box');
      const mobileSearch = document.querySelector('.mobile__search');

      if (searchBox && mobileSearch && !mobileSearch.contains(searchBox)) {
        mobileSearch.appendChild(searchBox);
      }
    });

    observer.observe(document.body, { childList: true, subtree: true });

    return () => observer.disconnect();
  }, []);

  const isHomePage = location.pathname === getUrl({ lang:lang, currentUrl: ROUTE_HOME, channelToSet:channel });

  const link: string = getPreviousPath(location.pathname);

  document.addEventListener('scroll', (e) => {
    if ((ref.current?.getClientRects()[0].top ?? 0) < -10) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  });

  return (
    <>
      <header className={styles.wrapper + ` ${activeBtn ? styles.border_btm : ""} ${openSearch ? styles.wrapper__header : ""} `} >
        {
          location.pathname.includes("checkout")
            ? <Link to={link}>
              <ArrowSvg className={styles.arrow} />
            </Link>
            : <div>
              <div
                className={styles.locationWrapper}
                onClick={() => setModalOpen(true)}
              >
                <LocationSvg className={styles.locationIcon} />
                {
                  (isLoading || isFetching)
                    ? <Placeholder as={Card.Title} animation="wave" className={styles.title_load}>
                      <Placeholder xs={12} bg="secondary" />
                    </Placeholder>
                    : <span className={styles.title}>{data?.data?.find(item => item.id === +city)?.name || ''}</span>
                }
              </div>
              <CityModal
                data={data}
                isFetching={isFetching}
                isLoading={isLoading}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
              />
            </div>
        }

        <span className={styles.icon}>

          <Link to={getUrl({ lang: lang, currentUrl: ROUTE_HOME, channelToSet:channel })}>
            <MainHeader />
          </Link>
        </span>
        <div>
          {
            (activeBtn || !(isHomePage || id?.startsWith('c'))) &&
            <SearchIcon className={styles.search_icon} onClick={() => { setOpenSearch(true); openModal() }} />
          }
          <button className={styles.button} onClick={() => setMenu(true)} aria-label={t('Global.Open menu')}>
            <Hamburger />
          </button>
        </div>
      </header>
      {
        (isHomePage || id?.startsWith('c')) &&
        <div
          ref={ref}
          className={styles.search__input}
          onClick={() => { setOpenSearch(true); openModal(); handleClick() }}
        >
          <label
            className={styles.search__text}
          >
            {t("Header.Search among 10,000 products")}
          </label>
          <SearchIcon />
        </div>
      }

      <div>
        <div className={openSearch ? styles.modal__wrapper : styles.hidden + ` mobile__search`}>
          <SearchInput
            inputRef={inputRef}
            className={styles.search__wrapper}
            onClose={setOpenSearch}
          />
        </div>
      </div>

      <div className={styles.navbarFade + ` ${menu && styles.active}`}></div>
      <div className={styles.navbarOverlay + ` ${menu && styles.active}`}>
        <div className={styles.langSwitcherWrap}>
          <div className={styles.langSwitcher}>
            <button
              className={lang === "ru" ? styles.langSwitcherActive : ""}
              onClick={() => { currentLangueage("ru"); setMenu(false); }}
            >
              Рус
            </button>
            <button
              className={lang === "kk" ? styles.langSwitcherActive : ""}
              onClick={() => { currentLangueage("kk"); setMenu(false); }}
            >
              Қаз
            </button>
          </div>
          <div><CloseIcon className={styles.closeIcon} onClick={() => setMenu(false)} /></div>
        </div>

        <div className={styles.block}>
          <div className={styles.menuWrapper}>
            <Link to={getUrl({ lang, currentUrl: `/${ROUTE_CABINET_FAVORITES}`, channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
              <HeartIcon className={styles.menuIcon} />
              <span className={styles.subTitle}>{t("Header.Favorites")}</span>
            </Link>
          </div>

          <div className={styles.menuWrapper}>
            <Link to={compareInfo && compareInfo.length > 0 ? getUrl({ lang, currentUrl: `/comparison/${compareInfo[0]?.category_id}`, channelToSet:channel}) : getUrl({ lang, currentUrl: '/comparison', channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
              <CompareIcon className={styles.menuIcon} />
              <span className={styles.subTitle}>{t("Header.Comparison")}</span>
            </Link>
          </div>
        </div>

        <div className={styles.divider}></div>

        <div className={styles.menuWrapper}>
          <NavLink to={getUrl({ lang, currentUrl: `/${ROUTE_SALES}`, channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
            <SaleIcon className={styles.menuIcon} />
            <span className={styles.subTitle}>{t("Header.Stocks")}</span>
          </NavLink>
        </div>

        <div className={styles.menuWrapper}>
          <NavLink to={getUrl({ lang, currentUrl: `/${ROUTE_SHOPS_PAGE}`, channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
            <ShopIcon className={styles.menuIcon} />
            <span className={styles.subTitle}>{t("Global.Shops")}</span>
          </NavLink>
        </div>

        <div className={styles.menuWrapper}>
          <NavLink to={getUrl({ lang, currentUrl: `/${ROUTE_CREDIT_AND_INSTALLMENT}`, channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
            <ReturnIcon className={styles.menuIcon} />
            <span className={styles.subTitle}>{t('Header.Credit and installments')}</span>
          </NavLink>
        </div>

        <div className={styles.menuWrapper}>
          <div className={styles.menuItems} onClick={() => { setShowOrderStatusModal(true); setMenu(false) }}>
            <OrderIcon className={styles.menuIcon} />
            <span className={styles.subTitle}>{t("Header.Order status")}</span>
          </div>
        </div>

        <div className={styles.menuWrapper}>
          <NavLink to={getUrl({ lang, currentUrl: `/${userToken ? ROUTE_CABINET_BONUSES : ROUTE_BONUSES}`, channelToSet:channel })} className={styles.menuItems} onClick={() => setMenu(false)}>
            <BonusIcon className={styles.menuIcon} />
            <span className={styles.subTitle}>Smart Bonus</span>
          </NavLink>
        </div>
      </div>
      <ModalBox
        isOpen={showOrderStatusModal}
        onClose={() => null}
      >
        <OrderStatus
          setShowOrderStatusModal={setShowOrderStatusModal}
        />
      </ModalBox>

      <ToastContainer
        draggable
        autoClose={3000}
        newestOnTop={false}
        toastStyle={{ top: "60px" }}
        icon={({ type }) => {
          if (type === "success") return <IconSuccess />;
          if (type === "error") return <IconWarning />;
          else return "ℹ️";
        }}
        onClick={() => toast.dismiss()}
      />
    </>
  );
}
