import { tabType } from "modules/product-page/types/TabTypes";
import styles from "./styles.module.scss";

interface ITab {
  id?: string,
  isHidden?: boolean,
  title: string,
  value: tabType,
  isActive?: boolean,
  onClick: (value: tabType) => void,
}

export default function Tab({ id, isHidden, title, isActive, value, onClick }: ITab) {
  return (
    <div
      id={id}
      style={isHidden ? { display: "none" } : { display: "flex" }}
      className={styles.tab + ` ${isActive && styles.active}`}
      onClick={() => onClick(value)}
    >
      {title}
    </div>
  )
}
