import { IUserInfo } from "../actions/userInfoActions";

export interface IUserInfoAction {
  type: "SET_USER" | "CLEAR_USER",
  user: IUserInfo
}

const userInfoReducer = (state: IUserInfo | null = null, action: IUserInfoAction) => {
  switch (action.type) {
    case "SET_USER":
      return action.user;

    case "CLEAR_USER":
      return null;

    default:
      return state;
  }
}

export default userInfoReducer; 
