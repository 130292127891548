import { CHANNELS } from "global/hooks/channel/ChannelTypes";
import { IProductInfo } from "../actions/productInfoActions";
import { createChannelState } from "../utils/createChannelState";

export interface IProductInfoAction {
  type: "SET_PRODUCT" | "CLEAR_PRODUCT" | "REMOVE_PRODUCT",
  product: IProductInfo[],
  channel?: string; // Added by middleware
}

// Dynamically generate the initial state for all channels
const initialState = createChannelState<IProductInfo[] | null>(null);

const productInfoReducer = (
  state = initialState,
  action: IProductInfoAction
) => {
  const { channel } = action;

  // If no channel is specified or the channel is invalid, return the current state
  if (!channel || !(channel in state)) {
    return state;
  }

  // Handle actions for the specific channel
  switch (action.type) {
    case "SET_PRODUCT":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.product, // Update only the specified channel's state
      };

    case "REMOVE_PRODUCT":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: action.product, // Replace the product list for the channel
      };

    case "CLEAR_PRODUCT":
      return {
        ...state, // Shallow copy of the entire state
        [channel]: null, // Clear the product list for the channel
      };

    default:
      return state; // No changes for unrecognized actions
  }
};

export default productInfoReducer;
