const YOUTUBE_EMBED_SRC = '//www.youtube.com/embed/%id?autoplay=1';
// export const YOUTUBE_IFRAME_MARKUP = '<iframe src="%s" allowfullscreen></iframe>';

function getYoutubeId(str: string) {
	str = str.replace(/#t=.*$/, '');

	const shortCode = /youtube:\/\/|https?:\/\/youtu\.be\/|http:\/\/y2u\.be\//g;

	if (shortCode.test(str)) {
		const shortCodeId = str.split(shortCode)[1];
		return stripParameters(shortCodeId);
	}

	const inlineV = /\/v\/|\/vi\//g;

	if (inlineV.test(str)) {
		const inlineId = str.split(inlineV)[1];
		return stripParameters(inlineId);
	}

	const parameterV = /v=|vi=/g;

	if (parameterV.test(str)) {
		const arr = str.split(parameterV);
		return stripParameters(arr[1].split('&')[0]);
	}
}

function stripParameters(str: string) {
	if (str.indexOf('?') > -1) {
		return str.split('?')[0];
	} else if (str.indexOf('/') > -1) {
		return str.split('/')[0];
	} else if (str.indexOf('&') > -1) {
		return str.split('&')[0];
	}

	return str;
}

export const getYoutubeSrc = (src: string) => {
	const id = getYoutubeId(src);

	if (id) {
		return YOUTUBE_EMBED_SRC.replace('%id', id);
	}

	return '';
};

// /**
//  * @param {HTMLElement} target
//  */
// export const appendYoutubeIframe = (target) => {
// 	const src = getYoutubeSrc(target.dataset.src);

// 	if (src) {
// 		target.innerHTML = YOUTUBE_IFRAME_MARKUP.replace('%s', src);
// 	}
// };
