import { IUserData } from "modules/cart/types/sendData"

export interface IUserInfo extends IUserData {
  selectTab?: "login" | "register",
  useBonuses?: boolean,
  bonus?: number,
  maxBonus?: number,
  bonusUsageRestricted?: boolean,
  smsId?: number,
  bonusCard?: string,
  usePromocode?: boolean,
  promocodeName?: string
}

export const setUserInfo = ({ email = '', lastName = '', firstName = '', phone = '', selectTab = "login", userId = null, registerMe = false, useBonuses = false, bonus = 0, maxBonus = 0, smsId = 0, bonusCard = "", promocodeName = "", usePromocode = false, bonusUsageRestricted = false }: IUserInfo) => {
  // console.log("🚀 ~ file: .js:3 ~ setUserInfo ~ user:", user)

  return {
    type: "SET_USER",
    user: { firstName, email, lastName, phone, selectTab, userId, registerMe, useBonuses, bonus, smsId, bonusCard, maxBonus, promocodeName, usePromocode, bonusUsageRestricted }
  }
}

export const clearUserInfo = () => ({
  type: "CLEAR_USER",
})
