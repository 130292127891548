export const HEADER = {
  ru: {
    language:`Рус`,
    buy: "Купить",
    sales: 'Акции',
    shops: 'Магазины',
    installmentsAndLoans: 'Рассрочка и кредиты',
    chat:"Чат с оператором",
    help:"Помощь и консультация"
  },
  kk: {
    language:`Каз`,
    buy: "Сатып алу",
    sales: 'Акциялар',
    shops: 'Дүкендер',
    installmentsAndLoans: 'Несие және бөліп төлеу',
    chat:"Чат с оператором",
    help:"Көмек пен консультация"
  },
};
