import { useContext, useState } from "react";
import styles from "../../../product-bar/styles.module.scss";
import { ProductPageContext } from "modules/product-page/services";
import { FiMail } from "react-icons/fi";
import { t } from "i18next";
import { ModalBox } from "global/components/modal/ModalBox";
import ProductSubscribeModalForm from "./ProductSubscribeModalForm";
import Button from "global/components/button/Button";

interface IBackInStoreModal {
  isCard?: boolean,
  id?: number
}

export function BackInStoreModal({ isCard, id }: IBackInStoreModal) {
  const product = useContext(ProductPageContext);
  const [showSubscribeModal, setShowSubscribeModal] = useState(false);

  function handleSubscribeModal() {
    setShowSubscribeModal(true);
  }

  return (
    <>
      {
        isCard
          ? <Button
            className={styles.basket}
            onClick={handleSubscribeModal}
          >
            Узнать <br /> о поступлении
          </Button>
          : <div className={styles.OutOfStockBlock}>
            <span>{t("Global.Out of stock")}</span>
            <button
              className={"button " + styles.orangeBtn}
              onClick={handleSubscribeModal}
            >
              <FiMail
                className="brand-neutrals-white"
                style={{ width: "22px", height: "22px" }}
              />
              {t("ProductPage.Remind me about availability")}
            </button>
          </div>
      }
      <ModalBox
        isOpen={showSubscribeModal}
        onClose={() => null}
      >
        <ProductSubscribeModalForm onClose={setShowSubscribeModal} productId={isCard ? id : product.id} />
      </ModalBox>
    </>
  )
}
